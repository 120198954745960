import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl, useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown as DeprecatedDropdown, SingleText} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';
import {ComboBox, Text} from '@forecast-it/design-system';
import {DesignSystemComboBoxOldLookAdapter} from '../../../forecast-app/shared/components/dropdowns/DesignSystemComboBoxOldLookAdapter';

export const changeRateCard = (projectId, rateCardId, rateCards, project) => {
	const onSuccess = () => {
		Util.localStorageDeleteFincancialMap();
	};
	const oldDisabledRoles = project.rateCard ? project.rateCard.disabledRoles : [];
	const newDisabledRoles = rateCards.find(rateCard => rateCard.node.id === rateCardId).node.disabledRoles;
	const disabledRolesChanged = JSON.stringify(oldDisabledRoles) !== JSON.stringify(newDisabledRoles);
	Util.CommitMutation(
		UpdateProjectMutation,
		{
			id: projectId,
			rateCardId,
			disabledRolesChanged,
		},
		onSuccess
	);
};

export const RateCardDropdown = ({
	rateCards,
	company,
	project,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	noRateCardOption,
	userpilot,
	emphasizeEmptyState,
	isNested,
	isMultiSelect,
	disabled,
	showDisabled,
	dataCy,
	listDataCy,
	...rest
}) => {
	const intl = useIntl();

	const useDeprecatedDropdown = isNested || isMultiSelect;

	// Deep copy rate cards
	let filteredRateCards = rateCards.map(rateCard => {
		return {
			...rateCard.node,
		};
	});

	// Filter adjusted rate cards
	filteredRateCards = filteredRateCards.filter(rateCard => !rateCard.parentRateCardId);

	// List of enabled rate cards
	const enabledRateCards = filteredRateCards.filter(rateCard => {
		return !rateCard.disabled;
	});

	const sortRateCard = rateCards => {
		return [...rateCards].sort((a, b) => {
			if (a.id === null) return -1;
			if (b.id === null) return 1;
			if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
			if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
			return 0;
		});
	};

	// Sort rate cards alphabetically
	let sortedRateCards = sortRateCard(enabledRateCards);

	// If we are showing disabled rate cards, sort them and add them to end of list
	if (showDisabled) {
		const disabledRateCards = filteredRateCards.filter(rateCard => rateCard.disabled);
		sortedRateCards = sortedRateCards.concat(sortRateCard(disabledRateCards));
	}

	// If project ratecard is not in the list of sortedRateCards, add it (even if it is disabled or filtered for other reasons)
	if (project && project.rateCard && !sortedRateCards.find(rateCard => project.rateCard.id === rateCard.id)) {
		const projectRateCard = filteredRateCards.find(rateCard => project.rateCard.id === rateCard.id);
		if (projectRateCard) {
			sortedRateCards.push(projectRateCard);
		}
	}

	const getElementsDeprecated = () => {
		let elements = [];
		if (noRateCardOption) {
			elements.push(
				<SingleText
					cy={listDataCy + '-option-' + intl.formatMessage({id: 'rate_card_selector.no_rate_card_text'})}
					key={null}
					value={null}
					searchString={intl.formatMessage({id: 'rate_card_selector.no_rate_card_text'})}
				>
					{intl.formatMessage({id: 'rate_card_selector.no_rate_card_text'})}
				</SingleText>
			);
		}
		return elements.concat(
			sortedRateCards.map(edge => {
				const {id, name, disabled} = edge;
				return (
					<SingleText cy={listDataCy + '-' + name} disabled={disabled} key={id} value={id} searchString={name}>
						{name}
					</SingleText>
				);
			})
		);
	};

	const getElements = () => {
		let elements = [];
		if (noRateCardOption) {
			elements.push({
				label: intl.formatMessage({id: 'rate_card_selector.no_rate_card_text'}),

				value: null,
			});
		}
		return elements.concat(
			sortedRateCards.map(edge => {
				const {id, name, disabled} = edge;
				return {
					label: name,
					value: id,
					disabled: disabled,
				};
			})
		);
	};
	return useDeprecatedDropdown ? (
		<DeprecatedDropdown
			{...rest}
			disabled={disabled}
			isNested={isNested}
			isMultiSelect={isMultiSelect}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={dataCy}
		>
			{isNested ? (
				<DeprecatedDropdown.Group name={optionsName} key={'dropdown-options'}>
					{getElementsDeprecated()}
				</DeprecatedDropdown.Group>
			) : (
				getElementsDeprecated()
			)}
		</DeprecatedDropdown>
	) : (
		<DesignSystemComboBoxOldLookAdapter>
			<ComboBox
				data-cy={dataCy || 'rate-card-dropdown'}
				defaultItems={getElements()}
				value={selectedItems[0] || null}
				onChange={onSelect}
				width={ComboBox.WIDTH.FLEX}
				size={'small'}
				menuTrigger="focus"
				disabled={disabled}
			>
				{option => (
					<ComboBox.Item key={option.value} textValue={option.label}>
						<Text data-cy={'option-' + option.label}>{option.label}</Text>
					</ComboBox.Item>
				)}
			</ComboBox>
		</DesignSystemComboBoxOldLookAdapter>
	);
};

RateCardDropdown.propTypes = {
	rateCards: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	disabled: PropTypes.bool,
	showDisabled: PropTypes.bool,
};

RateCardDropdown.defaultProps = {
	optionsName: 'Rate Cards',
	name: 'Rate Cards',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default injectIntl(
	//TODO should make fragment for project.rateCard and project.invoices
	createFragmentContainer(RateCardDropdown, {
		rateCards: graphql`
			fragment RateCardDropdown_rateCards on RateCardTypeEdge @relay(plural: true) {
				node {
					id
					name
					currency
					parentRateCardId
					disabled
				}
			}
		`,
	}),
	createFragmentContainer(RateCardDropdown, {
		company: graphql`
			fragment RateCardDropdown_company on Company {
				currency
			}
		`,
	})
);
