import React, { useMemo } from 'react';
import { Alert, FlexColumn, FlexRow, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import BulkDeleteExpenseItemsMutation from '../../../mutations/bulk_delete_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import { partitionArray } from '../../../forecast-app/shared/util/array-utils';
const DeleteExpensesModal = ({ selectedRows, companyId, closeModal, onSuccessfulDelete, }) => {
    const { formatMessage } = useIntl();
    const [selectedLockedRows, selectedUnlockedRows] = useMemo(() => partitionArray(selectedRows, item => item.locked), [selectedRows]);
    const numLockedExpenses = selectedLockedRows.length;
    const numTotalExpenses = selectedRows.length;
    const onDelete = () => {
        closeModal();
        const onSuccess = () => {
            onSuccessfulDelete();
            createToast({
                duration: 5000,
                message: formatMessage({
                    id: selectedUnlockedRows.length > 1
                        ? 'expense_item.has_been_deleted_bulk'
                        : 'expense_item.has_been_deleted',
                }),
            });
        };
        Util.CommitMutation(BulkDeleteExpenseItemsMutation, {
            ids: selectedUnlockedRows.map(({ id }) => id),
            companyId,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.modal.delete_expenses" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, null,
                React.createElement(FlexRow, null,
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: "expense_management.modal.are_you_sure_delete_expenses" }))),
                selectedLockedRows.length ? (selectedRows.length > 1 ? (React.createElement(FlexRow, null,
                    React.createElement(Alert, { text: formatMessage({ id: 'expense_management.modal.expenses_locked_cannot_be_deleted' }, { numLockedExpenses, numTotalExpenses }), type: Alert.TYPE.INFORMATION }))) : (React.createElement(FlexRow, null,
                    React.createElement(Alert, { text: formatMessage({ id: 'expense_management.modal.expense_locked_cannot_be_deleted' }), type: Alert.TYPE.INFORMATION })))) : null)),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: onDelete },
                React.createElement(FormattedMessage, { id: "expense_management.modal.delete_expenses" })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default DeleteExpensesModal;
