import React, {useMemo, useRef, useState} from 'react';
import Styled from 'styled-components';
import {ClickAwayListener} from '@material-ui/core';
import {CSS_CONSTANTS} from '../../../../css_variables';
import Util from '../../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import UpdateTaskHierarchyMutation from '../../../../mutations/UpdateTaskHierarchyMutation';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import HoursInput from '../../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import ActionsMenu from '../../../../forecast-app/shared/components/action-menu/actions_menu';
import DropdownV2 from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import {ForecastExpansionTile} from '../main-section/ForecastExpansionTile';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {PersonDropdown} from '../../../../forecast-app/shared/components/dropdowns/Person_dropdown';
import {MODULE_TYPES} from '../../../../constants';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {profilePicSrc} from '../../../../directApi';

const Column = Styled.div`
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-left: 16px;
	min-width:${props => props.minWidth};
	justify-content:${props => (props.rightAlign ? 'flex-end' : 'flex-start')};
`;

const ChildrenColumn = Styled(Column)`
	padding-right: ${props => props.withRightPadding && '16px'};
	font-size: 13px;
	height:40px;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
	display:flex;
	align-items:center;
	color:#535353;
	${props => props.onClick && 'cursor:pointer; &:hover{color:#6E0FEB;}'};
`;

const NameWrapper = Styled.div`
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
`;

const InputWrapper = Styled.div`
    width: 100px;
`;

const DropdownWrapper = Styled.div`
    width: 142px;
`;

const SubChildrenCountWrapper = Styled.div`
	margin: 0 0 0 8px;
	color: #4C4C58;
	border: solid 1px #C4C4CC;
	background: #E6E6ED;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	display:flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	font-size: 11px;
`;

const SubChildrenCount = Styled.div`
`;

const EstimateExpandWrapper = Styled.div`
	width: 100%;
	height: 90%;
`;

const EstimateWrapper = Styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
`;

const ExpandedTileWrapper = Styled.div`
	position: absolute;
	background-color: white;
	border: 1px solid ${CSS_CONSTANTS.app_border_color};
	cursor: default;
	border-radius: 4px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
	z-index: 4;
	// this element is positioned in a pretty hacky way to allow it to stick with the scroll despite being inside a grid
	left: 435px; 
`;

const onEstimateBlur = (val, task) => {
	const estimateForecast = isNaN(val) ? null : val;

	let isWrong = !estimateForecast && estimateForecast !== 0;

	if (isWrong) {
		return;
	}

	//Don't mutate if values did not change
	if (estimateForecast && estimateForecast === task.estimateForecast) return;
	Util.CommitSchedulingModalUpdate(
		UpdateTaskHierarchyMutation,
		{
			ids: [task.id],
			forecast: estimateForecast,
		},
		() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
	);
};

const assignPersonToTask = (ids, task) => {
	const assignedIds = task.assignedPersons ? task.assignedPersons.map(person => person.id) : [];
	if (ids && ids.length > 0) {
		const newPersonIds = [...assignedIds, ids[0]];
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [task.id],
			assignedPersons: newPersonIds,
		});
	}
};

const unassignPersonFromTask = (ids, task) => {
	let taskPersons = task.assignedPersons ? task.assignedPersons.map(person => person.id) : [];
	if (ids && ids.length > 0) {
		taskPersons.splice(taskPersons.indexOf(ids[0]), 1);
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [task.id],
			assignedPersons: taskPersons,
		});
	}
};

export const ChildRow = ({
	viewer,
	child,
	projectId,
	isEstimatedInHours,
	disabled,
	hideTimeRegistrations,
	isClientUser,
	projectPersons,
	showDeletePrompt,
	statusOptions,
	onStatusColumnChange,
	history,
}) => {
	const [_estimateExpanded, setEstimateExpanded] = useState(false);

	const intl = useIntl();
	const tileRef = useRef();
	const inputRef = useRef();

	const isClientActionsRestricted = useMemo(() => Util.isClientTaskActionsRestricted(viewer), [viewer]);
	const isClientViewRestricted = useMemo(() => Util.isClientTaskViewRestricted(viewer), [viewer]);

	const persons = useMemo(
		() => projectPersons.map(projectPerson => ({node: projectPerson.node.person})),
		[projectPersons, viewer]
	);

	const task = child.node;

	const assignedPersons = useMemo(
		() =>
			task?.assignedPersons
				? task.assignedPersons.filter(person => !isClientViewRestricted || person.id === viewer.actualPersonId)
				: [],
		[task.assignedPersons, viewer]
	);

	const getActionMenuOptions = () => {
		return [{text: intl.formatMessage({id: 'common.delete'}), onClick: () => showDeletePrompt(task)}];
	};

	const assignPerson = persons => {
		assignPersonToTask(persons, task);
	};

	const unassignPerson = person => {
		unassignPersonFromTask(person, task);
	};

	const subChildrenCount = task.childrenCount;
	const subChildrenEstimateRollup = task.rollupEstimate + task.estimateForecast;

	const totalMinutesRegistered =
		task.timeRegistrations?.edges.reduce((total, tReg) => total + tReg.node.minutesRegistered, 0) ?? 0;
	const totalMinutesRollup = totalMinutesRegistered + (task.rollupTimeEntries ?? 0);

	const hasChildren = subChildrenCount > 0;

	const columns = [];

	columns.push(
		<ChildrenColumn key={'name'} firstColumn={true} onClick={() => Util.showTaskModal(task.companyTaskId, history)}>
			<NameWrapper title={task.name}>{task.name}</NameWrapper>
			{subChildrenCount > 0 && (
				<SubChildrenCountWrapper title={subChildrenCount + ' subtasks'}>
					<SubChildrenCount>{subChildrenCount}</SubChildrenCount>
				</SubChildrenCountWrapper>
			)}
		</ChildrenColumn>
	);
	columns.push(
		<ChildrenColumn key={'assignee'}>
			<DropdownWrapper>
				<PersonDropdown
					persons={persons}
					name={intl.formatMessage({id: 'common.assign_people'})}
					optionsName={intl.formatMessage({id: 'common.persons'})}
					selectedGroupName={intl.formatMessage({id: 'common.applied'})}
					selectedItems={assignedPersons.map(person => person.id)}
					selectedItemsPlaceholder={assignedPersons.map(person => ({
						fullName: person.fullName,
						imageSource: profilePicSrc(person.profilePictureId),
					}))}
					disabled={disabled || isClientActionsRestricted || isClientViewRestricted}
					onSelect={ids => assignPerson(ids, task)}
					onRemove={ids => unassignPerson(ids, task)}
					dropdownAlignment={'left'}
					width={255}
					taskName={task.name}
					projectId={projectId}
					useSuggestions
					showRole
					usePortal
				/>
			</DropdownWrapper>
		</ChildrenColumn>
	);
	columns.push(
		<ChildrenColumn key={'status'}>
			<DropdownWrapper>
				<DropdownV2
					focusOnClick={true}
					optionClickEvent={true}
					onChange={option => onStatusColumnChange(task, option, intl)}
					options={statusOptions}
					value={task.statusColumnV2 ? task.statusColumnV2.id : null}
					hideLabel={true}
					disabled={disabled || isClientUser}
					restrictWidth={true}
					customHeight={30}
				/>
			</DropdownWrapper>
		</ChildrenColumn>
	);
	columns.push(
		<ChildrenColumn key={'estimate'} rightAlign={true} withRightPadding={hasChildren}>
			{hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES) ? (
				'-'
			) : hasChildren ? (
				<EstimateExpandWrapper>
					<EstimateWrapper ref={tileRef} onClick={() => setEstimateExpanded(true)}>
						{isEstimatedInHours
							? Util.convertMinutesToFullHour(subChildrenEstimateRollup, intl, true)
							: subChildrenEstimateRollup + 'p'}
					</EstimateWrapper>
					{_estimateExpanded && (
						<ClickAwayListener onClickAway={() => setEstimateExpanded(false)}>
							<ExpandedTileWrapper>
								<ForecastExpansionTile
									isEstimatedInHours={isEstimatedInHours}
									estimateForecast={task.estimateForecast}
									disabled={disabled || isClientActionsRestricted}
									estimateTotal={subChildrenEstimateRollup}
									subtaskInputRef={inputRef}
									onEstimateBlur={value => onEstimateBlur(value, task)}
								/>
							</ExpandedTileWrapper>
						</ClickAwayListener>
					)}
				</EstimateExpandWrapper>
			) : (
				<InputWrapper>
					{isEstimatedInHours ? (
						<HoursInput
							value={task.estimateForecast / 60}
							innerRef={inputRef}
							customClassName={'hours-input'}
							mutation={value => onEstimateBlur(value * 60, task)}
							onClick={e => e.focus()}
							disabled={disabled || isClientActionsRestricted || hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)}
						/>
					) : (
						<AffixedInputWrapper
							disabled={disabled || isClientActionsRestricted}
							value={task.estimateForecast}
							callback={value => onEstimateBlur(value, task)}
							affix={intl.formatMessage({id: 'common.points.short'})}
						/>
					)}
				</InputWrapper>
			)}
		</ChildrenColumn>
	);
	columns.push(
		<ChildrenColumn key={'time-registrations'} rightAlign={true}>
			{hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES)
				? '-'
				: hideTimeRegistrations
				? null
				: Util.convertMinutesToFullHour(hasChildren ? totalMinutesRollup : totalMinutesRegistered, intl, true)}
		</ChildrenColumn>
	);
	columns.push(
		<ChildrenColumn key={'actions-menu'}>
			{task.userCanDeleteTask && (
				<ActionsMenu
					disabled={disabled || isClientActionsRestricted}
					options={getActionMenuOptions()}
					whiteInner={true}
				/>
			)}
		</ChildrenColumn>
	);
	return columns;
};
