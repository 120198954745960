import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../../constants';
import GooglePicker from '../../../task-modal/google_picker';

class UploadButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showUploadMenu: false,
		};
	}
	toggleUploadMenu() {
		this.setState({showUploadMenu: !this.state.showUploadMenu});
		this.uploadButton.focus();
	}
	uploadFile() {
		const file = this._file.files.item(0);
		if (file) {
			this.props.uploadFile(file);
		}
		this.setState({showUploadMenu: false});
	}
	handleKeyDown(e) {
		if (e.keyCode === 27) {
			//escape
			this.setState({showUploadMenu: false});
		} else if (e.keyCode === 32 || e.keyCode === 13) {
			//space or enter
			this.props.uploadGoogleDriveFile();
		}
	}

	handleBlur(e) {
		// const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		// setTimeout(() => {
		// 	this.setState({showUploadMenu: false});
		// }, 100);
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className={'upload-menu-wrapperV2' + (this.state.showUploadMenu ? ' expanded' : '')}>
				<Button
					buttonStyle={BUTTON_STYLE.OUTLINE}
					colorTheme={BUTTON_COLOR.PURPLE}
					text={formatMessage({id: 'common.upload'})}
					onClick={this.toggleUploadMenu.bind(this)}
					onKeyDown={this.handleKeyDown.bind(this)}
					onBlur={this.handleBlur.bind(this)}
					ref={input => (this.uploadButton = input)}
					symbolClass={'file-upload'}
					placeSymbolLast={true}
					symbolSize={14}
				/>
				{this.state.showUploadMenu ? (
					<div>
						<div className="spacer" />
						<ul className="upload-menu has-arrow" role="menu">
							<li className="context-menu-optionV2 add-file-option first-option">
								<input
									name="add-file"
									id="add-file"
									className="input-fileV2"
									type="file"
									onClick={e => {
										e.target.value = null;
									}}
									onChange={this.uploadFile.bind(this)}
									ref={input => (this._file = input)}
									role="menuitem"
									tabIndex={-1}
									onKeyDown={this.handleKeyDown.bind(this)}
								/>
								<label htmlFor="add-file" className="input-file-label">
									<div className={'option-icon attach'} />
									<FormattedMessage id="project_files.upload_button_file" />
								</label>
							</li>
							<li
								className="context-menu-optionV2 google-drive-option"
								role="option"
								tabIndex={-1}
								onKeyDown={this.handleKeyDown.bind(this)}
							>
								<GooglePicker
									newUI={this.props.newUI}
									onChange={this.props.uploadGoogleDriveFile.bind(this)}
									multiselect={true}
								>
									<div className={'option-icon g-drive'} />
									<FormattedMessage id="settings.app_catalog.google_drive" />
								</GooglePicker>
							</li>
						</ul>
					</div>
				) : null}
			</div>
		);
	}
}
export default injectIntl(UploadButton);
