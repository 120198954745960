import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import GithubAttachModal, {githubAttachModalQuery} from './github_attach_modal';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';

class GithubAttachModalRenderer extends Component {
	render() {
		return (
			<div className="github-attach-modal">
				<ForecastQueryRenderer
					modal={true}
					key="query-render-githubAttachModalQuery"
					query={githubAttachModalQuery}
					variables={{id: this.props.taskId}}
					render={relayProps => {
						return <GithubAttachModal {...relayProps} type={this.props.type} closeModal={this.props.closeModal} />;
					}}
				/>
			</div>
		);
	}
}

export default injectIntl(GithubAttachModalRenderer);
