import React, {useEffect, useMemo, useRef, useState} from 'react';
import {cloneDeep, groupBy} from 'lodash';
import {HeaderContainer, PageContent, TableWrapper} from './timesheets_team_page_styled';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {TIME_PERIOD} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_change_date_view';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import TimeRegistrationLine, {LINE_TYPE} from './TimeRegistrationLine';
import {TableHeader} from './timesheets_team_table_header';
import Moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {getCompanyLockedDate, getLockedDate} from '../time-lock/TimeLockUtil';
import {hasTimeLocking} from '../../../forecast-app/shared/util/FeatureUtil';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {getTeamTimesheetsFilters, getTeamTimeshetsPreappliedFilter} from './timesheets_team_filter';
import {getTimeOffSearchQuery} from '../TimeManagementUtil';
import {createRefetchContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {getSocketConfig} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheet_socket_handling';

const TimesheetTeamPage = ({
	viewer,
	relay,
	setSocketConfig,
	setSocketVariables,
	goToToday,
	adjacentPeriod,
	handleDateRange,
	currentViewingDate,
}) => {
	const intl = useIntl();
	const urlparam = Util.getUrlQueryParameter('project');
	const preAppliedFilters = urlparam ? getTeamTimeshetsPreappliedFilter(urlparam, viewer.company.allPersons.edges) : null;
	const [filterFunctions, setFilterFunctions] = useState(getTeamTimesheetsFilters(preAppliedFilters));
	const {formatMessage} = intl;

	useEffect(() => {
		const allPersonIdInt = viewer.company.allPersons.edges.map(p => parseInt(atob(p.node.id).replace('Person:', '')));
		setSocketConfig(getSocketConfig(allPersonIdInt), relay.refetch);
	}, []);

	const refetch = variables => {
		/* If adding a loader or something similar that is triggered by this refetch,
		 * remember to remove it when the socket triggered refetch is done, see TimeApprovalPage
		 **/
		relay.refetch(variables);
		setSocketVariables(variables);
	};

	const didMount = useRef(false);

	const sortFunc = (a, b) => {
		const aName = Util.normalizedWord((a.node.firstName || '') + (a.node.lastName || ''));
		const bName = Util.normalizedWord((b.node.firstName || '') + (b.node.lastName || ''));
		if (aName < bName) return -1;
		if (aName > bName) return 1;
		return 0;
	};

	//Avoid to redo the sorting everytime we change the date
	const personList = useMemo(() => {
		return cloneDeep(viewer.company.allPersons.edges).sort(sortFunc);
	}, [viewer.company.allPersons.edges]);

	const companyWeekLocked = useMemo(() => {
		const companyLockedDate = getCompanyLockedDate(viewer.company);
		const endOfWeek = currentViewingDate.clone().endOf('week');
		return companyLockedDate && companyLockedDate.isSameOrAfter(endOfWeek);
	}, [currentViewingDate]);

	useEffect(() => {
		// Only run if not mount
		if (!didMount.current) {
			didMount.current = true;
		} else {
			//we need to fetch now the time entries of each person
			const startDate = currentViewingDate.clone().startOf('week').format('YYYY-MM-DD');

			const endDate = currentViewingDate.clone().endOf('week').format('YYYY-MM-DD');

			refetch({
				startDateString: startDate,
				endDateString: endDate,
				searchQuery: getTimeOffSearchQuery(viewer),
			});
		}
	}, [currentViewingDate]);

	const onLockButtonClick = () => {
		showModal({
			type: MODAL_TYPE.TIME_LOCK,
		});
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		const dateChanger = {
			type: ELEMENT_TYPE.DATE_CHANGER_V2,
			useTodayButton: true,
			timePeriod: TIME_PERIOD.WEEK_VIEW,
			currentViewingDate: currentViewingDate,
			handleTodayButtonClick: goToToday,
			handleAdjacentDateButtonClick: adjacentPeriod.bind(this, false),
			updateDateRange: handleDateRange.bind(this, false),
			boldLongDayMonth: true,
			selectorTooltipEnabled: true,
			selectorTooltipProps: {
				autoPlace: true,
				grey: true,
				infoText: formatMessage({id: 'common.select_date'}),
			},
			tooltipEnabled: true,
			tooltipProps: {
				autoPlace: true,
				infoText: Moment().format('ddd, DD. MMM') + ' ' + Moment().format('YYYY'),
				grey: true,
			},
		};
		leftContent.push(dateChanger);

		if (companyWeekLocked) {
			leftContent.push({
				type: ELEMENT_TYPE.HEADER_LABEL,
				text: intl.formatMessage({id: 'time_lock.week_locked'}),
			});
		}

		if (
			hasTimeLocking(viewer.company.modules) &&
			viewer.company.useTimeLocking &&
			hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)
		) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: intl.formatMessage({id: 'time_lock.set_timesheet_lock'}),
				callback: onLockButtonClick,
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.LIGHTGREY,
				dataCy: 'time-lock-button',
			});
		}

		const peopleFilters = [FILTER_TYPE.TEAM_MEMBER, FILTER_TYPE.TEAM, FILTER_TYPE.ROLE];

		if (Util.hasDepartments(viewer.company.modules)) {
			peopleFilters.push(FILTER_TYPE.DEPARTMENT);
		}
		const projectFilters = [];
		const taskFilters = [];
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.PEOPLE,
			projectFilters,
			peopleFilters: getFiltersAlphabetically(peopleFilters, intl.formatMessage),
			taskFilters,
			viewer: viewer,
			filterSection: FILTER_SECTION.TEAM_TIMESHEET,
			appliedFiltersName: `timesheets-team-filter-v4`,
			preAppliedFilters: preAppliedFilters,
			onFiltersChange: (_, filterFunctions) => setFilterFunctions(filterFunctions),
		});

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} noPadding />;
	};

	const filterOptions = {teams: viewer.company.teams.edges};

	const matchFilter = person => {
		if (filterFunctions && filterFunctions.personFilter) {
			return filterFunctions.personFilter(person.node, filterOptions);
		}
		return true;
	};

	const timeRegsPerPerson = groupBy(viewer.company.timeRegistrations.edges, 'node.person.id');

	return (
		<PageContent>
			<HeaderContainer data-userpilot="timesheets-team-header">{getHeader()}</HeaderContainer>
			<CustomScrollDiv className="custom-scrollbar-div">
				<TableWrapper>
					<table data-userpilot="timesheets-team-all">
						<TableHeader intl={intl} firstDayOfWeek={currentViewingDate.clone().startOf('week')} />
						<tbody>
							{personList.map((person, index) => {
								if (matchFilter(person)) {
									const lockedDate = getLockedDate(viewer.company, person.node);
									return (
										<TimeRegistrationLine
											id={index}
											key={index}
											viewer={viewer}
											person={person.node}
											firstDayOfWeek={currentViewingDate.clone().startOf('week')}
											timeRegs={timeRegsPerPerson[person.node.id] || []}
											lockedDate={lockedDate}
											lineType={LINE_TYPE.PERSON}
										/>
									);
								}
								return null;
							})}
						</tbody>
					</table>
				</TableWrapper>
			</CustomScrollDiv>
		</PageContent>
	);
};

const timesheetsTeamPageQuery = graphql`
	query timesheetsTeamPage_Query($searchQuery: TaskSearchQueryType, $startDateString: String, $endDateString: String) {
		viewer {
			actualPersonId
			component(name: "team-timesheet-page")
			...timesheetsTeamPage_viewer
				@arguments(searchQuery: $searchQuery, startDateString: $startDateString, endDateString: $endDateString)
			company {
				useTimeApproval
			}
		}
	}
`;

export {timesheetsTeamPageQuery};

export default withSocketHandling(
	createRefetchContainer(
		TimesheetTeamPage,
		{
			viewer: graphql`
				fragment timesheetsTeamPage_viewer on Viewer
				@argumentDefinitions(
					searchQuery: {type: "TaskSearchQueryType"}
					startDateString: {type: "String"}
					endDateString: {type: "String"}
				) {
					component(name: "team-timesheet-page")
					id
					harvestUser
					actualPersonId
					email
					backendId
					availableFeatureFlags {
						key
					}
					filters(first: 10000, filterSection: TEAM_TIMESHEET) @connection(key: "Viewer_filters", filters: []) {
						edges {
							node {
								id
								name
								section
								value
								updatedAt
							}
						}
					}
					language
					projectOwner
					company {
						id
						harvestEnabled
						bambooHREnabled
						characterLimit
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						useTimeApproval
						useTimeLocking
						sageIntacctConnectionEnabled
						modules {
							moduleType
						}
						tier
						idleTimes {
							edges {
								node {
									id
									isInternalTime
								}
							}
						}
						timeRegistrations(
							first: 10000000
							startDate: $startDateString
							endDate: $endDateString
							searchQuery: $searchQuery
						) @connection(key: "Company_timeRegistrations", filters: []) {
							edges {
								node {
									id
									day
									month
									year
									minutesRegistered
									billableMinutesRegistered
									billable
									lockedInPeriod
									approvalStatus
									invoiced

									person {
										id
										startDate
										endDate
										monday
										tuesday
										wednesday
										thursday
										friday
										saturday
										sunday
										excludeFromCompanyLockedPeriod
									}
									role {
										id
										name
									}
									notes
									project {
										id
										name
										projectColor
										companyProjectId
										...DeprecatedProjectIndicatorJS_project
										fullAccessToProject
										readOnlyAccess
										billable
									}
									task {
										id
										name
										companyTaskId
										billable
										taskType
										estimateForecastMinutes
										totalMinutesRegistered
										project {
											id
											name
											companyProjectId
											customProjectId
											...DeprecatedProjectIndicatorJS_project
											projectColor
											fullAccessToProject
											readOnlyAccess
										}
									}
									idleTimeId
									idleTime {
										id
										name
										isInternalTime
									}
								}
							}
						}
						teams(first: 1000000) {
							edges {
								node {
									id
									name
									teamPersons(first: 1000000) {
										edges {
											node {
												id
												person {
													id
												}
											}
										}
									}
								}
							}
						}
						roles(first: 1000000) {
							edges {
								node {
									id
									name
								}
							}
						}
						labels(first: 100000) {
							edges {
								node {
									id
									name
									color
								}
							}
						}
						departments(first: 100000) {
							edges {
								node {
									id
									name
								}
							}
						}
						allPersons(first: 1000000, excludeClientUsers: true) {
							edges {
								node {
									holidayCalendar {
										id
										holidayCalendarEntries(
											first: 100000
											startDate: $startDateString
											endDate: $endDateString
										) {
											edges {
												node {
													id
													day
													month
													year
													name
												}
											}
										}
										name
									}
									id
									role {
										id
										name
									}
									personLabels(first: 100000) {
										edges {
											node {
												id
												label {
													id
												}
											}
										}
									}
									department {
										id
										name
									}
									firstName
									createdAt
									startDate
									endDate
									lastName
									systemUser
									initials
									active
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									excludeFromCompanyLockedPeriod
									submitLockedDateYear
									submitLockedDateMonth
									submitLockedDateDay
									harvestUser
									economicUser
									profilePictureId
									profilePictureDefaultId
									assignedProjectIds
									email
								}
							}
						}
					}
				}
			`,
		},
		graphql`
			query timesheetsTeamPageRefetchQuery(
				$searchQuery: TaskSearchQueryType
				$startDateString: String
				$endDateString: String
			) {
				viewer {
					component(name: "team-timesheet-page")
					...timesheetsTeamPage_viewer
						@arguments(searchQuery: $searchQuery, startDateString: $startDateString, endDateString: $endDateString)
				}
			}
		`
	)
);
