import {injectIntl} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Util from '../../forecast-app/shared/util/util';
import WarningIcon from '../../images/warning_icon';
import PriorityTaskInputIconPath from '../../images/ico-priority';
import BugTaskInputIconPath from '../../images/ico-bug';
import BlockedTaskInputIconPath from '../../images/ico-blocked';
import StarTask from '../../forecast-app/shared/components/star/star_task';
import CheckBox from './check_box';
import {dispatch, EVENT_ID} from '../../containers/event_manager';
import {TaskParentName} from './backlog_card.styled';
import DeprecatedProjectIndicatorJS from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

class BacklogCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newTaskInput: '',
		};
	}

	handleSelected(e) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		if (this.props.onRowSelected) {
			this.props.onRowSelected(this.props.id, e.shiftKey, e.ctrlKey, this.props.emptyPhase);
		}
		if (this.state.showContextMenu) {
			this.setState({showContextMenu: false});
		}
	}

	toggleSelected(e) {
		dispatch(
			EVENT_ID.WORKFLOW_MULTISELECT,
			this.props.task,
			this.props.projectGroupStatusColumnId ? this.props.projectGroupStatusColumnId : this.props.statusColumnId,
			this.props.tasks,
			e.shiftKey
		);
		this.handleSelected(e);
	}

	refreshRemaining() {
		this.setState({remainingInput: this.props.task.timeLeft});
	}

	toggleContextMenu(e) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		e.preventDefault();
		e.stopPropagation();
		this.props.onContextMenu(this.props.task, e);
	}

	onTaskClick(e, task, elem) {
		if (elem !== 'div' && !this.props.selectionMode && !e.shiftKey) {
			const newTarget = e.relatedTarget || e.explicitOriginalTarget;
			if (newTarget && (newTarget.tagName === 'INPUT' || newTarget.tagName === 'Button')) {
				return;
			}
			this.openCardModal(e, task);
		}
	}

	openCardModal(e, item) {
		if (e.target && e.target.className === 'project-indicator-text') return;
		e.stopPropagation();
		e.preventDefault();
		if (this.props.showTaskModal) {
			this.props.showTaskModal(item.companyTaskId);
		}
	}

	getTaskNameLink(taskId) {
		if (this.props.selectionMode) {
			return null;
		}
		const windowLocation = window.location.href;
		const target = windowLocation.endsWith('/')
			? window.location.href + 'T' + taskId
			: window.location.href + '/T' + taskId;
		return target;
	}

	render() {
		if (this.props.task) {
			const {task, selected} = this.props;
			const taskProject = this.props.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges.find(project => project.node.id === task.project.id)
				: this.props.viewer.project;
			const taskProjectNode = this.props.isConnectedParent ? (taskProject ? taskProject.node : null) : taskProject;
			const {formatMessage} = this.props.intl;
			this.estimationUnit = this.props.isEstimatedInHours
				? formatMessage({id: 'common.hours.short'})
				: formatMessage({id: 'common.points.short'});
			const taskWarning = Util.GetTaskWarnings(task, this.props.intl);
			this.path =
				(window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/') +
				'T' +
				task.companyTaskId;

			// Parent task name
			const parentTaskName =
				task.project.useTaskHierarchy && task.parentTask?.taskType !== 'EPIC' && task.parentTask?.name;

			return (
				<div
					data-cy="task-card"
					data-userpilot={this.props.index === 0 ? 'backlog-task-first' : null}
					className={
						'board-card-outer-v3' +
						(this.props.selectionMode ? ' select-mode' : '') +
						(this.props.disabled ? ' locked' : '') +
						(this.props.isNewTask ? ' animated pulse 1s' : '') +
						(this.props.task.favoured ? ' favoured' : '')
					}
					key={task.id}
					onClick={e =>
						this.props.selectionMode || e.shiftKey ? this.handleSelected(e) : this.openCardModal(e, task)
					}
					tabIndex="0"
					id={task.id}
					ref={card => (this.card = card)}
					onContextMenu={e => (this.props.disabled ? null : this.props.onContextMenu(task, e))}
					onAnimationEnd={this.props.clearNewTask}
				>
					<div className={'card-top-bar' + (selected ? ' selected' : '')}>
						<div className={'card-top-left' + (selected ? ' selected' : '')} />
						<div className={'card-top-angle' + (selected ? ' selected' : '')} />
					</div>
					<div className={'card-contents' + (selected ? ' selected' : '')}>
						{this.props.disabled ? <div className={'overlay-div'} /> : null}
						<div className="project-workflow-task-header">
							<div className={'id-name-wrapper'}>
								<div className={'task-project-id'}>
									<div className="task-id">
										<div className={'task-indicators'}>
											<div className="task-indicators-item">
												<CheckBox
													verySmall={true}
													id={task.id}
													key={'selector'}
													isDisabled={this.props.disabled}
													isChecked={!!this.props.selected}
													isFocusable={!this.props.disabled}
													onClick={this.toggleSelected.bind(this)}
													cy={'selector-checkbox'}
												/>
											</div>
											{task.highPriority ? (
												<div className="task-indicators-item">
													<PriorityTaskInputIconPath
														title={formatMessage({id: 'common.high_priority'})}
														active={task.highPriority}
													/>
												</div>
											) : null}
											{task.bug ? (
												<div className="task-indicators-item">
													<BugTaskInputIconPath
														cy="task-bug-icon"
														title={formatMessage({id: 'common.bug'})}
														active={task.bug}
													/>
												</div>
											) : null}
											{task.blocked ? (
												<div className="task-indicators-item">
													<BlockedTaskInputIconPath
														cy="task-bug-icon"
														title={formatMessage({id: 'common.blocked'})}
														active={task.blocked}
													/>
												</div>
											) : null}

											<div className="task-indicators-item">{'T' + task.companyTaskId}</div>
										</div>
									</div>
									{this.props.isConnectedParent ? (
										<div className="project-id">
											{taskProjectNode ? (
												<DeprecatedProjectIndicatorJS project={taskProjectNode} />
											) : null}
										</div>
									) : null}
									<StarTask task={task} parentId={this.props.viewer.id} />
								</div>
								<div className="name-wrapper">
									{!taskWarning[0] ? null : (
										<WarningIcon
											title={taskWarning[0].message}
											className={
												taskWarning[0].color.includes('yellow')
													? ' warning-yellow-icon'
													: ' warning-red-icon'
											}
											danger={true}
										/>
									)}

									<a
										className={'task-name'}
										href={this.getTaskNameLink(task.companyTaskId)}
										tabIndex={'0'}
										onKeyDown={
											task
												? e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null)
												: null
										}
										onClick={task ? e => this.onTaskClick(e, task) : null}
										title={task.name}
									>
										{parentTaskName && <TaskParentName>{parentTaskName}</TaskParentName>}
										{task.name}
									</a>
								</div>
							</div>

							{this.props.selectionMode || this.props.disabled ? null : (
								<div className={'dot-options'} onClick={this.toggleContextMenu.bind(this)} />
							)}
						</div>
						<div className={'remaining-wrapper'}>
							{formatMessage({id: 'cards.remaining-colons'}) + ' '}
							{taskProjectNode
								? taskProjectNode.estimationUnit === 'HOURS'
									? Util.convertMinutesToFullHour(task.timeLeft, this.props.intl)
									: formatMessage({id: 'common.x_points'}, {points: task.timeLeft})
								: null}
						</div>
					</div>
				</div>
			);
		}
	}
}

BacklogCard.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	task: PropTypes.object,
	index: PropTypes.number,
	tableRowElems: PropTypes.array,
	rowHeight: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	shouldHaveBorderLeft: PropTypes.bool,
	onRowSelected: PropTypes.func,
	noBorderRight: PropTypes.bool,
	isHighlighted: PropTypes.bool,
};

export default injectIntl(BacklogCard);
