import React from 'react';
import {FormattedMessage} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import UpdateCompanyMutation from '../../mutations/update_company_mutation.modern';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import Util from '../../forecast-app/shared/util/util';
import DirectApi from '../../directApi';

const JIRA_PREFIX = {
	NONE: 'NONE',
	SIMPLE: 'SIMPLE',
	FULL: 'FULL',
};

async function fetchJiraBoards(jiraProjectId, deep, jiraType) {
	try {
		return await DirectApi.Fetch(
			`jiraBoards?type=${jiraType}${jiraProjectId ? `&projectId=${jiraProjectId}` : ''}&deep=${deep}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchJiraCustomFields(jiraType, jiraProjectStyle) {
	try {
		return await DirectApi.Fetch(`jirafields/${jiraType}?projectType=${jiraProjectStyle}`).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchIssuesCountForJiraProject(jiraProjectId, type, cutoffDate) {
	try {
		return await DirectApi.Fetch(
			`jira/project_issues_count/${type}/${jiraProjectId}${
				cutoffDate ? `?cutoffDate=${cutoffDate.format('YYYY-MM-DD')}` : ''
			}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchEpicsCountForJiraProject(jiraProjectId, type, cutoffDate) {
	try {
		return await DirectApi.Fetch(
			`jira/project_epics_count/${type}/${jiraProjectId}${
				cutoffDate ? `?cutoffDate=${cutoffDate.format('YYYY-MM-DD')}` : ''
			}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchIssuesCountForJiraEpics(jiraEpicIds, type, cutoffDate) {
	try {
		return await DirectApi.Fetch(
			`jira/epic_issues_count/${type}?epicIds=${jiraEpicIds.join(',')}${
				cutoffDate ? `&cutoffDate=${cutoffDate.format('YYYY-MM-DD')}` : ''
			}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchUsersCountForJiraProject(jiraProjectId, type) {
	try {
		return await DirectApi.Fetch(`jira/project_teammembers_count/${type}/${jiraProjectId}`).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchSprintsCountForJiraBoard(boardId, type, cutoffDate) {
	try {
		return await DirectApi.Fetch(
			`jira/board_sprints_count/${type}/${boardId}${cutoffDate ? `?cutoffDate=${cutoffDate.format('YYYY-MM-DD')}` : ''}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

async function fetchVersionsCountForJiraProject(jiraProjectId, type, cutoffDate) {
	try {
		return await DirectApi.Fetch(
			`jira/project_versions_count/${type}/${jiraProjectId}${
				cutoffDate ? `?cutoffDate=${cutoffDate.format('YYYY-MM-DD')}` : ''
			}`
		).then(json => {
			return json;
		});
	} catch (error) {
		return error;
	}
}

function unlinkProject(project) {
	const deactivateForReal = () => {
		Util.CommitMutation(UpdateProjectMutation, {
			project,
			jiraProjectId: '',
			unsynchedJiraProject: true,
		});
	};

	showModal({
		type: MODAL_TYPE.WARNING,
		warningMessageId: 'settings.unlink_jira_warning_title',
		warningInformation: [
			<div className="whitespace-pre">
				<FormattedMessage id="settings.unlink_jira_warning" />
			</div>,
		],
		buttons: [
			{
				text: <FormattedMessage id="common.cancel" />,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: <FormattedMessage id="common.unlink" />,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				callback: deactivateForReal,
			},
		],
	});
}

function changeJiraPrefixType(jiraPrefixType, companyId, isServer) {
	showModal({
		type: MODAL_TYPE.WARNING,
		warningMessageId: 'app_jira.change_prefix.confirm',
		warningInformation: [<FormattedMessage id="app_jira.change_prefix.description" />],
		buttons: [
			{
				text: <FormattedMessage id="common.cancel" />,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: <FormattedMessage id="common.ok" />,
				callback: () => {
					const onSuccess = () => {
						createToast({duration: 5000, message: 'Jira prefix settings changed'});
					};
					Util.CommitMutation(
						UpdateCompanyMutation,
						{
							id: companyId,
							jiraPrefixType: isServer ? undefined : jiraPrefixType,
							jiraServerPrefixType: isServer ? jiraPrefixType : undefined,
						},
						onSuccess
					);
				},
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
			},
		],
	});
}

module.exports = {
	JIRA_PREFIX,
	fetchJiraBoards,
	fetchJiraCustomFields,
	fetchIssuesCountForJiraProject,
	fetchEpicsCountForJiraProject,
	fetchIssuesCountForJiraEpics,
	fetchUsersCountForJiraProject,
	fetchSprintsCountForJiraBoard,
	fetchVersionsCountForJiraProject,
	unlinkProject,
	changeJiraPrefixType,
};
