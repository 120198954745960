import React, {Component} from 'react';
import PropTypes from 'prop-types';
import UpdateIdleTimeMutation from '../../mutations/update_idle_time_mutation';
import DeleteIdleTimeMutation from '../../mutations/delete_idle_time_mutation';
import {injectIntl} from 'react-intl';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {Dropdown} from 'web-components';

class SettingsIdleTimesLineItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.idleTime.name ? this.props.idleTime.name : '',
			showActionMenu: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.idleTime.id !== this.props.idleTime.id) {
			this.setState({name: nextProps.idleTime.name});
		}
	}

	handleIdleTimeNameChange(e) {
		this.setState({name: e.target.value});
	}

	handleIdleTimeNameBlur() {
		// Dont allow empty string names
		if (this.state.name) {
			Util.CommitMutation(
				UpdateIdleTimeMutation,
				{
					id: this.props.idleTime.id,
					name: this.state.name,
				},
				null,
				true
			);
		} else {
			this.setState({name: this.props.idleTime.name ? this.props.idleTime.name : 'Idle time'});
		}
	}

	handleKeyPressIdleTime(e) {
		if (e.key === 'Enter') {
			this.idleTimeInput.blur();
		}
	}

	handleIdleTimeDisable() {
		const {formatMessage} = this.props.intl;

		// If bamboo, unlink it as well
		if (this.props.bambooHRTimeOffTypes) {
			const id = Util.getIdFromBase64String(this.props.idleTime.id);
			const linked = this.props.bambooHRTimeOffTypes.find(t => t.forecastId === id);
			if (linked) {
				this.props.setBambooHRTimeOffType();
			}
		}

		const callbackPositive = params => {
			Util.CommitMutation(
				UpdateIdleTimeMutation,
				{
					id: this.props.idleTime.id,
					disabled: !this.props.idleTime.disabled,
				},
				null,
				true
			);
		};
		if (!this.props.idleTime.disabled) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings_idle_times.disable_idle_time',
				warningInformation: [formatMessage({id: 'settings_idle_times.disable_idle_time_info'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: 'Disable',
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: callbackPositive,
					},
				],
			});
		} else {
			callbackPositive();
		}
	}

	handleIdleTimeDelete() {
		const {formatMessage} = this.props.intl;
		const onSuccess = response => {
			// Bad error handling until we find a good way to handle errors in Relay Modern.
			const nothingDeleted = response.deleteIdleTime.deletedIdleTimeId === null;
			const errors = response.deleteIdleTime.errors; // Doesn't work with Relay Modern
			if (errors) {
				const errMsgTranslationId = errors.includes('CANNOT_DELETE_IDLE_TIME')
					? 'settings_idle_times.invalid-operation-description'
					: 'settings_idle_times.invalid-operation-description-allocation';
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'common.invalid_action_modal_title',
					warningInformation: [formatMessage({id: errMsgTranslationId})],
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else if (nothingDeleted) {
				const errMsgTranslationId = `settings_idle_times.invalid-operation-description-allocation-timeregistration`;
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'common.invalid_action_modal_title',
					warningInformation: [formatMessage({id: errMsgTranslationId})],
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'idle-time.has-been-deleted'}),
				});
			}
		};

		const callbackPositive = params => {
			Util.CommitMutation(
				DeleteIdleTimeMutation,
				{
					id: this.props.idleTime.id,
					companyId: this.props.companyId,
				},
				onSuccess,
				true
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	handleMoveToOtherIdleTimeCategory() {
		Util.CommitMutation(
			UpdateIdleTimeMutation,
			{
				id: this.props.idleTime.id,
				isInternalTime: !this.props.idleTime.isInternalTime,
			},
			null,
			true
		);
	}

	getActionsMenuOptions() {
		const {formatMessage} = this.props.intl;
		const options =
			this.props.idleTime.timeRegistrations.edges.length > 0 || this.props.hasAllocation
				? [
						{
							text: this.props.idleTime.disabled
								? formatMessage({id: 'integration.enable'})
								: formatMessage({id: 'integration.disable'}),
							onClick: this.handleIdleTimeDisable.bind(this),
						},
				  ]
				: [
						{
							text: formatMessage({id: 'common.delete'}),
							onClick: this.handleIdleTimeDelete.bind(this),
							cy: 'delete-time',
						},
				  ];
		const text = this.props.idleTime.isInternalTime
			? formatMessage({id: 'settings_idle_times.change_to_time_off'})
			: formatMessage({id: 'settings_idle_times.change_to_internal_time'});
		options.unshift({
			text,
			onClick: this.handleMoveToOtherIdleTimeCategory.bind(this),
			cy: 'change-to-other-idle-category',
		});
		return options;
	}

	handleMouseEnter() {
		if (!this.state.showActionMenu) {
			this.setState({showActionMenu: true});
		}
	}

	handleMouseLeave() {
		if (this.state.showActionMenu) {
			this.setState({showActionMenu: false});
		}
	}

	render() {
		let linkedBambooOption = null;
		const id = Util.getIdFromBase64String(this.props.idleTime.id);
		if (this.props.bambooHRTimeOffTypes) {
			const linked = this.props.bambooHRTimeOffTypes.find(t => t.forecastId === id);
			if (linked) linkedBambooOption = linked;
		}

		return (
			<tr onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
				<td>
					<div className="idle-time-input">
						<input
							data-cy={this.props.cy}
							disabled={this.props.idleTime.disabled}
							ref={input => (this.idleTimeInput = input)}
							value={this.state.name}
							onKeyPress={this.handleKeyPressIdleTime.bind(this)}
							onChange={this.handleIdleTimeNameChange.bind(this)}
							onBlur={this.handleIdleTimeNameBlur.bind(this)}
						/>
					</div>
				</td>
				{this.props.bambooHRTimeOffTypes ? (
					<td>
						<div className="bamboo-time-off-container">
							<Dropdown
								selectedItems={linkedBambooOption ? [linkedBambooOption.value] : []}
								name={this.props.intl.formatMessage({id: 'settings_idle_times.bamboohr_time_off_types.link'})}
								isClearable={true}
								onRemove={() => this.props.setBambooHRTimeOffType()}
								onSelect={val => (linkedBambooOption ? null : this.props.setBambooHRTimeOffType(val))}
								disabled={this.props.bambooHRLoading}
								isMultiSelect={false}
							>
								{(linkedBambooOption
									? [linkedBambooOption]
									: this.props.bambooHRTimeOffTypes.filter(t => !t.forecastId || t.forecastId === id)
								).map(option => (
									<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
										{option.label}
									</Dropdown.SingleText>
								))}
							</Dropdown>
						</div>
					</td>
				) : null}
				<td className="actions-menu-cell">
					<ActionsMenu
						cy={this.props.idleTime.isInternalTime === true ? 'internal-time' : 'time-off'}
						customClass={this.state.showActionMenu ? '' : 'hidden'}
						disabled={false}
						customWidth={20}
						whiteInner={true}
						isWhite={false}
						options={this.getActionsMenuOptions()}
					/>
				</td>
			</tr>
		);
	}
}

SettingsIdleTimesLineItem.propTypes = {
	idleTime: PropTypes.object.isRequired,
	companyId: PropTypes.string.isRequired,
};
export default injectIntl(SettingsIdleTimesLineItem);
