import React, {useEffect, useMemo, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {BasicTextInput, Button, PlusIcon} from 'web-components';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import {createToast} from '../../../../forecast-app/shared/components/toasts/toast';
import CreateTaskMutation from '../../../../mutations/create_task_mutation';
import Util from '../../../../forecast-app/shared/util/util';
import HoursInput from '../../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Styled from 'styled-components/macro';
import {injectIntl, useIntl} from 'react-intl';
import ExpandIcon from '../../../../images/v2/components/dropdown/expand_icon';
import SubtaskIcon from '../../../../images/v2/components/workflow-task/v3/ico-subtask.svg';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {AffixedInputWrapper} from '../../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {ChildRow} from './ChildRow';
import {hasPermission, isClientUser} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {sortBySortOrder} from '../../../../forecast-app/shared/components/task-tables/task-table-v3/table-util/TableUtil';
import {cloneDeep} from 'lodash';
import {PERMISSION_TYPE} from '../../../../Permissions';
import RoleDropdown from '../../../../forecast-app/shared/components/dropdowns/Role_dropdown';
import {PersonDropdown} from '../../../../forecast-app/shared/components/dropdowns/Person_dropdown';
import {profilePicSrc} from '../../../../directApi';

const TaskHierarchySectionWrapper = Styled.div`
    margin: 0 58px;
	padding: 24px 0 44px;       
	border-top: 1px solid #e6e6e6;
`;

const HeaderWrapper = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	color: #535353;
	font-size: 13px;
	font-weight: 500;
	padding: 0 8px 0 0;
	margin:0 0 25px 0;
	cursor:pointer;
	.title {
		display: flex;
		flex: 1;
		margin-left: 16px;
		align-items: center;
	}
	.beta {
		margin-left: 8px;
	}
	.total{
		margin: 0 16px 0 0;
	}
`;

const ChildrenList = Styled.div`
	display: grid;
	grid-template-columns: 1fr auto auto auto auto auto;
	width:804px;
`;

const Column = Styled.div`
	display: flex;
	border-bottom: solid 1px ${CSS_CONSTANTS.app_border_color};
	padding-left: 16px;
	min-width:${props => props.minWidth};
	justify-content:${props => (props.rightAlign ? 'flex-end' : 'flex-start')};
`;

const ChildrenColumnHeader = Styled(Column)`
	padding-right: ${props => props.withRightPadding && '16px'};
	color:${CSS_CONSTANTS.v2_text_light_gray};
	font-size: 9px;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding-bottom:8px;
	font-weight: 500;
`;

const NewSubtaskWrapper = Styled.div`
	padding:0 16px;
	height: 40px;
	display:flex;
	align-items:center;
`;

const NewSubtask = Styled.div`
	color:#6E0FEB;
	font-size: 13px;
	cursor:pointer;
	margin:8px 0 0 0;

`;

const CreateTaskWrapper = Styled.div`
    display:flex;
    flex-direction:row;
	width:100%;
	margin:8px 0 0 0;
`;

const NameInputWrapper = Styled.div`
	margin: 0 8px 0 0;
	flex:1;
`;

const DropdownWrapper = Styled.div`
	height:30px;
	width:142px;
	margin: 0 8px 0 0;
	display: flex;
`;

const EstimateInputWrapper = Styled.div`
	margin: 0 8px 0 0;
	width:102px;
`;

const TaskHierarchySection = React.forwardRef(
	(
		{
			parentId,
			viewer,
			companyId,
			projectId,
			history,
			companyRoles,
			projectPersons,
			showSuggestions,
			isEstimatedInHours,
			disabled,
			hideTimeRegistrations,
			statusOptions,
			onStatusColumnChange,
			showDeletePrompt,
			setSocketConfig,
		},
		ref
	) => {
		const intl = useIntl();
		const {formatMessage} = intl;

		const [_newSubTaskName, setNewSubTaskName] = useState('');
		const [_newSubTaskEstimate, setNewSubTaskEstimate] = useState();
		const [_newSubTaskRole, setNewSubTaskRole] = useState();
		const [_newSubTaskPersons, setNewSubTaskPersons] = useState([]);

		const [_expanded, setExpanded] = useState(true);
		const [_addSubtaskSection, setAddSubtaskSection] = useState(false);
		let inputRef = null;

		const isClientActionsRestricted = useMemo(() => Util.isClientTaskActionsRestricted(viewer), [viewer]);
		const isClientViewRestricted = useMemo(() => Util.isClientTaskViewRestricted(viewer), [viewer]);

		const children = cloneDeep(viewer.parentTask.childrenTasks.edges);
		useEffect(() => {
			if (children) {
				const taskIds = children.map(task => parseInt(atob(task.node.id).replace('Task:', '')));
				const socketEvents = [
					{
						type: SOCKET_EVENT_TYPE.TASK,
						action: SOCKET_ACTION.UPDATE,
						taskIds: taskIds,
					},
					{
						type: SOCKET_EVENT_TYPE.TASK,
						action: SOCKET_ACTION.DELETE,
						taskIds: taskIds,
					},
					{
						type: SOCKET_EVENT_TYPE.TIME_REG,
						action: SOCKET_ACTION.UPDATE,
						taskIds: taskIds,
					},
					{
						type: SOCKET_EVENT_TYPE.TIME_REG,
						action: SOCKET_ACTION.CREATE,
						taskIds: taskIds,
					},
					{
						type: SOCKET_EVENT_TYPE.TIME_REG,
						action: SOCKET_ACTION.DELETE,
						taskIds: taskIds,
					},
				];
				setSocketConfig(socketEvents);
			}
		}, []);

		const handleCreateSubTask = () => {
			//hack for safari not handling correctly the on blur
			inputRef.blur();
			const estimation = _newSubTaskEstimate ?? Util.convertTimeInputToMinutes(inputRef.value) / 60;

			const name = _newSubTaskName.trim();

			const onSuccess = res => {
				setNewSubTaskName('');
				setNewSubTaskEstimate(null);
				setNewSubTaskRole(null);
				setNewSubTaskPersons([]);
				createToast({duration: 5000, message: intl.formatMessage({id: 'task_modal.subtask-has-been-created'})});
			};

			if (name.length > 300) {
				createToast({duration: 5000, message: intl.formatMessage({id: 'errors.name-too-long'})});
				return;
			}

			if (name !== '') {
				Util.CommitSchedulingModalUpdate(
					CreateTaskMutation,
					{
						companyId: companyId,
						projectId: projectId,
						placementOfTask: 'BOTTOM',
						name: name,
						parentTaskId: parentId,
						forecast: estimation * (isEstimatedInHours ? 60 : 1),
						assignedPersons: _newSubTaskPersons.map(person => person.id),
						roleId: _newSubTaskRole?.id,
					},
					onSuccess
				);
			}
		};

		const assignRole = ids => {
			const role = companyRoles.find(r => ids.includes(r.node.id));
			setNewSubTaskRole(role?.node);
		};

		const unassignRole = () => {
			setNewSubTaskRole(null);
		};

		const assignPerson = ids => {
			const personToAdd = projectPersons.map(person => person.node.person).find(person => ids.includes(person.id));
			if (personToAdd) {
				const newPersons = [..._newSubTaskPersons, personToAdd];
				setNewSubTaskPersons(newPersons);
			}
		};

		const unassignPerson = ids => {
			const newPersons = _newSubTaskPersons.filter(person => !ids.includes(person.id));
			setNewSubTaskPersons(newPersons);
		};

		const handlePressEnter = e => {
			if (e.key === 'Enter') {
				handleCreateSubTask();
			}
		};

		const handleTitleBlur = () => {
			if (_newSubTaskName === '') {
				setAddSubtaskSection(false);
			}
		};

		const childrenList = children.sort(sortBySortOrder).map(child => {
			return (
				<ChildRow
					viewer={viewer}
					child={child}
					projectId={projectId}
					isEstimatedInHours={isEstimatedInHours}
					disabled={disabled}
					hideTimeRegistrations={hideTimeRegistrations}
					isClientUser={isClientUser()}
					projectPersons={projectPersons}
					showDeletePrompt={showDeletePrompt}
					statusOptions={statusOptions}
					onStatusColumnChange={onStatusColumnChange}
					history={history}
				/>
			);
		});

		return (
			<TaskHierarchySectionWrapper data-cy={'task-hierarchy-section'}>
				<HeaderWrapper onClick={() => setExpanded(!_expanded)}>
					<img src={SubtaskIcon} alt={formatMessage({id: 'common.sub_tasks'})} />

					<div className="title">{formatMessage({id: 'common.sub_tasks'})}</div>

					<ExpandIcon expanded={_expanded} />
				</HeaderWrapper>
				{_expanded && (
					<>
						{children.length > 0 && (
							<ChildrenList>
								<ChildrenColumnHeader firstColumn={true}>
									{formatMessage({id: 'common.subtask'})}
								</ChildrenColumnHeader>
								<ChildrenColumnHeader minWidth={0}>
									{formatMessage({id: 'common.assignees'})}
								</ChildrenColumnHeader>
								<ChildrenColumnHeader>{formatMessage({id: 'common.status'})}</ChildrenColumnHeader>
								<ChildrenColumnHeader rightAlign={true} withRightPadding={true}>
									{formatMessage({id: 'common.estimate'})}
								</ChildrenColumnHeader>
								{!hideTimeRegistrations ? (
									<ChildrenColumnHeader rightAlign={true}>
										{formatMessage({id: 'common.time_entries'})}
									</ChildrenColumnHeader>
								) : (
									<ChildrenColumnHeader />
								)}
								<ChildrenColumnHeader></ChildrenColumnHeader>
								{childrenList}
							</ChildrenList>
						)}
						{!disabled && (
							<NewSubtaskWrapper>
								{_addSubtaskSection ? (
									<CreateTaskWrapper>
										<NameInputWrapper>
											<BasicTextInput
												focusOnMount={true}
												value={_newSubTaskName}
												onChange={setNewSubTaskName}
												placeholder={intl.formatMessage({id: 'task_modal.new_subtask'})}
												callbackOnEnter={handleCreateSubTask}
												onBlur={handleTitleBlur}
											></BasicTextInput>
										</NameInputWrapper>
										<DropdownWrapper>
											<RoleDropdown
												selectedItems={_newSubTaskRole ? [_newSubTaskRole.id] : []}
												onSelect={id => assignRole(id)}
												onRemove={() => unassignRole()}
												dropdownAlignment={'left'}
												key={'assigned-role'}
												width={255}
												name={formatMessage({id: 'common.assign_role'})}
												isMultiSelect={false}
												isClearable={true}
												roles={companyRoles}
												optionsName={formatMessage({id: 'settings.roles'})}
												selectedGroupName={formatMessage({id: 'common.applied'})}
												disabled={
													_newSubTaskName === '' ||
													isClientActionsRestricted ||
													isClientViewRestricted
												}
												taskName={_newSubTaskName}
												projectId={projectId}
												useSuggestions
												usePortal
											/>
										</DropdownWrapper>
										<DropdownWrapper>
											<PersonDropdown
												persons={projectPersons.map(projectPerson => {
													return {node: projectPerson.node.person};
												})}
												name={formatMessage({id: 'common.assign_people'})}
												optionsName={formatMessage({id: 'common.persons'})}
												selectedGroupName={formatMessage({id: 'common.applied'})}
												selectedItems={_newSubTaskPersons.map(person => person.id)}
												selectedItemsPlaceholder={_newSubTaskPersons.map(person => {
													return {
														fullName: person.fullName,
														imageSource: profilePicSrc(person.profilePictureId),
													};
												})}
												disabled={
													_newSubTaskName === '' ||
													isClientActionsRestricted ||
													isClientViewRestricted
												}
												onSelect={ids => assignPerson(ids)}
												onRemove={ids => unassignPerson(ids)}
												dropdownAlignment={'left'}
												width={255}
												taskName={_newSubTaskName}
												projectId={projectId}
												useSuggestions
												showRole
												usePortal
											/>
										</DropdownWrapper>
										<EstimateInputWrapper>
											{isEstimatedInHours ? (
												<div style={{minWidth: '90px', marginRight: '4px'}}>
													<HoursInput
														value={_newSubTaskEstimate}
														hasError={_newSubTaskEstimate <= 0 && _newSubTaskEstimate !== null}
														placeholder={intl.formatMessage({id: 'common.estimate'})}
														mutation={setNewSubTaskEstimate}
														disabled={
															_newSubTaskName === '' ||
															isClientActionsRestricted ||
															hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES)
														}
														innerRef={input => (inputRef = input)}
														parentKeyDown={handlePressEnter}
													/>
												</div>
											) : (
												<AffixedInputWrapper
													parentKeyDown={handlePressEnter}
													placeholder={intl.formatMessage({id: 'common.estimate'})}
													disabled={
														_newSubTaskName === '' ||
														hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES)
													}
													customClassName={'hours-input'}
													value={_newSubTaskEstimate}
													callback={setNewSubTaskEstimate}
													affix={intl.formatMessage({id: 'common.points.short'})}
													innerRef={input => (inputRef = input)}
												/>
											)}
										</EstimateInputWrapper>
										<Button
											disabled={_newSubTaskName === ''}
											showText={false}
											iconPosition={Button.ICON_POSITION.LAST}
											size={Button.SIZE.STANDARD}
											icon={color => <PlusIcon color={color} />}
											variant={Button.VARIANT.GREEN_FILLED}
											onClick={handleCreateSubTask}
										/>
									</CreateTaskWrapper>
								) : (
									<NewSubtask onClick={() => setAddSubtaskSection(true)}>
										+ {intl.formatMessage({id: 'task_modal.new_subtask'})}
									</NewSubtask>
								)}
							</NewSubtaskWrapper>
						)}
					</>
				)}
			</TaskHierarchySectionWrapper>
		);
	}
);

const TaskHierarchySectionQuery = graphql`
	query TaskHierarchySection_Query($id: String) {
		viewer {
			component(name: "task_hierarchy_section")
			actualPersonId
			id
			...TaskHierarchySection_viewer @arguments(id: $id)
		}
	}
`;

export {TaskHierarchySectionQuery};

export default injectIntl(
	withSocketHandling(
		createFragmentContainer(TaskHierarchySection, {
			viewer: graphql`
				fragment TaskHierarchySection_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
					actualPersonId
					parentTask: task(companyTaskId: $id) {
						id
						name
						childrenTasks(first: 1000000) @connection(key: "ParentTask_childrenTasks", filters: []) {
							edges {
								node {
									id
									name
									childrenCount
									rollupEstimate
									rollupTimeEntries
									parentTaskId
									companyTaskId
									estimateForecast
									userCanDeleteTask
									jiraId
									sortOrder
									statusColumnV2 {
										id
										name
									}
									assignedPersons {
										id
										fullName
										profilePictureId
										profilePictureDefaultId
									}
									timeRegistrations(first: 1000000) {
										edges {
											node {
												id
												minutesRegistered
											}
										}
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
