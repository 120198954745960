import Moment from 'moment';
import {DATE_FORMAT_DAY, MODULE_TYPES, PROJECT_STATUS, SCHEDULING_VIEW} from '../../../constants';
import ColorManipulation from 'color';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {getStaticDayData} from '../static/serializationUtil';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {drawTimeOffSection} from '../DrawingUtils';
import DataManager from '../DataManager';
import {getNonWorkingDays, isPersonDayOff} from '../heatmap/NonWorkingDaysLogic';
import {getEyeOptionStorageKey, isTaskItem} from '../SchedulingUtils';
import {TOTAL_RESOURCE_UTILIZATION_GROUP_ID} from '../IDManager';
import {EYE_OPTION_NAME} from '../constants';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import ComposeManager from '../ComposeManager';
import ProjectGroup from '../components/groups/project_group';
import {PLACEHOLDERS_EYE_OPTIONS} from '../placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {canApproveAllocation, hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {SCALE_SETTINGS, STEP_LABEL_FORMATS} from './canvas_timeline';
import Util from '../../../forecast-app/shared/util/util';
import {
	getPersonWorkingHoursAndMinutes,
	removeHolidaysFromWeekdays,
	removeWeekdaysOutsidePersonTimePeriod,
} from '../heatmap/HeatmapLogic';
import {getWeekdaysBetweenDatesCached, getWeekdaysBetweenDatesNew} from '../../scheduling/project_allocation_logic';

export const MIN_DATE_YEAR = 2000;
export const YEAR_SPAN = 50;
export const CANVAS_DATE_DAYS_OFFSET_FIRST_WEEK = 2;

//Group section width in pixels
export const GROUP_SECTION_WIDTH = 351;

//How wide (in pixels) does a draggable item have to be for the drag handles to appear when you mouse over it
export const DRAG_HANDLE_VISIBILITY_THRESHOLD = 54;

//Maximum display length of a name in pixels
const DEFAULT_MAX_TEXT_WIDTH = 160;

// Items
export const DISABLED_ITEM_COLOR = '#d4d4d4';

// PLACEHOLDER GROUP
export const STAFFING_MODE_MARGIN_TOP_BOTTOM = 22;

// TASK ITEM
export const TASK_ITEM_TIMELINE_BACKGROUND_COLOR = '#E3E3ED';
export const TASK_ITEM_DONE_BACKGROUND_COLOR = '#6BD675';
export const TASK_ITEM_ICON_WIDTH = 16;
export const TASK_ITEM_ICON_MARGIN = 5;
export const TASK_ITEM_MIDDLE_MARGIN = 16;
export const TASK_ITEM_CHECKMARK_SIZE = 12;
export const TASK_ITEM_TEXT_ICON_SPACING = 8;
export const TASK_ITEM_MINIMUM_TRIM = 45;

//Date steps total height
export const DATE_STEPS_SECTION_BACKGROUND_COLOR = '#ffffff';
export const DATE_STEPS_SECTION_HEIGHT = 56;
export const DATE_STEPS_SECTION_SHADOW_COLOR = '#e0e0e0';
export const DATE_STEPS_SECTION_SHADOW_BLUR = 10;

// expand icon
export const EXPAND_ICON_WIDTH = 10;
export const EXPAND_ICON_HEIGHT = 4;

// group section
export const GROUP_SECTION_MARGIN_LEFT = 24;
export const GROUP_SECTION_PADDING_LEFT = 18;
export const GROUP_SECTION_PADDING_LEFT_SMALL = 6.5;
export const GROUP_SECTION_SPACING_LEVEL_ONE = GROUP_SECTION_MARGIN_LEFT + GROUP_SECTION_PADDING_LEFT;
export const GROUP_SECTION_SPACING_LEVEL_TWO = GROUP_SECTION_SPACING_LEVEL_ONE + GROUP_SECTION_MARGIN_LEFT;
export const GROUP_SECTION_SPACING_LEVEL_THREE = GROUP_SECTION_SPACING_LEVEL_TWO + GROUP_SECTION_PADDING_LEFT;
export const GROUP_SECTION_SPACING_LEVEL_FOUR = GROUP_SECTION_SPACING_LEVEL_THREE + GROUP_SECTION_PADDING_LEFT;
export const GROUP_SECTION_TEXT_GREY = '#7C7D8D';
export const GROUP_SECTION_TEXT_GREY_DARK = '#393946';
export const GROUP_SECTION_PROJECT_BORDER_WIDTH = 4;
export const GROUP_SECTION_BADGE_PADDING_VERTICAL = 8;
export const GROUP_SECTION_BADGE_PADDING_HORIZONTAL = 16;
export const GROUP_SECTION_BACKGROUND_COLOR = '#ffffff';
export const GROUP_SECTION_ACTION_BUTTON_SIZE = 30;
export const GROUP_SECTION_ACTIONS_MENU = 15;
export const GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE = 320;
export const GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE = 24;
export const GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE = 14;
export const GROUP_SECTION_SHADOW_COLOR = '#a6a6a6';
export const GROUP_SECTION_SHADOW_BLUR = 10;
export const GROUP_SECTION_EXPAND_ICON_WIDTH = 12;
export const GROUP_SECTION_EXPAND_ICON_HEIGHT = 6;
export const GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH = 13;
export const GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT = 13;
export const GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_MARGIN_LEFT = 6;
export const GROUP_SECTION_CAPACITY_PLACEHOLDER_GROUP_HEIGHT = 48;
export const GROUP_SECTION_PLACEHOLDER_AVATAR_SIZE = 16;
export const GROUP_SECTION_PROJECT_BADGE_BORDER_RADIUS = 4;
export const GROUP_SECTION_PROJECT_BADGE_HEIGHT = 20;
export const GROUP_SECTION_PROJECT_BADGE_WIDTH = 20;
export const GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP = 0.5;
export const GROUP_SECTION_PROGRAM_BADGE_GAP = 2;
export const GROUP_SECTION_PROGRAM_BADGE_PREFIX_GAP = 12;

// timeline
export const TIMELINE_BAR_BORDER_RADIUS = 4;
export const TIMELINE_GREY_TASK_BAR_COLOR = '#D8D8E3';
export const TIMELINE_BAR_PADDING_X = 10;
export const TIMELINE_BACKGROUND_COLOR = '#F7F7FE';
export const TIMELINE_HEATMAP_BACKGROUND_COLOR = '#F1F1FD';
export const TIMELINE_HEATMAP_TIME_OFF_BACKGROUND_COLOR = '#E7E7F3';
export const TIMELINE_HEATMAP_ITEM_HEIGHT = 48;
export const TIMELINE_BORDER_COLOR = '#EBEBEE';
export const TIMELINE_EXPANDED_GROUND_HIGHLIGHT_COLOR = '#E7E7F34D';

// schedule people
export const SCHEDULE_PEOPLE_HEATMAP_TIME_OFF_BACKGROUND_COLOR = '#F1F1FD';
export const SCHEDULE_PEOPLE_HEATMAP_ITEM_SIZE = 48;
export const SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR = '#d9d9d9';
export const SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR = 5;
export const SCHEDULE_PEOPLE_NON_PROJECT_TIME_COLOR = '#C9C9D4';
export const SCHEDULE_PEOPLE_PERSON_GROUP_COMPLETION_COLOR = '#ADEBB3';
export const SCHEDULE_PEOPLE_PERSON_GROUP_ACTUAL_TIME = '#E5F4FF';

// heatmap
export const HEATMAP_CELL_BORDER_THICKNESS = 1;
export const HEATMAP_FONT_SIZE = 12;
export const HEATMAP_TIME_OFF_BACKGROUND_COLOR = '#F1F1FD';
export const HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE = 7;
export const HEATMAP_FULL_BACKGROUND_COLOR_DARK = '#7AD683';
export const HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR = '#FD8B86';
export const HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR_DARK = '#EF726C';
export const HEATMAP_OVER_ALLOCATED_PROGRESS_COLOR = '#FD9590';
export const HEATMAP_OVER_ALLOCATED_BACKGROUND_COLOR = '#FEB6B3';
export const HEATMAP_ACTUAL_COLOR = '#E5F4FF';
export const HEATMAP_ACTUAL_TEXT_COLOR = '#00589E';
export const HEATMAP_ACTUAL_BACKGROUND_COLOR = '#ADDBFF';
export const HEATMAP_CELL_DETAIL_BOX_WIDTH = 122;
export const HEATMAP_CELL_DETAIL_BOX_HEIGHT = 40;
export const HEATMAP_CELL_PADDING_X = 20;
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_WIDTH = 13;
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_GAP = 1;
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR = '#6BD675';
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR = '#EB5751';
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR_DARK = '#5FB467'; // '#43B24D';
export const HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR_DARK = '#E05852'; // '#E43C35';

// canvas button
export const CANVAS_BUTTON_DEFAULT_PADDING_TOP_BOTTOM = 8;
export const CANVAS_BUTTON_DEFAULT_PADDING_LEFT_RIGHT = 10;
export const CANVAS_BUTTON_DEFAULT_BORDER_RADIUS = 4;
export const CANVAS_BUTTON_DEFAULT_BORDER_THICKNESS = 1;
export const CANVAS_BUTTON_DEFAULT_BORDER_COLOR_HOVER = '#393946';
export const CANVAS_BUTTON_DEFAULT_BORDER_COLOR = '#727483';
export const CANVAS_BUTTON_DEFAULT_DISABLED_BORDER_COLOR = '#dbdbdb';
export const CANVAS_BUTTON_DEFAULT_TEXT_COLOR = '#727483';
export const CANVAS_BUTTON_DEFAULT_TEXT_COLOR_HOVER = '#393946';
export const CANVAS_BUTTON_GROUP_SECTION_PADDING_RIGHT = 16;

// canvas groups
export const CANVAS_GROUP_LINES_STROKE_COLOR_LIGHT = '#E7E7F3';

// allocation
export const ALLOCATION_TIMEOFF_OR_INTERNAL_TIME_ITEM_COLOR = '#C9C9D4';
export const ALLOCATION_BAR_HEIGHT = 24;

export const MONTH_NAMES_SHORT = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
export const DAY_NAMES = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const DAY_INDEX = {
	MONDAY: 0,
	TUESDAY: 1,
	WEDNESDAY: 2,
	THURSDAY: 3,
	FRIDAY: 4,
	SATURDAY: 5,
	SUNDAY: 6,
};

export const VISUALIZATION_MODE = {
	COMBINATION: 'combination',
	ALLOCATION: 'allocation',
	TASK_PLAN: 'taskPlan',
	TASK_ACTUAL: 'taskActual',
};

export const UTILIZATION_FORMAT = {
	HOURS: 'hours',
	PERCENTAGE: 'percentage',
};

// TOOLTIP
export const TOOLTIP_CURSOR_OFFSET = 15;
export const TOOLTIP_CURSOR_WINDOW_SPACING = 10;
export const TOOLTIP_DISPLAY_DELAY_MS = 200;

// GRAPH
export const TIMELINE_GRAPH_GROUP_TITLE_FONT_SIZE = 13;
export const TIMELINE_GRAPH_GROUP_VERTICAL_SPACING = 12;
export const TIMELINE_GRAPH_GROUP_ENTITY_TYPE_HEIGHT = 16;
export const TIMELINE_GRAPH_GROUP_PADDING = 20;
export const TIMELINE_GRAPH_GROUP_LEGEND_WIDTH = 16;
export const TIMELINE_GRAPH_GROUP_LEGEND_SPACING = 10;
export const TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT = 4;
export const TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH = 1;
export const TIMELINE_GRAPH_GROUP_BOTTOM_BORDER_BORDER_COLOR = '#D3D3DF';
export const TIMELINE_GRAPH_GROUP_BOTTOM_BORDER_BACKGROUND_COLOR = '#E7E7F3';
export const TIMELINE_GRAPH_ITEM_BAR_ELEMENT_SPACING = 10;
export const TIMELINE_GRAPH_ITEM_BAR_ELEMENT_BORDER_THICKNESS = 1;
export const TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT = 4;
export const TIMELINE_GRAPH_MAX_HEIGHT_PERCENTAGE = 90;
export const TIMELINE_GRAPH_LINE_WIDTH = 2;
export const TIMELINE_GRAPH_BACKGROUND_COLOR = '#F1F1FD';
export const TIMELINE_GRAPH_HEIGHT = 236;

export const TIMELINE_GRAPH_ENTITY_TYPE = {
	LINE: 'LINE',
	BAR_ELEMENT: 'BAR_ELEMENT',
	BAR_HIGHLIGHTER: 'BAR_HIGHLIGHTER',
};

export const TIMELINE_GRAPH_LINE_TYPE = {
	TOTAL_AVAILABILITY: 'TOTAL_AVAILABILITY',
};

export const TIMELINE_GRAPH_HIGHLIGHTER_TYPE = {
	ROLE_OVERALLOCATED: 'ROLE_OVERALLOCATED',
};

export const TIMELINE_GRAPH_BAR_ELEMENT_TYPE = {
	REMAINING_AVAILABILITY: 'REMAINING_AVAILABILITY',
	PLACEHOLDER: 'PLACEHOLDER',
	SOFT_ALLOCATION: 'SOFT_ALLOCATION',
	ALLOCATION: 'ALLOCATION',
};

export const TIMELINE_GRAPH_COLORS = {
	[TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY]: {
		STROKE: '#FFAD0A',
	},
	[TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED]: {
		STROKE: '#FD8B86',
	},
	[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.REMAINING_AVAILABILITY]: {
		BACKGROUND: '#FFFFFF',
		BORDER: '#F1F1FD',
		HIDE_BAR_ELEMENT_BORDER: true,
		IS_REMAINING: true,
	},
	[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER]: {
		BACKGROUND: '#F0E7FE',
		BORDER: '#6E0FEB',
		IS_BORDER_DASHED: true,
	},
	[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION]: {
		BACKGROUND: '#B27DF7',
		BORDER: '#6E0FEB',
	},
	[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION]: {
		BACKGROUND: '#6E0FEB',
		BORDER: '#6E0FEB',
		HIDE_BAR_ELEMENT_BORDER: true,
	},
};

export const GROUP_TYPE = {
	NON_PROJECT_TIME: 1,
	PEOPLE_SCHEDULING_TASK: 3,
	PEOPLE_SCHEDULING_UNASSIGNED_ROLE: 4,
	PEOPLE_SCHEDULING_UNASSIGNED_ROLE_TASK: 5,
	PROJECT_SCHEDULING_PHASE: 7,
	PROJECT_SCHEDULING_PROJECT_SUB_GROUP: 10,
	PEOPLE_SCHEDULING_COMPANY_UTILIZATION: 12,
	PEOPLE_SCHEDULING_PERSON_ALLOCATIONS: 13,
	LOADING: 15,
	CAPACITY_PLACEHOLDER_GROUP: 17,
	PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP: 18,
	PLACEHOLDERS_SCHEDULING_STAFFING_GROUPING_GROUP: 19,
	CAPACITY_OVERVIEW_ENTITY_GROUPING_GROUP: 20,
	TIMELINE_GRAPH_GROUP: 22,
	PROGRAM: 23,
	NO_CONTENT: 24,
	PERSON: 25,
	PROJECT: 26,
	TASK: 27,
	PHASE: 28,
	TOTAL_RESOURCE_UTILIZATION: 29,
	PERSON_GROUPING_GROUP: 30,
	PROJECT_ENTITY_GROUP: 31,
};

export const ITEM_TYPE = {
	PROJECT_SCHEDULING_PROJECT: 0,
	PROJECT_SCHEDULING_PHASE: 1,
	TASK: 2,
	PROJECT_ALLOCATION: 5,
	PEOPLE_SCHEDULING_UNASSIGNED_TASK: 9,
	PEOPLE_SCHEDULING_UNASSIGNED_HEATMAP: 10,
	PROJECT_SCHEDULING_TEAM_ITEM: 13,
	PLACEHOLDER_ALLOCATION: 15,
	TIMELINE_GRAPH_ITEM: 17,
	HEATMAP_ITEM: 18,
};

export const CURSOR = {
	DEFAULT: 'default',
	POINTER: 'pointer',
	CREATE: 'grabbing',
	RESIZE: 'ns-resize',
	RESIZE_HORIZONTAL: 'ew-resize',
};

export const DRAGGABLE_TYPE = {
	DATE_ONLY: 'dateOnly',
	DATE_AND_GROUP: 'dateAndGroup',
};

export const ITEM_DRAG_POINT = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
};

//Height of the separator between collapsable section and main section in pixels
export const SECTION_SPLITTER_HEIGHT = 12;

export const AUTO_SCHEDULING_PERSON_WIDTH = 24;

export const ITEM_POSITION_CHANGE_ANIMATION_DURATION = 200;
export const GROUP_HIGHLIGHT_ANIMATION_DURATION = 400;

const measureTextCache = new Map();

/***
 * This method should only be used to initialize schedulingOptions on the different scheduling pages.
 *
 * It uses a combination of company settings and local storage to determine what the default value for
 * schedulingOptions should be.
 *
 * @param company
 * @param storageKey - local storage key selected VISUALIZATION_MODE is stored
 * @param ignoreMixedMode - on pages where mixed mode is not supported we use this boolean to fall back to allocation mode
 * @returns {string} - VISUALIZATION_MODE
 */
export const getSchedulingOptionVisualizationMode = (company, storageKey = null, ignoreMixedMode = true) => {
	let companyVisualizationMode;

	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

	if (ignoreMixedMode && isMixedAllocationModeEnabled) {
		companyVisualizationMode = VISUALIZATION_MODE.ALLOCATION;
	} else if (isMixedAllocationModeEnabled) {
		companyVisualizationMode = hasFeatureFlag('scheduling_disable_combined_view')
			? VISUALIZATION_MODE.ALLOCATION
			: VISUALIZATION_MODE.COMBINATION;
	} else if (company.isUsingProjectAllocation) {
		companyVisualizationMode = VISUALIZATION_MODE.ALLOCATION;
	} else if (company.isUsingSchedulingPlanMode && !company.isUsingProjectAllocation) {
		companyVisualizationMode = VISUALIZATION_MODE.TASK_PLAN;
	} else if (!company.isUsingSchedulingPlanMode && !company.isUsingProjectAllocation) {
		companyVisualizationMode = VISUALIZATION_MODE.TASK_ACTUAL;
	}
	const isCompanyInCombinationMode = !ignoreMixedMode && isMixedAllocationModeEnabled;
	let localStorageVisualizationMode;

	if (storageKey) {
		localStorageVisualizationMode = localStorage.getItem(storageKey);
		// If the company settings has been changed to something not compatible with the option stored in local storage
		// reset storage and ignore stored setting
		if (
			(localStorageVisualizationMode === VISUALIZATION_MODE.TASK_PLAN && !company.isUsingSchedulingPlanMode) ||
			(localStorageVisualizationMode === VISUALIZATION_MODE.TASK_ACTUAL && company.isUsingSchedulingPlanMode) ||
			(localStorageVisualizationMode === VISUALIZATION_MODE.COMBINATION &&
				hasFeatureFlag('scheduling_disable_combined_view'))
		) {
			localStorage.removeItem(storageKey);
			localStorageVisualizationMode = null;
		}
	}
	return isCompanyInCombinationMode && localStorageVisualizationMode
		? localStorageVisualizationMode
		: companyVisualizationMode;
};

/***
 * This method is used to get the current VISUALIZATION_MODE based on current scheduling page's schedulingOptions and company settings.
 *
 * @param schedulingOptions - schedulingOptions from the current scheduling page
 * @param company
 * @param hasVisualizationMode - VISUALIZATION_MODE to check for
 * @returns {boolean} - specifies whether VISUALIZATION_MODE argument is active
 */
export const getVisualizationMode = (schedulingOptions, company, hasVisualizationMode) => {
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

	// If the company is in mixed mode, we use the VISUALIZATION_MODE set in the schedulingOptions
	if (isMixedAllocationModeEnabled) {
		return schedulingOptions.visualizationMode === hasVisualizationMode;
	} else {
		// If the company is not in mixed mode, we use the allocation mode set on the company
		switch (hasVisualizationMode) {
			case VISUALIZATION_MODE.ALLOCATION:
				return company.isUsingProjectAllocation;
			case VISUALIZATION_MODE.TASK_PLAN:
				return company.isUsingSchedulingPlanMode && !company.isUsingProjectAllocation;
			case VISUALIZATION_MODE.TASK_ACTUAL:
				return !company.isUsingSchedulingPlanMode && !company.isUsingProjectAllocation;
		}
	}
};

export const isTaskVisualizationMode = (schedulingOptions, company) => {
	return (
		getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL) ||
		getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_PLAN)
	);
};

export const getCombinedTaskMode = company => {
	if (company.isUsingSchedulingPlanMode) {
		return VISUALIZATION_MODE.TASK_PLAN;
	} else if (!company.isUsingSchedulingPlanMode) {
		return VISUALIZATION_MODE.TASK_ACTUAL;
	}
};

export const getVisualizationModeDropdownOptions = (company, formatMessage) => {
	const visualizationModeDropdownOptions = [];

	if (company.isUsingSchedulingPlanMode) {
		visualizationModeDropdownOptions.push({
			value: VISUALIZATION_MODE.TASK_PLAN,
			label: formatMessage({id: 'scheduling.visualization_mode.task'}),
		});
	} else {
		visualizationModeDropdownOptions.push({
			value: VISUALIZATION_MODE.TASK_ACTUAL,
			label: formatMessage({id: 'scheduling.visualization_mode.task'}),
		});
	}

	visualizationModeDropdownOptions.push({
		value: VISUALIZATION_MODE.ALLOCATION,
		label: formatMessage({id: 'scheduling.visualization_mode.allocation'}),
	});

	if (!hasFeatureFlag('scheduling_disable_combined_view')) {
		visualizationModeDropdownOptions.push({
			value: VISUALIZATION_MODE.COMBINATION,
			label: formatMessage({id: 'scheduling.visualization_mode.combination'}),
		});
	}
	return visualizationModeDropdownOptions;
};

export const getAnonymizedId = anonymizedEntity => {
	return anonymizedEntity.anonymizedProjectGroupId || anonymizedEntity.anonymizedProjectId;
};

const setMeasureTextData = (maxTextWidth, text, trimmedText) => {
	const width = Math.floor(maxTextWidth);
	let map = measureTextCache.get(width);
	if (!map) {
		map = new Map();
		map.set(text, trimmedText);
		measureTextCache.set(width, map);
	} else {
		map.set(text, trimmedText);
	}
};

const getMeasureTextData = (maxTextWidth, text) => {
	const outerMap = measureTextCache.get(Math.floor(maxTextWidth));
	if (!outerMap) return null;
	return outerMap.get(text);
};

let cachedCompanyDefaultWorkingDays = undefined;
export const getCompanyDefaultWorkingDays = company => {
	if (!cachedCompanyDefaultWorkingDays) {
		cachedCompanyDefaultWorkingDays = DAY_NAMES.filter(day => company[day] > 0);
	}
	return cachedCompanyDefaultWorkingDays;
};

export const getMinDate = () => Moment().year(MIN_DATE_YEAR).startOf('year');
export const getMaxDate = () => getMinDate().add(YEAR_SPAN, 'years').endOf('year');

export const getCanvasTimelineDateFromMoment = momentDate => {
	if (!momentDate.isValid()) {
		return 0;
	}
	return momentDate.diff(getMinDate(), 'days', false);
};

export const getCanvasTimelineDateFromStringDate = stringDate => {
	return stringDate ? getCanvasTimelineDateFromMoment(Moment(stringDate, 'YYYY-MM-DD')) : null;
};

export const getMomentFromCanvasTimelineDate = canvasTimelineDate => {
	return getMinDate().add(Math.ceil(canvasTimelineDate), 'days');
};

// javascript getDay returns an integer from 0-6 where sunday is 0 and saturday 6
// this function returns an integer from 0-6 where monday is 0 and sunday 6
export const getIsoWeekFromDate = date => {
	const day = date.getDay();
	return day === 0 ? 6 : day - 1;
};

const getDateWithoutTime = date => {
	date.setHours(0, 0, 0, 0);
	return date;
};

const MIN_DATE_YEAR_IN_MS = new Date(Date.UTC(MIN_DATE_YEAR, 0, 1)).getTime();
const MS_PER_DAY = 24 * 60 * 60 * 1000;
export const getDateFromCanvasTimelineDate = canvasTimelineDate => {
	const canvasTimelineDateInMilliseconds = canvasTimelineDate * MS_PER_DAY;
	const dateFromCanvasTimelineDate = new Date(MIN_DATE_YEAR_IN_MS + canvasTimelineDateInMilliseconds);
	return getDateWithoutTime(dateFromCanvasTimelineDate);
};

export const getCanvasTimelineDate = (year, month, day) => {
	const date = new Date(Date.UTC(year, month - 1, day));
	return Math.floor((date.getTime() - MIN_DATE_YEAR_IN_MS) / MS_PER_DAY);
};

export const dateIsBetweenOrSame = (date, startDate, endDate) => {
	const dateWithoutTime = getDateWithoutTime(date);
	const startDateWithoutTime = getDateWithoutTime(startDate);
	const endDateWithoutTime = getDateWithoutTime(endDate);

	const isSameAsStartDate = dateWithoutTime.getTime() === startDateWithoutTime.getTime();
	const isSameAsEndDate = dateWithoutTime.getTime() === endDateWithoutTime.getTime();
	const isBetween =
		dateWithoutTime.getTime() > startDateWithoutTime.getTime() && dateWithoutTime.getTime() < endDateWithoutTime.getTime();

	return isSameAsStartDate || isSameAsEndDate || isBetween;
};

const canvasDateIsSameOrBefore = (canvasDate, canvasCompareDate) => {
	return canvasDate <= canvasCompareDate;
};

const canvasDateIsSameOrAfter = (canvasDate, canvasCompareDate) => {
	return canvasDate >= canvasCompareDate;
};

export const withinTimePeriod = (item, canvasStartDate, canvasEndDate) => {
	const {startDate, endDate} = item;
	return canvasDateIsSameOrBefore(startDate, canvasEndDate) && canvasDateIsSameOrAfter(endDate, canvasStartDate);
};

const canvasTimelineDateCache = new Map();

export const createCanvasTimelineDate = (year, month, day) => {
	let yearCache = canvasTimelineDateCache.get(year);
	if (!yearCache) {
		yearCache = new Map();
		canvasTimelineDateCache.set(year, yearCache);
	}
	let monthCache = yearCache.get(month);
	if (!monthCache) {
		monthCache = new Map();
		yearCache.set(month, monthCache);
	}
	let canvasTimelineDate = monthCache.get(day);
	if (canvasTimelineDate === undefined) {
		canvasTimelineDate = getCanvasTimelineDate(year, month, day);
		monthCache.set(day, canvasTimelineDate);
	}
	return canvasTimelineDate;
};

export const setInitialExpansion = (pageComponent, groups) => {
	const processGroup = group => {
		if (pageComponent.props.expansionMap?.size) {
			const shouldChangeGroup = (pageComponent.props.expansionMap.get(group.id) || false) !== group.expanded;

			if (shouldChangeGroup) {
				group.toggleExpansion();
			}
		}

		if (group.groups) {
			for (const childGroup of group.groups) {
				if (!childGroup.parentGroup) {
					childGroup.parentGroup = group;
				}

				processGroup(childGroup);
			}
		}
	};

	groups.forEach(group => processGroup(group));
};

export const getGroupsBy = (pageComponent, predicate) => {
	const {groups} = pageComponent.state;
	const unfilteredGroups = [];

	const addGroupAndSubGroups = group => {
		unfilteredGroups.push(group);

		if (group.groups) {
			group.groups.forEach(subGroup => addGroupAndSubGroups(subGroup));
		}
	};

	groups.forEach(group => addGroupAndSubGroups(group));

	return unfilteredGroups.filter(predicate);
};

export const isProjectPlanning = projectStatus => {
	return [PROJECT_STATUS.PLANNING, PROJECT_STATUS.OPPORTUNITY].includes(projectStatus);
};

export const isProjectDoneOrHalted = projectStatus => {
	return ['DONE', 'HALTED'].includes(projectStatus);
};

export const allowAllocationEdit = allocation => allocation.idleTimeId || allocation.isSoft || canApproveAllocation();

export const areItemDatesValid = (itemStartDate, itemEndDate) => {
	//The dates passed here should be dates already converted to canvas timeline dates through getCanvasTimelineDateFromMoment of createCanvasTimelineDate
	return itemStartDate && itemEndDate && itemEndDate >= itemStartDate;
};

const isDark = color => {
	let brightness = 255;
	const brightnessThreshold = 165;

	try {
		const c = parseInt(color.slice(1), 16);
		const r = c >> 16;
		const g = (c >> 8) & 0x00ff;
		const b = c & 0x0000ff;

		brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
	} catch (e) {}
	return brightness < brightnessThreshold;
};

export const getTextColor = (backgroundColor, lightOption = '#ffffff', darkOption = '#2a2a2a') => {
	if (!backgroundColor) return '#535353';
	const useLightText = isDark(backgroundColor);
	return useLightText ? lightOption : darkOption === '#2a2a2a' ? GROUP_SECTION_TEXT_GREY_DARK : darkOption;
};

export const isFreeDayItem = item => item?.data?.isDayOffItem;

export const isHolidayStep = (pageComponent, startDate, endDate) => {
	const {company, holidayCalendarEntries} = pageComponent.state.data;

	if (holidayCalendarEntries?.length > 0) {
		const {defaultHolidayCalendarId} = company;

		for (const holidayCalendarEntry of holidayCalendarEntries) {
			const {holidayCalendarId} = holidayCalendarEntry;

			if (holidayCalendarId === defaultHolidayCalendarId) {
				const {year, month, day} = holidayCalendarEntry;

				const holidayCanvasDate = createCanvasTimelineDate(year, month, day);
				if (startDate === holidayCanvasDate && endDate === holidayCanvasDate) {
					return true;
				}
			}
		}
	}

	return false;
};

const personDatesCache = new Map();
const isDateOutsidePersonDates = (pageComponent, date, personId) => {
	if (personId) {
		if (!personDatesCache.has(personId)) {
			const person = DataManager.getPersonById(pageComponent, personId);

			const personDateData = {
				startDate: person.startDate || null,
				endDate: person.endDate || null,
			};

			personDatesCache.set(personId, personDateData);
		}

		const {startDate, endDate} = personDatesCache.get(personId);

		if (startDate !== null && date < startDate) {
			return true;
		}

		if (endDate !== null && date > endDate) {
			return true;
		}
	}

	return false;
};

const dayOffCache = new Map();
export const isDayOffStep = (pageComponent, stepData, personId) => {
	const {startDate, endDate, nonWorkingDaysMap} = stepData;

	if (startDate === endDate) {
		const {isWeekend} = stepData;

		if (!dayOffCache.has(startDate)) {
			if (hasFeatureFlag('inverted_pto_non_working_days')) {
				dayOffCache.set(startDate, isWeekend || isHolidayStep(pageComponent, startDate, endDate));
			} else {
				dayOffCache.set(startDate, isWeekend || isPersonDayOff(startDate, personId, nonWorkingDaysMap));
			}
		}

		return dayOffCache.get(startDate) || isDateOutsidePersonDates(pageComponent, startDate, personId);
	}

	return false;
};

export const drawTimeOffBackground = (canvasContext, items) => {
	let section = [];

	for (const item of items) {
		if (!item?.data) {
			continue;
		}

		const {isDayOffItem, isTimeOffItem} = item.data;

		if (!isDayOffItem && !isTimeOffItem) {
			continue;
		}

		const previousSectionItem = section.length > 0 ? section[section.length - 1] : null;

		// first item of new section
		if (!previousSectionItem && !isFreeDayItem(item)) {
			section.push(item);
			continue;
		}

		// free day item
		if (isFreeDayItem(item)) {
			if (previousSectionItem) {
				drawTimeOffSection(canvasContext, section);
				section = [];
			}

			continue;
		}

		// item is placed next to previous item
		const itemPlacedNextToPreviousItem = item.data.x <= previousSectionItem.data.x + previousSectionItem.data.width;
		if (itemPlacedNextToPreviousItem) {
			section.push(item);
			continue;
		}

		// start of new section item
		drawTimeOffSection(canvasContext, section);
		section = [item];
	}

	if (section.length > 0) {
		drawTimeOffSection(canvasContext, section);
	}
};

export const drawLine = (context, x1, y1, x2, y2, options = {}) => {
	context.strokeStyle = options.color || '#ebebee';
	context.lineWidth = options.lineWidth || 1;

	if (options.dashedLine) {
		context.setLineDash(options.dashedLinePattern || [10, 10]);
	}

	// start
	context.beginPath();
	context.moveTo(x1, y1);

	// finnish
	context.lineTo(x2, y2);
	context.stroke();

	if (options.dashedLine) {
		context.setLineDash([]);
	}
};

export const isAllocatedHoursNaN = number => !(number <= 0) && !(number > 0);

export const getNumber = value => {
	if (!isAllocatedHoursNaN(value)) {
		return value;
	}

	return 0;
};

export const drawRectangle = (context, x, y, width, height, options = {}) => {
	const backgroundColor = options.backgroundColor || '#ffffff';
	const backgroundOpacity = options.backgroundOpacity !== undefined ? options.backgroundOpacity : 1;
	const borderThickness = options.borderThickness || 0;
	const borderColor = options.borderColor || '#ffffff';
	//Do not allow the sum of top/bottom border radius to exceed width
	const maxBorderRadius =
		width /
		(((options.borderTopLeftRadius || options.borderRadius) && (options.borderTopRightRadius || options.borderRadius)) ||
		((options.borderBottomLeftRadius || options.borderRadius) && (options.borderBottomRightRadius || options.borderRadius))
			? 2
			: 1);
	const borderTopLeftRadius = Math.min(options.borderTopLeftRadius || options.borderRadius || 0, maxBorderRadius);
	const borderTopRightRadius = Math.min(options.borderTopRightRadius || options.borderRadius || 0, maxBorderRadius);
	const borderBottomLeftRadius = Math.min(options.borderBottomLeftRadius || options.borderRadius || 0, maxBorderRadius);
	const borderBottomRightRadius = Math.min(options.borderBottomRightRadius || options.borderRadius || 0, maxBorderRadius);

	const clearBeforeDrawing = options.clearBeforeDrawing || false;
	const isCornerTopRightCutOff = options.isCornerTopRightCutOff || false;
	const isCornerBottomRightCutOff = options.isCornerBottomRightCutOff || false;
	const isCornerBottomLeftCutOff = options.isCornerBottomLeftCutOff || false;
	const isCornerTopLeftCutOff = options.isCornerTopLeftCutOff || false;

	context.strokeStyle = borderColor;
	context.lineWidth = borderThickness;

	if (options.dashedBorder) {
		context.setLineDash(options.dashedBorderPattern || [10, 10]);
	}

	const lineOffset = borderThickness / 2;

	let triangleBase, triangleHeight;
	if (isCornerTopRightCutOff || isCornerTopLeftCutOff || isCornerBottomRightCutOff || isCornerBottomLeftCutOff) {
		// in case the cut off corners are overlapping (meaning they do not fit in the rectangle) share the with of the rectangle between the cut off corners
		const maxCornerWidth =
			width /
			((isCornerTopLeftCutOff && isCornerTopRightCutOff) || (isCornerBottomLeftCutOff && isCornerBottomRightCutOff)
				? 2
				: 1);
		const angle = 30; // Degrees
		triangleBase = maxCornerWidth > 12 ? 12 : maxCornerWidth;
		triangleHeight = triangleBase * Math.tan((angle * Math.PI) / 180);
	}

	context.beginPath();
	context.moveTo(x + lineOffset + borderTopLeftRadius, y + lineOffset);

	// Top Right Corner
	if (borderTopRightRadius) {
		context.lineTo(x + width - lineOffset - borderTopRightRadius, y + lineOffset);
		context.arcTo(
			x + width - lineOffset,
			y + lineOffset,
			x + width - lineOffset,
			y + lineOffset + borderTopRightRadius,
			borderTopRightRadius
		);
	} else if (isCornerTopRightCutOff) {
		context.lineTo(x + width - lineOffset - triangleBase, y + lineOffset);
		context.lineTo(x + width - lineOffset, y + lineOffset + triangleHeight);
	} else {
		context.lineTo(x + width - lineOffset, y + lineOffset);
	}

	// Bottom Right Corner
	if (borderBottomRightRadius) {
		context.lineTo(x + width - lineOffset, y + height - lineOffset - borderBottomRightRadius);
		context.arcTo(
			x + width - lineOffset,
			y + height - lineOffset,
			x + width - lineOffset - borderBottomRightRadius,
			y + height - lineOffset,
			borderBottomRightRadius
		);
	} else if (isCornerBottomRightCutOff) {
		context.lineTo(x + width - lineOffset, y + height - lineOffset - triangleHeight);
		context.lineTo(x + width - lineOffset - triangleBase, y + height - lineOffset);
	} else {
		context.lineTo(x + width - lineOffset, y + height - lineOffset);
	}

	// Bottom Left Corner
	if (borderBottomLeftRadius) {
		context.lineTo(x + lineOffset + borderBottomLeftRadius, y + height - lineOffset);
		context.arcTo(
			x + lineOffset,
			y + height - lineOffset,
			x + lineOffset,
			y + height - lineOffset - borderBottomLeftRadius,
			borderBottomLeftRadius
		);
	} else if (isCornerBottomLeftCutOff) {
		context.lineTo(x + lineOffset + triangleBase, y + height - lineOffset);
		context.lineTo(x + lineOffset, y + height - lineOffset - triangleHeight);
	} else {
		context.lineTo(x + lineOffset, y + height - lineOffset);
	}

	// Top Left Corner
	if (borderTopLeftRadius) {
		context.lineTo(x + lineOffset, y + borderTopLeftRadius);
		context.arcTo(
			x + lineOffset,
			y + lineOffset,
			x + lineOffset + borderTopLeftRadius,
			y + lineOffset,
			borderTopLeftRadius
		);
	} else if (isCornerTopLeftCutOff) {
		context.lineTo(x + lineOffset, y + triangleHeight);
		context.lineTo(x + lineOffset + triangleBase, y + lineOffset);
	} else {
		context.lineTo(x + lineOffset, y);
	}

	if (clearBeforeDrawing) {
		context.fillStyle = '#ffffff';
		context.fill();
	}
	context.fillStyle = backgroundColor;
	if (backgroundOpacity !== 1) {
		context.globalAlpha = backgroundOpacity;
	}
	context.fill();
	context.globalAlpha = 1;
	//Draw border if there should be one
	if (borderThickness) {
		context.stroke();
	}

	if (options.dashedBorder) {
		context.setLineDash([]);
	}
};

export const getTaskProjectBackgroundColor = projectColor => {
	const baseColor = ColorManipulation(projectColor);
	return baseColor.fade(0.5);
};

export function drawHexagon(context, x, y, width, fillColor, fillOpacity, strokeColor, strokeWidth) {
	const fill = fillColor !== '';
	strokeWidth = strokeWidth || 2;

	context.fillStyle = fillColor;
	context.strokeStyle = strokeColor;
	context.lineWidth = strokeWidth;
	context.globalAlpha = fillOpacity || 1;

	let hexHeight,
		hexRadius,
		hexRectangleHeight,
		hexRectangleWidth,
		hexagonAngle = 0.523598776, // 30 degrees in radians
		sideLength = width / 2;

	hexHeight = Math.sin(hexagonAngle) * sideLength;
	hexRadius = Math.cos(hexagonAngle) * sideLength;
	hexRectangleHeight = sideLength + 2 * hexHeight;
	hexRectangleWidth = 2 * hexRadius;

	context.beginPath();
	context.moveTo(x + hexRadius, y);
	context.lineTo(x + hexRectangleWidth, y + hexHeight);
	context.lineTo(x + hexRectangleWidth, y + hexHeight + sideLength);
	context.lineTo(x + hexRadius, y + hexRectangleHeight);
	context.lineTo(x, y + sideLength + hexHeight);
	context.lineTo(x, y + hexHeight);
	context.closePath();

	fill ? context.fill() : context.stroke();
}

export function fillText2Colors(ctx, text, x, y, cutOverX, color1, color2) {
	const canvas = ctx.canvas;
	ctx.save();
	ctx.beginPath();
	ctx.fillStyle = color1;
	ctx.rect(0, 0, cutOverX, canvas.height);
	ctx.clip();
	ctx.fillText(text, x, y);
	ctx.restore();
	ctx.save();
	ctx.beginPath();
	ctx.rect(cutOverX, 0, canvas.width, canvas.height);
	ctx.clip();
	ctx.fillStyle = color2;
	ctx.fillText(text, x, y);
	ctx.restore();
}

//Drawing 1px grey line on the bottom and on the right
export const drawBorderLines = (context, x, y, width, height, useGreyColor, customColor, borderWidth = 1) => {
	context.fillStyle = customColor ? customColor : useGreyColor ? '#ebebee' : '#ffffff';
	context.fillRect(x, y + height - 1, width, borderWidth);
	context.fillStyle = useGreyColor ? '#ebebee' : '#ffffff';
	context.fillRect(width - 1, y, 1, height);
};

export const drawBorder = (context, x, y, length, vertical, color = '#ebebee', borderWidth = 1) => {
	context.fillStyle = color;
	context.fillRect(x, y + borderWidth - 1, vertical ? borderWidth : length, vertical ? length : borderWidth);
};

export const drawBackground = (context, x, y, width, height, useGreyColor, customColor) => {
	context.fillStyle = customColor ? customColor : useGreyColor ? TIMELINE_BACKGROUND_COLOR : '#ffffff';
	context.fillRect(x, y, width, height);
};

/*
 * If a name is too long, this will return the longest text that fits
 * within the maximum name length.
 */
export const getTrimmedText = (context, text = '', maxWidth = DEFAULT_MAX_TEXT_WIDTH) => {
	const cachedText = getMeasureTextData(maxWidth, text);

	if (cachedText) return cachedText;

	//The context that is passed to this function should have font size and style already specified before calling this function
	let substring = text;
	if (context.measureText(substring).width <= maxWidth) {
		setMeasureTextData(maxWidth, text, text);
		return text;
	}

	//Find the maximum number of characters that can fit within the given maxWidth
	let min = 0;
	let max = text.length;

	//Find the middle between text length and 0
	//If the text does not fit, then we know that the text has to be shorter than that middle
	//If the text fits, then we know it can be equal to or longer than the middle value
	//Repeat until we find the exact position
	while (min < max) {
		const mid = (min + max) >>> 1; //convert to int and divide by 2
		substring = text.substring(0, mid);
		const substringWidth = context.measureText(substring).width;
		if (substringWidth < maxWidth) {
			min = mid + 1;
		} else {
			max = mid;
		}
	}

	const trimmedText = substring.length ? substring + '...' : '';
	setMeasureTextData(maxWidth, text, trimmedText);

	return trimmedText;
};

export const drawArrows = (context, textColor, x, y, previousArrowIcon, nextArrowIcon, extramarginY, extraMarginX) => {
	if (previousArrowIcon && nextArrowIcon) {
		context.drawImage(
			previousArrowIcon,
			x + (extraMarginX ? extraMarginX : 0),
			y + (extramarginY ? extramarginY : 0),
			6,
			12
		);
		context.drawImage(
			nextArrowIcon,
			x + (11 + (extraMarginX ? extraMarginX : 0)),
			y + (extramarginY ? extramarginY : 0),
			6,
			12
		);
	}
};

export const addOriginalCopyToItem = item => {
	if (item.draggableType === DRAGGABLE_TYPE.DATE_AND_GROUP && item.canGhost && !item.originalItem) {
		item.originalItem = {
			groupId: item.groupId,
			data: item.data,
		};
	}
};

export const createCacheCanvas = (width, height) => {
	const {devicePixelRatio} = window;
	let canvas;
	if (window.OffscreenCanvas) {
		canvas = new OffscreenCanvas(width * devicePixelRatio, height * devicePixelRatio);
	} else {
		canvas = document.createElement('canvas');
		canvas.height = height * devicePixelRatio;
		canvas.width = width * devicePixelRatio;
	}
	const context = canvas.getContext('2d');
	context.scale(devicePixelRatio, devicePixelRatio);
	return canvas;
};

const getBusinessDays = (d1, d2) => {
	const days = d2.diff(d1, 'days') + 1;
	let newDay = d1.toDate();
	let workingDays = 0;
	for (let i = 0; i < days; i++) {
		const day = newDay.getDay();
		newDay = d1.add(1, 'days').toDate();
		const isWeekend = day % 6 === 0;
		if (!isWeekend) {
			workingDays++;
		}
	}
	return workingDays;
};

const addToMap = (key, value, map) => {
	const currentValue = map.get(key);
	if (currentValue) {
		map.set(key, currentValue + value);
	} else {
		map.set(key, value);
	}
	return map.get(key);
};

let workingDaysMap = null;

/**
 * Generate map containing working day sums for month, quarter and year groupings.
 *
 * month key format: 2000-1-1     (Month of January 2022, month keys are always the first day of the month)
 * quarter key format: 2000-1     (First quarter, starting in January, quarter keys are always the starting month of the quarter)
 * year key format: 2000          (Year 2000)
 */
const getWorkingDaysMap = () => {
	if (workingDaysMap) {
		return workingDaysMap;
	}
	workingDaysMap = new Map();

	const minDate = getMinDate();
	const maxDate = getMaxDate();
	const loopDate = minDate;
	while (loopDate.isBefore(maxDate)) {
		let workingDays = getBusinessDays(loopDate.clone().startOf('month'), loopDate.clone().endOf('month'));
		// Add year grouping sum
		addToMap(loopDate.year(), workingDays, workingDaysMap);
		// Add quarter grouping sum
		addToMap(loopDate.year() + '-' + (loopDate.clone().startOf('quarter').month() + 1), workingDays, workingDaysMap);
		// Add month grouping sum
		addToMap(loopDate.year() + '-' + (loopDate.month() + 1) + '-' + loopDate.date(), workingDays, workingDaysMap);
		loopDate.add(1, 'month');
	}

	return workingDaysMap;
};

let dayData = null;
let isSundayFirstDayOfWeekCache = null;

export const isSundayFirstDayOfWeek = () => {
	if (isSundayFirstDayOfWeekCache === null) {
		isSundayFirstDayOfWeekCache = Moment().weekday(0).isoWeekday() === 7;
	}
	return isSundayFirstDayOfWeekCache;
};

export const clearLocaleCache = () => {
	dayData = null;
	isSundayFirstDayOfWeekCache = null;
};

export const getDayData = () => {
	if (dayData) {
		return dayData;
	}
	const minDate = getMinDate();
	const maxDate = getMaxDate();

	const startPerformance = performance?.now() || 0;

	let workingDaysMap = getWorkingDaysMap();
	dayData = [];

	const loopDate = minDate;
	while (loopDate.isBefore(maxDate)) {
		const moment = loopDate.clone();
		const startOfMonth = moment.clone().startOf('month');
		const startOfQuarter = moment.clone().startOf('quarter');

		// Working day map keys
		const monthKey = startOfMonth.year() + '-' + (startOfMonth.month() + 1) + '-' + startOfMonth.date();
		const quarterKey = startOfQuarter.year() + '-' + (startOfQuarter.month() + 1);
		const yearKey = moment.year();

		dayData.push({
			moment,
			isoWeekday: moment.isoWeekday(),
			weekday: moment.weekday(),
			daysInWeek: 7,
			workingDaysInWeek: 5,
			daysInMonth: moment.daysInMonth(),
			workingDaysInMonth: workingDaysMap.get(monthKey),
			daysInQuarter: moment.clone().endOf('quarter').diff(startOfQuarter, 'days') + 1,
			workingDaysInQuarter: workingDaysMap.get(quarterKey),
			daysInYear: moment.isLeapYear() ? 366 : 365,
			workingDaysInYear: workingDaysMap.get(yearKey),
			isoWeek: moment.isoWeek(),
			week: moment.week(),
			dayOfMonth: moment.date(),
			dayOfQuarter: moment.diff(startOfQuarter, 'days') + 1,
			dayOfYear: moment.dayOfYear(),
		});
		loopDate.add(1, 'day');
	}

	console.log('getDayData took ' + ((performance.now() || 0) - startPerformance) + ' ms');

	return dayData;
};

export const getDayDataForStaticGeneration = locale => {
	const dayData = [];

	Moment.locale(locale);

	const minDate = getMinDate();
	const maxDate = getMaxDate();
	const workingDaysMap = getWorkingDaysMap();

	const loopDate = minDate;
	while (loopDate.isBefore(maxDate)) {
		const moment = loopDate.clone();

		const startOfWeek = moment.clone().startOf('week');
		const endOfWeek = moment.clone().endOf('week');
		const startOfMonth = moment.clone().startOf('month');
		const startOfYear = moment.clone().startOf('year');
		const startOfQuarter = moment.clone().startOf('quarter');
		const endOfQuarter = moment.clone().endOf('quarter');

		const canvasTimelineDate = getCanvasTimelineDateFromMoment(moment);

		// Working day map keys
		const monthKey = startOfMonth.year() + '-' + (startOfMonth.month() + 1) + '-' + startOfMonth.date();
		const quarterKey = startOfQuarter.year() + '-' + (startOfQuarter.month() + 1);
		const yearKey = moment.year();

		dayData.push({
			canvas_start_year: getCanvasTimelineDateFromMoment(startOfYear),
			canvas_start_quarter: getCanvasTimelineDateFromMoment(startOfQuarter),
			canvas_start_month: getCanvasTimelineDateFromMoment(startOfMonth),
			startOfQuarter: startOfQuarter.format(DATE_FORMAT_DAY),
			endOfQuarter: endOfQuarter.format(DATE_FORMAT_DAY),
			canvasTimelineDate,
			isoWeekday: moment.isoWeekday(),
			weekday: moment.weekday(),
			daysInWeek: 7,
			workingDaysInWeek: 5,
			daysInMonth: moment.daysInMonth(),
			workingDaysInMonth: workingDaysMap.get(monthKey),
			daysInQuarter: moment.clone().endOf('quarter').diff(startOfQuarter, 'days') + 1,
			workingDaysInQuarter: workingDaysMap.get(quarterKey),
			daysInYear: moment.isLeapYear() ? 366 : 365,
			workingDaysInYear: workingDaysMap.get(yearKey),
			isoWeek: moment.isoWeek(),
			week: moment.week(),
			startOfWeek: startOfWeek.format(DATE_FORMAT_DAY),
			endOfWeek: endOfWeek.format(DATE_FORMAT_DAY),
			dayOfMonth: moment.date(),
			dayOfQuarter: moment.diff(startOfQuarter, 'days') + 1,
			dayOfYear: moment.dayOfYear(),
			dateFormatted: moment.format(DATE_FORMAT_DAY),
		});

		loopDate.add(1, 'day');
	}

	return dayData;
};

export const getWeekdaysBetweenDates = (
	startDate,
	endDate,
	dayData = null,
	excludeStartDay,
	excludeEndDay,
	nonWorkingDaysMap,
	personId
) => {
	const hasInvertedPtoNonWorkingDays = hasFeatureFlag('inverted_pto_non_working_days');
	const weekdays = [0, 0, 0, 0, 0, 0, 0];

	if (hasFeatureFlag('static_day_data_generation')) {
		if (!dayData) {
			dayData = getStaticDayData();
		}

		for (let i = excludeStartDay ? 1 : 0; i < endDate - startDate + (excludeEndDay ? 0 : 1); i++) {
			const date = startDate + i;
			if (hasInvertedPtoNonWorkingDays || !isPersonDayOff(date, personId, nonWorkingDaysMap)) {
				weekdays[dayData[date].isoWeekday - 1]++;
			}
		}
	} else {
		for (let i = excludeStartDay ? 1 : 0; i < endDate - startDate + (excludeEndDay ? 0 : 1); i++) {
			const date = startDate + i;
			if (dayData === null) {
				dayData = getDayData();
			}
			if (hasInvertedPtoNonWorkingDays || !isPersonDayOff(date, personId, nonWorkingDaysMap)) {
				weekdays[dayData[date].isoWeekday - 1]++;
			}
		}
	}

	return weekdays;
};

export const dateLabelSize = {
	s: 's',
	m: 'm',
	l: 'l',
	full: 'full',
	fromColumnWidth: pixelWidth => {
		if (pixelWidth < 70) {
			return dateLabelSize.s;
		} else if (pixelWidth < 100) {
			return dateLabelSize.m;
		} else {
			return dateLabelSize.l;
		}
	},
};

function getWeekLabelStatic(dayData, formatMessage, formatDate, size) {
	if (size === dateLabelSize.full) {
		const startOfWeekDate = formatDate(dayData.startOfWeek, {month: 'long', day: 'numeric', timeZone: 'UTC'});
		const endOfWeekDate = formatDate(dayData.endOfWeek, {month: 'long', day: 'numeric', timeZone: 'UTC'});
		return startOfWeekDate + ' - ' + endOfWeekDate;
	} else {
		const endMonth = size === dateLabelSize.l ? 'long' : 'short';
		const startOfWeekDate = formatDate(dayData.startOfWeek, {day: 'numeric', timeZone: 'UTC'});
		const endOfWeekDate = formatDate(dayData.endOfWeek, {day: 'numeric', timeZone: 'UTC'});
		const endOfWeekMonth = formatDate(dayData.endOfWeek, {month: endMonth, timeZone: 'UTC'});
		const separator = size === dateLabelSize.s ? '-' : ' - ';
		return startOfWeekDate + separator + endOfWeekDate + ' ' + endOfWeekMonth;
	}
}

function getMonthLabelStatic(dayData, formatMessage, formatDate, size) {
	const month = size === dateLabelSize.full ? 'long' : 'short';
	return formatDate(dayData.dateFormatted, {month, timeZone: 'UTC'});
}

function getDayLabelStatic(dayDate, formatMessage, formatDate, size) {
	const weekday = size === dateLabelSize.full ? 'long' : 'short';
	return formatDate(dayDate.dateFormatted, {weekday, day: 'numeric', timeZone: 'UTC'});
}

function getQuarterLabelStatic(dayData, formatDate, size) {
	const month = size === dateLabelSize.full ? 'long' : 'short';
	const quarterStartDate = formatDate(dayData.startOfQuarter, {month, timeZone: 'UTC'});
	const quarterEndDate = formatDate(dayData.endOfQuarter, {month, timeZone: 'UTC'});
	if (size === dateLabelSize.full) {
		return `${quarterStartDate} - ${quarterEndDate}`;
	} else {
		return `${quarterStartDate}-${quarterEndDate}`;
	}
}

export const getStepLabelStatic = (stepLabelFormat, dayData, intl, size) => {
	const {formatDate, formatMessage} = intl;

	if (stepLabelFormat === STEP_LABEL_FORMATS.DAY) {
		return getDayLabelStatic(dayData, formatMessage, formatDate, size);
	} else if (stepLabelFormat === STEP_LABEL_FORMATS.WEEK) {
		return getWeekLabelStatic(dayData, formatMessage, formatDate, size);
	} else if (stepLabelFormat === STEP_LABEL_FORMATS.MONTH) {
		return getMonthLabelStatic(dayData, formatMessage, formatDate, size);
	} else if (stepLabelFormat === STEP_LABEL_FORMATS.QUARTER) {
		return getQuarterLabelStatic(dayData, formatDate, size);
	} else if (stepLabelFormat === STEP_LABEL_FORMATS.DAY_CANVAS_DATE) {
		return '' + dayData.canvasTimelineDate;
	} else {
		return formatDate(dayData.dateFormatted, {...stepLabelFormat, timeZone: 'UTC'});
	}
};

const getWorkingDaysBetweenCanvasTimelineDates = (start, end) => {
	const weekdaysInPeriod = getWeekdaysBetweenDates(start, end, null, false, false);
	return weekdaysInPeriod[0] + weekdaysInPeriod[1] + weekdaysInPeriod[2] + weekdaysInPeriod[3] + weekdaysInPeriod[4];
};

export const getWorkingDaysBetween = (d1, d2) => {
	const start = getCanvasTimelineDateFromMoment(d1);
	const end = getCanvasTimelineDateFromMoment(d2);
	return getWorkingDaysBetweenCanvasTimelineDates(start, end);
};

export const getStaticWorkingDaysBetween = (dayData, unit) => {
	const {canvasTimelineDate} = dayData;
	const unitStartCanvasDate = dayData[`canvas_start_${unit}`];
	return getWorkingDaysBetweenCanvasTimelineDates(unitStartCanvasDate, canvasTimelineDate);
};

export const getWorkingDaysForPeriodUnit = (dayData, unit) => {
	const dayDataStart = dayData.moment.clone();
	const dayDataEnd = dayData.moment;

	dayDataStart.startOf(unit);

	return getWorkingDaysBetween(dayDataStart, dayDataEnd);
};

export const getMinutesInWeekdays = (entity, weekdays) => {
	return weekdays.reduce((total, weekday, index) => total + entity[DAY_NAMES[index]] * weekday, 0);
};

/***
 *
 * @param allocation
 * @param weekdays - array containing numbers of occurring weekdays starting from monday
 * @returns {number}
 */
export const getMinutesInWeekdaysNew = (allocation, weekdays) => {
	let minutes = 0;

	minutes += allocation[DAY_NAMES[DAY_INDEX.MONDAY]] * weekdays[DAY_INDEX.MONDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.TUESDAY]] * weekdays[DAY_INDEX.TUESDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.WEDNESDAY]] * weekdays[DAY_INDEX.WEDNESDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.THURSDAY]] * weekdays[DAY_INDEX.THURSDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.FRIDAY]] * weekdays[DAY_INDEX.FRIDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.SATURDAY]] * weekdays[DAY_INDEX.SATURDAY];
	minutes += allocation[DAY_NAMES[DAY_INDEX.SUNDAY]] * weekdays[DAY_INDEX.SUNDAY];

	return minutes;
};

export const getWeekendDaysBefore = (item, timelineStartDate) => {
	return (
		getWeekdaysBetweenDates(Math.floor(timelineStartDate), item.startDate, null, false, true)[5] +
		getWeekdaysBetweenDates(Math.floor(timelineStartDate), item.startDate, null, false, true)[6]
	);
};

export const getWeekendDaysUntilTimeline = (item, timelineStartDate) => {
	return (
		getWeekdaysBetweenDates(item.startDate, Math.floor(timelineStartDate), null, false, true)[5] +
		getWeekdaysBetweenDates(item.startDate, Math.floor(timelineStartDate), null, false, true)[6]
	);
};

export const getWeekendDaysWithin = item => {
	return (
		getWeekdaysBetweenDates(item.startDate, item.endDate, null, false, false)[5] +
		getWeekdaysBetweenDates(item.startDate, item.endDate, null, false, false)[6]
	);
};

export const calculateItemX = (item, startDate, pixelsPerDay, hideWeekends) => {
	let weekendDaysBefore = 0;
	let weekendDaysUntilTimeline = 0;
	if (hideWeekends) {
		weekendDaysBefore = getWeekendDaysBefore(item, startDate);
		weekendDaysUntilTimeline = getWeekendDaysUntilTimeline(item, startDate);
	}

	return hideWeekends
		? (item.startDate - startDate - weekendDaysBefore + weekendDaysUntilTimeline) * pixelsPerDay
		: (item.startDate - startDate) * pixelsPerDay;
};

export const calculateItemWidth = (item, pixelsPerDay, hideWeekends) => {
	let weekendDaysWithin = 0;
	if (hideWeekends) {
		weekendDaysWithin = getWeekendDaysWithin(item);
	}

	return hideWeekends
		? (item.endDate - weekendDaysWithin - item.startDate + 1) * pixelsPerDay - 1
		: (item.endDate - item.startDate + 1) * pixelsPerDay - 1;
};

export const isDateWithinPersonStartEndDate = (person, canvasDate) => {
	return (
		(person.startDate === null || person.startDate <= canvasDate) &&
		(person.endDate === null || person.endDate >= canvasDate)
	);
};

export const getHolidayCalendar = (person, data) => {
	let holidayCalendar;
	if (person.holidayCalendarId) {
		holidayCalendar = data.holidayCalendarMap.get(person.holidayCalendarId);
	}
	if (!holidayCalendar && data.company.defaultHolidayCalendarId) {
		holidayCalendar = data.holidayCalendarMap.get(data.company.defaultHolidayCalendarId);
	}
	if (!holidayCalendar && data.holidayCalendars.length === 1) {
		holidayCalendar = data.holidayCalendars[0];
	}
	return holidayCalendar || null;
};

export const getHolidayDates = (person, data, personWorkingMinutes, dayDataArray) => {
	if (!dayDataArray) {
		dayDataArray = hasFeatureFlag('static_day_data_generation') ? getStaticDayData() : dayData;
	}

	const holidayCalendar = getHolidayCalendar(person, data);

	const holidaysIncludingDaysOff = [];
	const holidaysExcludingDaysOff = [];

	if (holidayCalendar) {
		const holidayCalendarEntries = data.holidayCalendarEntriesByCalendar[holidayCalendar.id] || [];

		for (const holidayCalendarEntry of holidayCalendarEntries) {
			const holidayDate = createCanvasTimelineDate(
				holidayCalendarEntry.year,
				holidayCalendarEntry.month,
				holidayCalendarEntry.day
			);
			// Ignore out-of-range holidays
			if (!dayDataArray[holidayDate]) continue;
			if (holidaysIncludingDaysOff.includes(holidayDate)) continue;
			holidaysIncludingDaysOff.push(holidayDate);
			if (!personWorkingMinutes[dayDataArray[holidayDate].isoWeekday - 1]) continue;
			holidaysExcludingDaysOff.push(holidayDate);
		}
	}

	return {holidaysIncludingDaysOff, holidaysExcludingDaysOff};
};

const HOLIDAY_DATES_CACHE = new Map();
export const getHolidayDatesCached = (person, data, personWorkingMinutes, dayDataArray) => {
	let cachedHolidayDates = HOLIDAY_DATES_CACHE.get(person.id);

	if (!cachedHolidayDates) {
		cachedHolidayDates = getHolidayDates(person, data, personWorkingMinutes, dayDataArray);
		HOLIDAY_DATES_CACHE.set(person.id, cachedHolidayDates);
	}

	return cachedHolidayDates;
};

const doGetAllGroupsOfType = (groups, groupType) => {
	let matchingGroups = [];
	if (groups) {
		for (const group of groups) {
			if (group.groupType === groupType) {
				matchingGroups.push(group);
			} else if (group.groups) {
				matchingGroups = matchingGroups.concat(doGetAllGroupsOfType(group.groups, groupType));
			}
		}
	}
	return matchingGroups;
};

export const getAllGroupsOfType = (groups, groupType) => {
	return doGetAllGroupsOfType(groups, groupType);
};

export const getPersonGroups = groups => {
	return getAllGroupsOfType(groups, GROUP_TYPE.PERSON);
};

let simulationMode = false;

export const setSimulationMode = mode => (simulationMode = mode);
export const isSimulationMode = () => simulationMode;

export const getFontHeight = canvasContext => {
	const fontParts = canvasContext.font.split(' ');
	const fontSizePixels = fontParts.find(part => part.includes('px'));

	if (fontSizePixels) {
		// remove "px" and parse to integer
		return parseInt(fontSizePixels.slice(0, fontSizePixels.length - 2));
	}

	// default
	return 13 * 1.2;
};

export const isSymbol = char => char.toUpperCase() === char.toLowerCase();

export const getFirstLetter = name => {
	const firstName = name.split(' ').find(n => n !== '');
	let firstLetter = firstName[0];

	if (isSymbol(firstLetter)) {
		for (const character of firstName) {
			if (!isSymbol(character)) {
				firstLetter = character;
				break;
			}
		}
	}

	return firstLetter;
};

// in the new design the holidays marked with the diagonal lines pattern from the top of the canvas to the bottom is the default calendar.
// if there is no default calendar only weekends will have the pattern
export const createCanvasDefaultHolidayCalendarEntries = data => {
	if (data?.holidayCalendarEntriesByCalendar) {
		const defaultHolidayCalendarEntries =
			data?.holidayCalendarEntriesByCalendar[data.company?.defaultHolidayCalendarId] || [];
		return defaultHolidayCalendarEntries.map(entry => createCanvasTimelineDate(entry.year, entry.month, entry.day));
	}

	return [];
};

export const createRelayTeamDataStructure = data => {
	if (!data.teams || !data.teamPersonByTeamMap || !data.teamPersons) return [];

	return [
		...data.teams.map(team => {
			const teamPersonsForTeam = data.teamPersonByTeamMap[team.id] || [];
			return {
				node: {
					...team,
					id: btoa(`Team:${team.id}`),
					teamPersons: {
						edges: teamPersonsForTeam.map(tp => {
							return {node: {...tp, person: {id: tp.personId}}};
						}),
					},
				},
			};
		}),
		{
			node: {
				id: null,
				teamPersons: {
					edges: data.teamPersons.map(tp => {
						return {node: {...tp, person: {id: tp.personId}}};
					}),
				},
			},
		},
	];
};

export const isGlobalRecalculationNeeded = pageComponent => {
	const {hasDrawnStepDataArray, anyGroupNeedsRecalculation} = pageComponent.timeline;
	return !hasDrawnStepDataArray || anyGroupNeedsRecalculation;
};

export const anyGroupNeedsRecalculation = pageComponent => {
	const {anyGroupNeedsRecalculation} = pageComponent.timeline;
	return anyGroupNeedsRecalculation;
};

const drawnStepDataArrayMap = new Map();

export const clearDrawnStepDataArrayMap = () => {
	drawnStepDataArrayMap.clear();
};

const getHasDrawnStepArrayKey = (firstStartDate, lastStartDate) => {
	return `${firstStartDate}-${lastStartDate}`;
};

export const hasDrawnStepArray = (timelineMinorStep, stepDataArray) => {
	const stepCache = drawnStepDataArrayMap.get(timelineMinorStep);

	if (stepCache) {
		const {startDate: firstStartDate} = stepDataArray[0];
		const {startDate: lastStartDate} = stepDataArray[stepDataArray.length - 1];

		return stepCache.has(getHasDrawnStepArrayKey(firstStartDate, lastStartDate));
	}

	return false;
};

export const markStepDataArrayDrawn = (timelineMinorStep, stepDataArray) => {
	let stepCache = drawnStepDataArrayMap.get(timelineMinorStep);
	if (!stepCache) {
		stepCache = new Set();
		drawnStepDataArrayMap.set(timelineMinorStep, stepCache);
	}

	let firstStartDate = null;
	let lastStartDate = null;
	for (const step of stepDataArray) {
		const {isHidden, startDate} = step;

		if (!isHidden) {
			if (!firstStartDate) {
				firstStartDate = startDate;
			}

			lastStartDate = startDate;
		}
	}

	if (firstStartDate && lastStartDate) {
		stepCache.add(getHasDrawnStepArrayKey(firstStartDate, lastStartDate));
	}
};

const drawnStepsMap = new Map();

let earliestStepDrawn = null;
let latestStepDrawn = null;

export const getDrawnStepsInterval = () => [earliestStepDrawn, latestStepDrawn];

export const clearDrawnStepsMap = () => {
	drawnStepsMap.clear();
	earliestStepDrawn = null;
	latestStepDrawn = null;
};

export const hasDrawnAnySteps = () => drawnStepsMap.size > 0;

export const hasDrawnStep = (timelineMinorStep, startDate) => {
	const stepCache = drawnStepsMap.get(timelineMinorStep);

	if (stepCache) {
		return stepCache.has(startDate);
	}

	return false;
};

export const markStepDrawn = (timelineMinorStep, startDate, endDate) => {
	let stepCache = drawnStepsMap.get(timelineMinorStep);
	if (!stepCache) {
		stepCache = new Set();
		drawnStepsMap.set(timelineMinorStep, stepCache);
	}

	if (earliestStepDrawn === null || startDate < earliestStepDrawn) {
		earliestStepDrawn = startDate;
	}

	if (latestStepDrawn === null || endDate > latestStepDrawn) {
		latestStepDrawn = endDate;
	}

	return stepCache.add(startDate);
};

export const getWeekendDaysBetween = (canvasTimelineDate1, canvasTimelineDate2, dayData) => {
	const from = Math.min(Math.floor(canvasTimelineDate1), Math.floor(canvasTimelineDate2));
	const to = Math.max(Math.floor(canvasTimelineDate1), Math.floor(canvasTimelineDate2));
	let numberOfWeekendDays = 0;
	for (let day = from; day <= to; day++) {
		if (dayData[day].isoWeekday > 5) {
			numberOfWeekendDays++;
		}
	}
	return numberOfWeekendDays;
};

const getOutOfWeekendPadding = (date, startDate) => {
	const startingDayOfWeek = (date - 2) % 7;
	let alreadyInWeekendAdjustment = 0;
	if (startingDayOfWeek >= 5) {
		if (startDate) {
			alreadyInWeekendAdjustment = 7 - startingDayOfWeek;
		} else {
			alreadyInWeekendAdjustment = 4 - startingDayOfWeek;
		}
	}
	return alreadyInWeekendAdjustment;
};

export const getWeekendDaysDuringDiff = (canvasTimelineDate, diffDaysWithoutWeekend) => {
	const date = Math.floor(canvasTimelineDate);
	const dayOfWeek = diffDaysWithoutWeekend > 0 ? Math.max(date % 7, 2) - 2 : Math.min((date - 2) % 7, 4);
	return Math.floor((dayOfWeek + diffDaysWithoutWeekend) / 5) * 2;
};

export const weekendAdjustedMovedDays = (canvasDate, movedDays, hideWeekend) => {
	if (hideWeekend) {
		return movedDays + getWeekendDaysDuringDiff(canvasDate, movedDays);
	} else {
		return movedDays;
	}
};

// This moveItem logic must be aligned with the similar logic in backend (ShiftLogic.java). Functions are named the same.
export const moveItem = (item, movedDays, hideWeekend) => {
	let startWeekendPadding = 0;
	let endWeekendPadding = 0;

	if (hideWeekend) {
		startWeekendPadding = getOutOfWeekendPadding(item.startDate, true);
		endWeekendPadding = getOutOfWeekendPadding(item.endDate, false);

		const isInSameWeekend = startWeekendPadding && endWeekendPadding && item.endDate - item.startDate <= 2;
		if (isInSameWeekend) {
			const startingOnSunday = startWeekendPadding === 1;
			const endingOnSaturday = endWeekendPadding === -1;
			const forward = movedDays > 0;

			if (forward && !startingOnSunday) {
				startWeekendPadding = 1;
				endWeekendPadding = 1;
			} else if (!forward && !endingOnSaturday) {
				startWeekendPadding = -1;
				endWeekendPadding = -1;
			} else {
				startWeekendPadding = 0;
				endWeekendPadding = 0;
			}
		}
	}

	const startDifference = weekendAdjustedMovedDays(item.startDate + startWeekendPadding, movedDays, hideWeekend);
	const endDifference = weekendAdjustedMovedDays(item.endDate + endWeekendPadding, movedDays, hideWeekend);

	return {
		startDifference: startDifference + startWeekendPadding,
		endDifference: endDifference + endWeekendPadding,
	};
};

export const applyLocalStorageEyeOptions = (eyeOptions, localStorageKey) => {
	const localStorageEyeOptions = JSON.parse(localStorage.getItem(localStorageKey));

	if (localStorageEyeOptions?.length) {
		for (const option of eyeOptions) {
			const localStorageOption = localStorageEyeOptions.find(
				localStorageOption => localStorageOption.name === option.name
			);

			if (localStorageOption) {
				option.checked = localStorageOption.checked;

				if (option.nestedOptions) {
					option.nestedOptions.forEach(nestedOption => {
						if (localStorageOption.nestedOptions) {
							const localStorageNestedOption = localStorageOption.nestedOptions.find(
								localStorageNestedOption => localStorageNestedOption.name === nestedOption.name
							);

							if (localStorageNestedOption) {
								nestedOption.checked = localStorageNestedOption.checked;
							}
						}
					});
				}
			}
		}
	}
};

export const applySelectedEyeOption = (eyeOptions, selected) => {
	let column = eyeOptions.find(column => column.name === selected.name);

	if (column) {
		if (column.nestedOptions) {
			column.nestedOptions.forEach(option => {
				option.checked = !column.checked;
			});
		}
	}

	if (!column) {
		for (let i = 0; i < eyeOptions.length; i++) {
			if (eyeOptions[i].nestedOptions) {
				column = eyeOptions[i].nestedOptions.find(column => column.name === selected.name);
				if (column !== undefined) {
					if (!selected.checked) {
						eyeOptions[i].checked = true;
					}
					break;
				}
			}
		}
	}

	if (!column) return;
	column.checked = !column.checked;
};

export const createDefaultEyeOptions = (pageComponent, resetStateData) => {
	const {schedulingView} = pageComponent.props;
	const eyeOptions = [];

	if (schedulingView === SCHEDULING_VIEW.PEOPLE) {
		const data = resetStateData || pageComponent.getData();
		const {company} = data;
		const {weekendDisplayPerUser} = company;
		const {schedulingOptions} = pageComponent.state;
		const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
		const isInCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		if (!isUsingProjectAllocation && !isInCombinationMode && pageComponent.viewCompanySchedule) {
			eyeOptions.push({name: 'unassigned-tasks', checked: true, translationId: 'scheduling.show_unassigned_tasks'});
		}

		if (pageComponent.viewCompanySchedule) {
			eyeOptions.push({
				name: TOTAL_RESOURCE_UTILIZATION_GROUP_ID,
				checked: true,
				translationId: 'scheduling.total_resource_utilization',
			});
		}

		if (weekendDisplayPerUser !== false) {
			eyeOptions.push({
				name: EYE_OPTION_NAME.SHOW_WEEKENDS,
				checked: true,
				translationId: 'scheduling.show_weekends',
			});
		}

		eyeOptions.push({
			name: EYE_OPTION_NAME.SHOW_HEATMAP,
			checked: true,
			translationId: 'scheduling.show_heatmap',
			openRight: false,
		});
	} else if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
		const {weekendOptions} = pageComponent.props;
		if (weekendOptions.weekendDisplayPerUser !== false) {
			eyeOptions.push({
				name: EYE_OPTION_NAME.SHOW_WEEKENDS,
				checked: true,
				translationId: 'scheduling.show_weekends',
			});
		}
	} else if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS) {
		const {weekendOptions} = pageComponent.props;
		const {staffingModeActive} = pageComponent.state || {};

		if (weekendOptions.weekendDisplayPerUser !== false) {
			eyeOptions.push({
				name: EYE_OPTION_NAME.SHOW_WEEKENDS,
				checked: true,
				translationId: 'scheduling.show_weekends',
			});
		}

		if (!staffingModeActive) {
			eyeOptions.push({
				name: PLACEHOLDERS_EYE_OPTIONS.SHOW_PLACEHOLDERS_WITH_NO_ALLOCATIONS,
				checked: true,
				translationId: 'scheduling.show_placeholders_with_no_allocation',
			});
		}
	}

	return eyeOptions;
};

export const getInitialEyeOptions = (pageComponent, resetStateData) => {
	const eyeOptions = createDefaultEyeOptions(pageComponent, resetStateData);

	const localStorageKey = getEyeOptionStorageKey(pageComponent);
	if (localStorageKey) {
		const localStorageEyeOptions = JSON.parse(localStorage.getItem(localStorageKey));

		if (localStorageEyeOptions?.length) {
			for (const option of eyeOptions) {
				const localStorageOption = localStorageEyeOptions.find(
					localStorageOption => localStorageOption.name === option.name
				);

				if (localStorageOption) {
					option.checked = localStorageOption.checked;

					if (option.nestedOptions) {
						option.nestedOptions.forEach(nestedOption => {
							if (localStorageOption.nestedOptions) {
								const localStorageNestedOption = localStorageOption.nestedOptions.find(
									localStorageNestedOption => localStorageNestedOption.name === nestedOption.name
								);

								if (localStorageNestedOption) {
									nestedOption.checked = localStorageNestedOption.checked;
								}
							}
						});
					}
				}
			}
		}
	}

	return eyeOptions;
};

export const getPerson = (personId, persons) => {
	if (!personId) return;
	return persons.find(person => person.id === personId);
};

export const getProject = (projectId, projects) => {
	if (!projectId) return null;
	return projects.find(project => project.id === projectId);
};

export const getProjectGroup = (projectGroupId, projectGroups) => {
	if (!projectGroupId) return null;
	return projectGroups.find(projectGroup => projectGroup.id === projectGroupId);
};

export const getPlaceholderProject = (placeholder, projects, projectGroups) => {
	return placeholder.projectId
		? getProject(placeholder.projectId, projects)
		: getProjectGroup(placeholder.projectGroupId, projectGroups);
};

export const getPlaceholderAllocationsByProjectId = (pageComponent, projectId) => {
	const data = pageComponent.getFilterData() || pageComponent.getData();
	let placeholderAllocations = [];
	const placeholders = data.placeholdersByProjectOrProjectGroup[projectId];

	if (placeholders) {
		placeholders.forEach(placeholder => {
			const allocationsByPlaceholder = data.placeholderAllocationsByPlaceholder[placeholder.id];
			if (allocationsByPlaceholder?.length > 0) {
				placeholderAllocations = placeholderAllocations.concat(allocationsByPlaceholder);
			}
		});
	}

	return placeholderAllocations;
};

export const mapPlaceholderSkillsGroupData = data => placeholderSkill => ({
	skill: data.skills.find(skill => placeholderSkill.skillId === skill.id),
	level: placeholderSkill.skillLevelId ? data.skillLevels.find(sl => placeholderSkill.skillLevelId === sl.id) : undefined,
});

export const getProjectColors = projectColor => {
	const baseColor = ColorManipulation(projectColor);
	return {
		background: baseColor.lighten(0.2).saturate(0.6).hex(),
		border: baseColor.darken(0.4).hex(),
		text: baseColor.isDark() ? '#ffffff' : baseColor.darken(0.65).hex(),
	};
};

export const drawTimelineGraphBottomBar = (canvasContext, x, y, height, width) => {
	let bottomBarY = y + height - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH * 2;

	// top border
	drawBorder(
		canvasContext,
		x,
		bottomBarY,
		width,
		false,
		TIMELINE_GRAPH_GROUP_BOTTOM_BORDER_BORDER_COLOR,
		TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH
	);
	bottomBarY += TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH;

	// background
	drawRectangle(canvasContext, x, bottomBarY, width, TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT, {
		backgroundColor: TIMELINE_GRAPH_GROUP_BOTTOM_BORDER_BACKGROUND_COLOR,
	});
	bottomBarY += TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT;

	// bottom border
	drawBorder(canvasContext, x, bottomBarY, width, false, TIMELINE_GRAPH_GROUP_BOTTOM_BORDER_BORDER_COLOR);
};

export const updateAllItemsData = (pageComponent, predicate = null) => {
	const {items} = pageComponent.state;

	if (items?.length > 0) {
		let itemsToUpdate = items;

		if (predicate) {
			itemsToUpdate = itemsToUpdate.filter(predicate);
		}

		itemsToUpdate.forEach(item => {
			item.refreshData();
		});
	}
};

export const adjustForHiddenWeekend = (startDate, delta) => {
	// startDate is saturday
	if (Math.floor(startDate) % 7 === 0) {
		if (delta >= 0) {
			startDate += 2;
		} else {
			startDate -= 1;
		}
		// startDate is sunday
	} else if (Math.floor(startDate + 6) % 7 === 0) {
		if (delta >= 0) {
			startDate += 1;
		} else {
			startDate -= 2;
		}
	}

	return startDate;
};

const getDaysInStep = (step, dayData) => {
	return step === 'week'
		? {daysInStep: dayData.daysInWeek, workingDaysInStep: dayData.workingDaysInWeek}
		: step === 'month'
		? {daysInStep: dayData.daysInMonth, workingDaysInStep: dayData.workingDaysInMonth}
		: step === 'quarter'
		? {daysInStep: dayData.daysInQuarter, workingDaysInStep: dayData.workingDaysInQuarter}
		: step === 'year'
		? {daysInStep: dayData.daysInYear, workingDaysInStep: dayData.workingDaysInYear}
		: {daysInStep: 1, workingDaysInStep: 1};
};

const getStepData = (position, startDate, stepLabelFormat, dayData, step, pixelsPerDay, isHidingWeekends, intl) => {
	const dataObject = {position};
	const {daysInStep, workingDaysInStep} = getDaysInStep(step, dayData);

	//format is undefined for week since it is not possible to show that with formatDate
	dataObject.startDate = parseInt(startDate, 10);

	const days = isHidingWeekends ? workingDaysInStep : daysInStep;
	dataObject.width = days * pixelsPerDay;

	dataObject.label = getStepLabelStatic(stepLabelFormat, dayData, intl, dateLabelSize.fromColumnWidth(dataObject.width));

	if (Moment.locale() === 'da') {
		//Remove unnecessary part of translation string that does not fit when on smallest version of day view
		dataObject.label = dataObject.label.replace('den ', '');
	}

	//Capitalize first letter, in danish months/days are not capitalized and it looks ugly
	dataObject.label = dataObject.label.charAt(0).toUpperCase() + dataObject.label.slice(1);

	const todayIndex = getCanvasTimelineDateFromMoment(Moment());
	const thisWeekRange = {
		start: getCanvasTimelineDateFromMoment(Moment().startOf('week')),
		end: getCanvasTimelineDateFromMoment(Moment().endOf('week')),
	};
	const thisMonthRange = {
		start: getCanvasTimelineDateFromMoment(Moment().startOf('month')),
		end: getCanvasTimelineDateFromMoment(Moment().endOf('month')),
	};
	const thisQuarterRange = {
		start: getCanvasTimelineDateFromMoment(Moment().startOf('quarter')),
		end: getCanvasTimelineDateFromMoment(Moment().endOf('quarter')),
	};

	dataObject.isWeekend = step === 'day' && dayData.isoWeekday > 5;
	dataObject.isCurrentPeriod =
		step === 'day'
			? parseInt(startDate, 10) === todayIndex
			: step === 'week'
			? parseInt(startDate, 10) >= thisWeekRange.start && parseInt(startDate, 10) <= thisWeekRange.end
			: step === 'month'
			? parseInt(startDate, 10) >= thisMonthRange.start && parseInt(startDate, 10) <= thisMonthRange.end
			: step === 'quarter'
			? parseInt(startDate, 10) >= thisQuarterRange.start && parseInt(startDate, 10) <= thisQuarterRange.end
			: false;

	return {
		dataObject,
		daysInStep,
		workingDaysInStep,
	};
};

export const getStepDataArrayStartAndEndDate = stepDataArray => {
	const overallStartDate = stepDataArray[0].startDate;
	const overallEndDate = stepDataArray[stepDataArray.length - 1].endDate;
	return {overallStartDate, overallEndDate};
};

export const getStepDataArray = (
	step,
	stepLabelFormat,
	dayDataMap,
	canvasWidth,
	startDate,
	pixelsPerDay,
	isHidingWeekends,
	intl
) => {
	if (!dayDataMap) return [];

	// TODO we would love to get rid of this hack, seems to be related to Math.ceil and a misaligned stepDataArray
	// so might be fixed by changing Math.ceil to Math.floor and shifting stepDataArray
	let parsedStartDate = parseInt(startDate, 10);
	if (startDate === parsedStartDate) {
		//There is some odd interaction if the startDate is a natural number which usually is the case only on page load
		//Without this the background is drawn off by 1 day until you scroll horizontally for the first time, then it jumps back to correct position
		//Could be something with ceiling the startDate few lines below, not sure
		//No time to look into it at the moment and this solves the issue
		startDate += 0.00001;
	}

	let dayData = dayDataMap[parseInt(startDate, 10)];

	if (!dayData) {
		console.log(
			'Could not find dayData',
			JSON.stringify({
				startDate,
				startDateIndex: parseInt(startDate, 10),
				staticDayData: hasFeatureFlag('static_day_data_generation'),
				dayDataMapSize: dayDataMap?.length,
			})
		);
	}

	let position;
	const startingRemainder = Math.ceil(startDate) - startDate;
	switch (step) {
		case 'year':
			let daysInYear = dayData.dayOfYear;

			if (isHidingWeekends) {
				if (hasFeatureFlag('static_day_data_generation')) {
					daysInYear = getStaticWorkingDaysBetween(dayData, 'year');
				} else {
					daysInYear = getWorkingDaysForPeriodUnit(dayData, 'year');
				}
			}

			position = (startingRemainder - daysInYear) * pixelsPerDay;
			startDate -= dayData.dayOfYear - 1;

			break;
		case 'quarter':
			let daysInQuarter = dayData.dayOfQuarter;

			if (isHidingWeekends) {
				if (hasFeatureFlag('static_day_data_generation')) {
					daysInQuarter = getStaticWorkingDaysBetween(dayData, 'quarter');
				} else {
					daysInQuarter = getWorkingDaysForPeriodUnit(dayData, 'quarter');
				}
			}

			position = (startingRemainder - daysInQuarter) * pixelsPerDay;
			startDate -= dayData.dayOfQuarter - 1;

			break;
		case 'month':
			let daysInMonth = dayData.dayOfMonth;

			if (isHidingWeekends) {
				if (hasFeatureFlag('static_day_data_generation')) {
					daysInMonth = getStaticWorkingDaysBetween(dayData, 'month');
				} else {
					daysInMonth = getWorkingDaysForPeriodUnit(dayData, 'month');
				}
			}

			position = (startingRemainder - daysInMonth) * pixelsPerDay;
			startDate -= dayData.dayOfMonth - 1;

			break;
		case 'week':
			let daysInWeek = dayData.weekday + 1;
			if (isHidingWeekends && isSundayFirstDayOfWeek()) {
				daysInWeek -= 1;
			}

			position = (startingRemainder - daysInWeek) * pixelsPerDay;
			startDate -= dayData.weekday;

			break;
		default:
			position = (startingRemainder - 1) * pixelsPerDay;
			break;
	}

	const stepDataArray = [];
	while (position < canvasWidth && dayData) {
		const {dataObject, daysInStep, workingDaysInStep} = getStepData(
			position,
			startDate,
			stepLabelFormat,
			dayData,
			step,
			pixelsPerDay,
			isHidingWeekends,
			intl
		);

		const days = isHidingWeekends ? workingDaysInStep : daysInStep;
		if (!isHidingWeekends || !dataObject.isWeekend) {
			stepDataArray.push(dataObject);
			position += days * pixelsPerDay;
		}

		startDate += daysInStep;
		dataObject.endDate = parseInt(startDate, 10) - 1;
		dayData = dayDataMap[parseInt(startDate, 10)];
	}

	return stepDataArray;
};

let throttledRedrawTimeoutId;
export const throttledRedraw = () => {
	if (throttledRedrawTimeoutId) return;
	throttledRedrawTimeoutId = setTimeout(() => {
		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: true});
		throttledRedrawTimeoutId = undefined;
	}, 1000);
};

export const resetThrottledRedraw = () => {
	if (throttledRedrawTimeoutId) {
		clearTimeout(throttledRedrawTimeoutId);
		throttledRedrawTimeoutId = undefined;
	}
};

export const isIdleTimeAllocation = allocation => {
	return allocation.idleTimeId;
};

export const isTimeOffAllocation = allocation => {
	return isIdleTimeAllocation(allocation) && !allocation.isIdleTimeInternal;
};

export const getProjectGroupColor = (pageComponent, project) => {
	if (project.isInProjectGroup) {
		return DataManager.getProjectGroupById(pageComponent, project.projectGroupId)?.color;
	}

	return null;
};

export const getPhaseItems = (pageComponent, phaseItem) => {
	const {items} = pageComponent.state;
	const {phase} = phaseItem.data;

	return items.filter(item => {
		if (isTaskItem(item)) {
			const {task} = item.data;
			return task.phaseId === phase.id;
		}

		return false;
	});
};

export const isPhaseAutoScheduled = (pageComponent, phase) => {
	const {simulationMode} = pageComponent.state;

	if (simulationMode) {
		const data = pageComponent.getData();
		const {autoScheduleProposalData} = data;

		if (phase) {
			return autoScheduleProposalData?.excludedPhasesIds.includes(phase.id);
		} else {
			return autoScheduleProposalData.excludedPhasesIds.some(id => +atob(id).replace('PhaseType:', '') === -1);
		}
	}

	return false;
};

export const getProjectItemDates = (pageComponent, project, projectGroup, projects, program) => {
	const dates = {startDate: null, endDate: null};

	if (projectGroup && projects) {
		for (const project of projects) {
			const {projectStartYear, projectStartMonth, projectStartDay, projectEndYear, projectEndMonth, projectEndDay} =
				project;

			if (projectStartYear && projectEndYear) {
				const projectStartDate = createCanvasTimelineDate(projectStartYear, projectStartMonth, projectStartDay);
				const projectEndDate = createCanvasTimelineDate(projectEndYear, projectEndMonth, projectEndDay);

				if (dates.startDate === null || projectStartDate < dates.startDate) {
					dates.startDate = projectStartDate;
				}

				if (dates.endDate === null || projectEndDate > dates.endDate) {
					dates.endDate = projectEndDate;
				}
			}
		}
	} else if (project) {
		const {projectStartYear, projectStartMonth, projectStartDay, projectEndYear, projectEndMonth, projectEndDay} = project;

		if (projectStartYear && projectEndYear) {
			dates.startDate = createCanvasTimelineDate(projectStartYear, projectStartMonth, projectStartDay);
			dates.endDate = createCanvasTimelineDate(projectEndYear, projectEndMonth, projectEndDay);
		}
	} else if (program) {
		const {startDate, endDate} = program;

		if (startDate && endDate) {
			dates.startDate = getCanvasTimelineDateFromStringDate(startDate);
			dates.endDate = getCanvasTimelineDateFromStringDate(endDate);
		}
	}

	return dates;
};

export const hasSchedulingViewPermission = pageComponent => {
	const {schedulingView, isProjectTimeline} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PROJECTS:
			if (!isProjectTimeline) {
				return hasPermission(PERMISSION_TYPE.ALL_TIMELINES_VIEW_ONLY);
			}

			break;
		case SCHEDULING_VIEW.PEOPLE:
			return hasPermission(PERMISSION_TYPE.PEOPLE_SCHEDULE_VIEW_ONLY);
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return hasPermission(PERMISSION_TYPE.DEMAND_VIEW_ONLY);
		case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			return hasPermission(PERMISSION_TYPE.CAPACITY_OVERVIEW_VIEW_ONLY);
		default:
			break;
	}

	return false;
};

export const showPlaceholders = pageComponent => {
	const {sharedContext} = pageComponent.props;
	const {company} = pageComponent.getData();
	const isUsingProjectAllocation = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		company,
		VISUALIZATION_MODE.ALLOCATION
	);
	const isUsingCombinationMode =
		hasFeatureFlag('combined_heatmap_logic_extensions') &&
		getVisualizationMode(pageComponent.state.schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

	return (
		hasFeatureFlag('placeholders') &&
		hasModule(MODULE_TYPES.PLACEHOLDERS) &&
		(hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS) || hasSchedulingViewPermission(pageComponent)) &&
		(isUsingProjectAllocation || isUsingCombinationMode) &&
		!sharedContext
	);
};

export const createPersonProjectGroups = (pageComponent, person) => {
	const projectGroups = [];

	const projectPersons = DataManager.getProjectPersonsByPersonId(pageComponent, person.id);
	if (projectPersons) {
		const createdProjectGroupIds = new Set();

		for (const projectPerson of projectPersons) {
			const project = DataManager.getProjectById(pageComponent, projectPerson.projectId);
			const projectGroup = project.isInProjectGroup
				? DataManager.getProjectGroupById(pageComponent, project.projectGroupId)
				: null;

			if (!projectGroup || !createdProjectGroupIds.has(projectGroup.id)) {
				const projectGroupData = ComposeManager.composeProjectGroup(
					pageComponent,
					person,
					null,
					projectGroup ? null : project,
					projectGroup
				);

				if (projectGroupData) {
					projectGroups.push(new ProjectGroup(pageComponent, projectGroupData));

					if (projectGroup) {
						createdProjectGroupIds.add(projectGroup.id);
					}
				}
			}
		}
	}

	return projectGroups;
};

export const hasPageViewOnlyAccess = pageComponent => {
	if (hasFeatureFlag('scheduling_read_only_permissions')) {
		const {schedulingView, isProjectTimeline} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PROJECTS:
				if (!isProjectTimeline) {
					return hasPermission(PERMISSION_TYPE.ALL_TIMELINES_VIEW_ONLY);
				}

				break;
			case SCHEDULING_VIEW.PEOPLE:
				return hasPermission(PERMISSION_TYPE.PEOPLE_SCHEDULE_VIEW_ONLY);
			case SCHEDULING_VIEW.PLACEHOLDERS:
				return hasPermission(PERMISSION_TYPE.DEMAND_VIEW_ONLY);
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				return hasPermission(PERMISSION_TYPE.CAPACITY_OVERVIEW_VIEW_ONLY);
			default:
				break;
		}
	}

	return false;
};

export const hasSchedulingAccess = pageComponent => {
	if (hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS)) {
		return true;
	}

	return hasPageViewOnlyAccess(pageComponent);
};

export const isViewerPartOfProject = (pageComponent, projectId, projectGroupId) => {
	if (projectId || projectGroupId) {
		const projectPersons = projectId
			? DataManager.getProjectPersonsByProjectId(pageComponent, projectId)
			: DataManager.getProjectPersonsByProjectGroupId(pageComponent, projectGroupId);
		return projectPersons.some(projectPerson => projectPerson.personId === pageComponent.state.data?.viewer.actualPersonId);
	}

	return false;
};

export const hasViewOnlyAccess = (pageComponent, projectId, projectGroupId) => {
	if (hasFeatureFlag('scheduling_read_only_permissions')) {
		if (hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
			return false;
		}

		if (isViewerPartOfProject(pageComponent, projectId, projectGroupId)) {
			return false;
		}

		return hasSchedulingViewPermission(pageComponent);
	}

	return false;
};

export const canViewClientName = (pageComponent, projectId, projectGroupId) => {
	if (hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
		return true;
	}

	if (isViewerPartOfProject(pageComponent, projectId, projectGroupId)) {
		return true;
	}

	if (hasFeatureFlag('scheduling_read_only_permissions')) {
		return hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY) && hasSchedulingViewPermission(pageComponent);
	}

	return false;
};

export const isProgramRelation = (pageComponent, project, person) => {
	if (project) {
		const projectPersonsByPerson = DataManager.getProjectPersonsByPersonId(pageComponent, person?.id);
		const projectPerson = projectPersonsByPerson?.find(pp => pp.projectId === project.id);
		return projectPerson?.relationType === 'PROGRAM';
	}
	return false;
};

export const isPeopleScheduleCombinedModeWithLazyLoading = pageComponent => {
	const {viewer, schedulingView} = pageComponent.props;
	const {company} = viewer;
	const isCombinedModePeopleSchedule =
		schedulingView === SCHEDULING_VIEW.PEOPLE && Util.isMixedAllocationModeEnabled(company);
	return isCombinedModePeopleSchedule && hasFeatureFlag('combined_mode_performance_improvements');
};

export const useItemsLazyLoading = pageComponent => {
	return isPeopleScheduleCombinedModeWithLazyLoading(pageComponent) || hasFeatureFlag('incremental_load_more_include_items');
};

const personHeatmapMetaDataCache = new Map();

export const clearPersonHeatmapMetaData = () => {
	if (personHeatmapMetaDataCache) {
		personHeatmapMetaDataCache.clear();
	}
};

export const clearPersonHeatmapMetaDataStepCache = (personId, clearStepCache = false) => {
	if (personHeatmapMetaDataCache) {
		const personCache = personHeatmapMetaDataCache.get(personId);

		if (personCache) {
			if (clearStepCache) {
				for (const scaleSetting of Object.values(SCALE_SETTINGS)) {
					const stepCache = personCache.get(scaleSetting?.minorStep);

					if (stepCache) {
						stepCache.clear();
					}
				}
			} else {
				personCache.clear();
			}
		}
	}
};

export const getPersonHeatmapMetaData = (pageComponent, personGroup, timelineMinorStep, startDate, endDate) => {
	const personGroupData = personGroup.data;
	const {personId} = personGroupData;

	let personCache = personHeatmapMetaDataCache.get(personId);
	if (!personCache) {
		personCache = new Map();
		personHeatmapMetaDataCache.set(personId, personCache);
	}

	const data = pageComponent.getData();
	const {dayData} = pageComponent.props;

	let personMetaData = personCache.get(null);
	if (!personMetaData) {
		const hasCombinedHeatmapImprovements = hasFeatureFlag('combined_mode_performance_improvements');

		const {personWorkingMinutes, personWorkingHours} = getPersonWorkingHoursAndMinutes(personGroupData);

		const {holidaysIncludingDaysOff, holidaysExcludingDaysOff} = hasCombinedHeatmapImprovements
			? getHolidayDatesCached(personGroupData, data, personWorkingMinutes, dayData)
			: getHolidayDates(personGroupData, data, personWorkingMinutes);

		personMetaData = {
			personWorkingMinutes,
			personWorkingHours,
			holidaysIncludingDaysOff,
			holidaysExcludingDaysOff,
			personTimeRegSearchTreeMap: DataManager.getLookupMap(pageComponent, 'timeRegSearchTreeMap')?.get(
				personGroupData.personId
			),
		};

		personCache.set(null, personMetaData);
	}

	let stepCache = personCache.get(timelineMinorStep);
	if (!stepCache) {
		stepCache = new Map();
		personCache.set(timelineMinorStep, stepCache);
	}

	let heatmapMetaData = stepCache.get(startDate);
	if (!heatmapMetaData) {
		const hasCombinedHeatmapImprovements = hasFeatureFlag('combined_mode_performance_improvements');

		const {nonWorkingDaysMap} = data;
		const {holidaysIncludingDaysOff, personWorkingHours} = personMetaData;

		const weekdaysInPeriod = hasCombinedHeatmapImprovements
			? getWeekdaysBetweenDatesCached(timelineMinorStep, startDate, endDate, nonWorkingDaysMap, personGroupData.personId)
			: getWeekdaysBetweenDatesNew(startDate, endDate, nonWorkingDaysMap, personGroupData.personId);

		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			removeHolidaysFromWeekdays(weekdaysInPeriod, holidaysIncludingDaysOff, startDate, endDate, dayData);
		}

		removeWeekdaysOutsidePersonTimePeriod(personGroupData, startDate, endDate, weekdaysInPeriod, dayData);

		const weekdaysInPeriodIgnorePTO = getWeekdaysBetweenDatesNew(startDate, endDate, null, personGroupData.personId);
		const workingMinutes = getMinutesInWeekdaysNew(personWorkingHours, weekdaysInPeriodIgnorePTO);

		heatmapMetaData = {
			minutesAvailable: getMinutesInWeekdaysNew(personWorkingHours, weekdaysInPeriod),
			nonWorkingDays: getNonWorkingDays(startDate, endDate, nonWorkingDaysMap, personGroupData.personId),
			workingMinutes,
		};

		stepCache.set(startDate, heatmapMetaData);
	}

	return {
		...personMetaData,
		...heatmapMetaData,
	};
};
