import React from 'react';
import {TaskModalEstimatePlaceholder} from '../../../../../forecast-app/shared/components/placeholders/TaskModalEstimatePlaceholder';
import {TaskModalTimeEntriesPlaceholder} from '../../../../../forecast-app/shared/components/placeholders/TaskModalTimeEntriesPlaceholder';
import {TaskModalRemainingPlaceholder} from '../../../../../forecast-app/shared/components/placeholders/TaskModalRemainingPlaceholder';

export const TaskRollupSectionPlaceholder = () => {
	return (
		<>
			<TaskModalEstimatePlaceholder />
			<TaskModalTimeEntriesPlaceholder />
			<TaskModalRemainingPlaceholder />
		</>
	);
};
