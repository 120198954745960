import Moment from 'moment';
import {PERIOD_BUDGET_TYPE, PERIODICITY} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';

export const timeRegIsInConflict = (timeReg, period) => {
	if (period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS) return false;

	const timeRegDate = Moment(timeReg.updatedAt);
	const lockedDate = Moment(period.periodLockedTime);
	return period.periodLocked && !timeReg.retainerConflictHandled && timeRegDate.isAfter(lockedDate);
};

export function getLastPeriodEndDateForSelectedPeriodCount(
	latestPeriodEndDate,
	numNewPeriods,
	defaultPeriodLength,
	defaultPeriodPeriodicity
) {
	if (!latestPeriodEndDate || numNewPeriods < 1 || !defaultPeriodLength) {
		return null;
	}
	if (defaultPeriodPeriodicity === PERIODICITY.MONTHLY) {
		const lastPeriodIsEndOfMonth = latestPeriodEndDate.isSame(latestPeriodEndDate.clone().endOf('month'), 'day');
		if (lastPeriodIsEndOfMonth) {
			return latestPeriodEndDate.clone().startOf('month').add(numNewPeriods, 'months').endOf('month');
		} else {
			return latestPeriodEndDate.clone().add(numNewPeriods, 'months');
		}
	} else {
		return latestPeriodEndDate
			.clone()
			.add(defaultPeriodLength * numNewPeriods, defaultPeriodPeriodicity === PERIODICITY.DAILY ? 'days' : 'weeks');
	}
}

export function getTimeRegsForPeriod(timeRegs, period, removeConflicts) {
	const sortByTimeRegDate = (a, b) => {
		const aDate = Util.CreateNonUtcMomentDate(a.node.year, a.node.month, a.node.day);
		const bDate = Util.CreateNonUtcMomentDate(b.node.year, b.node.month, b.node.day);
		if (aDate.isAfter(bDate)) return 1;
		if (bDate.isAfter(aDate)) return -1;
		return 0;
	};

	const startDate = Util.CreateMomentDate(period.startYear, period.startMonth, period.startDay);
	const endDate = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
	const timeRegWithinDate = timeReg => {
		const tDate = Util.CreateMomentDate(timeReg.node.year, timeReg.node.month, timeReg.node.day);
		return tDate && tDate.isSameOrAfter(startDate) && tDate.isSameOrBefore(endDate);
	};
	if (removeConflicts) {
		return timeRegs
			.filter(timeReg => timeRegWithinDate(timeReg) && !timeRegIsInConflict(timeReg.node, period))
			.sort(sortByTimeRegDate);
	}
	return timeRegs.filter(timeReg => timeRegWithinDate(timeReg)).sort(sortByTimeRegDate);
}

function getCSVData(timeRegs, currency, intl) {
	const getReportedTime = entry => {
		const {task, project} = entry.node;
		return {
			tid: task && task.companyTaskId ? task.companyTaskId : '',
			name: task && task.name ? task.name : project.name,
			date: Util.CreateMomentDate(entry.node.year, entry.node.month, entry.node.day).format('D MMM YYYY'),
			timeEntry: Util.convertMinutesToFullHour(entry.node.billableMinutesRegistered, intl),
			price: Util.GetCurrencySymbol(currency) + entry.node.price,
			person: entry.node.person.firstName + ' ' + entry.node.person.lastName,
			period: entry.node.period.name,
		};
	};

	let csvData = [];
	csvData.push({
		tid: 'T-ID',
		taskProject: 'Task/Project',
		date: 'Date',
		timeEntry: 'Time Entry',
		price: 'Price',
		person: 'Person',
		period: 'Period',
	});
	timeRegs.forEach(entry => {
		csvData.push(getReportedTime(entry));
	});
	return csvData;
}

export function handleCsvDownloadPress(timeRegs, currency, intl) {
	const csvData = getCSVData(timeRegs, currency, intl);
	const exportData = Util.JSONToCSV(csvData, currency, intl);
	const fileName = `reported_time.csv`.toLowerCase();
	Util.exportToCSV(exportData, fileName);
}

export function addPeriodIdToTimeReg(timeReg, period) {
	return {node: {...timeReg.node, period}};
}

export const sortByStartDate = (a, b) => {
	const aNode = a.node || a;
	const bNode = b.node || b;
	const aDate = Util.CreateNonUtcMomentDate(aNode.startYear, aNode.startMonth, aNode.startDay);
	const bDate = Util.CreateNonUtcMomentDate(bNode.startYear, bNode.startMonth, bNode.startDay);
	if (!aDate || !bDate) return 0;
	if (aDate.isBefore(bDate)) return -1;
	if (bDate.isBefore(aDate)) return 1;
	return 0;
};

export const getRolloversFromPeriods = (retainerPeriods, periodIds) => {
	const rollovers = [];
	retainerPeriods.edges
		.map(retainerPeriod => retainerPeriod.node)
		.forEach(retainerPeriod => {
			const periodRolloversFromPeriod = retainerPeriod.retainerPeriodRollovers.edges
				.filter(rollover => rollover.node.periodFrom && periodIds.includes(rollover.node.periodFrom.id))
				.map(rollover => rollover.node);
			periodRolloversFromPeriod.forEach(rollover => rollovers.push(rollover));
		});
	return rollovers;
};

export const getRevertableRolloversFromPeriods = (retainerPeriods, periodIds) => {
	const rollovers = getRolloversFromPeriods(retainerPeriods, periodIds);
	// A rollover is revertable if the impacted period is not locked
	return rollovers.filter(rollover => rollover.periodTo.periodLocked === false);
};
