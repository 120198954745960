import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import RichTextField from '../../new-ui/rich_text_field';
import Person from '../../../forecast-app/shared/components/person/person';
import CreateCommentMutation from '../../../mutations/create_comment_mutation';
import {injectIntl} from 'react-intl';
import {convertToRaw} from 'draft-js';
import AddButton from '../../../components/add_button';
import {findDOMNode} from 'react-dom';
import {profilePicSrc} from '../../../directApi';

class CommentFooterSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			focusCommentInput: false,
		};

		this.inputContainer = React.createRef();

		this.viewerProfileSrc = profilePicSrc(props.viewer.profilePictureId);
		this.mentions =
			props.project.projectPersons &&
			props.project.projectPersons.edges
				.filter(projectPerson => {
					return projectPerson.node.person.active;
				})
				.map(projectPerson => {
					return {
						personId: projectPerson.node.person.id,
						name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
						avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
					};
				});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.viewer.profilePictureId !== this.props.viewer.profilePictureId) {
			this.viewerProfileSrc = profilePicSrc(this.props.viewer.profilePictureId);
		}
		if (prevProps.project !== this.props.project) {
			this.mentions =
				this.props.project.projectPersons &&
				this.props.project.projectPersons.edges
					.filter(projectPerson => {
						return projectPerson.node.person.active;
					})
					.map(projectPerson => {
						return {
							personId: projectPerson.node.person.id,
							name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
							avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
						};
					});
		}
	}

	submitComment() {
		const commentText = JSON.stringify(
			convertToRaw(this.richTextField.getDecoratedComponentInstance().state.editorState.getCurrentContent())
		);

		if (document.activeElement) {
			document.activeElement.blur();
			this.setState({canSubmit: false});
		}
		this.richTextField.getDecoratedComponentInstance().clear();
		this.richTextField.getDecoratedComponentInstance().handleBlur();
		const onSuccess = () => {
			this.props.toggleComments(true);
			this.props.scrollToBottom();
			this.props.onInputExpanded(0); //remove margin in the comments section when clicking out of the input
		};
		Util.CommitMutation(
			CreateCommentMutation,
			{
				comment: commentText,
				taskId: this.props.task.id,
				person: this.props.viewer,
				currentDate: Moment(),
			},
			onSuccess
		);
	}

	handleClick(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (!newTarget.className.includes('DraftEditor') && !this.props.isTaskReadOnly) {
			this.richTextField.getDecoratedComponentInstance().handleBlur();
		}

		if (newTarget.className.includes('task-modal-v3-comments-footer-section')) {
			this.props.onInputExpanded(0); //remove margin in the comments section when clicking out of the input
		}
	}

	// Don't blur the comment field if it contains text
	shouldPreventTextFieldBlur(_, content) {
		if (content) {
			try {
				const parsedContent = JSON.parse(content);
				return parsedContent?.blocks?.some(block => block.text.length > 0);
			} catch {
				return false;
			}
		}

		return false;
	}

	onTextChanged() {
		if (this.richTextField) {
			this.setState({
				canSubmit:
					convertToRaw(this.richTextField.getDecoratedComponentInstance().state.editorState.getCurrentContent())
						.blocks[0].text.length > 0,
			});
		}
		// add margin in the comments section to be able to see all the comments
		this.props.onInputExpanded(this.inputContainer.current.clientHeight - 46);

		// auto scroll to the bottom of the modal when typing a comment only when the user was already scrolled to the bottom
		if (
			this.props.commentSectionRef.mounted &&
			findDOMNode(this.props.commentSectionRef) &&
			findDOMNode(this.props.commentSectionRef).getBoundingClientRect().bottom < 900
		) {
			this.props.scrollToBottom();
		}
	}

	toggleInputFocus(focusState) {
		this.setState({focusCommentInput: focusState});
	}

	render() {
		if (this.props.isTaskReadOnly) return null;

		const {formatMessage} = this.props.intl;
		const {viewer, isTaskReadOnly} = this.props;
		const {canSubmit, focusCommentInput} = this.state;

		const mentionsLabels = viewer.company.labels.edges.map(label => {
			return {id: label.node.id, name: label.node.name};
		});

		return (
			<div
				ref={this.inputContainer}
				className={
					'task-modal-v3-comments-footer-section' +
					(this.props.showFooterSection ? '' : ' comments-footer-section-hidden')
				}
				onClick={this.handleClick.bind(this)}
				tabIndex="-1"
				data-cy="task-modal-comments-section"
				data-userpilot={'task-modal-comments-section'}
			>
				<div className="comment-lower">
					{!isTaskReadOnly ? (
						<>
							<div className="person-profile-picture">
								<Person
									name={`${viewer.firstName} ${viewer.lastName}`}
									showName={false}
									showRole={false}
									imageSize="medium"
									imageSrc={this.viewerProfileSrc}
								/>
							</div>
							<div className="new-comment-box">
								<RichTextField
									ref={e => (this.richTextField = e)}
									text={''}
									alwaysShowControls={false}
									placeholder={formatMessage({id: 'cards.comment'})}
									mentions={this.mentions}
									mentionsLabels={mentionsLabels}
									characterLimit={viewer.company.characterLimit}
									onEnter={this.submitComment.bind(this)}
									cy="task-modal-comment-input"
									preventBlurTaskModal={this.shouldPreventTextFieldBlur.bind(this)}
									ignoreComponentWillReceiveProps={true}
									onChange={this.onTextChanged.bind(this)}
									onFocus={this.toggleInputFocus.bind(this, true)}
									handleBlur={this.toggleInputFocus.bind(this, false)}
									preventBlurOnClasses={['add-comment-button']}
									maxHeight={190}
								/>
								{canSubmit && focusCommentInput ? (
									<AddButton onClick={this.submitComment.bind(this)} customClass="add-comment-button" />
								) : null}
							</div>
						</>
					) : null}
				</div>
			</div>
		);
	}
}

CommentFooterSection.propTypes = {
	viewer: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	isTaskReadOnly: PropTypes.bool.isRequired,
	showFooterSection: PropTypes.bool.isRequired,
	task: PropTypes.object.isRequired,
};

export default injectIntl(CommentFooterSection);
