import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ActivityLog, {activityLogQuery} from './activity_log';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';

class ActivityLogRenderer extends Component {
	shouldComponentUpdate(nextProps) {
		return this.props.footerBottomPosition !== nextProps.footerBottomPosition;
	}

	render() {
		const {footerBottomPosition, taskLevels, taskId} = this.props;
		return (
			<ForecastQueryRenderer
				key="query-render-activityLogQuery"
				query={activityLogQuery}
				variables={{taskId}}
				modal={true}
				customLoader={() => (
					<div
						className="activity-list-container activity-list-container-loading"
						style={{height: footerBottomPosition}}
					/>
				)}
				render={relayProps => {
					return (
						<ActivityLog
							footerBottomPosition={footerBottomPosition}
							taskLevels={taskLevels}
							taskId={taskId}
							viewer={relayProps.viewer}
						/>
					);
				}}
			/>
		);
	}
}

ActivityLogRenderer.propTypes = {
	taskId: PropTypes.string.isRequired,
};

export default ActivityLogRenderer;
