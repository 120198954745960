import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import {addQuantityDecimals, convertPriceToInvoiceCurrency, formatDate} from './invoicing_generate_lines_utils';
import Util from '../../../../forecast-app/shared/util/util';
import {RateCardUtil} from '../../../../forecast-app/shared/util/RateCardUtil';
import {sortByStartDate} from '../../../new-ui/retainer_period/RetainerPeriodUtil';

export const getTotalPrice = (unInvoicedProjectTimeRegs, periodStartDate, periodEndDate, periodHoursAmount, rateCard) => {
	let totalPrice = 0;
	let addedHours = 0;
	let defaultRate = 0;

	if (rateCard) {
		const rateCardUtil = new RateCardUtil(rateCard);
		defaultRate = rateCardUtil.getAverageRateBetweenDates(null, periodStartDate, periodEndDate);
	}
	for (let i = 0; i < unInvoicedProjectTimeRegs.length; i++) {
		const timeReg = unInvoicedProjectTimeRegs[i];
		const timeRegDate = Util.CreateMomentDate(timeReg.year, timeReg.month, timeReg.day);
		const minutesRegistered = timeReg.billableMinutesRegistered;

		if (timeRegDate >= periodStartDate && timeRegDate <= periodEndDate) {
			const addedPercentage = Math.min(1.0, (periodHoursAmount - addedHours) / (minutesRegistered / 60));
			addedHours += (minutesRegistered / 60) * addedPercentage;
			totalPrice += timeReg.price * addedPercentage;
		}
		if (addedHours >= periodHoursAmount) break;
	}
	if (addedHours < periodHoursAmount) {
		totalPrice += (periodHoursAmount - addedHours) * defaultRate;
	}

	return totalPrice;
};

export const generateLinesForFixedHoursRetainer = (intl, invoice, unInvoicedTimeRegistrationsByProject, projects, company) => {
	const invoiceLines = [];

	projects
		.filter(project => project.budgetType === BUDGET_TYPE.RETAINER)
		.forEach(project => {
			const projectCurrency = project.rateCard ? project.rateCard.currency : company.currency;
			const periodsToInvoice = project.retainerPeriods.edges
				.map(edge => edge.node)
				.sort(sortByStartDate)
				.filter(
					period =>
						period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS && period.periodLocked && !period.invoiced
				);

			if (periodsToInvoice.length > 0) {
				periodsToInvoice.forEach(period => {
					const periodName = `${period.name} (${formatDate(
						intl,
						Util.CreateMomentDate(period.startYear, period.startMonth, period.startDay)
					)} - ${formatDate(intl, Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay))})`;
					const description = `${periodName}`;

					const unInvoicedProjectTimeRegs = unInvoicedTimeRegistrationsByProject[project.id] || [];
					const {startDay, startMonth, startYear, endDay, endMonth, endYear, periodHoursAmount} = period;
					const periodStartDate = Util.CreateMomentDate(startYear, startMonth, startDay);
					const periodEndDate = Util.CreateMomentDate(endYear, endMonth, endDay);

					let quantity = periodHoursAmount;
					const totalPrice = convertPriceToInvoiceCurrency(
						getTotalPrice(
							unInvoicedProjectTimeRegs,
							periodStartDate,
							periodEndDate,
							periodHoursAmount,
							project.rateCard
						),
						projectCurrency,
						company,
						invoice
					);

					const unitPrice = Math.round((totalPrice / quantity) * 100) / 100;
					const projectId = project.id;
					const tax = invoice.tax;
					const discount = invoice.discount;
					const periodIds = [period.id];

					invoiceLines.push({
						description,
						quantity: addQuantityDecimals(quantity, company),
						unitPrice,
						projectId,
						tax,
						discount,
						periodIds,
					});
				});
			}
		});

	return invoiceLines;
};
