import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ActionsMenu from '../../../../forecast-app/shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import DeleteGithubAttachedObjectMutation from '../../../../mutations/delete_github_attached_object_mutation';
import Util from '../../../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Warning from '../../../../components/warning';

class GitHubObject extends Component {
	removeAttachedObject() {
		const {formatMessage} = this.props.intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: this.realRemoveAttachedObject.bind(this, this.props.githubObject),
				},
			],
		});
	}

	realRemoveAttachedObject(node) {
		Util.CommitMutation(DeleteGithubAttachedObjectMutation, {
			id: node.id,
			taskId: this.props.taskId,
		});
	}

	getPullRequest() {
		const {formatMessage} = this.props.intl;
		const {title, url, repo, number, createdBy, state, head, base} = this.props.githubObject;
		const iconMerged = (
			<svg className={'merge-icon state-merged'} width="12" height="16" viewBox="0 0 12 16">
				<path
					fillRule="evenodd"
					d="M10 7.01c-.73 0-1.38.41-1.73 1.02v-.02C7.22 7.99 6 7.65 5.14 6.99c-.75-.58-1.5-1.61-1.89-2.44A1.993 1.993 0 0 0 2 1C.89 1 0 1.9 0 3.01a2 2 0 0 0 1 1.72v6.56c-.59.35-1 .99-1 1.72 0 1.11.89 2 2 2a1.993 1.993 0 0 0 1-3.72V7.68c.67.7 1.44 1.27 2.3 1.69.86.42 2.03.63 2.97.64v-.02c.36.61 1 1.02 1.73 1.02 1.11 0 2-.89 2-2 0-1.11-.89-2-2-2zm-6.8 6c0 .66-.55 1.2-1.2 1.2-.65 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2zM2 4.21c-.66 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2zm8 6c-.66 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2z"
				/>
			</svg>
		);
		const iconClosed = (
			<svg
				className={'merge-icon' + (state === 'closed' ? ' state-closed' : '')}
				width="12"
				height="16"
				viewBox="0 0 12 16"
			>
				<path
					fillRule="evenodd"
					d="M11 11.28V5c-.03-.78-.34-1.47-.94-2.06C9.46 2.35 8.78 2.03 8 2H7V0L4 3l3 3V4h1c.27.02.48.11.69.31.21.2.3.42.31.69v6.28A1.993 1.993 0 0 0 10 15a1.993 1.993 0 0 0 1-3.72zm-1 2.92c-.66 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2zM4 3c0-1.11-.89-2-2-2a1.993 1.993 0 0 0-1 3.72v6.56A1.993 1.993 0 0 0 2 15a1.993 1.993 0 0 0 1-3.72V4.72c.59-.34 1-.98 1-1.72zm-.8 10c0 .66-.55 1.2-1.2 1.2-.65 0-1.2-.55-1.2-1.2 0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2zM2 4.2C1.34 4.2.8 3.65.8 3c0-.65.55-1.2 1.2-1.2.65 0 1.2.55 1.2 1.2 0 .65-.55 1.2-1.2 1.2z"
				/>
			</svg>
		);
		return (
			<div className="github-object-wrapper">
				<a className="github-object-link-wrapper" href={url} target="_blank" rel="noopener noreferrer">
					<div className="github-object">
						<div className="object-header">
							<div className="object-icon">{state === 'merged' ? iconMerged : iconClosed}</div>
							<div className="object-title link-blue bold">Pull request: {title}</div>
						</div>
						<div className="object-description">
							<span className="dark-grey bold">{repo}</span>{' '}
							<span className="light-grey">
								#{number} opened by {createdBy}
							</span>
							<div className="object-line3 light-grey">
								merge <span className="dark-grey bold">{head}</span> into{' '}
								<span className="dark-grey bold">{base}</span>
							</div>
						</div>
					</div>
				</a>
				<ActionsMenu
					whiteInner={true}
					options={[
						{
							text: formatMessage({id: 'common.remove'}),
							onClick: this.removeAttachedObject.bind(this),
						},
					]}
				/>
			</div>
		);
	}

	getIssue() {
		const {formatMessage} = this.props.intl;
		const {title, url, repo, number, createdBy, state} = this.props.githubObject;
		return (
			<div className="github-object-wrapper">
				<a className="github-object-link-wrapper" href={url} target="_blank" rel="noopener noreferrer">
					<div className="github-object">
						<div className="object-header">
							<div className={'issue-status-icon ' + state} />
							<div className="object-title link-blue bold">{title}</div>
						</div>
						<div className="object-description">
							<span className="dark-grey bold">{repo}</span>{' '}
							<span className="light-grey">
								#{number} opened by {createdBy}
							</span>
							<div className="object-line3 light-grey">Status: {state}</div>
						</div>
					</div>
				</a>
				{this.props.disableActionMenu ? null : (
					<ActionsMenu
						whiteInner={true}
						options={[
							{
								text: formatMessage({id: 'common.remove'}),
								onClick: this.removeAttachedObject.bind(this),
							},
						]}
					/>
				)}
			</div>
		);
	}

	getBranch() {
		const iconBranch = (
			<svg height="16" viewBox="0 0 12 16" width="16">
				<path
					fill-rule="evenodd"
					d="M11.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 113 2.122V6A2.5 2.5 0 0110 8.5H6a1 1 0 00-1 1v1.128a2.251 2.251 0 11-1.5 0V5.372a2.25 2.25 0 111.5 0v1.836A2.492 2.492 0 016 7h4a1 1 0 001-1v-.628A2.25 2.25 0 019.5 3.25zM4.25 12a.75.75 0 100 1.5.75.75 0 000-1.5zM3.5 3.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
				></path>
			</svg>
		);
		const {formatMessage} = this.props.intl;
		const {url, name, repo, createdBy} = this.props.githubObject;
		return (
			<div className="github-object-wrapper">
				<a className="github-object-link-wrapper" href={url} target="_blank" rel="noopener noreferrer">
					<div className="github-object">
						<div className="object-header">
							<div className="object-icon">{iconBranch}</div>
							<div className="object-title link-blue bold">Branch: {name}</div>
						</div>
						<div className="object-description">
							<span className="dark-grey bold">{repo}</span>{' '}
							<span className="light-grey">Created by {createdBy}</span>
						</div>
					</div>
				</a>
				{this.props.disableActionMenu ? null : (
					<ActionsMenu
						whiteInner={true}
						options={[
							{
								text: formatMessage({id: 'common.remove'}),
								onClick: this.removeAttachedObject.bind(this),
							},
						]}
					/>
				)}
			</div>
		);
	}

	render() {
		switch (this.props.githubObject.type) {
			case 'issue':
				return this.getIssue();
			case 'pullrequest':
				return this.getPullRequest();
			case 'branch':
				return this.getBranch();
			default:
				return <div />;
		}
	}
}

GitHubObject.propTypes = {
	githubObject: PropTypes.shape({
		id: PropTypes.string,
		type: PropTypes.string,
		title: PropTypes.string,
		url: PropTypes.string,
		repo: PropTypes.string,
		number: PropTypes.string,
		createdBy: PropTypes.string,
		status: PropTypes.string,
		state: PropTypes.string,
		head: PropTypes.string,
		base: PropTypes.string,
		disableActionMenu: PropTypes.bool,
	}),
};

export default injectIntl(GitHubObject);
