import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../../constants';
import TimeEntry from './time_entry';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import WarningIcon from '../../../../images/warning_icon';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import Tooltip from '../../../../components/new-ui/tooltip';
import {findDOMNode} from 'react-dom';
import TimeReg from '../../../new-ui/time_reg';
import {usingTimeApproval} from '../../../../forecast-app/shared/util/FeatureUtil';
import {getLockedDate} from '../../../timesheets/time-lock/TimeLockUtil';
import {
	TileExpansionBackgroundWrapper,
	TileExpansionRow,
	TileExpansionText,
	TileExpansionTextLight,
} from './MainSectionTile.styled';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {TaskModalTimeEntriesPlaceholder} from '../../../../forecast-app/shared/components/placeholders/TaskModalTimeEntriesPlaceholder';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {isTaskTimeRegistrationAllowed} from '../../../../forecast-app/shared/util/time-registration/time-registration-settings/TimeRegistrationTaskFilter';
import {isRolesAllowed} from '../../../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';

function getPersonProjectRolesByPersonId(project) {
	if (!project?.projectPersons?.edges) {
		return {};
	}
	return project.projectPersons.edges.reduce((map, projectPerson) => {
		if (projectPerson.node.role) {
			map[projectPerson.node.person.id] = projectPerson.node.role.name;
		}
		return map;
	}, {});
}

class timeEntriesTile extends Component {
	constructor(props) {
		super(props);

		this.timeEntriesTile = React.createRef();
		this.addButton = React.createRef();
		this.modalRef = React.createRef();
		this.state = {
			expanded: false,
			newEntryHours: '',
			createdTimeRegistrationIds: [],
			showTimeRegTooltip: false,
			tooltipPosition: {},
			shouldSelectInput: false,
		};

		// -- function bindings --
		this.onTimeRegDone = this.onTimeRegDone.bind(this);
		this.setTimeRegRef = this.setTimeRegRef.bind(this);
		this.setHeaderTimeEntriesRef = this.setHeaderTimeEntriesRef.bind(this);
		this.handleMouseClick = this.handleMouseClick.bind(this);
		this.handleSubtaskDone = this.handleSubtaskDone.bind(this);
		// -- end function bindings
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleMouseClick);
		subscribe(EVENT_ID.SUBTASK_MARKED_AS_DONE, this.handleSubtaskDone);
		const taskId = this.props.viewer.task ? parseInt(atob(this.props.viewer.task.id).replace('Task:', '')) : '';
		const actualPersonIdInt = parseInt(atob(this.props.viewer.actualPersonId).replace('Person:', ''));
		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.TIME_REG,
				action: SOCKET_ACTION.UPDATE,
				taskIds: taskId,
			},
			{
				type: SOCKET_EVENT_TYPE.TIME_REG,
				action: SOCKET_ACTION.CREATE,
				taskIds: taskId,
			},
			{
				type: SOCKET_EVENT_TYPE.TIME_REG,
				action: SOCKET_ACTION.DELETE,
				taskIds: taskId,
			},
			{
				type: SOCKET_EVENT_TYPE.TIME_LOCK,
				action: SOCKET_ACTION.UPDATE,
				personIds: actualPersonIdInt,
			},
		];
		this.props.setSocketConfig(socketEvents);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleMouseClick);
		unsubscribe(EVENT_ID.SUBTASK_MARKED_AS_DONE, this.handleSubtaskDone);
	}

	setHeaderTimeEntriesRef(node) {
		this.headerTimeRegRef = node;
	}

	setTimeRegRef(node) {
		this.timeRegRef = node;
	}

	onTimeRegDone() {
		if (this.state.expanded) {
			this.toggleExpansion();
		}
	}

	handleMouseClick(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		if (this.modalRef.current) {
			return;
		}

		if (
			e.target &&
			e.target.tagName &&
			(e.target.tagName === 'svg' || e.target.tagName === 'path') &&
			this.state.expanded
		) {
			// Special case to avoid collapsing when clicking the caret icon in the role dropdown
			if (isRolesAllowed() && e.target.getAttribute('class') && e.target.getAttribute('class').includes('caret-icon')) {
				return;
			}

			if (!e.target.getAttribute('class') || !e.target.getAttribute('class').includes('ai-icon')) {
				this.toggleExpansion();
			}
			return;
		}

		if (
			this.timeRegRef &&
			!this.timeRegRef.contains(e.target) &&
			this.headerTimeRegRef &&
			!this.headerTimeRegRef.contains(e.target) &&
			newTarget &&
			newTarget.className &&
			!newTarget.className.includes('harvest-dropdown') &&
			!newTarget.className.includes('calendar-day') &&
			!newTarget.className.includes('calendars-wrapper') &&
			!newTarget.className.includes('calendars-dropdown-wrapper') &&
			!newTarget.className.includes('viewed-year') &&
			!newTarget.className.includes('viewed-month') &&
			!newTarget.className.includes('control') &&
			!newTarget.className.includes('date-picker') &&
			!newTarget.className.includes('actions') &&
			!newTarget.className.includes('label-hidden') &&
			!newTarget.className.includes('date-picker-button-standard') &&
			!newTarget.className.includes('text-tooltip-wrapper') &&
			!newTarget.className.includes('option') &&
			!newTarget.className.includes('hour-input') &&
			!newTarget.className.includes('role-drop-down')
		) {
			this.toggleExpansion();
		}
	}

	handleSubtaskDone(taskId, estimate) {
		if (taskId === this.props.viewer.task.id) {
			this.toggleExpansion();
			this.setState({
				showTimeRegTooltip: true,
				shouldSelectInput: true,
				newEntryHours: this.props.isTwoLevelSubTask
					? this.props.viewer.task.project.estimationUnit === 'HOURS'
						? estimate / 60
						: (estimate / 60) * this.props.viewer.task.project.minutesPerEstimationPoint
					: '',
			});

			requestAnimationFrame(() => {
				if (this.newTimeEntryHoursInput) {
					const timeEntryInput = findDOMNode(this.newTimeEntryHoursInput);
					this.setState({tooltipPosition: timeEntryInput.getBoundingClientRect()});
				}
			});
		}
	}

	toggleExpansion(e) {
		const expanded = !this.state.expanded;
		this.setState({expanded, showTimeRegTooltip: false});
	}

	focusTimeEntriesTile() {
		this.timeEntriesTile.current.focus();
	}

	getTimeEntriesWarning(totalMinutesRegistered) {
		if (
			this.props.viewer.task.project.estimationUnit === 'HOURS'
				? totalMinutesRegistered > this.props.viewer.task.estimateForecast
				: totalMinutesRegistered >
				  this.props.viewer.task.estimateForecast * this.props.viewer.task.project.minutesPerEstimationPoint
		) {
			return {
				showWarning: true,
				danger: false,
				message: this.props.intl.formatMessage({id: 'card.over_forecast.message'}),
			};
		}
		return 0;
	}

	tileClicked() {
		trackEvent('Time Entries Field', 'Clicked', {expanded: !this.state.expanded});
		this.toggleExpansion();
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer, disabled, isClientUser, rollupTimeEntries} = this.props;
		const {task} = viewer;
		// if task was deleted
		if (!task || !task.id) return null;

		const lockedDate = getLockedDate(viewer.company, viewer);

		const {project} = task;
		const {expanded, createdTimeRegistrationIds, showTimeRegTooltip} = this.state;

		const taskStatusCategory = task.statusColumnV2?.category;
		const assignedPersonIds = task.assignedPersons ? task.assignedPersons.map(person => person.id) : [];

		const totalMinutesRegistered = task.timeRegistrations.edges.reduce(
			(totalMinutesRegistered, timeRegistrationEdge) =>
				totalMinutesRegistered + timeRegistrationEdge.node.minutesRegistered,
			0
		);
		const totalMinutesRollup = totalMinutesRegistered + (rollupTimeEntries ?? 0);

		const usingHarvest =
			task && project !== null && project.harvestProject !== null && viewer.company.harvestEnabled && viewer.harvestUser;
		const harvestTaskOptions = [];
		if (usingHarvest && project.harvestProjectTasks) {
			project.harvestProjectTasks.forEach(option => {
				harvestTaskOptions.push({
					value: option.id,
					label: option.name,
				});
			});
		}

		const edges = JSON.parse(JSON.stringify(task.timeRegistrations.edges)).filter(e => e.node.person !== undefined);
		edges.sort((a, b) => {
			const aDate = Util.CreateNonUtcMomentDate(a.node.year, a.node.month, a.node.day);
			const bDate = Util.CreateNonUtcMomentDate(b.node.year, b.node.month, b.node.day);
			const aCreatedIndex = createdTimeRegistrationIds.findIndex(id => a.node.id === id);
			const bCreatedIndex = createdTimeRegistrationIds.findIndex(id => b.node.id === id);

			if (aDate === null) {
				return bDate === null ? 0 : 1;
			} else if (bDate === null) {
				return -1;
			}

			if (aCreatedIndex !== -1 && bCreatedIndex !== -1) {
				if (aCreatedIndex < bCreatedIndex) {
					return 1;
				}
				if (bCreatedIndex < aCreatedIndex) {
					return -1;
				}
			} else {
				if (aCreatedIndex !== -1 && bCreatedIndex === -1) {
					return -1;
				}
				if (bCreatedIndex !== -1 && aCreatedIndex === -1) {
					return 1;
				}
				if (aDate.isBefore(bDate)) {
					return 1;
				}
				if (bDate.isBefore(aDate)) {
					return -1;
				}
			}
			return 0;
		});

		const timeEntriesWarning = this.getTimeEntriesWarning(totalMinutesRegistered);

		const personProjectRoles = getPersonProjectRolesByPersonId(project);

		const noNewTimeEntries =
			isClientUser ||
			disabled ||
			!isTaskTimeRegistrationAllowed(taskStatusCategory, viewer.actualPersonId, assignedPersonIds, task.project);

		const useTimeApproval = usingTimeApproval(viewer.company.useTimeApproval);

		if (hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES)) {
			return <TaskModalTimeEntriesPlaceholder />;
		} else {
			return (
				<div
					className={'header-tile time-entries-tile' + (expanded ? ' expanded' : '')}
					onClick={this.tileClicked.bind(this)}
					tabIndex="0"
					data-cy={this.props.cy}
					data-userpilot={'task-modal-time-entries-tile'}
					ref={this.timeEntriesTile}
				>
					<div className="tile-wrapper" ref={this.setHeaderTimeEntriesRef}>
						<div className="tile-container">
							<div className="tile-label">{formatMessage({id: 'common.time_entries'})}</div>
							<div className="text-container">
								{timeEntriesWarning && timeEntriesWarning.showWarning ? (
									<span className="warning" title={timeEntriesWarning.message}>
										<WarningIcon danger={timeEntriesWarning.danger} />
									</span>
								) : null}
								<div className="number-text" data-cy={`${this.props.cy}-number`}>
									{Util.convertMinutesToFullHour(totalMinutesRollup, this.props.intl, true)}
								</div>
							</div>
						</div>
					</div>
					{/* need to place tooltip outside the ternary to work */}
					<Tooltip
						infinteDuration
						autoPlace={true}
						grey={true}
						position={this.state.tooltipPosition}
						shown={showTimeRegTooltip}
						infoText={
							this.props.isTwoLevelSubTask
								? formatMessage({id: 'task_modal.time_entry_tile_tooltip_text'})
								: formatMessage({id: 'task_modal.time_entry_tile_todo_tooltip_text'})
						}
					/>
					{expanded ? (
						<>
							<div className="header-tile-dropdown-container" ref={this.setTimeRegRef}>
								<div
									className={'content-container-time-entries' + (noNewTimeEntries ? ' done' : '')}
									onClick={e => e.stopPropagation()}
								>
									<CustomScrollDiv
										autoHeight={true}
										autoHeightMin={1}
										autoHeightMax={window.innerHeight > 800 ? 500 : 380}
									>
										{rollupTimeEntries != null && (
											<TileExpansionBackgroundWrapper>
												<TileExpansionRow withPadding>
													<TileExpansionText>
														{formatMessage({id: 'task_modal.time_entries_on_this_task'})}
													</TileExpansionText>
													<TileExpansionText>
														{Util.convertMinutesToFullHour(
															totalMinutesRegistered,
															this.props.intl,
															true
														)}
													</TileExpansionText>
												</TileExpansionRow>
												<TileExpansionRow>
													<TileExpansionTextLight>
														{formatMessage({id: 'task_modal.subtasks_total_time_entries'})}
													</TileExpansionTextLight>
													<TileExpansionTextLight>
														{Util.convertMinutesToFullHour(
															totalMinutesRollup,
															this.props.intl,
															true
														)}
													</TileExpansionTextLight>
												</TileExpansionRow>
											</TileExpansionBackgroundWrapper>
										)}
										{noNewTimeEntries && !edges.length ? (
											<div className="no-time-registered-text">
												{formatMessage({id: 'common.no_time_registered'})}
											</div>
										) : null}
										{noNewTimeEntries ? null : (
											<div className="new-entry-row">
												<TimeReg
													task={task}
													viewer={viewer}
													onDone={this.onTimeRegDone}
													isTaskModal={true}
													focusOnMount={true}
													harvestTaskOptions={harvestTaskOptions}
													lockedDate={lockedDate}
												/>
											</div>
										)}
										{edges.map(timeRegistrationEdge => {
											const timeRegPerson = timeRegistrationEdge.node.person;
											const personLockedDate = getLockedDate(viewer.company, timeRegPerson);
											return (
												<TimeEntry
													key={timeRegistrationEdge.node.id}
													viewer={viewer}
													personProjectRoles={personProjectRoles}
													task={task}
													timeReg={timeRegistrationEdge.node}
													disabled={disabled}
													focusTimeEntriesTile={this.focusTimeEntriesTile.bind(this)}
													harvestTaskOptions={harvestTaskOptions}
													modalRef={this.modalRef}
													lockedDate={personLockedDate}
													useTimeApproval={useTimeApproval}
												/>
											);
										})}
									</CustomScrollDiv>
								</div>
							</div>
						</>
					) : null}
				</div>
			);
		}
	}
}

const timeEntriesTileQuery = graphql`
	query timeEntriesTile_Query($taskId: ID, $personId: ID!) {
		viewer {
			actualPersonId
			component(name: "time_entries")
			...timeEntriesTile_viewer @arguments(taskId: $taskId, personId: $personId)
		}
	}
`;

export {timeEntriesTileQuery};

export default withSocketHandling(
	injectIntl(
		createFragmentContainer(timeEntriesTile, {
			viewer: graphql`
				fragment timeEntriesTile_viewer on Viewer @argumentDefinitions(taskId: {type: "ID"}, personId: {type: "ID"}) {
					id
					actualPersonId
					firstName
					lastName
					profilePictureId
					profilePictureDefaultId
					harvestUser
					unit4User
					economicUser
					language
					harvestUser
					excludeFromCompanyLockedPeriod
					submitLockedDateYear
					submitLockedDateMonth
					submitLockedDateDay
					startDate
					endDate
					createdAt
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					availableFeatureFlags {
						key
					}
					company {
						id
						harvestEnabled
						unit4Enabled
						economicEnabled
						characterLimit
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						useTimeApproval
						person(id: $personId) {
							id
							role {
								id
								name
							}
						}
						modules {
							moduleType
						}
						roles(includeDisabled: false) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					task(id: $taskId) {
						id
						estimateForecast
						estimateForecastMinutes
						totalMinutesRegistered
						timeLeft
						name
						companyTaskId
						parentTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						billable
						role {
							id
						}
						assignedPersons {
							id
						}
						statusColumnV2 {
							category
						}
						project {
							minutesPerEstimationPoint
							estimationUnit
							id
							companyProjectId
							billable
							status
							name
							projectColor
							projectStartYear
							projectStartMonth
							projectStartDay
							projectEndYear
							projectEndMonth
							projectEndDay
							manualProgressOnProjectEnabled
							manualProgressOnPhasesEnabled
							manualProgressOnTasksEnabled
							harvestProject {
								id
							}
							harvestProjectTasks {
								id
								name
							}
							unit4Project {
								id
								activitiesEnabled
							}
							projectPersons(first: 10000) {
								edges {
									node {
										person {
											id
										}
										role {
											id
											name
										}
									}
								}
							}
							program {
								name
								prefix
								budgetType
								members {
									edges {
										node {
											role
											person {
												id
											}
										}
									}
								}
							}
						}
						timeRegistrations(first: 10000) @connection(key: "Task_timeRegistrations") {
							edges {
								node {
									id
									minutesRegistered
									billableMinutesRegistered
									year
									month
									day
									notes
									invoiced
									approvalStatus
									unit4TimeEntryId
									unit4ActivityId
									unit4ActivityName
									harvestTask {
										id
										name
									}
									harvestTimeId
									harvestError
									xeroInvoiceId
									lockedInPeriod
									person {
										id
										fullName
										isViewer
										profilePictureId
										profilePictureDefaultId
										excludeFromCompanyLockedPeriod
										submitLockedDateYear
										submitLockedDateMonth
										submitLockedDateDay
										harvestUser
										monday
										tuesday
										wednesday
										thursday
										friday
										saturday
										sunday
										role {
											id
											name
										}
									}
									role {
										id
										name
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
