import React from 'react';
import {useIntl} from 'react-intl';
import Util from '../../../../forecast-app/shared/util/util';
import {AffixedInputWrapper} from '../../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import HoursInput from '../../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {
	TileExpansionInputText,
	TileExpansionInputWrapper,
	TileExpansionRow,
	TileExpansionText,
	TileExpansionTextLight,
	TileExpansionWrapper,
} from './MainSectionTile.styled';

export const ForecastExpansionTile = ({
	isEstimatedInHours,
	estimateForecast,
	disabled,
	estimateTotal,
	tileRef,
	subtaskInputRef,
	onEstimateBlur,
}) => {
	const intl = useIntl();

	return (
		<TileExpansionWrapper ref={tileRef}>
			<TileExpansionRow withPadding>
				<TileExpansionText>{intl.formatMessage({id: 'task_modal.estimate_on_this_task'})}</TileExpansionText>
				<TileExpansionInputWrapper>
					{isEstimatedInHours ? (
						<HoursInput
							id="task-modal-est-low"
							focusOnMount
							value={estimateForecast / 60}
							customClassName={'hours-input'}
							mutation={value => onEstimateBlur(value * 60, 'estimateForecast')}
							onClick={e => e.focus()}
							cy={'estimate-input'}
							disabled={disabled}
							userpilot={'task-modal-estimate-input'}
						></HoursInput>
					) : (
						<AffixedInputWrapper
							innerRef={subtaskInputRef}
							disabled={disabled}
							value={estimateForecast}
							callback={value => onEstimateBlur(value, 'estimateForecast')}
							affix={intl.formatMessage({id: 'common.points.short'})}
						/>
					)}
				</TileExpansionInputWrapper>
			</TileExpansionRow>
			<TileExpansionRow>
				<TileExpansionTextLight>
					{intl.formatMessage({id: 'task_modal.subtasks_total_estimate'})}
				</TileExpansionTextLight>
				<TileExpansionInputText>
					<TileExpansionTextLight>
						{isEstimatedInHours
							? Util.convertMinutesToFullHour(estimateTotal, intl, true)
							: estimateTotal + intl.formatMessage({id: 'common.points.short'})}
					</TileExpansionTextLight>
				</TileExpansionInputText>
			</TileExpansionRow>
		</TileExpansionWrapper>
	);
};
