import React from 'react';
import { Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import DeleteClientBillTo from '../../../mutations/invoicing/DeleteClientBillToMutation';
const RemoveClientBillToModal = ({ clientId, clientBillToId, closeModal }) => {
    const removeClientBillTo = () => {
        Util.CommitMutation(DeleteClientBillTo, {
            clientBillToId,
            clientId,
        }, closeModal);
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.unassociate_client' })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.unassociate_client.description' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: removeClientBillTo },
                React.createElement(FormattedMessage, { id: 'common.remove' })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export default RemoveClientBillToModal;
