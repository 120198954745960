import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {BasicNumberInput, LockIcon} from 'web-components';

import {currencies} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import UpdateExchangeRateMutation from '../../mutations/update_exchange_rate_mutation';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteExchangeRateMutation from '../../mutations/delete_exchange_rate_mutation';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {CSS_CONSTANTS} from '../../css_variables';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';

export const CurrencyRow = injectIntl(({intl: {formatMessage}, exchangeRate, company, isFirstRow}) => {
	const currency = currencies.find(c => c.value === exchangeRate.currency);
	const [hasError, setHasError] = useState(false);

	const updateRate = rate => {
		if (rate <= 0) {
			setHasError(true);
		} else {
			setHasError(false);
			if (rate !== exchangeRate.rate) {
				Util.CommitMutation(
					UpdateExchangeRateMutation,
					{
						id: exchangeRate.id,
						rate: +rate,
					},
					null,
					true
				);
			}
		}
	};

	const deleteExchangeRate = () => {
		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'exchange-rate.has-been-deleted'})});
		};
		const callbackPositive = () => {
			Util.CommitMutation(
				DeleteExchangeRateMutation,
				{
					companyId: company.id,
					id: exchangeRate.id,
				},
				onSuccess,
				true
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	};

	const canDelete = !exchangeRate.isUsed;

	return (
		<>
			<td className="column-currency">
				<div className="currency-wrapper">
					<div>{currency ? currency.label : exchangeRate.currency}</div>
					<div className="currency-lock">
						{!canDelete && (
							<TooltipContainer
								tooltipDuration={1000 * 60}
								infoText={formatMessage({id: 'settings_finance.currency_locked'})}
							>
								<LockIcon size={LockIcon.SIZE.STANDARD} color={CSS_CONSTANTS.v2_text_gray} />
							</TooltipContainer>
						)}
					</div>
				</div>
			</td>
			<td className="column-rate">
				<div className="rate-wrapper">
					<div className="rate-text">{`${company.currency} 1.00 = ${exchangeRate.currency}`}</div>
					<div className="rate-input" data-userpilot={isFirstRow ? 'currencies-rate' : null}>
						<BasicNumberInput value={exchangeRate.rate} callback={updateRate} hasError={hasError} width="70px" />
					</div>
				</div>
			</td>
			<td className="column-actions">
				{canDelete && (
					<ActionsMenu
						whiteInner={true}
						options={[
							{
								text: formatMessage({id: 'common.delete'}),
								onClick: deleteExchangeRate,
							},
						]}
					/>
				)}
			</td>
		</>
	);
});
