import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Dropdown from '../../../../forecast-app/shared/components/dropdowns/dropdown';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE, DependencyType} from '../../../../constants';
import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../../forecast-app/shared/util/util';
import CreateDependencyMutation from '../../../../mutations/create_dependency_mutation';
import UpdateDependencyMutation from '../../../../mutations/update_dependency_mutation';
import Warning from '../../../../components/warning';
import AddButton from '../../../add_button';

class AddDependencyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDependencyId: this.props.dependency
				? this.props.before
					? this.props.dependency.node.thisDependsOnTask.id
					: this.props.dependency.node.taskDependsOnThis.id
				: null,
			toStart: this.props.dependency ? this.props.dependency.node.type === DependencyType.CANNOT_START : true,
		};
	}

	onDependencySelect(selected) {
		this.setState({selectedDependencyId: selected ? selected.value : null});
	}

	onConditionSelect(condition) {
		if (condition && condition.value === 'start') {
			this.setState({toStart: true});
		} else {
			this.setState({toStart: false});
		}
	}

	callbackPositive() {
		const {formatMessage} = this.props.intl;
		const onSuccess = result => {
			if (
				(!this.props.dependency && result.createDependency.errors && result.createDependency.errors.length !== 0) ||
				(this.props.dependency && result.updateDependency.errors && result.updateDependency.errors.length !== 0)
			) {
				const error = (result.updateDependency || result.createDependency).errors[0];
				let message = '';
				switch (error) {
					case 'DEPENDING_TASK_ALREADY_STARTED':
						message = formatMessage(
							{id: 'dependency.not_to_do_warning'},
							{
								taskId:
									'T' +
									this.props.projectTasks.edges.find(
										taskEdge =>
											taskEdge.node.id ===
											(this.props.before ? this.props.task.id : this.state.selectedDependencyId)
									).node.companyTaskId,
							}
						);
						break;
					case 'DEPENDING_TASK_IS_ALREADY_DONE':
						message = formatMessage(
							{id: 'dependency.is_done_warning'},
							{
								taskId:
									'T' +
									this.props.projectTasks.edges.find(
										taskEdge =>
											taskEdge.node.id ===
											(this.props.before ? this.props.task.id : this.state.selectedDependencyId)
									).node.companyTaskId,
							}
						);
						break;
				}
				showModal({
					type: MODAL_TYPE.GENERIC,
					content: (
						<div className="default-warning-modal">
							<Warning messageId="common.invalid_action_modal_title" />
							<div className="warning-part-2">{message}</div>
						</div>
					),
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			} else {
				this.setState({selectedDependencyId: null, toStart: true});
				if (this.props.isEditing) {
					this.props.toggleForm();
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'task_modal.dependency_has_been_updated'}),
					});
				} else {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'task_modal.dependency_has_been_added'}),
					});
				}
			}
		};
		const isDependant = !this.props.before;
		const type = this.state.toStart ? DependencyType.CANNOT_START : DependencyType.CANNOT_BE_COMPLETED;

		if (this.props.dependency) {
			Util.CommitSchedulingModalUpdate(
				UpdateDependencyMutation,
				{
					id: this.props.dependency.node.id,
					type,
					taskIdDependsOnThis: isDependant ? this.state.selectedDependencyId : this.props.task.id,
					thisDependsOnTaskId: isDependant ? this.props.task.id : this.state.selectedDependencyId,
				},
				onSuccess
			);
		} else if (!this.props.dependency) {
			Util.CommitSchedulingModalUpdate(
				CreateDependencyMutation,
				{
					taskIdDependsOnThis: isDependant ? this.state.selectedDependencyId : this.props.task.id,
					thisDependsOnTaskId: isDependant ? this.props.task.id : this.state.selectedDependencyId,
					type,
				},
				onSuccess
			);
		}
	}

	render() {
		const {
			before,
			intl: {formatMessage},
			toggleForm,
			isEditing,
			task,
			projectTasks,
		} = this.props;

		const {selectedDependencyId, toStart} = this.state;

		const dependenciesDropdownOptions = projectTasks.edges
			.filter(taskEdge => taskEdge.node.id !== task.id && taskEdge.node.approved)
			.map(task => ({
				label: `T${task.node.companyTaskId} ${task.node.name}`,
				value: task.node.id,
				dataCy: 'dependency-option',
			}));

		const conditionDropdownOptions = [
			{
				dataCy: 'condition-option-start',
				label: formatMessage({id: 'common.start'}),
				value: 'start',
			},
			{
				dataCy: 'condition-option-finish',
				label: formatMessage({id: 'common.finish'}),
				value: 'finish',
			},
		];

		return (
			<>
				<div className={before ? `before-form dependency-form` : `after-form dependency-form`}>
					<div className="task-container-wrapper">
						{isEditing ? <div className="corner" /> : null}
						<div className="task-container">
							{!before ? (
								<>
									<span className="form-helper-text">
										{' '}
										{formatMessage({id: 'task_modal.finish_current_task_to'})}{' '}
									</span>
								</>
							) : (
								<span className="form-helper-text"> {formatMessage({id: 'common.finish'})} </span>
							)}
							{before ? (
								<Dropdown
									customClasses="dependencies-dropdown"
									onChange={this.onDependencySelect.bind(this)}
									options={dependenciesDropdownOptions}
									value={selectedDependencyId || null}
									placeholder={formatMessage({id: 'common.task_name'})}
									hideLabel={true}
									disabled={(isEditing && selectedDependencyId) || false}
									inputCy="add-before-dependency-dropdown"
									customHeight={30}
								/>
							) : null}
							{before ? <span className="form-helper-text"> {formatMessage({id: 'common.to'})} </span> : null}
							<Dropdown
								customClasses="conditions-dropdown"
								options={conditionDropdownOptions}
								onChange={this.onConditionSelect.bind(this)}
								value={toStart ? 'start' : 'finish'}
								placeholder={formatMessage({id: 'common.select'})}
								hideLabel={true}
								customHeight={30}
								inputCy="add-dependency-condition-dropdown"
							/>
							{before ? null : (
								<Dropdown
									customClasses="dependencies-dropdown after"
									onChange={this.onDependencySelect.bind(this)}
									options={dependenciesDropdownOptions}
									value={selectedDependencyId || null}
									placeholder={formatMessage({id: 'common.task_name'})}
									hideLabel={true}
									disabled={(isEditing && selectedDependencyId) || false}
									inputCy="add-after-dependency-dropdown"
									customHeight={30}
								/>
							)}

							<div className="buttons-row">
								{isEditing ? (
									<>
										<Button
											text={formatMessage({id: 'common.cancel'})}
											onClick={toggleForm}
											buttonStyle={BUTTON_STYLE.FILLED}
											colorTheme={BUTTON_COLOR.WHITE}
										/>
										<Button
											isDisabled={!selectedDependencyId}
											text={
												this.props.dependency
													? formatMessage({id: 'common.save'})
													: formatMessage({id: 'common.add'})
											}
											onClick={this.callbackPositive.bind(this)}
											buttonStyle={BUTTON_STYLE.FILLED}
											colorTheme={BUTTON_COLOR.GREEN}
											cy="create-new-dependency-button"
										/>
									</>
								) : (
									<AddButton
										onClick={this.state.selectedDependencyId ? this.callbackPositive.bind(this) : null}
										disabled={!this.state.selectedDependencyId}
										cy="create-new-dependency-button"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default injectIntl(AddDependencyForm);
