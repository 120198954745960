import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import InputField from '../inputs/input_field';
import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeletePersonMutation from '../../mutations/delete_person_mutation';
import GenericModal from '../../containers/modal/generic_modal';
import Warning from '../../components/warning';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';

class DeletePersomModalContent extends Component {
	constructor(props) {
		super(props);

		this.deletingPerson = false;
		this.confirmText = 'delete';
		if (this.props.personEmail && this.props.personEmail.trim() !== '') {
			this.confirmText = this.props.personEmail.trim();
		} else if (this.props.personName && this.props.personName.trim() !== '') {
			this.confirmText = this.props.personName.trim();
		}

		this.state = {
			input_field_value: '',
			loading: false,
		};
	}

	handleInputChange(e) {
		this.setState({input_field_value: e});
	}

	deletePerson() {
		if (!this.deletingPerson) {
			this.deletingPerson = true;

			this.setState({loading: true});

			const onSuccess = result => {
				if (
					result.deletePerson &&
					result.deletePerson.errors &&
					result.deletePerson.errors.length === 1 &&
					result.deletePerson.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD'
				) {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
						nonTimeRegWarning: true,
					});
				} else {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'settings_team.toast.user_deleted'}),
					});
					this.props.closeModal();
					window.location.reload();
				}
			};

			Util.CommitMutation(
				DeletePersonMutation,
				{
					id: this.props.personId,
					parentId: this.props.viewerId,
				},
				onSuccess
			);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const content = (
			<div className="default-warning-modal">
				<Warning messageId="common.delete-confirmation-title" />
				<div className="warning-part-2">
					<FormattedMessage
						id="settings_team.delete_person_modal.description"
						values={{
							list: (
								<FormattedMessage
									id="settings_team.delete_person_modal.list"
									values={{
										li: chunks => (
											<li>
												<b>{chunks}</b>
											</li>
										),
									}}
								>
									{message => <ul>{message}</ul>}
								</FormattedMessage>
							),
							b: chunks => <b>{chunks}</b>,
						}}
					/>
				</div>
				<div className="warning-part-2">
					<FormattedMessage
						id="settings_team.delete_person_modal.confirm_text"
						values={{confirmText: this.confirmText}}
					/>
				</div>
				<InputField
					value={this.state.input_field_value}
					onChange={this.handleInputChange.bind(this)}
					label={formatMessage(
						{id: 'settings_team.delete_person_modal.type_confirm_text'},
						{confirmText: this.confirmText}
					)}
					placeholder={formatMessage(
						{id: 'settings_team.delete_person_modal.type_confirm_text'},
						{confirmText: this.confirmText}
					)}
					type="text"
					autoFocus={true}
				/>
			</div>
		);

		return (
			<GenericModal
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.delete'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						callback: this.deletePerson.bind(this),
						disabled:
							this.state.loading ||
							this.confirmText.toLowerCase() !== this.state.input_field_value.toLowerCase().trim(),
					},
				]}
				content={content}
			/>
		);
	}
}

DeletePersomModalContent.propTypes = {
	personId: PropTypes.string.isRequired,
	viewerId: PropTypes.string.isRequired,
	personName: PropTypes.string,
	personEmail: PropTypes.string,
};

export default injectIntl(DeletePersomModalContent);
