import React, {Component} from 'react';
import DependencyIcon from '../../../../images/v2/task-modal/dependency-icon';
import TaskIcon from '../../../../images/v2/task-modal/task-icon';
import DependenciesRightArrow from '../../../../images/v2/task-modal/dependencies-section-right-arrow';
import {injectIntl} from 'react-intl';
import DependencyFrom from './dependency_form';
import DependencyContainer from './dependency_container';
import LockIcon from '../../../../images/v2/task-modal/lock_icon';
import ExpandIcon from '../../../../images/v2/components/dropdown/expand_icon';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import util from '../../../../forecast-app/shared/util/util';
import InformationIcon from '../../../../images/information-icon';

class DependencySection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddingDependency: false,
		};
	}

	componentDidMount() {
		if (this.props.addedByUser) {
			this.setState({isAddingDependency: this.props.addedByUser});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.addedByUser !== prevProps.addedByUser) {
			this.setState({isAddingDependency: this.props.addedByUser});
		}
	}

	listBeforeTasksInContainer = (dependencies, locked) => {
		let container = [];
		for (const dependency of dependencies) {
			container.push(
				<DependencyContainer
					key={dependency.node.id}
					projectTasks={this.props.projectTasks}
					dependencyBefore={true}
					dependency={dependency}
					task={this.props.task}
					project={this.props.project}
					locked={locked}
					cy="before-dependency"
				/>
			);
		}
		return container;
	};

	listAfterTasksInContainer = (dependencies, locked) => {
		let container = [];
		for (const dependency of dependencies) {
			container.push(
				<DependencyContainer
					key={dependency.node.id}
					projectTasks={this.props.projectTasks}
					dependencyBefore={false}
					dependency={dependency}
					task={this.props.task}
					project={this.props.project}
					locked={locked}
					cy="after-dependency"
				/>
			);
		}
		return container;
	};
	addDependencyToggle() {
		this.setState({isAddingDependency: !this.state.isAddingDependency});
	}

	canThisTaskStart(beforeDependencies) {
		return (
			beforeDependencies.filter(
				dependency =>
					dependency.node.type === 'CANNOT_START' &&
					dependency.node.thisDependsOnTask.statusColumnV2 &&
					dependency.node.thisDependsOnTask.statusColumnV2.category !== 'DONE'
			).length === 0
		);
	}

	canThisTaskFinish(beforeDependencies) {
		return (
			beforeDependencies.filter(
				dependency =>
					dependency.node.type === 'CANNOT_BE_COMPLETED' &&
					dependency.node.thisDependsOnTask.statusColumnV2 &&
					dependency.node.thisDependsOnTask.statusColumnV2.category !== 'DONE'
			).length === 0
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {addedByUser} = this.props;
		const {isClientUser, isTaskReadOnly, project, task, projectTasks} = this.props;
		const thisTaskDependsOn = this.props.task.thisTaskDependsOn.edges;
		const dependsOnThisTask = this.props.task.dependsOnThisTask.edges;
		const canThisTaskStart = this.canThisTaskStart(thisTaskDependsOn);
		const canThisTaskFinish = this.canThisTaskFinish(thisTaskDependsOn);

		if (
			isClientUser ||
			(thisTaskDependsOn.length === 0 && dependsOnThisTask.length === 0 && !addedByUser) ||
			project.isJiraProject
		)
			return null;

		return (
			<div
				className={'task-modal-v3-dependency-section' + (addedByUser ? ' animated fadeInLeft' : '')}
				data-cy="task-modal-dependency-section"
			>
				<button
					className="dependency-section-header"
					onClick={this.props.toggleDependencySection.bind(this)}
					data-cy={'dependency-section-header'}
				>
					<div className="dependencies-icon">
						<DependencyIcon color="#adafb8" />
					</div>
					<div className="title">{formatMessage({id: 'common.dependencies'})}</div>

					<div className="task-dependecies-line-up">
						{addedByUser || thisTaskDependsOn.length ? (
							<div className={'linked-tasks-info before'}>
								<div className="text-container">
									<span>{formatMessage({id: 'task_modal.this_task_dependent_on'})}</span>
									<span className="tasks-count">{thisTaskDependsOn.length}</span>
									<span>{formatMessage({id: 'common.x_tasks'}, {amount: thisTaskDependsOn.length})}</span>
								</div>
								<div className="before-dependency-bond">
									<div className="bond" />
									<DependenciesRightArrow color="#a1a1a1" />
								</div>
							</div>
						) : null}

						<TaskIcon />

						{addedByUser || dependsOnThisTask.length ? (
							<div className="linked-tasks-info after">
								<div className="after-dependency-bond">
									<DependenciesRightArrow color="#a1a1a1" />
									<div className="bond" />
								</div>
								<div className="text-container">
									<span className="tasks-count">{dependsOnThisTask.length}</span>
									<span>{formatMessage({id: 'common.x_tasks'}, {amount: dependsOnThisTask.length})}</span>
									<span>
										{formatMessage(
											{id: 'task_modal.task_dependent_on_this_task'},
											{amount: dependsOnThisTask.length}
										)}
									</span>
								</div>
							</div>
						) : null}
					</div>
					<ExpandIcon expanded={this.props.expanded} />
				</button>
				{this.props.expanded ? (
					<>
						<div className="dependencies-info">
							<div className="predecessor-task">
								{formatMessage({id: 'task_modal.predecessor_tasks'})}
								<TooltipContainer infoText={formatMessage({id: 'task_modal.dependency_predecessor_info'})}>
									<InformationIcon />
								</TooltipContainer>
							</div>
							<div className="current-task">{formatMessage({id: 'task_modal.current_task'})}</div>
							<div className="successor-task">
								{formatMessage({id: 'task_modal.successor_tasks'})}
								<TooltipContainer infoText={formatMessage({id: 'task_modal.dependency_successor_info'})}>
									<InformationIcon />
								</TooltipContainer>
							</div>
						</div>
						<div className="dependencies-list-container">
							{thisTaskDependsOn.length || dependsOnThisTask.length ? (
								<div className="dependencies">
									<div className={'tasks-before-container'}>
										{this.listBeforeTasksInContainer(thisTaskDependsOn, isTaskReadOnly)}
									</div>

									{thisTaskDependsOn.length || dependsOnThisTask.length ? (
										<TooltipContainer
											disabled={canThisTaskStart && canThisTaskFinish}
											infoText={
												!canThisTaskStart
													? formatMessage(
															{id: 'dependency_modal.cannot_start_before_x_is_finished'},
															{
																task: `T${thisTaskDependsOn[0].node.thisDependsOnTask.companyTaskId}`,
															}
													  )
													: !canThisTaskFinish
													? formatMessage(
															{id: 'dependency_modal.cannot_finish_before_x_is_finished'},
															{
																task: `T${thisTaskDependsOn[0].node.thisDependsOnTask.companyTaskId}`,
															}
													  )
													: ''
											}
											triangleLocation={'bottomMiddle'}
										>
											<div className="task-graphic-container">
												{thisTaskDependsOn.length ? (
													<DependenciesRightArrow
														class="left"
														color={this.props.project.projectColor}
													/>
												) : null}
												{thisTaskDependsOn.length ? (
													<div
														className="line before"
														style={{backgroundColor: this.props.project.projectColor}}
													/>
												) : null}
												<div className="task-graphic">
													<div className="corner" />
													<div
														className="task"
														style={{backgroundColor: this.props.project.projectColor}}
													>
														{canThisTaskStart ? null : (
															<LockIcon
																fill={util.getTextColorV2(this.props.project.projectColor)}
															/>
														)}
														<span
															style={{
																color: util.getTextColorV2(this.props.project.projectColor),
															}}
														>{`T${this.props.task.companyTaskId}`}</span>
														{canThisTaskFinish || !canThisTaskStart ? null : (
															<LockIcon
																fill={util.getTextColorV2(this.props.project.projectColor)}
															/>
														)}
													</div>
												</div>
												{dependsOnThisTask.length ? (
													<div
														className="line after"
														style={{backgroundColor: this.props.project.projectColor}}
													/>
												) : null}
												{dependsOnThisTask.length ? (
													<DependenciesRightArrow
														class="right"
														color={this.props.project.projectColor}
													/>
												) : null}
											</div>
										</TooltipContainer>
									) : null}
									<div
										className={`tasks-after-container ${
											thisTaskDependsOn.length === 0 ? 'extra-margin' : ''
										}`}
									>
										{this.listAfterTasksInContainer(dependsOnThisTask, isTaskReadOnly)}
									</div>
								</div>
							) : null}

							{isTaskReadOnly || (!thisTaskDependsOn.length && !dependsOnThisTask.length) ? null : (
								<button className="add-dependency-toggle" onClick={this.addDependencyToggle.bind(this)}>
									{formatMessage({id: 'task_modal.add_dependencies'})}
									<ExpandIcon expanded={this.state.isAddingDependency} />
								</button>
							)}

							{this.state.isAddingDependency ? (
								<div className="forms-container">
									<DependencyFrom task={task} project={project} before={true} projectTasks={projectTasks} />
									<div className="task-graphic-container form">
										<DependenciesRightArrow class="left" color={this.props.project.projectColor} />
										<div
											className="line before"
											style={{backgroundColor: this.props.project.projectColor}}
										/>
										<div className="task-graphic">
											<div className="corner" />
											<div className="task" style={{backgroundColor: this.props.project.projectColor}}>
												<span
													style={{color: util.getTextColorV2(this.props.project.projectColor)}}
												>{`T${this.props.task.companyTaskId}`}</span>
											</div>
										</div>
										<div
											className="line after"
											style={{backgroundColor: this.props.project.projectColor}}
										/>
										<DependenciesRightArrow class="right" color={this.props.project.projectColor} />
									</div>
									<DependencyFrom task={task} project={project} before={false} projectTasks={projectTasks} />
								</div>
							) : null}
						</div>
					</>
				) : null}
			</div>
		);
	}
}

export default injectIntl(DependencySection);
