import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import Util from '../../../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT, WorkflowCategories} from '../../../../constants';
import SmallCloseIcon from '../../../../../src/images/small_close_icon';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import HoursInput from '../../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {
	TileExpansionInputText,
	TileExpansionInputWrapper,
	TileExpansionRow,
	TileExpansionSeparator,
	TileExpansionText,
	TileExpansionTextLight,
	TileExpansionWrapper,
} from './MainSectionTile.styled';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {TaskModalRemainingPlaceholder} from '../../../../forecast-app/shared/components/placeholders/TaskModalRemainingPlaceholder';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';

class RemainingTile extends Component {
	constructor(props) {
		super(props);

		const isEstimatedInHours = props.project.estimationUnit === ESTIMATION_UNIT.HOURS;

		this.state = {
			expanded: false,
			remaining: props.task.timeLeft / (isEstimatedInHours ? 60 : 1),
			showRemainingUpdatedNotice: false,
			pulseRemaining: false,
			inputFocused: false,
		};

		this.animateRemaining = this.animateRemaining.bind(this);
		this.showNotice = this.showNotice.bind(this);

		this.inputRef = React.createRef();

		this.tileRef = React.createRef();
	}

	static getDerivedStateFromProps(props, state) {
		const isEstimatedInHours = props.project.estimationUnit === ESTIMATION_UNIT.HOURS;
		if (props.task.timeLeft / (isEstimatedInHours ? 60 : 1) !== state.remaining && !state.inputFocused) {
			return {remaining: props.task.timeLeft / (isEstimatedInHours ? 60 : 1)};
		}
		return null;
	}

	componentDidMount() {
		subscribe(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.animateRemaining);
		subscribe(EVENT_ID.SHOW_REMAINING_NOTICE_TASK_MODAL, this.showNotice);
	}

	componentWillUnmount() {
		unsubscribe(EVENT_ID.SUBTASK_ESTIMATE_UPDATE, this.animateRemaining);
		unsubscribe(EVENT_ID.SHOW_REMAINING_NOTICE_TASK_MODAL, this.showNotice);
	}

	animateRemaining(taskId) {
		if (taskId === this.props.task.id) {
			this.setState({pulseRemaining: true});
			setTimeout(() => this.setState({pulseRemaining: false}), 2000);
		}
	}

	showNotice(taskId) {
		if (taskId === this.props.task.id) {
			this.setState({showRemainingUpdatedNotice: true});
			setTimeout(() => this.setState({showRemainingUpdatedNotice: false}), 10000);
		}
	}

	onTileBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		const isTargetRemainingInput = target => {
			if (this.inputRef.current) {
				return this.inputRef.current === target;
			}
			return target.id === 'remaining-input-field';
		};

		const isTargetHeaderTile = target => {
			return typeof target.className === 'string' && target.className.includes('remaining-tile');
		};

		if ((isTargetRemainingInput(e.target) && isTargetHeaderTile(newTarget)) || isTargetRemainingInput(newTarget)) return;

		this.setState({expanded: false});
	}

	handleNoticeClose() {
		this.setState({showRemainingUpdatedNotice: false});
	}

	onKeyPress(e) {
		if (e.key === 'Enter' && document.activeElement) {
			this.toggleExpansion(e);
		}
	}

	toggleExpansion(e) {
		if (e.target && this.inputRef.current) {
			if (this.inputRef.current === e.target) return;
		}
		if (this.tileRef.current && this.tileRef.current.contains(e.target)) return;
		if (e.target && (e.target.nodeName === 'path' || e.target.nodeName === 'svg')) return;
		if (
			e.target &&
			(e.target.className.includes('factor') ||
				e.target.className.includes('content-container-remaining') ||
				e.target.className.includes('input-value-container') ||
				e.target.id === 'remaining-input-field')
		)
			return;

		this.setState({expanded: !this.state.expanded, showRemainingUpdatedNotice: false});
	}

	onRemainingChange(value) {
		this.setState({remaining: value});
	}

	onRemainingBlur(value) {
		this.setState({remaining: value});

		const isEstimatedInHours = this.props.project.estimationUnit === ESTIMATION_UNIT.HOURS;
		this.setState({inputFocused: false});
		if (value !== null && value >= 0 && value !== this.state.remaining) {
			Util.CommitSchedulingModalUpdate(UpdateTaskMutation, {
				ids: [this.props.task.id],
				remaining: isEstimatedInHours ? value * 60 : value * (isEstimatedInHours ? 60 : 1),
			});
		}
	}

	onRemainingFocus(e) {
		this.setState({inputFocused: true});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {task, project, disabled, rollupRemaining} = this.props;
		const {expanded} = this.state;
		const {timeLeft} = task;
		const isEstimatedInHours = project.estimationUnit === ESTIMATION_UNIT.HOURS;

		const timeLeftTotal = timeLeft + (rollupRemaining ?? 0);

		const roundToTwoDecimals = number => {
			return Math.round(number * 100) / 100;
		};

		const totalMinutesRegistered = task.timeRegistrations.edges.reduce(
			(totalMinutesRegistered, timeRegistrationEdge) =>
				totalMinutesRegistered + timeRegistrationEdge.node.minutesRegistered,
			0
		);

		const forecast = roundToTwoDecimals(isEstimatedInHours ? task.estimateForecast / 60 : task.estimateForecast);

		const subtaskTotalEstimate = task.subTasks.edges
			.filter(subTaskEdge => !subTaskEdge.node.done)
			.reduce(
				(total, subTaskEdge) =>
					total + (isEstimatedInHours ? subTaskEdge.node.estimate / 60 : subTaskEdge.node.estimate),
				0
			);

		if (hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES) || hasPermission(PERMISSION_TYPE.CLIENT_HIDE_TIME_ENTRIES)) {
			return <TaskModalRemainingPlaceholder />;
		} else {
			return (
				<div
					className={'header-tile remaining-tile' + (expanded ? ' expanded' : '')}
					onKeyPress={this.onKeyPress.bind(this)}
					onClick={this.toggleExpansion.bind(this)}
					onBlur={this.onTileBlur.bind(this)}
					tabIndex="0"
					data-userpilot={'task-modal-remaining-tile'}
				>
					{this.state.showRemainingUpdatedNotice ? (
						<div className="remaing-update-notice animated faster fadeInDown">
							<SmallCloseIcon onClick={this.handleNoticeClose.bind(this)} />
							<div>{formatMessage({id: 'task_modal.remaining_notice_line_1'})}</div>
							<div>{formatMessage({id: 'task_modal.remaining_notice_line_2'})}</div>
						</div>
					) : null}
					<div className="tile-wrapper">
						<div className="tile-container">
							<div className="tile-label">{formatMessage({id: 'common.remaining'})}</div>
							{isEstimatedInHours ? (
								<div className="text-container">
									<div
										className={`number-text ${
											this.state.showRemainingUpdatedNotice || this.state.pulseRemaining
												? 'animated bounceIn'
												: ''
										} `}
										data-cy={`${this.props.cy}-number`}
									>
										{Util.convertMinutesToFullHour(timeLeftTotal, this.props.intl, true)}
									</div>
								</div>
							) : (
								<div className="text-container">
									<div
										className={`number-text ${
											this.state.showRemainingUpdatedNotice || this.state.pulseRemaining
												? 'animated bounceIn'
												: ''
										} `}
										data-cy={`${this.props.cy}-number`}
									>
										{roundToTwoDecimals(timeLeftTotal)}
									</div>
									<div className="unit-text points">
										{formatMessage({id: isEstimatedInHours ? 'common.hours.short' : 'common.points.short'})}
									</div>
								</div>
							)}
						</div>
					</div>
					{expanded ? (
						<>
							<div className="header-tile-dropdown-container">
								<TileExpansionWrapper ref={this.tileRef}>
									<TileExpansionRow withPadding>
										<TileExpansionText>
											{formatMessage({id: 'task_modal.remaining_on_this_task'})}
										</TileExpansionText>
										{project.remainingAutoCalculated || ProjectUtil.projectUsesManualProgress(project) ? (
											<TileExpansionText>
												{isEstimatedInHours
													? Util.convertMinutesToFullHour(timeLeft, this.props.intl, true)
													: timeLeft + formatMessage({id: 'common.points.short'})}
											</TileExpansionText>
										) : (
											<TileExpansionInputWrapper>
												{isEstimatedInHours ? (
													<HoursInput
														id="remaining-input-field"
														focusOnMount
														value={this.state.remaining}
														mutation={v => this.onRemainingBlur(v)}
														onClick={e => this.onRemainingFocus(e)}
														hasError={this.state.isEstimateInvalid}
														mutationValidChange={true}
														cy={'low-estimate-input'}
														disabled={
															disabled || task.statusColumnV2.category === WorkflowCategories.DONE
														}
													></HoursInput>
												) : (
													<AffixedInputWrapper
														innerRef={this.inputRef}
														disabled={
															disabled || task.statusColumnV2.category === WorkflowCategories.DONE
														}
														value={this.state.remaining}
														callback={value => this.onRemainingBlur(value)}
														affix={formatMessage({id: 'common.points.short'})}
													/>
												)}
											</TileExpansionInputWrapper>
										)}
									</TileExpansionRow>
									{rollupRemaining != null && (
										<TileExpansionRow withPadding>
											<TileExpansionTextLight>
												{formatMessage({id: 'task_modal.subtasks_total_remaining'})}
											</TileExpansionTextLight>
											<TileExpansionInputText autoRemaining={project.remainingAutoCalculated}>
												<TileExpansionTextLight>
													{isEstimatedInHours
														? Util.convertMinutesToFullHour(timeLeftTotal, this.props.intl, true)
														: timeLeftTotal + formatMessage({id: 'common.points.short'})}
												</TileExpansionTextLight>
											</TileExpansionInputText>
										</TileExpansionRow>
									)}
									{!ProjectUtil.projectUsesManualProgress(project) && (
										<>
											<TileExpansionSeparator />
											{task.subTasks.edges.length ? (
												<TileExpansionRow withPadding>
													<TileExpansionTextLight>
														{formatMessage({id: 'task_modal.estimate_from_subtasks'})}
													</TileExpansionTextLight>
													<TileExpansionInputText autoRemaining={project.remainingAutoCalculated}>
														<TileExpansionTextLight>
															{isEstimatedInHours
																? Util.convertMinutesToFullHour(
																		subtaskTotalEstimate * 60,
																		this.props.intl,
																		true
																  )
																: subtaskTotalEstimate +
																  formatMessage({id: 'common.points.short'})}
														</TileExpansionTextLight>
													</TileExpansionInputText>
												</TileExpansionRow>
											) : (
												<TileExpansionRow withPadding>
													<TileExpansionTextLight>
														{formatMessage({id: 'task_modal.estimate_on_this_task'})}
													</TileExpansionTextLight>
													<TileExpansionInputText autoRemaining={project.remainingAutoCalculated}>
														<TileExpansionTextLight>
															{isEstimatedInHours
																? Util.convertMinutesToFullHour(
																		forecast * 60,
																		this.props.intl,
																		true
																  )
																: forecast + formatMessage({id: 'common.points.short'})}
														</TileExpansionTextLight>
													</TileExpansionInputText>
												</TileExpansionRow>
											)}
											<TileExpansionRow>
												<TileExpansionTextLight>
													{formatMessage({id: 'task_modal.time_entries_on_this_task'})}
												</TileExpansionTextLight>
												<TileExpansionInputText autoRemaining={project.remainingAutoCalculated}>
													<TileExpansionTextLight>
														{Util.convertMinutesToFullHour(
															totalMinutesRegistered,
															this.props.intl,
															true
														)}
													</TileExpansionTextLight>
												</TileExpansionInputText>
											</TileExpansionRow>
										</>
									)}
								</TileExpansionWrapper>
							</div>
						</>
					) : null}
				</div>
			);
		}
	}
}

export default injectIntl(RemainingTile);
