import {Table} from 'web-components';
import React from 'react';
import {injectIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {getTotalBillableTimeEntries} from '../../TaskReportUtil';

const TaskBillableTimeColumn = props => {
	const {timeRegistrations, intl} = props;

	const totalBillableTime = timeRegistrations ? getTotalBillableTimeEntries(timeRegistrations) : 0;

	return <Table.Column.Cell>{Util.convertMinutesToFullHour(totalBillableTime, intl)}</Table.Column.Cell>;
};

export default injectIntl(TaskBillableTimeColumn);
