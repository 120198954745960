import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import GenericModal from '../../containers/modal/generic_modal';
import InputField from '../../components/new-ui/input_field';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import UpdatePersonMutation from '../../mutations/update_person_mutation.modern';

class AddEmailModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			validEmail: true, // Don't show a warning initially
			errorMessageId: 'common.field-cannot-be-empty',
			committingData: false,
		};
	}

	handleEmailChange(value) {
		this.setState({email: value});
	}

	handleConfirm(email) {
		const {personId, callback, closeModal} = this.props;
		if (!email) {
			this.setState({
				validEmail: false,
				errorMessageId: 'common.field-cannot-be-empty',
			});
		}
		if (email && this.state.validEmail) {
			const onSuccess = result => {
				this.setState({committingData: false});
				if (result.updatePerson.errors && result.updatePerson.errors.length > 0) {
					this.setState({
						validEmail: false,
						errorMessageId: 'settings_team.duplicate-email',
					});
				} else {
					closeModal();
					callback();
				}
			};
			this.setState({committingData: true});
			Util.CommitMutation(
				UpdatePersonMutation,
				{
					id: personId,
					email,
				},
				onSuccess
			);
		}
	}

	checkEmailValidity(isValid) {
		this.setState({
			validEmail: isValid,
			errorMessageId: 'settings_team.invalid-email',
		});
	}

	render() {
		const {closeModal, cy} = this.props;
		const {formatMessage} = this.props.intl;
		const {email, validEmail, errorMessageId} = this.state;
		const headerText = formatMessage({id: 'login.enter-email-address'});
		const content = (
			<div>
				<div className="add-email-modal-description">{formatMessage({id: 'add_email_modal.description'})}</div>
				<div />
				<InputField
					id="add-email-input"
					type="email"
					value={email}
					onChange={this.handleEmailChange.bind(this)}
					placeholder={formatMessage({id: 'login.enter-email-address'})}
					hideLabel
					checkEmailValidity={this.checkEmailValidity.bind(this)}
					shouldFocus={!validEmail}
					invalidInput={!validEmail}
					errorMessage={formatMessage({id: errorMessageId})}
					cy="add-email-input"
				/>
			</div>
		);
		const buttons = [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.ok'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
				callback: this.handleConfirm.bind(this, email),
				preventDefaultClose: true,
				disabled: this.state.committingData,
			},
		];

		return (
			<GenericModal
				className="add-email-modal"
				closeModal={closeModal}
				content={content}
				headerText={headerText}
				cy={cy}
				buttons={buttons}
			/>
		);
	}
}

AddEmailModal.propTypes = {
	personId: PropTypes.string.isRequired,
	callback: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(AddEmailModal);
