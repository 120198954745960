import React, {useCallback, useEffect, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import {useHistory, withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {getTaskRow} from '../rows/TaskRow';
import {Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';

const TaskRelayWrapper = ({
	viewer,
	intl,
	relay,
	tableHeader,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	searchQuery,
	convertToProjectCurrency,
	projectCurrency,
	personId,
}) => {
	const tasks = viewer.company.allTasks.edges;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const currency = convertToProjectCurrency && projectCurrency ? projectCurrency : viewer.company.currency;
	const didMount = useRef(false);
	const history = useHistory();

	const hasNextPage = viewer.company.allTasks.pageInfo.hasNextPage;

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = tasks.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
		} else if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	}, [sortValue]);

	const dataRows = tasks.map((task, index) => {
		return task.node;
	});
	const data = {
		rows: dataRows,
	};

	const showTaskModal = useCallback(
		taskId => {
			Util.showTaskModal(taskId, history);
		},
		[history]
	);

	const rows = () => {
		return (
			<Table.Rows
				data={data}
				canExpand={false}
				autoload={hasNextPage && rowIndex === 1}
				loadMore={hasNextPage ? fetchMore : false}
				{...nextLevelProps}
			>
				{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							{getTaskRow(
								intl,
								rowData,
								currency,
								viewer.availableFeatureFlags,
								enabledColumns,
								fetchMore,
								showTaskModal,
								personId
							)}
						</Table.Row>
					);
				}}
			</Table.Rows>
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={rows}
			currentLength={rows.length}
			hasNextPage={viewer.company.allTasks.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		rows()
	);
};

const TaskRelayWrapperQuery = graphql`
	query TaskRelayWrapperQuery_Query(
		$pageSize: Int
		$searchQuery: TaskSearchQueryType
		$sortValue: String
		$validateAccess: Boolean
		$convertToProjectCurrency: Boolean
	) {
		viewer {
			actualPersonId
			component(name: "task_relay_wrapper")
			...TaskRelayWrapper_viewer
				@arguments(
					pageSize: $pageSize
					searchQuery: $searchQuery
					sortValue: $sortValue
					validateAccess: $validateAccess
					convertToProjectCurrency: $convertToProjectCurrency
				)
		}
	}
`;

export {TaskRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			TaskRelayWrapper,
			{
				viewer: graphql`
					fragment TaskRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						sortValue: {type: "String"}
						validateAccess: {type: "Boolean"}
						convertToProjectCurrency: {type: "Boolean"}
					) {
						company {
							currency
							allTasks(first: $pageSize, searchQuery: $searchQuery)
								@connection(key: "Company_allTasks", filters: ["searchQuery"]) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										customFieldValues {
											edges {
												node {
													id
													key
													value
													displayName
												}
											}
										}
										approved
										financialNumbers(convertToProjectCurrency: $convertToProjectCurrency) {
											plannedRevenue
											actualRevenue
											remainingRevenue
											forecastRevenue
											recognitionLockedRevenue
											recognitionOpenRevenue
											recognitionForecastRevenue
											totalActualRevenueRecognition
											totalForecastRevenueToComplete
											surplus
										}
										timeRegistrations(first: 100000000) {
											edges {
												node {
													id
													minutesRegistered
													billableMinutesRegistered
													price
													person {
														id
													}
												}
											}
										}
										fullAccessToProject
										sortOrder
										statusColumnV2 {
											id
											name
											category
										}
										# filter and sort start
										companyTaskId
										name
										latestUiUpdateAt
										subTaskCount
										deadlineDay
										deadlineMonth
										deadlineYear
										startYear
										startMonth
										startDay
										bug
										blocked
										highPriority
										billable
										favoured
										hasDependency
										progress
										currentPrice
										estimateForecast
										estimateForecastPrice
										timeLeft
										projectedTime
										project {
											id
											status
											companyProjectId
											projectEndDay
											projectEndMonth
											projectEndYear
											estimationUnit
											projectColor #sprint section only
											projectGroupId #sprint section only
											name
											client {
												id
												name
											}
											rateCard {
												id
												name
											}
										}
										taskLabels {
											label {
												id
												name
												color
											}
										}
										assignedPersons {
											id
											firstName
											lastName
											profilePictureId
											profilePictureDefaultId
										}
										role {
											name
										}
										phase {
											id
											name
											deadlineDay
											deadlineMonth
											deadlineYear
											startDay
											startMonth
											startYear
										}
										sprint {
											id
											endDay
											endMonth
											endYear
											startDay
											startMonth
											startYear
											#start -sprint section
											name
											isProjectGroupSprint
											projectGroupSprintId
											#end - sprint section
										}
										owner {
											id
										}
										followers {
											id
										}
										# filter and sort end
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query TaskRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$sortValue: String
					$validateAccess: Boolean
					$convertToProjectCurrency: Boolean
				) {
					viewer {
						...TaskRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								sortValue: $sortValue
								validateAccess: $validateAccess
								convertToProjectCurrency: $convertToProjectCurrency
							)
					}
				}
			`
		)
	)
);
