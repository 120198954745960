import Util from '../../../forecast-app/shared/util/util';
import UpdateApprovalStatusMutation from '../../../mutations/UpdateApprovalStatusMutation';
import {APPROVAL_STATUS, APPROVAL_TYPE, ESTIMATION_UNIT} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {graphql} from 'react-relay';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {personIsVirtualUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {profilePicSrc} from '../../../directApi';

export const GROUP_BY = {
	PERSON: 'person',
	PROJECT: 'project',
};

const NO_APPROVAL_STATUS_CHANGE_REASON = {
	INVOICED: 'INVOICED',
	LOCKED_PERIOD: 'LOCKED_PERIOD',
	COMPANY_LOCK: 'COMPANY_LOCK',
	HAS_LINKED_ALLOCATION: 'HAS_LINKED_ALLOCATION',
};

const getToastMessage = (person, approvalType, intl) => {
	if (!person) return intl.formatMessage({id: 'approval.project_approved'});
	else if (approvalType === APPROVAL_TYPE.UNAPPROVE || approvalType === APPROVAL_TYPE.UNSUBMIT) {
		return intl.formatMessage({id: 'approval.person_timesheet_rejected'}, {person: person.firstName || person.lastName});
	} else if (approvalType === APPROVAL_TYPE.APPROVE) {
		return intl.formatMessage({id: 'approval.person_timesheet_approved'}, {person: person.firstName || person.lastName});
	}
};

export const UpdateApprovalStatus = (
	personIds,
	projectIds,
	idleTimeIds,
	approvalType,
	currentViewingDate,
	intl,
	person,
	isTimeOffOnly
) => {
	const startDate = currentViewingDate.clone().startOf('week');
	const endDate = currentViewingDate.clone().endOf('week');
	const update = message => {
		const onSuccess = res => {
			const toastMessage = getToastMessage(person, approvalType, intl);

			if (Util.checkForSageErrorAndShowModal(res.updateApprovalStatus.errors)) {
				return;
			} else if (toastMessage) {
				createToast({duration: 4000, message: toastMessage});
			}
		};
		const mutationData = {
			personIds: personIds,
			projectIds: projectIds,
			idleTimeIds: idleTimeIds,
			approvalType: approvalType,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
			sendNotification: true,
			includeTimeOff: true,
		};
		if (message) {
			mutationData.rejectionMessage = message;
		}
		Util.CommitMutation(UpdateApprovalStatusMutation, mutationData, onSuccess);
	};

	// if only one person, we show modal for setting a message for the email, otherwise we just send the email
	if (!!personIds && personIds.length === 1 && approvalType === APPROVAL_TYPE.UNSUBMIT) {
		showModal({
			type: MODAL_TYPE.REJECT_TIMEAPPROVAL_SUBMISSION,
			startDate,
			endDate,
			rejectForName: person.fullName,
			isTimeOffOnly,
			onReject: update,
		});
	} else {
		update();
	}
};

const getNoApprovalStatusChangeForGroup = timeRegs => {
	if (timeRegs && timeRegs.length > 0) {
		// Any time registration in the group with one of these statuses instantly disqualifies group from Approval / Rejection
		const singleNoApprovalChangeTypes = [
			NO_APPROVAL_STATUS_CHANGE_REASON.COMPANY_LOCK,
			NO_APPROVAL_STATUS_CHANGE_REASON.HAS_LINKED_ALLOCATION,
			NO_APPROVAL_STATUS_CHANGE_REASON.LOCKED_PERIOD,
		];
		const lockedOrLinkedTimeReg = timeRegs.find(timeReg =>
			singleNoApprovalChangeTypes.includes(timeReg.noApprovalStatusChange)
		);
		if (lockedOrLinkedTimeReg) {
			return lockedOrLinkedTimeReg.noApprovalStatusChange;
		}

		// Approval / Rejection should only be disabled if all time regs in group are invoiced
		if (timeRegs.every(timeReg => timeReg.noApprovalStatusChange === NO_APPROVAL_STATUS_CHANGE_REASON.INVOICED)) {
			return NO_APPROVAL_STATUS_CHANGE_REASON.INVOICED;
		}
	}

	return null;
};

export const getPersonData = (datas, parentInfo, otherPerson, allTimes, personFilter, filterOptions) => {
	let personData = [];
	const parentId = parentInfo?.id;
	const parentGroupType = parentInfo?.groupType;

	datas &&
		datas.forEach(data => {
			const {id, firstName, lastName, fullName, profilePictureId, email} = data.person;
			const timeRegDate = Util.CreateNonUtcMomentDate(data.year, data.month, data.day);
			const dayIdx = timeRegDate.weekday();
			const idx = personData.findIndex(r => r.person.id === id);
			if (idx > -1) {
				personData[idx].person.timeRegs.push(data);
				personData[idx].person.totals[dayIdx] += data.minutesRegistered;
				personData[idx].person.billableTotals[dayIdx] += data.billableMinutesRegistered || 0;
				personData[idx].person.total += data.minutesRegistered;
				personData[idx].person.billableTotal += data.billableMinutesRegistered;
			} else {
				const minutesRegisteredTotals = [null, null, null, null, null, null, null];
				minutesRegisteredTotals[dayIdx] = data.minutesRegistered;
				const billableTimeRegsTotals = [null, null, null, null, null, null, null];
				billableTimeRegsTotals[dayIdx] = data.billableMinutesRegistered || 0;
				personData.push({
					person: {
						id: id,
						firstName: firstName,
						lastName: lastName,
						fullName: fullName,
						email: email,
						profilePictureId: profilePicSrc(profilePictureId),
						timeRegs: [data],
						totals: minutesRegisteredTotals,
						billableTotals: billableTimeRegsTotals,
						total: data.minutesRegistered,
						billableTotal: data.billableMinutesRegistered,
						parentId: parentId,
						parentGroupType: parentGroupType,
						noApprovalStatusChange: data.noApprovalStatusChange,
					},
				});
			}
		});

	personData.sort((a, b) => a.person.fullName.localeCompare(b.person.fullName));

	//add the people who dind't register any time yet
	if (otherPerson) {
		const res2 = [];
		otherPerson &&
			otherPerson
				.filter(
					person =>
						!personIsVirtualUser(person.node) && (personFilter ? personFilter(person.node, filterOptions) : true)
				)
				.forEach(person => {
					const idxNotSubmitted = personData.findIndex(r => r.person.id === person.node.id);
					const idxApproved = allTimes[APPROVAL_STATUS.APPROVED].findIndex(r => r.person.id === person.node.id);
					const idxSubmitted = allTimes[APPROVAL_STATUS.SUBMITTED].findIndex(r => r.person.id === person.node.id);

					if (idxNotSubmitted === -1 && idxApproved === -1 && idxSubmitted === -1) {
						res2.push({
							person: {
								id: person.node.id,
								firstName: person.node.firstName,
								lastName: person.node.lastName,
								fullName: person.node.fullName,
								profilePictureId: profilePicSrc(person.node.profilePictureId),
								timeRegs: [],
								totals: [],
								billableTotals: [],
								total: null,
								billableTotal: null,
								parentId: null,
								parentGroupType: null,
								noApprovalStatusChange: null,
							},
						});
					}
				});
		res2.sort((a, b) => a.person.fullName.localeCompare(b.person.fullName));
		personData = [...personData, ...res2];
	}

	personData.forEach(personResult => {
		if (personResult.person) {
			personResult.person.noApprovalStatusChange = getNoApprovalStatusChangeForGroup(personResult.person.timeRegs);
		}
	});

	return personData;
};

export const getProjectData = (datas, parentInfo, includeIdleTimes) => {
	const projectData = [];
	const parentId = parentInfo?.id;
	const parentGroupType = parentInfo?.groupType;

	datas &&
		datas.forEach(data => {
			const timeRegDate = Util.CreateNonUtcMomentDate(data.year, data.month, data.day);
			const dayIdx = timeRegDate.weekday();
			const project = data.task ? data.task.project : data.project;
			const idleTime = data.idleTime;
			if (project) {
				const projectIdx = projectData.findIndex(r => {
					return r.project ? r.project.id === project.id : r.task?.project.id === project.id;
				});
				if (projectIdx > -1) {
					projectData[projectIdx].project.timeRegs.push(data);
					projectData[projectIdx].project.totals[dayIdx] += data.minutesRegistered;
					projectData[projectIdx].project.billableTotals[dayIdx] += data.billableMinutesRegistered || 0;
					projectData[projectIdx].project.timeRegsOnDayIndex[dayIdx].push(data);
					projectData[projectIdx].project.total += data.minutesRegistered;
					projectData[projectIdx].project.billableTotal += data.billableMinutesRegistered;
				} else {
					const timeRegsTotals = [null, null, null, null, null, null, null];
					timeRegsTotals[dayIdx] = data.minutesRegistered;
					const billableTimeRegsTotals = [null, null, null, null, null, null, null];
					billableTimeRegsTotals[dayIdx] = data.billableMinutesRegistered || 0;
					const timeRegsOnDayIndex = [[], [], [], [], [], [], []];
					timeRegsOnDayIndex[dayIdx].push(data);
					const projectOwner = project.projectPersons.edges.map(projectOwner => {
						const person = projectOwner.node.person;
						return {
							fullName: person.fullName,
							imageSource: profilePicSrc(person.profilePictureId),
						};
					});

					projectData.push({
						project: {
							id: project.id,
							companyProjectId: project.companyProjectId,
							customProjectId: project.customProjectId,
							name: project.name,
							projectColor: project.projectColor,
							timeRegs: [data],
							totals: timeRegsTotals,
							billableTotals: billableTimeRegsTotals,
							timeRegsOnDayIndex: timeRegsOnDayIndex,
							total: data.minutesRegistered,
							billableTotal: data.billableMinutesRegistered,
							projectOwner: projectOwner,
							parentId: parentId,
							parentGroupType: parentGroupType,
							noApprovalStatusChange: data.noApprovalStatusChange,
							billable: project.billable,
						},
					});
				}
			}
			if (idleTime && includeIdleTimes) {
				const idleTimeIdx = projectData.findIndex(r => {
					return r.idleTime?.id === idleTime.id;
				});

				if (idleTimeIdx > -1) {
					projectData[idleTimeIdx].idleTime.timeRegs.push(data);
					projectData[idleTimeIdx].idleTime.totals[dayIdx] += data.minutesRegistered;
					projectData[idleTimeIdx].idleTime.billableTotals[dayIdx] = 0;
					projectData[idleTimeIdx].idleTime.total += data.minutesRegistered;
					projectData[idleTimeIdx].idleTime.noApprovalStatusChange =
						projectData[idleTimeIdx].idleTime.noApprovalStatusChange || data.noApprovalStatusChange;
				} else {
					const timeRegsOnDayIndex = [[], [], [], [], [], [], []];
					timeRegsOnDayIndex[dayIdx].push(data);
					const timeRegsTotals = [null, null, null, null, null, null, null];
					const billableTotals = [null, null, null, null, null, null, null];
					timeRegsTotals[dayIdx] = data.minutesRegistered;
					billableTotals[dayIdx] = 0;
					projectData.push({
						idleTime: {
							id: idleTime.id,
							name: idleTime.name,
							isInternalTime: idleTime.isInternalTime,
							timeRegs: [data],
							totals: timeRegsTotals,
							billableTotals: billableTotals,
							timeRegsOnDayIndex: timeRegsOnDayIndex,
							total: data.minutesRegistered,
							billableTotal: 0,
							parentId: parentId,
							parentGroupType: parentGroupType,
							noApprovalStatusChange: data.noApprovalStatusChange,
						},
					});
				}
			}
		});

	projectData.forEach(projectResult => {
		if (projectResult.project) {
			projectResult.project.noApprovalStatusChange = getNoApprovalStatusChangeForGroup(projectResult.project.timeRegs);
		}
	});
	return projectData;
};

export const getTimeData = datas => {
	const res = [];

	datas &&
		datas.forEach(data => {
			const timeRegDate = Util.CreateNonUtcMomentDate(data.year, data.month, data.day);
			const dayIdx = timeRegDate.weekday();
			const taskId = data.task ? data.task.id : 'project_time';
			const taskIdx = res.findIndex(r => {
				return r.task.id === taskId;
			});

			if (taskIdx > -1) {
				res[taskIdx].task.totals[dayIdx] += data.minutesRegistered;
				res[taskIdx].task.billableTotals[dayIdx] += data.billableMinutesRegistered;
				res[taskIdx].task.timeRegsOnDayIndex[dayIdx].push(data);
				res[taskIdx].task.total += data.minutesRegistered;
				res[taskIdx].task.billableTotal += data.billableMinutesRegistered;
				data.notes && res[taskIdx].task.timeRegistrationsWithNotes.push(data);
			} else {
				const timeRegsTotals = [null, null, null, null, null, null, null];
				const timeRegsOnDayIndex = [[], [], [], [], [], [], []];
				const billableTimeRegsTotals = [null, null, null, null, null, null, null];
				timeRegsTotals[dayIdx] = data.minutesRegistered;
				timeRegsOnDayIndex[dayIdx].push(data);
				billableTimeRegsTotals[dayIdx] = data.billableMinutesRegistered;

				if (data.task) {
					//only used for the estimation so on task level
					const storyPointConversion =
						data.task && data.task.project.estimationUnit === ESTIMATION_UNIT.POINTS
							? data.task.project.minutesPerEstimationPoint
							: 1;
					res.push({
						task: {
							id: taskId,
							taskId: data.task.companyTaskId,
							name: data.task.name,
							timeRegistrationsWithNotes: data.notes ? [data] : [],
							totals: timeRegsTotals,
							billableTotals: billableTimeRegsTotals,
							timeRegsOnDayIndex: timeRegsOnDayIndex,
							total: data.minutesRegistered,
							billableTotal: data.billableMinutesRegistered,
							estimated: data.task.estimateForecast * storyPointConversion,
							totalTaskTimeRegs: data.task.totalMinutesRegistered,
						},
					});
				} else {
					//TimeRegs on project. Keep the object as Task, to avoid more if
					res.push({
						task: {
							id: taskId,
							timeRegistrationsWithNotes: data.notes ? [data] : [],
							timeRegsOnDayIndex: timeRegsOnDayIndex,
							totals: timeRegsTotals,
							billableTotals: billableTimeRegsTotals,
							total: data.minutesRegistered,
							billableTotal: data.billableMinutesRegistered,
						},
					});
				}
			}
		});
	return res;
};

export const getEditTimeRegsPropsFromRowData = (data, index) => {
	const timeRegsOnDay = data.timeRegsOnDayIndex ? data.timeRegsOnDayIndex[index] : undefined;
	if (!timeRegsOnDay?.length) {
		return {
			dayRegNodes: undefined,
			dayOfInput: undefined,
		};
	}
	const dayRegNodes = timeRegsOnDay.map(t => {
		return {node: t};
	});

	const firstTimeRegOnDay = timeRegsOnDay?.[0];

	const momentDate = Util.CreateNonUtcMomentDate(firstTimeRegOnDay.year, firstTimeRegOnDay.month, firstTimeRegOnDay.day);

	return {
		dayRegNodes: dayRegNodes,
		dayOfInput: momentDate,
	};
};

export const getNoApprovalStatusChangeMessage = (noApprovalStatusChange, formatMessage) => {
	const noApprovalChangeGenericMessage = formatMessage({id: 'approval.no_approval_status_change'});
	switch (noApprovalStatusChange) {
		case NO_APPROVAL_STATUS_CHANGE_REASON.INVOICED:
			return 'You cannot reject this timesheet because all time registrations are invoiced';
		case NO_APPROVAL_STATUS_CHANGE_REASON.LOCKED_PERIOD:
			return noApprovalChangeGenericMessage + formatMessage({id: 'approval.no_approval_status_change_locked_period'});
		case NO_APPROVAL_STATUS_CHANGE_REASON.COMPANY_LOCK:
			return noApprovalChangeGenericMessage + formatMessage({id: 'approval.no_approval_status_change_company_lock'});
		case NO_APPROVAL_STATUS_CHANGE_REASON.HAS_LINKED_ALLOCATION:
			return (
				noApprovalChangeGenericMessage + formatMessage({id: 'approval.no_approval_status_change_has_linked_allocation'})
			);
		default:
			return null;
	}
};

export const ownedProjectPersonsQuery = graphql`
	query TimeApprovalLogicQuery($personId: ID) {
		viewer {
			id
			actualPersonId
			company {
				id
				person(id: $personId) {
					id
					ownedProjectPersons(first: 1000000) {
						edges {
							node {
								id
								firstName
								lastName
								fullName
								profilePictureId
								department {
									id
								}
								role {
									id
								}
								permissions
							}
						}
					}
				}
			}
		}
	}
`;

/* export const ownedProjectPersonsQuery = graphql`
	fragment TimeApprovalLogic_viewer on Viewer @argumentDefinitions(personId: {type: "ID"}) {
		id
		actualPersonId
		company {
			id
			person(id: $personId) {
				id
				ownedProjectPersons(first: 1000) {
					edges {
						node {
							id
							firstName
							lastName
							profilePictureId
						}
					}
				}
			}
		}
	}
`;
 */
