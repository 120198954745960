import {Table} from 'web-components';
import React from 'react';
import {injectIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {getTotalTimeEntries} from '../../TaskReportUtil';

const TaskTimeRegistrationColumn = props => {
	const {intl, timeRegistrations} = props;

	const totalTime = timeRegistrations ? getTotalTimeEntries(timeRegistrations) : 0;

	return <Table.Column.Cell>{Util.convertMinutesToFullHour(totalTime, intl)}</Table.Column.Cell>;
};

export default injectIntl(TaskTimeRegistrationColumn);
