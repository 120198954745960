import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import UpdateTaskMutation from '../../../../mutations/update_task_mutation.modern';
import Util from '../../../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT} from '../../../../constants';
import Tooltip from '../../../../components/new-ui/tooltip';
import HoursInput from '../../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import {AffixedInputWrapper} from '../../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {ForecastExpansionTile} from './ForecastExpansionTile';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {TaskModalEstimatePlaceholder} from '../../../../forecast-app/shared/components/placeholders/TaskModalEstimatePlaceholder';

class ForecastTile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			tilePosition: {},
			showTooltip: false,
			isMouseDown: false, //used to differentiate from click focus and keyboard focus on the forecast tile. We should only show the tooltip if the user is focusing on the tile using the keyboard
		};

		this.inputRef = React.createRef();
		this.subtaskInputRef = React.createRef();

		this.tileRef = React.createRef();
	}

	onTileFocus(e) {
		if (!this.state.expanded && this.tile) {
			this.setState({showTooltip: true, tilePosition: this.tile.getBoundingClientRect()});
		}
	}

	onTileBlur(e) {
		this.setState({showTooltip: false});

		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		const isTargetEstimateInput = target => {
			return (
				(typeof target.id === 'string' && target.id.includes('task-modal-est')) ||
				(this.subtaskInputRef.current && this.subtaskInputRef.current.contains(target))
			);
		};

		const isTargetHeaderTile = target => {
			return typeof target.className === 'string' && target.className.includes('header-tile-forecast');
		};

		if ((isTargetEstimateInput(e.target) && isTargetHeaderTile(newTarget)) || isTargetEstimateInput(newTarget)) return;
		this.setState({
			expanded: false,
		});
	}

	onKeyPress(e) {
		if (e.key === 'Enter' && document.activeElement && !e.target.className.includes('input-value')) {
			if (this.inputRef.current) {
				this.inputRef.current.focus();
			}
			this.setState({showTooltip: false});
			this.toggleExpansion(e);
		}
	}

	handleTileClick(e) {
		if (this.inputRef.current) {
			this.inputRef.current.focus();
		}
		this.setState({showTooltip: false});
		this.toggleExpansion(e);
	}

	onMouseDownHandler(e) {
		this.setState({isMouseDown: true});
	}

	onMouseUpHandler(e) {
		this.setState({isMouseDown: false});
	}

	toggleExpansion(e) {
		if (
			typeof e.target.className === 'string' &&
			(e.target.className.includes('content-container-forecast') ||
				e.target.className.includes('input-value-container') ||
				e.target.className.includes('header-tile-dropdown-container') ||
				e.target.className.includes('border-mask'))
		)
			return;

		if (this.tileRef.current && this.tileRef.current.contains(e.target)) return;
		if (typeof e.target !== 'string') {
			this.setState({expanded: !this.state.expanded});
			return;
		}
		if (e.target && (e.target.className.includes('content') || e.target.className.includes('input-value-container')))
			return;

		this.setState({expanded: !this.state.expanded});
	}

	onUpdateSuccess(result) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		} else {
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		}
	}

	onEstimateBlur(val, input) {
		const valRounded = Math.round(val); // Round to avoid edge cases caused by JS floating point representation
		let estimateForecast;
		if (input === 'estimateForecast') {
			estimateForecast = isNaN(valRounded) ? null : valRounded;
		}
		const {task} = this.props;

		this.setState({
			estimateForecast,
		});

		//Don't mutate if values did not change
		if (estimateForecast && estimateForecast === this.props.task.estimateForecast) return;
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutation,
			{
				ids: [task.id],
				forecast: estimateForecast,
			},
			this.onUpdateSuccess
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {task, project, disabled, rollupEstimate} = this.props;
		const {estimateForecast} = task;
		const estimateTotal = estimateForecast + (rollupEstimate ?? 0);
		const {expanded} = this.state;
		const isEstimatedInHours = project.estimationUnit === ESTIMATION_UNIT.HOURS;
		const hasRollupEstimate = rollupEstimate != null;
		if (hasPermission(PERMISSION_TYPE.CLIENT_HIDE_ESTIMATES)) {
			return <TaskModalEstimatePlaceholder />;
		} else {
			return (
				<div
					className={'header-tile header-tile-forecast' + (expanded ? ' expanded' : '')}
					onFocus={this.onTileFocus.bind(this)}
					onKeyPress={this.onKeyPress.bind(this)}
					onClick={this.handleTileClick.bind(this)}
					onMouseDown={this.onMouseDownHandler.bind(this)}
					onMouseUp={this.onMouseUpHandler.bind(this)}
					onBlur={this.onTileBlur.bind(this)}
					data-cy={this.props.cy}
					tabIndex="0"
					ref={node => (this.tile = node)}
					data-userpilot={'task-modal-estimate-tile'}
				>
					<div className="tile-wrapper">
						<div className="tile-container">
							<div className="tile-label">{formatMessage({id: 'common.estimate'})}</div>
							{!hasRollupEstimate ? (
								isEstimatedInHours ? (
									<HoursInput
										value={estimateForecast / 60}
										innerRef={this.inputRef}
										customClassName={'hours-input'}
										mutation={value => this.onEstimateBlur(value * 60, 'estimateForecast')}
										onClick={e => e.focus()}
										cy={'estimate-input'}
										disabled={disabled}
										userpilot={'task-modal-estimate-input'}
										taskModalStyle
									></HoursInput>
								) : (
									<AffixedInputWrapper
										innerRef={this.inputRef}
										disabled={disabled}
										taskModalStyle={true}
										value={estimateForecast}
										callback={value => this.onEstimateBlur(value, 'estimateForecast')}
										affix={formatMessage({id: 'common.points.short'})}
									/>
								)
							) : (
								<div className="text-container">
									<div className="number-text" data-cy="forcast-time-number">
										{isEstimatedInHours
											? Util.convertMinutesToFullHour(estimateTotal, this.props.intl, true)
											: estimateTotal}
									</div>
									{isEstimatedInHours ? null : (
										<div className="unit-text points">{formatMessage({id: 'common.points.short'})}</div>
									)}
								</div>
							)}
						</div>
					</div>
					{!this.state.isMouseDown ? (
						<Tooltip
							autoPlace={true}
							grey={true}
							position={this.state.tilePosition}
							shown={this.state.showTooltip}
							infoText={formatMessage({id: 'task_modal.tile_tooltip_text'})}
						/>
					) : null}
					{expanded && hasRollupEstimate ? (
						<>
							<div className="header-tile-dropdown-container">
								<ForecastExpansionTile
									isJiraTask={!!task.jiraId}
									tileRef={this.tileRef}
									subtaskInputRef={this.subtaskInputRef}
									isEstimatedInHours={isEstimatedInHours}
									estimateForecast={estimateForecast}
									disabled={disabled}
									estimateTotal={estimateTotal}
									onEstimateBlur={this.onEstimateBlur.bind(this)}
								/>
							</div>
						</>
					) : null}
				</div>
			);
		}
	}
}

export default injectIntl(ForecastTile);
