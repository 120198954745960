import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {Draggable, DragScrollBar, Droppable, VirtualizedScrollBar} from '@forecasthq/react-virtualized-dnd';
import VisibilitySensor from 'react-visibility-sensor';
import Util from '../../../forecast-app/shared/util/util';
import Person from '../../../forecast-app/shared/components/person/person';
import GenericTaskContextMenu from '../../../forecast-app/shared/components/context-menus/generic_task_context_menu';
import TableRow from './table_row';
import TaskTableHeader from './task_table_header';
import TimeRegTableRow from './time_reg_table_row';
import Role from '../../role';
import {personIsClientUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {profilePicSrc} from '../../../directApi';
import {getTotalActualPrice, getTotalBillableHoursRegistered, getTotalHoursRegistered} from './time_reg_util';

class TaskTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputTitle: '',
			inputRole: null,
			inputApprove: true,
			isEstimateInvalid: false,
			isNameInvalid: false,
			persons: [],
			role: null,
			selectedRows: [],
			collapsedPersonsMap: new Map(),
		};
		this.personRefs = [];
		this.sortPersons = this.sortPersons.bind(this);
		this.sortRoles = this.sortRoles.bind(this);
		this.closeContextMenu = this.closeContextMenu.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		this.updateTaskCellMaxWidth();

		if (this._droppable && this.props.registerScrollBar) {
			this.props.registerScrollBar(this._droppable);
		}
		window.addEventListener('resize', this.onWindowResize);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.taskCellMaxWidth) {
			const width = this.getTaskCellMaxWidth();
			if (width !== this.state.taskCellMaxWidth) {
				this.updateTaskCellMaxWidth();
			}
		}

		// We got our first id, or the new two ids differ
		const differentId = (id1, id2) => (!id1 && id2) || (id1 && id2 && id1 !== id2);
		const prevId = prevProps.createdTask ? prevProps.createdTask.node.id : null;
		const newId = this.props.createdTask ? this.props.createdTask.node.id : null;
		if (differentId(prevId, newId)) {
			if (this._droppable) {
				// Scroll to bottom of list
				if (this.props.groupByPerson || this.props.groupByRole) {
					// TODO, scroll person scroll down, not sprint scroll
					//this.scrollToPerson(this.props.createdTask.node);
					//this._droppable.scrollTop(this._droppable.getScrollHeight());
				} else this._droppable.animateScrollTop(this._droppable.getScrollHeight());
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onWindowResize);
	}

	getTaskCellMaxWidth() {
		if (this.tableContainer) {
			const w = this.tableContainer.clientWidth - (this.props.tablePadding || 26) * 2 - (this.props.tableMargin || 0); // padding inside, margin on the table itself
			// Calculate used space for other cols only
			const ignoreCol = col => col.name === 'task-name' || col.name === 'task-id';
			// Space used by other headers than task name + id
			const usedSpace = this.props.availableColumns.reduce(
				(acc, col) => ((col.checked || col.maintainSpace) && !ignoreCol(col) ? col.maxWidth + acc : acc),
				0
			);
			if (usedSpace > w) {
				// base minwidth for overflow
				return 200;
			}
			return Math.max(200, w - usedSpace);
		}
		return 200;
	}

	updateTaskCellMaxWidth() {
		const taskCellMaxWidth = this.props.taskCellMaxWidth ? this.props.taskCellMaxWidth : this.getTaskCellMaxWidth();
		// const minWidth = this.state.availableColumns.reduce((acc, elem) => acc + (elem.checked ? (elem.name === 'task-name' ? taskCellMaxWidth : elem.minWidth) : 0), 0);
		this.setState({taskCellMaxWidth: taskCellMaxWidth});
	}

	onWindowResize() {
		this.updateTaskCellMaxWidth();
	}

	sortBySortOrder(a, b) {
		if (a.node && b.node) {
			if (a.node.sortOrder < b.node.sortOrder) return -1;
			if (a.node.sortOrder > b.node.sortOrder) return 1;
		} else {
			if (a.sortOrder < b.sortOrder) return -1;
			if (a.sortOrder > b.sortOrder) return 1;
		}

		return 0;
	}

	getIsRowSelected(rowId, person) {
		if (this.props.groupByPerson && !this.props.emptyPhase) {
			return !!this.props.selectedRows.find(row => row.node.id === rowId && row.sourcePerson === person.id);
		}
		return !!this.props.selectedRows.find(row => (row.node ? row.node.id === rowId : row.id === rowId));
	}

	getPersonObjectById(id, tasksPerPersons) {
		if (id === 'UNASSIGNED') {
			return {id: id, firstName: this.props.intl.formatMessage({id: 'scheduling.unassigned_tasks'}), lastName: ''};
		}
		const projectPersons = cloneDeep(this.props.projectPersons);
		let person = projectPersons.find(pp => pp.node.person.id === id);
		if (person) {
			const p = person.node.person;
			//p.role = person.node.person.role;
			p.deactivated = false;
			return p;
		}

		if (tasksPerPersons) {
			const p = tasksPerPersons[0].assignedPersons.find(p => p.id === id);
			p.role = {
				name: p.active
					? this.props.intl.formatMessage({id: 'common.not_assigned_to_project'})
					: this.props.intl.formatMessage({id: 'team.deactivated-group-title'}),
			};
			p.deactivated = true;
			return p;
		}

		return {id: id};
	}

	getRoleObjectById(id) {
		if (id === 'UNASSIGNED') {
			return {id: id, name: this.props.intl.formatMessage({id: 'scheduling.unassigned_tasks'})};
		}
		const roles = this.props.viewer.company.roles.edges;
		let role = roles.find(role => role.node.id === id);
		if (role) {
			return role.node;
		}
		return {id: id};
	}

	getAssignedPersonCount(task) {
		if (this.props.groupByRole) {
			return 1;
		}
		return Math.max(task.assignedPersons.length, 1);
	}

	getColSumForPerson(col, person) {
		const personTasksUnfiltered = this.personTaskMap.get(person.id);
		if (personTasksUnfiltered.length === 0) {
			return '';
		}
		const personTasks = personTasksUnfiltered.filter(task => task.approved === true);

		const {formatNumber} = this.props.intl;
		let field = '';
		let accountForEstimationUnit = false;
		let unit = this.estimationUnit;

		let personTimeRegTotals = getTotalHoursRegistered(personTasks, person, this.props.groupByRole) * 60;
		let personBillableTimeRegTotals = getTotalBillableHoursRegistered(personTasks, person, this.props.groupByRole) * 60;
		let personNonBillableTimeRegTotals = personTimeRegTotals - personBillableTimeRegTotals;

		if (personTasks.length > 0) {
			switch (col.name) {
				case 'labels':
				case 'done-percentage':
					return null;
				case 'forecast':
					field = 'estimateForecast';
					accountForEstimationUnit = true;
					break;
				case 'remaining':
					field = 'timeLeft';
					accountForEstimationUnit = true;
					break;
				case 'price':
					unit = this.props.currencySymbol;
					field = 'estimateForecastPrice';
					break;
				case 'actual-price':
					if (this.props.lazyDataFetched) {
						unit = this.props.currencySymbol;
						const personTotalPriceFormatted = formatNumber(
							getTotalActualPrice(personTasks, person, this.props.groupByRole),
							{
								format: 'always_two_decimal',
							}
						);
						return Util.CurrencyIsPrefixed(unit)
							? unit + personTotalPriceFormatted
							: personTotalPriceFormatted + unit;
					} else {
						return '-';
					}
				case 'non-billable-time-entries':
					if (this.props.lazyDataFetched) {
						return Util.convertMinutesToFullHour(personNonBillableTimeRegTotals, this.props.intl);
					} else {
						return '-';
					}
				case 'billable-time-entries':
					if (this.props.lazyDataFetched) {
						return Util.convertMinutesToFullHour(personBillableTimeRegTotals, this.props.intl);
					} else {
						return '-';
					}
				case 'time-entries':
					if (this.props.lazyDataFetched) {
						return Util.convertMinutesToFullHour(personTimeRegTotals, this.props.intl);
					} else {
						return '-';
					}
				case 'over-forecast':
					if (this.props.lazyDataFetched) {
						const personForecastTotal = personTasks.reduce(
							(acc, t) => acc + t.estimateForecast / this.getAssignedPersonCount(t),
							0
						);
						const personOverForecast = personForecastTotal - personTimeRegTotals;
						if (this.props.isEstimatedInHours) {
							return Util.convertMinutesToFullHour(personOverForecast * 60, this.props.intl);
						} else {
							return formatNumber(personOverForecast, {format: 'rounded_two_decimal'}) + ' ' + unit;
						}
					} else {
						return '-';
					}
				default:
					return 0;
			}
		}

		const personPart =
			personTasks.reduce((acc, t) => acc + t[field] / this.getAssignedPersonCount(t), 0) /
			(accountForEstimationUnit && this.props.isEstimatedInHours ? 60 : 1);

		if (isNaN(personPart)) {
			return '-';
		}
		if (unit === this.props.currencySymbol) {
			const currencySymbol = this.props.currencySymbol;
			const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);
			const personPartWithCurrency = isDollarOrEuro
				? currencySymbol + formatNumber(personPart, {format: 'always_two_decimal'})
				: formatNumber(personPart, {format: 'always_two_decimal'}) + currencySymbol;
			return personPartWithCurrency;
		} else {
			return Util.convertMinutesToFullHour(personPart * 60, this.props.intl);
		}
	}

	getGroupedHeaderNameElement(person, isUnassignedGroup, personTaskAmount, projectPerson) {
		if (this.props.groupByRole) {
			const isRoleDisabled = this.props.viewer.project?.rateCard?.disabledRoles?.find(node => node.id === person.id);

			return (
				<Role
					role={person}
					key={person.id + '-role'}
					isRoleDisabled={isRoleDisabled}
					taskAmount={person.id === 'UNASSIGNED' ? personTaskAmount : null}
				/>
			);
		} else {
			return (
				<Person
					cy={person.id !== 'UNASSIGNED' ? `sprint-person-header` : 'sprint-unassigned-tasks-header'}
					key={person.id + '-person'}
					hideImage={isUnassignedGroup}
					imageSize={isUnassignedGroup ? undefined : 'new-ui-person-dropdown'}
					imageSrc={isUnassignedGroup ? undefined : profilePicSrc(person.profilePictureId)}
					name={(person.firstName || '') + ' ' + (person.lastName || '')}
					role={
						projectPerson && projectPerson.node.role
							? projectPerson.node.role.name
							: person.role
							? person.role.name
							: person.permissions && personIsClientUser(person)
							? this.props.intl.formatMessage({id: 'common.client'})
							: ''
					}
					showRole={true}
					isDeactivated={person.deactivated}
					isActive={!person.deactivated}
					taskAmount={person.id === 'UNASSIGNED' ? personTaskAmount : null}
				/>
			);
		}
	}

	getPersonNumbers(person, personTaskAmount) {
		const {formatMessage, formatNumber} = this.props.intl;
		const shouldHide = this.props.collapsed || this.getIsPersonCollapsed(person, personTaskAmount);
		const sprintDone = this.props.sprint.isDone;
		const isUnassignedGroup = person.id === null || person.id === 'UNASSIGNED';
		const isConnectedChild = !this.props.isConnectedParent && this.props.viewer.project.isInProjectGroup;
		const tasks = this.props.tasks
			? this.props.tasks.sort(this.sortBySortOrder)
			: this.props.phase.tasks.sort(this.sortBySortOrder);
		const personTasks = isUnassignedGroup
			? []
			: this.props.groupByRole
			? tasks.filter(task => task.role && task.role.id === person.id)
			: tasks.filter(task => task.assignedPersons.find(p => p.id === person.id));
		const projectPerson = this.props.projectPersons.find(pp => pp.node.person.id === person.id);
		const sprintPerson = this.props.sprintPersons
			? this.props.sprintPersons.find(sprintPerson => sprintPerson.node.person.id === person.id)
			: null;
		const sprintPersonsWithRole =
			this.props.groupByRole && this.props.sprintPersons
				? this.props.sprintPersons.filter(sprintPerson => {
						const projectPerson = this.props.projectPersons.find(
							pp => pp.node.person.id === sprintPerson.node.person.id
						);
						const projectRole = projectPerson?.node.role || sprintPerson.node.person.role;
						return projectRole && projectRole.id === person.id;
				  })
				: [];
		const availableMinutes =
			this.props.groupByRole && sprintPersonsWithRole
				? sprintPersonsWithRole.reduce((acc, person) => acc + (person.node.availableMinutes || 0), 0)
				: sprintPerson && sprintPerson.node
				? sprintPerson.node.availableMinutes || 0
				: null;
		const scheduledMinutes =
			this.props.groupByRole && sprintPersonsWithRole
				? sprintPersonsWithRole.reduce(
						(acc, person) => acc + (person.node.scheduledMinutes || person.node.taskAvailableMinutes || 0),
						0
				  )
				: sprintPerson && sprintPerson.node
				? sprintPerson.node.scheduledMinutes || sprintPerson.node.taskAvailableMinutes || 0
				: null;

		const isEstimatedInHours = this.props.isEstimatedInHours;
		const minutesPerEstimationPoint = isEstimatedInHours ? 1 : this.props.minutesPerEstimationPoint;

		const personDividerNumber = task => (this.props.groupByRole ? 1 : task.assignedPersons.length);

		let assignedTime =
			personTasks.length !== 0
				? personTasks.reduce((total, task) => {
						const taskAssignedTime = task.timeLeft / personDividerNumber(task);
						return total + taskAssignedTime;
				  }, 0) * minutesPerEstimationPoint
				: 0;

		const assignedForecast =
			personTasks.length > 0
				? personTasks.reduce((total, task) => {
						const taskAssignedTime = task.estimateForecast / personDividerNumber(task);
						return total + taskAssignedTime;
				  }, 0) * minutesPerEstimationPoint
				: 0;

		const leftCapacity = isUnassignedGroup || sprintDone ? 0 : availableMinutes - assignedTime;
		const registered = isUnassignedGroup
			? 0
			: personTasks.reduce((total, task) => {
					let registered = task.timeRegistrations
						? this.props.groupByRole
							? task.timeRegistrations.edges
									.filter(
										reg => reg.node.person && reg.node.person.role && reg.node.person.role.id === person.id
									)
									.reduce((total, reg) => total + reg.node.minutesRegistered, 0)
							: task.timeRegistrations.edges
									.filter(reg => reg.node.person && reg.node.person.id === person.id)
									.reduce((total, reg) => total + reg.node.minutesRegistered, 0)
						: 0;
					return total + registered;
			  }, 0);
		//const delivered = !isUnassignedGroup && sprintDone && scheduledMinutes !== 0 ? (registered / scheduledMinutes) * 100 : null;
		return (
			<div className={'person-header-row'} data-cy="header-row">
				<div key={person.id + '-person'} className={'elem-wrapper person'}>
					{this.getGroupedHeaderNameElement(person, isUnassignedGroup, personTaskAmount, projectPerson)}
				</div>
				<div className="time-info">
					<div className={'person-availability-second-row' + (sprintDone ? ' closed' : '')}>
						{this.props.lazyDataFetched ? (
							<>
								{isUnassignedGroup || !this.props.useManualAllocations || isConnectedChild ? (
									<span></span>
								) : sprintDone ? (
									<div className={'time-info-text'}>
										{scheduledMinutes !== null
											? isEstimatedInHours
												? Util.convertMinutesToFullHour(scheduledMinutes || 0, this.props.intl) +
												  ' ' +
												  formatMessage({id: 'common.total_availability'})
												: formatNumber(scheduledMinutes / minutesPerEstimationPoint, {
														format: 'rounded_two_decimal',
												  }) +
												  'p ' +
												  formatMessage({id: 'common.total_availability'})
											: null}
									</div>
								) : (
									<div className={'time-info-text'}>
										{availableMinutes !== null
											? isEstimatedInHours
												? Util.convertMinutesToFullHour(scheduledMinutes || 0, this.props.intl) +
												  ' ' +
												  formatMessage({id: 'common.total_availability'})
												: formatNumber(scheduledMinutes / minutesPerEstimationPoint, {
														format: 'rounded_two_decimal',
												  }) +
												  'p ' +
												  formatMessage({id: 'common.total_availability'})
											: null}
									</div>
								)}
								{isUnassignedGroup ? (
									<span></span>
								) : sprintDone ? (
									<div className={'time-info-text'}>
										{Util.convertMinutesToFullHour(registered, this.props.intl) +
											' ' +
											formatMessage({id: 'sprint_person.reported'})}
									</div>
								) : (
									<div className={'time-info-text'}>
										{isEstimatedInHours
											? Util.convertMinutesToFullHour(assignedTime, this.props.intl) +
											  ' ' +
											  formatMessage({id: 'common.remaining_work'})
											: formatNumber(Math.abs(assignedTime / minutesPerEstimationPoint), {
													format: 'rounded_two_decimal',
											  }) +
											  'p ' +
											  formatMessage({id: 'common.remaining_work'})}
									</div>
								)}
								{sprintDone ||
								isUnassignedGroup ||
								!this.props.useManualAllocations ||
								isConnectedChild ||
								(!sprintPerson && sprintPersonsWithRole.length === 0) ? (
									<span></span>
								) : (
									<div className="flex-container">
										{leftCapacity < 0 ? (
											<div
												title={formatMessage({id: 'sprint_person.over_assigned_warning'})}
												className="task-warning red"
											></div>
										) : null}
										<div data-cy="unassigned-time-header" className={'time-info-header'}>
											{isEstimatedInHours
												? Util.convertMinutesToFullHour(leftCapacity, this.props.intl) +
												  ' ' +
												  formatMessage({id: 'common.remaining_availability'})
												: formatNumber(leftCapacity / minutesPerEstimationPoint, {
														format: 'rounded_two_decimal',
												  }) +
												  'p ' +
												  formatMessage({id: 'common.remaining_availability'})}
										</div>
									</div>
								)}
							</>
						) : null}
						{isUnassignedGroup ? (
							<span></span>
						) : (
							<div className={'time-info-text'}>
								{isEstimatedInHours
									? Util.convertMinutesToFullHour(assignedForecast, this.props.intl) +
									  ' ' +
									  formatMessage({id: 'common.estimate'})
									: formatNumber(Math.abs(assignedForecast / minutesPerEstimationPoint), {
											format: 'rounded_two_decimal',
									  }) +
									  'p ' +
									  formatMessage({id: 'common.estimate'})}
							</div>
						)}
					</div>
				</div>
				<div className={'expand-arrow' + (shouldHide ? ' expand' : ' collapse')}></div>
			</div>
		);
	}

	getSummationColForPerson(col, person, personTaskAmount) {
		if (col.notAnElem || (!col.checked && !col.maintainSpace)) {
			return null;
		}
		const taskCellMaxWidth = this.props.taskCellMaxWidth || this.state.taskCellMaxWidth;
		const id = this.props.id ? this.props.id : 'NO_PHASE';
		const shouldHide = this.props.collapsed || this.getIsPersonCollapsed(person, personTaskAmount);

		// Only get interesting person columns
		switch (col.name) {
			case 'chip-right':
				return null;
			case 'status':
			case 'date':
			case 'assigned-person':
			case 'assigned-role':
			case 'task-id':
			case 'phase':
			case 'sprint':
			case 'approved':
				return (
					<div
						key={id + '-' + person.id + col.name}
						className={'elem-wrapper blank'}
						style={{maxWidth: col.maxWidth, minWidth: col.minWidth, textAlign: col.align}}
					></div>
				);
			case 'empty':
				return (
					<div
						key={id + '-' + person.id + col.name}
						className={'elem-wrapper'}
						style={{maxWidth: col.maxWidth, minWidth: col.minWidth, textAlign: col.align}}
					>
						<div className={'expand-arrow' + (shouldHide ? ' expand' : ' collapse')}></div>
					</div>
				);
			case 'task-name':
				const isUnassigned = person.id === 'UNASSIGNED';
				const projectPerson = this.props.projectPersons.find(pp => pp.node.person.id === person.id);
				return (
					<div
						key={id + '-' + person.id + '-person'}
						className={'elem-wrapper person'}
						style={{minWidth: taskCellMaxWidth, maxWidth: taskCellMaxWidth}}
					>
						{this.getGroupedHeaderNameElement(person, isUnassigned, personTaskAmount, projectPerson)}
					</div>
				);
			default:
				return (
					<div
						key={id + '-' + person.id + '-' + col.name}
						style={{
							maxWidth: col.maxWidth,
							minWidth: col.minWidth,
						}}
						className={'person-summation-col'}
					>
						{this.getColSumForPerson(col, person)}
					</div>
				);
		}
	}

	sortPersons(a, b) {
		const pA = this.getPersonObjectById(a[0]);
		const pB = this.getPersonObjectById(b[0]);
		const aName = pA.firstName && pA.lastName ? pA.firstName.toLowerCase() + pA.lastName.toLowerCase() : '';
		const bName = pB.firstName && pB.lastName ? pB.firstName.toLowerCase() + pB.lastName.toLowerCase() : '';
		if (aName === bName) {
			// Give consistent sorting for people with the same name
			return pA.id < pB.id ? 1 : -1;
		}
		return aName > bName ? 1 : -1;
	}

	sortRoles(a, b) {
		const rA = this.getRoleObjectById(a[0]);
		const rB = this.getRoleObjectById(b[0]);
		const aName = rA.name ? rA.name.toLowerCase() : '';
		const bName = rB.name ? rB.name.toLowerCase() : '';
		if (aName === bName) {
			return rA.id < rB.id ? 1 : -1;
		}
		return aName > bName ? 1 : -1;
	}

	getIsPersonCollapsed(person, personTaskAmount) {
		if (this.state.collapsedPersonsMap.get(person.id) != null) {
			return this.state.collapsedPersonsMap.get(person.id);
		} else return personTaskAmount === 0;
	}

	togglePersonCollapsed(person, personTaskAmount) {
		const curMap = this.state.collapsedPersonsMap;
		const curVal = this.state.collapsedPersonsMap.get(person.id);
		if (curVal != null) {
			curMap.set(person.id, !curVal);
		} else if (personTaskAmount === 0) {
			curMap.set(person.id, false);
		} else {
			curMap.set(person.id, true);
		}
		this.setState({collapsedPersonsMap: curMap});
	}

	onContextMenu(task, e) {
		const el = e.target;
		const projectStatus = task.project
			? task.project.status
			: this.props.viewer.project
			? this.props.viewer.project.status
			: '';
		if (projectStatus === 'DONE' || projectStatus === 'HALTED' || !this.props.lazyDataFetched) {
			return;
		}
		//clicked is when the context menu is open from the 3 dot-options on table-row
		if (
			el &&
			(el.tagName === 'INPUT' ||
				el.tagName === 'BUTTON' ||
				el.tagName === 'A' ||
				el.className.toLowerCase().includes('clicked'))
		) {
			this.closeContextMenu();
			return;
		}
		e.preventDefault();

		const contextMenuPosition = {};

		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			contextMenuPosition.x = btnBounds.right - btn.clientWidth - 2;
			if (window.innerHeight - btnBounds.bottom < 250) {
				contextMenuPosition.y = btnBounds.bottom - 282;
			} else {
				contextMenuPosition.y = btnBounds.bottom + 2;
			}
		} else {
			contextMenuPosition.x = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				contextMenuPosition.y = e.pageY - 250;
			} else {
				contextMenuPosition.y = e.pageY;
			}
		}
		this.setState({
			showContextMenu: true,
			contextMenuElement: el,
			contextMenuPosition,
			contextMenuTask: task,
		});
	}

	closeContextMenu() {
		if (this.state.showContextMenu) {
			this.setState({
				showContextMenu: false,
				contextMenuElement: null,
				contextMenuPosition: null,
				contextMenuTask: null,
			});
		}
	}

	getContextMenu() {
		const {contextMenuTask, contextMenuPosition} = this.state;
		return (
			<GenericTaskContextMenu
				cy="task-table-task-context-menu"
				task={contextMenuTask}
				viewer={this.props.viewer}
				contextMenuPosition={contextMenuPosition}
				closeContextMenu={this.closeContextMenu.bind(this)}
			/>
		);
	}

	shouldFetchMore = e => {
		if (this.props.fetchMore) {
			if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 50) {
				this.props.fetchMore();
			}
		}
	};

	render() {
		const allTaskIds = this.props.tasks.map(task => task.id);
		const selectedTaskIds = this.props.selectedRows.map(selected => selected.node.id);
		const allTasksSelected = allTaskIds.length > 0 && allTaskIds.every(id => selectedTaskIds.includes(id));

		const tableEntity = this.props.phase;
		this.estimationUnit = this.props.isEstimatedInHours
			? this.props.intl.formatMessage({id: 'common.hours.short'})
			: this.props.intl.formatMessage({id: 'common.points.short'});
		const isDoneOrHalted =
			this.props.viewer.project &&
			!this.props.isConnectedParent &&
			(this.props.viewer.project.status === 'HALTED' || this.props.viewer.project.status === 'DONE');
		const useTimeRegs = this.props.timeRegs && this.props.parentType === 'retainer';
		const tasks = this.props.tasks
			? this.props.tasks.sort(this.sortBySortOrder)
			: this.props.phase.tasks.sort(this.sortBySortOrder);
		const entityId = this.props.entityId
			? this.props.entityId
			: this.props.isConnectedParent
			? tableEntity.projectGroupSprintId != null
				? tableEntity.projectGroupSprintId
				: 'no-id'
			: tableEntity.id
			? tableEntity.id
			: 'no-id';
		const groupName = this.props.dragAndDropGroup ? this.props.dragAndDropGroup : 'no-group-name';
		const taskCellMaxWidth = this.props.taskCellMaxWidth || this.state.taskCellMaxWidth;
		const cols = this.props.availableColumns;
		const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		// REVERT 28/10
		this.personTaskMap = new Map();
		let getPersonTable;
		let getPersonTableHeader;
		let personTaskTables;
		// Round up to the nearest multiple of rowHeight
		const maxContainerHeight = this.props.tableHeight
			? this.props.tableHeight
			: Math.ceil(Math.max(250, windowHeight - 340) / this.props.rowHeight) * this.props.rowHeight;
		const minRowHeight = this.props.collapsed ? 0 : Math.min(maxContainerHeight, tasks.length * this.props.rowHeight);
		let tableMinHeight = minRowHeight + (this.props.collapsed ? 40 : 0);
		const minRowWidth = this.props.forceMinWidth
			? 200 +
			  this.props.availableColumns.reduce(
					(acc, col) => acc + ((col.checked || col.maintainSpace) && col.maxWidth ? col.maxWidth : 0),
					0
			  )
			: 0;
		const headerElem = (
			<div className={'header-wrapper' + (this.props.groupByPerson || this.props.groupByRole ? ' grouped' : '')}>
				{this.props.phaseHeader}
				{this.props.collapsed ? null : (
					<TaskTableHeader
						onSelectAllTasks={() => this.props.onSelectAllTasks && this.props.onSelectAllTasks(allTaskIds)}
						onDeselectAllTasks={() => this.props.onDeselectAllTasks && this.props.onDeselectAllTasks(allTaskIds)}
						allTasksSelected={allTasksSelected}
						parentType={this.props.parentType}
						groupByPerson={this.props.groupByPerson}
						groupByRole={this.props.groupByRole}
						projectLocked={this.props.projectLocked}
						hasFinancialAccess={this.props.hasFinancialAccess}
						harvestEnabled={this.props.isHarvestProject}
						isEmpty={tasks.length === 0}
						availableColumns={cols}
						taskCellMaxWidth={taskCellMaxWidth}
						sortValue={this.props.sortValue}
						onSortValueChange={this.props.onSortValueChange}
						showProjectIndicator={cols.some(col => col.name === 'project-id' && col.checked)}
						availableFeatureFlags={this.props.viewer.availableFeatureFlags}
					/>
				)}
			</div>
		);

		if ((this.props.groupByPerson || this.props.groupByRole) && !this.props.emptyPhase && !useTimeRegs) {
			tableMinHeight = 0;
			/*
       * REVERT 28/10
      this.populatePersonTaskMap(tasks);
      */
			if (this.props.groupByPerson) {
				tasks.forEach(t => {
					const assignedPersons = t.assignedPersons;
					if (assignedPersons.length > 0) {
						assignedPersons
							.filter(
								p =>
									this.props.allowedPersonsForGroupBy === null ||
									this.props.allowedPersonsForGroupBy === undefined ||
									this.props.allowedPersonsForGroupBy.includes(p.id)
							)
							.forEach(p => {
								if (this.personTaskMap.get(p.id)) {
									const arr = this.personTaskMap.get(p.id);
									arr.push(t);
									this.personTaskMap.set(p.id, arr);
								} else {
									this.personTaskMap.set(p.id, [t]);
								}
							});
					} else {
						if (this.personTaskMap.get('UNASSIGNED')) {
							const arr = this.personTaskMap.get('UNASSIGNED');
							arr.push(t);
							this.personTaskMap.set('UNASSIGNED', arr);
						} else {
							this.personTaskMap.set('UNASSIGNED', [t]);
						}
					}
				});
				if (this.props.showEmptyPersons) {
					const projectPersons = this.props.projectPersons;
					const personsToAdd = projectPersons
						.filter(
							p =>
								(this.props.allowedPersonsForGroupBy === null ||
									this.props.allowedPersonsForGroupBy === undefined ||
									this.props.allowedPersonsForGroupBy.includes(p.node.person.id)) &&
								!this.personTaskMap.get(p.node.person.id) &&
								!personIsClientUser(p.node.person)
						)
						.map(person => person.node.person);
					personsToAdd.forEach(person => this.personTaskMap.set(person.id, []));
				}
			} else if (this.props.groupByRole) {
				tasks.forEach(t => {
					const assignedRole = t.role;
					if (assignedRole) {
						if (this.personTaskMap.get(assignedRole.id)) {
							const arr = this.personTaskMap.get(assignedRole.id);
							arr.push(t);
							this.personTaskMap.set(assignedRole.id, arr);
						} else {
							this.personTaskMap.set(assignedRole.id, [t]);
						}
					} else {
						if (this.personTaskMap.get('UNASSIGNED')) {
							const arr = this.personTaskMap.get('UNASSIGNED');
							arr.push(t);
							this.personTaskMap.set('UNASSIGNED', arr);
						} else {
							this.personTaskMap.set('UNASSIGNED', [t]);
						}
					}
					// Also add all roles from people on the sprint
					const rolesToAdd = this.props.projectPersons.map(projectPerson => projectPerson.node.role);
					rolesToAdd.forEach(role => {
						if (role && !this.personTaskMap.get(role.id)) {
							this.personTaskMap.set(role.id, []);
						}
					});
				});
			}

			// REVERT 28/10
			// Always include unassigned
			if (!this.personTaskMap.get('UNASSIGNED')) {
				this.personTaskMap.set('UNASSIGNED', []);
			}

			getPersonTableHeader = (person, tasks, index) => {
				const personTaskAmount = tasks.length;
				return (
					<div
						className={'header-wrapper' + (this.props.groupByPerson || this.props.groupByRole ? ' grouped' : '')}
						tabIndex="0"
						onClick={this.togglePersonCollapsed.bind(this, person, personTaskAmount)}
						data-userpilot={
							index === 1 ? (this.props.groupByPerson ? 'person-header-first' : 'role-header-first') : null
						}
					>
						<div
							className={
								'person-header-row' +
								(person.id === 'UNASSIGNED' ? ' unassigned' : '') +
								(index === 0 ? ' first' : '')
							}
						>
							{this.props.showSprintNumbers ? (
								this.getPersonNumbers(person, personTaskAmount)
							) : (
								<div className={'person-totals-row'}>
									{cols.map((col, index) => this.getSummationColForPerson(col, person, personTaskAmount))}
								</div>
							)}
						</div>
					</div>
				);
			};
			getPersonTable = (person, tasks, index) => {
				const shouldHide = this.props.collapsed || this.getIsPersonCollapsed(person, tasks.length);

				const personMinRowHeight = shouldHide ? 0 : tasks.length * this.props.rowHeight;
				let personTableMinHeight = personMinRowHeight + (shouldHide ? 40 : 124);
				const headerElem = (
					<div
						id={person.id}
						key={person.id}
						className={'person-table-header'}
						ref={div => (this.personRefs[index] = div)}
					>
						{getPersonTableHeader(person, tasks, index)}
						{!shouldHide && (
							<TaskTableHeader
								parentType={this.props.parentType}
								key={entityId + person.id + 'table-header'}
								noPaddingLeft={true}
								withMargin={true}
								groupByPerson={this.props.groupByPerson}
								groupByRole={this.props.groupByRole}
								projectLocked={this.props.projectLocked}
								hasFinancialAccess={this.props.hasFinancialAccess}
								isEmpty={tasks.length === 0}
								availableColumns={cols}
								taskCellMaxWidth={taskCellMaxWidth}
								availableFeatureFlags={this.props.viewer.availableFeatureFlags}
							/>
						)}
					</div>
				);

				return (
					<div
						key={entityId + '#' + person.id + 'person-grouping'}
						data-cy="sprint-task-table"
						className={'person-grouping-wrapper'}
						style={{minHeight: personTableMinHeight}}
					>
						<VisibilitySensor
							scrollCheck={true}
							partialVisibility
							minTopValue={10}
							offset={{top: -15, bottom: -100}}
						>
							{({isVisible}) => {
								return this.props.forceHidden || isVisible === false ? (
									<div style={{minHeight: personTableMinHeight}}>LOADING...</div>
								) : (
									<Droppable
										key={entityId + '#' + person.id}
										placeholderStyle={{
											backgroundColor: '#F3F3F3',
											marginLeft: 10,
											marginRight: 10,
											marginTop: 0,
											marginBottom: 0,
											padding: 0,
											border: '1px dashed grey',
										}}
										hideList={shouldHide}
										listHeader={headerElem}
										listHeaderHeight={shouldHide ? 40 : 76}
										activeHeaderClass={shouldHide ? 'drop-active' : ''}
										containerHeight={4000} // Should only produce scroll in extreme cases
										droppableId={person.id + '#' + entityId}
										elemHeight={40}
										dragAndDropGroup={groupName}
									>
										{tasks.sort(this.sortBySortOrder).map((task, index) => {
											return (
												<Draggable
													disabled={
														this.props.projectLocked ||
														this.props.disabled ||
														task.readOnly.isReadOnly
													}
													// dragActiveClass={'active'}
													key={task.id}
													dragAndDropGroup={groupName}
													draggableId={person.id + '#' + task.id}
													noCancelOnMove={true}
												>
													<TableRow
														dragActive={this.props.dragActive}
														emptyPhase={this.props.emptyPhase}
														showTaskModal={this.props.showTaskModal}
														selectionMode={this.props.selectionMode}
														projectLocked={this.props.projectLocked}
														taskCellMaxWidth={taskCellMaxWidth}
														//roles={this.props.roles}
														groupByPerson={this.props.groupByPerson}
														groupByRole={this.props.groupByRole}
														assignablePersons={this.props.projectPersons}
														assignableRoles={this.props.roles}
														isConnectedParent={this.props.isConnectedParent}
														isEstimatedInHours={this.props.isEstimatedInHours}
														sortedSprints={this.props.sortedSprints}
														withMargin={true}
														groupPersonId={person.id}
														groupPersonName={person.firstName + ' ' + person.lastName}
														numPersonsAssignedToTask={
															this.props.groupByPerson
																? this.getAssignedPersonCount(task)
																: undefined
														}
														index={index}
														availableColumns={cols}
														columnWidths={this.props.columnWidths}
														task={task}
														disabled={task.readOnly.isReadOnly || this.props.projectLocked}
														id={person.id + '#' + task.id}
														selected={this.getIsRowSelected(task.id, person)}
														key={'tr' + index}
														shouldHaveBorderLeft={true}
														onRowSelected={this.props.handleRowSelected}
														rowHeight={this.props.rowHeight ? this.props.rowHeight : 40}
														noBorderRight={true}
														onContextMenu={this.onContextMenu.bind(this)}
														viewer={this.props.viewer}
														roundingDecimals={1}
														language={this.props.language}
														currencySymbol={this.props.currencySymbol}
														parentType={this.props.parentType}
														placeUnitBeforeValue={this.props.placeUnitBeforeValue}
														compactShowYear={this.props.compactShowYear}
														lazyDataFetched={this.props.lazyDataFetched}
														lowHighModelScore={this.props.lowHighModelScore}
													/>
												</Draggable>
											);
										})}
									</Droppable>
								);
							}}
						</VisibilitySensor>
					</div>
				);
			};
			let personArr = Array.from(this.personTaskMap);
			const unassignedIndex = personArr.findIndex(p => p[0] === 'UNASSIGNED');
			const unassignedPerson = personArr[unassignedIndex];
			if (unassignedIndex >= 0) {
				// Remove unassigned person (for sorting)
				personArr.splice(unassignedIndex, 1);
				// Sort
				if (this.props.groupByPerson) {
					personArr.sort(this.sortPersons);
				} else if (this.props.groupByRole) {
					personArr.sort(this.sortRoles);
				}
				// Re-add unassigned at the start
				personArr.unshift(unassignedPerson);
			} else {
				if (this.props.groupByPerson) {
					personArr.sort(this.sortPersons);
				} else if (this.props.groupByRole) {
					personArr.sort(this.sortRoles);
				}
			}
			personTaskTables = personArr.map((el, index) => {
				if (this.props.groupByRole) {
					return getPersonTable(this.getRoleObjectById(el[0]), el[1], index);
				} else {
					return getPersonTable(this.getPersonObjectById(el[0], el[1]), el[1], index);
				}
			});
		}
		const scrollProps = {autoHide: true, hideTracksWhenNotNeeded: true};

		return (
			<div
				className="task-table-v3"
				style={this.props.forceMinWidth ? {minWidth: minRowWidth + (this.props.tablePadding || 26) * 2} : null}
				data-cy={this.props.cy + '-scoping-task-table-v3' + (this.props.isEmpty ? '-no-phase' : '')}
				ref={div => (this.tableContainer = div)}
			>
				{this.state.showContextMenu ? this.getContextMenu() : null}
				<div className="table">
					<div
						className="tbody"
						style={{minHeight: tableMinHeight}}
						data-cy={this.props.cy + '-scoping-task-table-v3-body' + (this.props.isEmpty ? '-no-phase' : '')}
					>
						{(this.props.groupByPerson || this.props.groupByRole) && !this.props.emptyPhase ? (
							<div className={'table-person-grouping'}>
								{this.props.phaseHeader}
								{this.props.collapsed ? null : (
									<DragScrollBar
										autoScrollThreshold={40}
										dragAndDropGroup={groupName}
										minHeight={1}
										maxHeight={maxContainerHeight}
										ref={div => (this._droppable = div)}
									>
										{personTaskTables}
									</DragScrollBar>
								)}
							</div>
						) : this.props.forceHidden ? null : useTimeRegs ? (
							<div className={'task-table-with-header'}>
								{headerElem}
								<VirtualizedScrollBar
									dragAndDropGroup={groupName}
									scrollProps={scrollProps}
									ref={div => (this._droppable = div)}
									containerHeight={this.props.rowHeight * this.props.timeRegs.length + 15}
									staticElemHeight={this.props.rowHeight}
									stickyElems={[]}
								>
									{this.props.collapsed
										? null
										: this.props.timeRegs.map((timeReg, index) => (
												<TimeRegTableRow
													hasConflict={
														this.props.conflictedTimeEntries
															? this.props.conflictedTimeEntries.find(
																	t =>
																		t.node.id === timeReg.node.id &&
																		!timeReg.node.retainerConflictHandled
															  )
															: false
													}
													emptyPhase={this.props.emptyPhase}
													showTaskModal={this.props.showTaskModal}
													taskCellMaxWidth={taskCellMaxWidth}
													assignablePersons={this.props.projectPersons}
													assignableRoles={this.props.roles}
													selectionMode={this.props.selectionMode}
													projectLocked={this.props.projectLocked}
													isConnectedParent={this.props.isConnectedParent}
													isEstimatedInHours={this.props.isEstimatedInHours}
													withMargin={false}
													index={index}
													availableColumns={cols}
													columnWidths={this.props.columnWidths}
													task={null}
													timeReg={timeReg}
													disabled={isDoneOrHalted || this.props.projectLocked}
													id={timeReg.id}
													selected={false}
													key={'tr' + index}
													shouldHaveBorderLeft={true}
													rowHeight={this.props.rowHeight ? this.props.rowHeight : 40}
													noBorderRight={true}
													viewer={this.props.viewer}
													roundingDecimals={1}
													language={this.props.language}
													currencySymbol={this.props.currencySymbol}
													parentType={this.props.parentType}
													placeUnitBeforeValue={this.props.placeUnitBeforeValue}
													harvestEnabled={this.props.isHarvestProject}
													tableEntity={tableEntity}
													showBillableMinutes={this.props.showBillableMinutes}
												/>
										  ))}
								</VirtualizedScrollBar>
							</div>
						) : (
							<Droppable
								dragAndDropGroup={groupName}
								listHeader={headerElem}
								scrollProps={scrollProps}
								onScroll={this.shouldFetchMore.bind(this)}
								ref={div => (this._droppable = div)}
								// Task table header is 30px + 6px margin
								listHeaderHeight={
									this.props.collapsed ? this.props.listHeaderHeight : (this.props.listHeaderHeight || 0) + 36
								}
								hideList={this.props.collapsed}
								activeHeaderClass={
									this.props.collapsed
										? this.props.activeHeaderClass
											? this.props.activeHeaderClass
											: 'drop-active'
										: ''
								}
								droppableId={entityId}
								containerHeight={maxContainerHeight}
								elemHeight={this.props.rowHeight}
								enforceContainerMinHeight={this.props.enforceContainerMinHeight}
							>
								{this.props.collapsed
									? null
									: tasks.map((task, index) => {
											return (
												<Draggable
													disabled={
														this.props.projectLocked ||
														this.props.disabled ||
														task.readOnly.isReadOnly ||
														task.isUnit4Task
													}
													// dragActiveClass={'active'}
													key={task.id + index}
													dragAndDropGroup={groupName}
													draggableId={task.id}
													noCancelOnMove={true}
												>
													<TableRow
														dragActive={this.props.dragActive}
														emptyPhase={this.props.emptyPhase}
														isNewTask={this.props.createdTaskId === task.companyTaskId}
														showTaskModal={this.props.showTaskModal}
														taskCellMaxWidth={taskCellMaxWidth}
														assignablePersons={this.props.projectPersons}
														assignableRoles={this.props.roles}
														groupByRole={this.props.groupByRole}
														selectionMode={this.props.selectionMode}
														isClientUser={this.props.isClientUser}
														projectLocked={this.props.projectLocked}
														isConnectedParent={this.props.isConnectedParent}
														isEstimatedInHours={
															this.props.isEstimatedInHours ||
															task.project.estimationUnit === 'HOURS'
														}
														sortedSprints={this.props.sortedSprints}
														withMargin={false}
														index={index}
														availableColumns={cols}
														columnWidths={this.props.columnWidths}
														task={task}
														entityId={entityId}
														disabled={task.readOnly.isReadOnly || this.props.projectLocked}
														id={
															this.props.groupByPerson
																? task.id + '-' + this.props.personId
																: task.id
														}
														selected={this.getIsRowSelected(task.id)}
														key={'tr' + index}
														shouldHaveBorderLeft={true}
														onRowSelected={this.props.handleRowSelected}
														rowHeight={this.props.rowHeight ? this.props.rowHeight : 40}
														noBorderRight={true}
														onContextMenu={this.onContextMenu.bind(this)}
														contextMenuTaksId={
															this.state.showContextMenu ? this.state.contextMenuTask.id : null
														}
														viewer={this.props.viewer}
														roundingDecimals={1}
														language={this.props.language}
														currencySymbol={this.props.currencySymbol}
														parentType={this.props.parentType}
														placeUnitBeforeValue={this.props.placeUnitBeforeValue}
														disableDragHandle={this.props.disableDragHandle}
														showProjectIndicator={cols.some(
															col => col.name === 'project-id' && col.checked
														)}
														compactShowYear={this.props.compactShowYear}
														favoriteTaskList={this.props.favoriteTaskList}
														lazyDataFetched={this.props.lazyDataFetched}
														lowHighModelScore={this.props.lowHighModelScore}
													/>
												</Draggable>
											);
									  })}
							</Droppable>
						)}
					</div>
				</div>
			</div>
		);
	}
}

TaskTable.propTypes = {
	roles: PropTypes.array.isRequired,
	isEstimatedInHours: PropTypes.bool,
	projectLocked: PropTypes.bool,
	hasFinancialAccess: PropTypes.bool.isRequired,
};

TaskTable.defaultProps = {
	lazyDataFetched: true,
};
export default injectIntl(TaskTable);
