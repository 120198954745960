import React from 'react';
import { Modal, Text } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import DeleteProjectBillToMutation from '../../../mutations/invoicing/DeleteProjectBillToMutation';
import Util from '../../../forecast-app/shared/util/util';
const RemoveProjectBillToModal = ({ projectBillToId, projectId, closeModal }) => {
    const { formatMessage } = useIntl();
    const removeProjectBillTo = () => {
        Util.CommitMutation(DeleteProjectBillToMutation, {
            projectBillToId: projectBillToId,
            projectId: projectId,
        }, closeModal);
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'project_settings.remove_billing_option_button_label' })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, { size: '1' }, formatMessage({ id: 'project_settings.remove_billing_option_modal_desc' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: removeProjectBillTo }, formatMessage({ id: 'common.remove' })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal }, formatMessage({ id: 'common.close' })))));
};
export default RemoveProjectBillToModal;
