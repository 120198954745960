import React from 'react';
import { Alert, Modal, Text } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
const WarningClientChangeExportedInvoiceBillToMismatchModal = ({ closeModal, }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'settings.change_client.warning_invoiced_bill_to.header' })),
        React.createElement(Modal.Content, null,
            React.createElement(Alert, { text: formatMessage({ id: 'settings.change_client.warning_invoiced_bill_to.alert' }), type: Alert.TYPE.ERROR }),
            React.createElement(Text, null,
                React.createElement(FormattedHTMLMessage, { id: 'settings.change_client.warning_invoiced_bill_to.body' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default WarningClientChangeExportedInvoiceBillToMismatchModal;
