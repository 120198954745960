import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedRelativeTime, injectIntl} from 'react-intl';
import Moment from 'moment';
import RichTextField from '../../../new-ui/rich_text_field';
import Person from '../../../../forecast-app/shared/components/person/person';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import ActionsMenu from '../../../../forecast-app/shared/components/action-menu/actions_menu';
import UpdateCommentMutation from '../../../../mutations/update_comment_mutation';
import DeleteCommentMutation from '../../../../mutations/delete_comment_mutation';
import Util from '../../../../forecast-app/shared/util/util';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import {convertToRaw} from 'draft-js';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import {getTimeDifferenceAndUnit} from '../../../../forecast-app/shared/util/TimeDifferenceUtil';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {profilePicSrc} from '../../../../directApi';

class Comment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isInEditMode: false,
		};
	}

	startEditingComment() {
		this.setState({isInEditMode: true});
	}

	stopEditingComment() {
		this.setState({isInEditMode: false});
		this.richTextField.getDecoratedComponentInstance().setText(this.props.comment.node.comment || '');
	}

	saveComment() {
		this.setState({isInEditMode: false});
		Util.CommitMutation(UpdateCommentMutation, {
			id: this.props.comment.node.id,
			comment: JSON.stringify(
				convertToRaw(this.richTextField.getDecoratedComponentInstance().state.editorState.getCurrentContent())
			),
		});
	}

	deleteComment() {
		const {formatMessage} = this.props.intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: () => {
						Util.CommitMutation(DeleteCommentMutation, {
							id: this.props.comment.node.id,
							taskId: this.props.taskId,
						});
					},
				},
			],
		});
	}

	getActionMenuOptions() {
		const {formatMessage} = this.props.intl;

		const actionMenuOptions = [];

		if (!this.state.isInEditMode) {
			actionMenuOptions.push({
				text: formatMessage({id: 'common.edit'}),
				onClick: this.startEditingComment.bind(this),
			});
		}

		actionMenuOptions.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: this.deleteComment.bind(this),
		});

		return actionMenuOptions;
	}

	render() {
		const {isViewerComment, isTaskReadOnly, project, viewer} = this.props;
		const {formatMessage} = this.props.intl;
		const {isInEditMode} = this.state;
		const comment = this.props.comment.node;
		const person = comment.person;

		const commentCreationDate = Moment.utc({
			y: comment.year,
			M: comment.month - 1,
			d: comment.day,
			h: comment.hours,
			m: comment.minutes,
			s: comment.seconds,
		});

		const actionMenuOptions = this.getActionMenuOptions();

		const mentions =
			project.projectPersons &&
			project.projectPersons.edges
				.filter(projectPerson => {
					return projectPerson.node.person.active;
				})
				.map(projectPerson => {
					return {
						personId: projectPerson.node.person.id,
						name: projectPerson.node.person.firstName + ' ' + projectPerson.node.person.lastName,
						avatar: profilePicSrc(projectPerson.node.person.profilePictureId),
					};
				});

		const mentionsLabels = viewer.company.labels.edges.map(label => {
			return {id: label.node.id, name: label.node.name};
		});

		const {value, unit} = getTimeDifferenceAndUnit(commentCreationDate);

		return (
			<div className="comment">
				<div className="profile-picture">
					<Person
						name={person.fullName || `${person.firstName} ${person.lastName}`}
						showName={false}
						showRole={false}
						imageSize="medium"
						imageSrc={profilePicSrc(person.profilePictureId)}
					/>
				</div>
				<div className="comment-section">
					<div className="comment-header">
						<div className="person-name">{person.fullName}</div>
						<div className="time-ago" title={commentCreationDate.format('LLL')}>
							{unit === 'month' || unit === 'year' ? (
								commentCreationDate.format('LL')
							) : (
								<FormattedRelativeTime
									numeric="auto"
									value={unit === 'second' && value > -10 ? 0 : value}
									unit={unit}
								/>
							)}
						</div>
					</div>
					<RichTextField
						mentions={mentions}
						mentionsLabels={mentionsLabels}
						ref={e => (this.richTextField = e)}
						text={comment.comment}
						alwaysShowControls={false}
						readOnly={!isInEditMode}
					/>
					{isInEditMode ? (
						<div className="edit-mode-buttons">
							<Button
								text={formatMessage({id: 'common.cancel'})}
								colorTheme={BUTTON_COLOR.WHITE}
								onClick={this.stopEditingComment.bind(this)}
								buttonStyle={BUTTON_STYLE.FILLED}
							/>
							<Button
								text={formatMessage({id: 'common.save'})}
								colorTheme={BUTTON_COLOR.GREEN}
								onClick={this.saveComment.bind(this)}
								buttonStyle={BUTTON_STYLE.FILLED}
							/>
						</div>
					) : null}
				</div>
				{(isViewerComment || hasPermission(PERMISSION_TYPE.COMMENT_DELETE_ALL)) && !isTaskReadOnly ? (
					<ActionsMenu
						className="time-entry-actions"
						disabled={isTaskReadOnly}
						options={actionMenuOptions}
						whiteInner={true}
					/>
				) : null}
			</div>
		);
	}
}

Comment.propTypes = {
	comment: PropTypes.object.isRequired,
};

export default injectIntl(Comment);
