function shouldCountTimeReg(timeReg, personOrRole, groupByRole) {
	const personOrRoleId = personOrRole?.id || personOrRole;
	if (!personOrRole || personOrRoleId === 'UNASSIGNED') {
		return true;
	}
	if (groupByRole) {
		return timeReg.node.person.role.id === personOrRoleId;
	} else {
		return timeReg.node.person.id === personOrRoleId;
	}
}

export function getTotalFromTimeRegs(timeRegs, personOrRole, getTimeRegField, groupByRole) {
	return timeRegs.reduce((acc, timeReg) => {
		if (shouldCountTimeReg(timeReg, personOrRole, groupByRole)) {
			return acc + getTimeRegField(timeReg);
		}
		return acc;
	}, 0);
}

function getTotalFromTaskTimeRegs(tasks, personOrRole, groupByRole, getTimeRegField) {
	return tasks.reduce((acc, task) => {
		const taskTotal = getTotalFromTimeRegs(task.timeRegistrations.edges, personOrRole, getTimeRegField, groupByRole);

		return acc + taskTotal;
	}, 0);
}

export function getTotalHoursRegistered(tasks, person, groupByRole) {
	const getMinutesRegistered = timeReg => timeReg.node.minutesRegistered;
	const validTasks = tasks.filter(task => task.approved && task.timeRegistrations);
	const totalMinutesRegistered = getTotalFromTaskTimeRegs(validTasks, person, groupByRole, getMinutesRegistered);
	return totalMinutesRegistered / 60;
}

export function getTotalBillableHoursRegistered(tasks, person, groupByRole) {
	const getBillableMinutesRegistered = timeReg => timeReg.node.billableMinutesRegistered;
	const validTasks = tasks.filter(t => t.approved && t.timeRegistrations);
	const totalBillableMinutesRegistered = getTotalFromTaskTimeRegs(
		validTasks,
		person,
		groupByRole,
		getBillableMinutesRegistered
	);
	return totalBillableMinutesRegistered / 60;
}

export function getTotalActualPrice(tasks, person, groupByRole) {
	const getPrice = timeReg => timeReg.node.price;
	const validTasks = tasks.filter(t => t.approved && t.billable && t.timeRegistrations);
	return getTotalFromTaskTimeRegs(validTasks, person, groupByRole, getPrice);
}
