import React, {useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const IntegrationSectionWrapper = styled.div`
	margin-left: 58px;
	display: flex;
	flex-direction: row;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-size: 13px;
`;

const IntegrationIconWrapper = styled.div`
	height: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
`;

const IntegrationTextWrapper = styled.div`
	margin: auto 0;
`;

const integrationSection = ({link, systemName, integrationIcon}) => {
	const intl = useIntl();
	const [showLogo, setShowLogo] = useState(true);
	const logoRef = useRef();

	return (
		<IntegrationSectionWrapper>
			<IntegrationTextWrapper>{intl.formatMessage({id: 'settings.linked_to'})} </IntegrationTextWrapper>

			<IntegrationIconWrapper
				ref={logoRef}
				onMouseEnter={() => setShowLogo(false)}
				onMouseLeave={() => setShowLogo(true)}
			>
				{showLogo && (!logoRef.current || (logoRef.current && logoRef.current.matches(':hover')) === false) ? (
					integrationIcon
				) : (
					<IntegrationTextWrapper>
						<a href={link} target="_blank">
							<FormattedMessage id="settings_clients.open_in" values={{systemName: systemName}} />
						</a>
					</IntegrationTextWrapper>
				)}
			</IntegrationIconWrapper>
		</IntegrationSectionWrapper>
	);
};

export default integrationSection;
