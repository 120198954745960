import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ActionMenu from '../../../forecast-app/shared/components/action-menu/actions_menu';
import {injectIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import UpdateTaskMutationModern from '../../../mutations/update_task_mutation.modern';
import DuplicateTaskMutation from '../../../mutations/duplicate_task_mutation';
import DeleteRepeatingTaskMutation from '../../../mutations/delete_repeating_task_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {duplicateTask} from '../../scheduling/scheduling_mutations';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../components/warning';
import NestedDropdown from '../../../forecast-app/shared/components/dropdowns/nested_dropdown';
import LinkIcon from '../../../images/link_icon';
import GooglePicker from '../../task-modal/google_picker';
import CreateGoogleDriveFileAttachmentMutation from '../../../mutations/create_google_drive_file_attachment';
import PriorityTaskInputIconPath from '../../../images/ico-priority';
import BlockedTaskInputIconPath from '../../../images/ico-blocked';
import BugTaskInputIconPath from '../../../images/ico-bug';
import NonBillableTaskInputIconPath from '../../../images/ico-nonbillable';
import {cloneDeep} from 'lodash';
import MoveTaskToAnotherProjectMutation from '../../../mutations/move_task_to_another_project_mutation';

import {withRouter} from 'react-router-dom';
import {hasPermission, isClientUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

import ProjectDropdownWrapper from '../project_dropdown_wrapper';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import {
	canLoggedInPersonManageProgram,
	hasTopDownProgramBudgetFeature,
} from '../../../forecast-app/shared/util/ProgramFinancialLogic';
import {ACTION} from '../../../forecast-app/shared/components/modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {projectUrl} from '../../../directApi';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class TopSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isClientActionsRestricted: Util.isClientTaskActionsRestricted(this.props.viewer),
		};

		this.disableBillableChange = this.props.task.hasInvoicedTime && this.props.task.billable;
	}

	toggleBlockStatus() {
		const onSuccess = result => {
			createToast({
				duration: 5000,
				message: result.updateTask.tasks[0].blocked
					? this.props.intl.formatMessage({id: 'task_modal.has-been-blocked'})
					: this.props.intl.formatMessage({id: 'task_modal.has-been-unblocked'}),
			});
		};

		Util.CommitMutation(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				blocked: !this.props.task.blocked,
			},
			onSuccess
		);
	}

	toggleBugStatus() {
		const onSuccess = result => {
			createToast({
				duration: 5000,
				message: result.updateTask.tasks[0].bug
					? this.props.intl.formatMessage({id: 'task_modal.has-been-marked-as-bug'})
					: this.props.intl.formatMessage({id: 'task_modal.has-been-unmarked-as-bug'}),
			});
		};

		Util.CommitMutation(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				bug: !this.props.task.bug,
			},
			onSuccess
		);
	}

	duplicateTask() {
		const {task, project} = this.props;
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'task_modal.has-been-duplicated'}),
			});
		};

		if (Util.isScheduling()) {
			duplicateTask(
				{
					taskId: task.id,
				},
				result => {
					onSuccess();
					Util.dispatchScheduleEvent(result);
				}
			);
		} else {
			Util.CommitSchedulingModalUpdate(
				DuplicateTaskMutation,
				{
					taskId: task.id,
					supportTaskHierarchy: true,
					companyId: this.props.viewer.company.id,
					projectId: project.id,
					viewerId: this.props.viewer.id,
					filter: this.props.connectedProject
						? task.statusColumnV2.projectGroupStatusColumnId
						: task.statusColumnV2.id,
					viewer: this.props.viewer,
				},
				onSuccess
			);
		}
	}

	toggleBillableStatus() {
		if (this.disableBillableChange) return;
		const newBillableStatus = !this.props.task.billable;
		const project = this.props.project;
		if (hasTopDownProgramBudgetFeature() && newBillableStatus && project.isProgramRevenueLocked) {
			trackEvent('Task', 'Set As Billable', {location: 'From Top Section', error: 'Program Revenue Locked'});
			this.showProgramRevenueLockedMessage();
			return;
		}
		const onSuccess = result => {
			if (result.updateTask.errors && result.updateTask.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(result.updateTask.errors);
			} else {
				createToast({
					duration: 5000,
					message: result.updateTask.tasks[0].billable
						? this.props.intl.formatMessage({id: 'task_modal.has-been-marked-billable'})
						: this.props.intl.formatMessage({id: 'task_modal.has-been-unmarked-billable'}),
				});
			}
		};

		Util.CommitMutation(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				billable: newBillableStatus,
			},
			onSuccess
		);
	}

	showProgramRevenueLockedMessage() {
		const project = this.props.project;
		const program = project.program;
		const loggedInPersonId = this.props.viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.MARK_TASK_BILLABLE,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});
	}

	toggleTaskApproval() {
		const onSuccess = result => {
			createToast({
				duration: 5000,
				message: result.updateTask.tasks[0].approved
					? this.props.intl.formatMessage({id: 'common.task_approved'})
					: this.props.intl.formatMessage({id: 'common.task_unapproved'}),
			});
		};

		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				approved: !this.props.task.approved,
			},
			onSuccess
		);
	}

	showProjectSelectionModal() {
		showModal({
			type: MODAL_TYPE.NEW_TASK,
			currentProjectId: this.props.project.id,
			taskId: this.props.task.id,
		});
	}

	setPriority(highPriority) {
		Util.CommitSchedulingModalUpdate(UpdateTaskMutationModern, {
			ids: [this.props.task.id],
			highPriority,
		});
	}

	showRecurringTaskModal() {
		showModal({
			type: MODAL_TYPE.TASK_RECURRENCE,
			repeatingTask: this.props.task.repeatingTask,
			taskId: this.props.task.id,
		});
	}

	stopRecurringTask() {
		if (this.props.task.repeatingTask) {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'task_modal.has-been-set-to-non-repeating'}),
				});
			};

			Util.CommitMutation(
				DeleteRepeatingTaskMutation,
				{
					id: this.props.task.repeatingTask.id,
					taskId: this.props.task.id,
				},
				onSuccess
			);
		}
	}

	uploadFile() {
		let file = this._file.files.item(0);

		const upload = [];

		upload.push({
			taskId: this.props.task.id,
			mimeType: file.type,
			name: file.name,
			file: file,
		});

		let coverFile = null;
		if (!this.props.task.coverFile) {
			coverFile = upload.find(f => {
				const ext = f.name.split('.').pop();
				return ext === 'png' || ext === 'jpg' || ext === 'gif';
			});
		}

		const onSuccess = result => {
			if (!this.props.task.coverFile && coverFile && result) {
				Util.CommitMutation(UpdateTaskMutationModern, {
					ids: [this.props.task.id],
					coverFileId: result.createFile.file.node.id,
				});
			}
		};
		Util.uploadFiles(upload, onSuccess, coverFile);
	}

	uploadFilesGoogleDrive(data) {
		if (data && data.docs) {
			data.docs.forEach(doc => {
				const input = {
					taskId: this.props.task.id,
					personId: this.props.viewer.actualPersonId,
					googleDriveId: doc.id,
					name: doc.name,
					link: doc.url,
					thumb: doc.iconUrl.replace('/16/', '/64/'),
				};
				Util.CommitMutation(CreateGoogleDriveFileAttachmentMutation, input);
			});
		}
	}

	getActionMenuOptions() {
		const {formatMessage} = this.props.intl;
		const {project, task, viewer} = this.props;
		const {isClientActionsRestricted} = this.state;
		const actionMenuOptions = [];

		const hideTimeRegistrations =
			ProjectUtil.projectUsesManualProgress(project) || hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);

		const taskActionsOptions = [];

		taskActionsOptions.push({
			label: formatMessage({id: 'task_modal.attach_computer_file'}),
			onClick: () => this._file.click(),
		});

		if (viewer.company.gdriveEnabled) {
			taskActionsOptions.push({
				label: (
					<GooglePicker
						onChange={this.uploadFilesGoogleDrive.bind(this)}
						multiselect={true}
						ref={node => (this.googlePicker = node)}
					>
						{this.props.intl.formatMessage({id: 'task_modal.attach_gdrive_file'})}
					</GooglePicker>
				),
				onClick: () => {
					if (this.googlePicker) this.googlePicker.onChoose();
				},
			});
		}

		//SHOW/ HIDE DEPENDENCIES

		if (
			!isClientActionsRestricted &&
			!task.dependsOnThisTask.edges.length &&
			!task.thisTaskDependsOn.edges.length &&
			!project.isJiraProject
		) {
			taskActionsOptions.push({
				label: formatMessage({
					id: this.props.dependenciesSectionAddedByUser
						? 'task_modal.remove_dependencies_section'
						: 'task_modal.add_dependencies_section',
				}),
				onClick: this.props.addDependenciesSection,
				cy: this.props.dependenciesSectionAddedByUser ? 'remove-dependencies-section' : 'add-dependencies-section',
			});
		}

		// SHOW/HIDE TO DOS
		if (!isClientActionsRestricted) {
			if (!task.subTasks.edges.length && !this.props.taskHierachyFlag) {
				taskActionsOptions.push({
					label: formatMessage({
						id: this.props.subtaskSectionAddedByUser
							? project.taskLevels === 2
								? 'task_modal.remove_subtasks_section'
								: 'task_modal.remove_todos_section'
							: project.taskLevels === 2
							? 'task_modal.add_subtasks_section'
							: 'task_modal.add_todos_section',
					}),
					onClick: this.props.addSubtaskSection,
					cy: this.props.subtaskSectionAddedByUser ? 'remove-subtasks-section' : 'add-subtasks-section',
				});
			} else if (this.props.taskHierachyFlag) {
				taskActionsOptions.push({
					label: formatMessage({
						id: this.props.subtaskSectionAddedByUser
							? 'task_modal.remove_todos_section'
							: 'task_modal.add_todos_section',
					}),
					onClick: this.props.addSubtaskSection,
					cy: this.props.subtaskSectionAddedByUser ? 'remove-subtasks-section' : 'add-subtasks-section',
				});
			}
		}

		// Do not show timer for clients and for users that doesn't use time registration
		if (!isClientActionsRestricted && !viewer.client && !hideTimeRegistrations) {
			taskActionsOptions.push({
				label: formatMessage({id: this.props.showTimer ? 'timer.hide' : 'timer.show'}),
				onClick: this.props.toggleTimer.bind(this),
			});
		}

		// REPEAT OPTION
		if (!isClientActionsRestricted && !project.isJiraProject) {
			if (task.repeatingTask) {
				taskActionsOptions.push({
					label: formatMessage({id: 'card_modal.stop_recurring'}),
					onClick: this.stopRecurringTask.bind(this),
				});
			} else {
				taskActionsOptions.push({
					label: formatMessage({id: 'card_modal.start_recurring'}),
					onClick: this.showRecurringTaskModal.bind(this),
				});
			}
		}

		//Do not allow changing project of the task if task is in a project that is connected to harvest/jira/locked in retainer period/has invoiced time
		if (
			!project.harvestProject &&
			!project.isJiraProject &&
			!isClientUser() &&
			task.userCanDeleteTask &&
			!this.props.hasParent &&
			!this.props.hasChildren
		) {
			taskActionsOptions.push({
				label: formatMessage({id: 'task_location_modal.title'}),
				onClick: this.showProjectSelectionModal.bind(this),
				cy: 'change-task-project',
			});
		}

		if (hasPermission(PERMISSION_TYPE.PHASE_UPDATE) && !isClientActionsRestricted) {
			taskActionsOptions.push({
				label: this.props.task.approved
					? formatMessage({id: 'common.unapprove_task'})
					: formatMessage({id: 'common.approve_task'}),
				onClick: this.toggleTaskApproval.bind(this),
				cy: 'change-task-approval',
			});
		}

		const taskStatusOptions = [];

		taskStatusOptions.push({
			label: formatMessage({id: task.highPriority ? 'task-modal.unmark-priority' : 'task-modal.mark-priority'}),
			onClick: this.setPriority.bind(this, !task.highPriority),
			icon: <PriorityTaskInputIconPath active={task.highPriority} />,
		});
		//Everyone sees block/bug/duplicate options
		taskStatusOptions.push({
			label: formatMessage({id: task.blocked ? 'card_modal.unblock-card' : 'card_modal.block-card'}),
			onClick: this.toggleBlockStatus.bind(this),
			icon: <BlockedTaskInputIconPath active={task.blocked} />,
		});
		if (!project.isJiraProject) {
			taskStatusOptions.push({
				label: formatMessage({id: task.bug ? 'card_modal.unmark-as-bug' : 'card_modal.mark-as-bug'}),
				onClick: this.toggleBugStatus.bind(this),
				icon: <BugTaskInputIconPath active={task.bug} />,
			});
		}

		//Only show billable option if project is billable
		if (ProjectUtil.projectTracksRevenue(project) && hasPermission(PERMISSION_TYPE.PHASE_UPDATE)) {
			taskStatusOptions.push({
				label: formatMessage({id: task.billable ? 'card_modal.unmark-billable' : 'card_modal.mark-billable'}),
				onClick: this.toggleBillableStatus.bind(this),
				icon: <NonBillableTaskInputIconPath active={!task.billable} />,
				disabled: this.disableBillableChange,
			});
		}

		const taskMeetingsOptions = [];

		const startingDate = Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay);
		const meetingOptions = {
			title: `T${task.companyTaskId} - ${task.name} - meeting`,
			attendees: task.assignedPersons.filter(p => p.email).map(per => per.email),
			startTime:
				startingDate && Moment().isBefore(startingDate)
					? Moment(startingDate.toDate()).set('h', 8).startOf('h')
					: Moment().startOf('h').add(1, 'h'),
			endTime:
				startingDate && Moment().isBefore(startingDate)
					? Moment(startingDate.toDate()).set('h', 9).startOf('h')
					: Moment().startOf('h').add(2, 'h'),
			organizerEmail: viewer.email,
			organizerName: ((viewer.firstName ? viewer.firstName : '') + ' ' + (viewer.lastName ? viewer.lastName : '')).trim(),
			description: task.description ? Util.ConvertDraftJsToPlainText(task.description, '%0A') : '',
		};

		if (!isClientActionsRestricted) {
			taskMeetingsOptions.push({
				label: formatMessage({id: 'meeting_creator.create_google_meeting'}),
				onClick: () => Util.googleCalendarExport(meetingOptions),
			});

			taskMeetingsOptions.push({
				label: formatMessage({id: 'meeting_creator.create_client_meeting'}),
				onClick: () => Util.iCalExport(meetingOptions),
			});

			actionMenuOptions.push(
				{
					value: 'task_actions',
					label: formatMessage({id: 'task_modal.task_actions'}),
					nestedOptions: taskActionsOptions,
				},
				{
					value: 'task_indicators',
					label: formatMessage({id: 'task_modal.task_indicators'}),
					nestedOptions: taskStatusOptions,
				},
				{value: 'meetings', label: formatMessage({id: 'common.meetings'}), nestedOptions: taskMeetingsOptions}
			);
		} else {
			actionMenuOptions.push({
				value: 'task_actions',
				label: formatMessage({id: 'task_modal.task_actions'}),
				nestedOptions: taskActionsOptions,
			});
		}

		if (!isClientActionsRestricted && !task.jiraId && !task.vstsId) {
			actionMenuOptions.push({
				value: null,
				clearOption: true,
				label: formatMessage({id: 'common.duplicate'}),
				onClick: this.duplicateTask.bind(this),
				nestedOptions: [],
			});
		}

		if (!isClientActionsRestricted && task.userCanDeleteTask) {
			actionMenuOptions.push({
				value: null,
				clearOption: true,
				label: formatMessage({id: 'common.delete'}),
				onClick: this.props.showDeletePrompt.bind(this, task),
				cy: 'delete-task',
				nestedOptions: [],
			});
		}

		return actionMenuOptions;
	}

	selectPhase(selected) {
		const phaseId = selected ? selected.value : null;
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				phaseId: phaseId,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	}

	selectSprint(selected) {
		const onSuccess = response => {
			if (
				response &&
				response.updateTask &&
				response.updateTask.errors &&
				response.updateTask.errors.includes('jira_sprint_change_failed')
			) {
				// Jira rejected the sprint change. Show message to user.
				Util.showJiraSprintChangeRejected(this.props.intl);
			}
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		};

		const sprintId = selected ? selected.value : null;
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				sprintId: sprintId,
			},
			onSuccess
		);
	}

	changeProjectPrompt(selected) {
		const {formatMessage} = this.props.intl;
		const callbackPositive = params => {
			this.selectProject(selected);
		};

		if (!selected || selected.id === this.props.task.project.id) return;

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning
						message={formatMessage({id: 'move_task_to_project_modal.description'}, {project: selected.label})}
					/>
				</div>
			),
			className: 'default-warning-modal',
			cy: 'task-modal-move-task-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.move'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
				},
			],
		});
	}

	selectProject(selected) {
		const projectId = selected ? selected.id : null;
		if (selected.isHalted) {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'task_modal.cannot_select_halted_project'},
					{project: selected.companyProjectId}
				),
			});
			return;
		}
		if (selected.isDone) {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'task_modal.cannot_select_done_project'},
					{project: selected.companyProjectId}
				),
			});
			return;
		}
		if (selected.isProjectGroup) {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'task_modal.cannot_select_connected_project'},
					{project: selected.companyProjectId}
				),
			});
			return;
		}

		if (projectId) {
			const onSuccess = result => {
				if (result.updateTask?.errors?.length > 0) {
					this.props.closeModal();
					showModal({
						type: MODAL_TYPE.GENERIC,
						className: 'default-warning-modal',
						content: <Warning message={result.updateTask.errors[0]} />,
						buttons: [
							{
								text: this.props.intl.formatMessage({id: 'common.cancel'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage(
							{id: 'task_modal.task_project_updated'},
							{project: selected.companyProjectId}
						),
					});
					dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
				}
			};
			const affectedStatusColumn = this.props.task.statusColumnV2;
			const filters = [affectedStatusColumn.id];
			if (affectedStatusColumn.projectGroupStatusColumnId) {
				filters.push(affectedStatusColumn.projectGroupStatusColumnId);
			}
			Util.CommitSchedulingModalUpdate(
				MoveTaskToAnotherProjectMutation,
				{
					ids: [this.props.task.id],
					projectId: projectId,
					previousProjectId: this.props.task.project.id,
					viewer: this.props.viewer,
					filters: filters,
					sameProjectGroup:
						selected.projectGroupId && selected.projectGroupId === this.props.task.project.projectGroupId,
				},
				onSuccess
			);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {isClientActionsRestricted} = this.state;
		const {project, task, isTaskReadOnly} = this.props;
		const {phase, sprint} = task;

		// Task modal has been throwing errors in production , due to missing data on the task. We're looking into it.
		if (task.statusColumnV2 == null) {
			return <div className={'error-statuscol2-empty'} />;
		}

		const activePhases = [],
			futurePhases = [],
			pastPhases = [],
			today = Moment().startOf('day');
		if (project.phases)
			cloneDeep(project.phases.edges)
				.sort((a, b) => {
					const aValidDate = a.node.deadlineYear && a.node.deadlineMonth && a.node.deadlineDay;
					const bValidDate = b.node.deadlineYear && b.node.deadlineMonth && b.node.deadlineDay;
					if (!aValidDate && !bValidDate) return 0;
					if (!aValidDate) return 1;
					if (!bValidDate) return -1;
					const aDate = Util.CreateNonUtcMomentDate(a.node.deadlineYear, a.node.deadlineMonth, a.node.deadlineDay);
					const bDate = Util.CreateNonUtcMomentDate(b.node.deadlineYear, b.node.deadlineMonth, b.node.deadlineDay);
					if (aDate.isAfter(bDate)) return 1;
					if (bDate.isAfter(aDate)) return -1;
					return 0;
				})
				.forEach(phase => {
					const phaseOptionObject = {value: phase.node.id, label: phase.node.name};
					if (!phase.node.startYear || !phase.node.deadlineYear) {
						activePhases.push(phaseOptionObject);
					} else {
						const phaseStartDate = Util.CreateNonUtcMomentDate(
							phase.node.startYear,
							phase.node.startMonth,
							phase.node.startDay
						);
						const phaseEndDate = Util.CreateNonUtcMomentDate(
							phase.node.deadlineYear,
							phase.node.deadlineMonth,
							phase.node.deadlineDay
						);
						if (phaseStartDate.isBefore(today) && phaseEndDate.isBefore(today)) {
							pastPhases.push(phaseOptionObject);
						} else if (!phaseStartDate.isAfter(today) && !phaseEndDate.isBefore(today)) {
							activePhases.push(phaseOptionObject);
						} else {
							futurePhases.push(phaseOptionObject);
						}
					}
				});

		const phaseOptions = [
			{value: null, label: formatMessage({id: 'card_modal.no-scope'}), clearOption: true, nestedOptions: []},
			{value: 'active_phases', label: formatMessage({id: 'common.active_phases'}), nestedOptions: activePhases},
			{value: 'future_phases', label: formatMessage({id: 'common.future_phases'}), nestedOptions: futurePhases},
			{
				value: 'past_phases',
				label: formatMessage({id: 'common.past_phases'}),
				nestedOptions: pastPhases,
				collapseByDefault: true,
			},
		];
		const activeSprints = [],
			futureSprints = [],
			pastSprints = [];
		cloneDeep(project.sprints ? project.sprints.edges : [])
			.sort((a, b) => {
				const aDate = Util.CreateNonUtcMomentDate(a.node.endYear, a.node.endMonth, a.node.endDay);
				const bDate = Util.CreateNonUtcMomentDate(b.node.endYear, b.node.endMonth, b.node.endDay);
				if (aDate.isAfter(bDate)) return 1;
				if (bDate.isAfter(aDate)) return -1;
				return 0;
			})
			.forEach(sprint => {
				const sprintOptionObject = {value: sprint.node.id, label: sprint.node.name};
				if (!sprint.node.startYear || !sprint.node.endYear) {
					activeSprints.push(sprintOptionObject);
				} else {
					const sprintStartDate = Util.CreateNonUtcMomentDate(
						sprint.node.startYear,
						sprint.node.startMonth,
						sprint.node.startDay
					);
					const sprintEndDate = Util.CreateNonUtcMomentDate(
						sprint.node.endYear,
						sprint.node.endMonth,
						sprint.node.endDay
					);
					if (sprintStartDate.isBefore(today) && sprintEndDate.isBefore(today)) {
						pastSprints.push(sprintOptionObject);
					} else if (!sprintStartDate.isAfter(today) && !sprintEndDate.isBefore(today)) {
						activeSprints.push(sprintOptionObject);
					} else {
						futureSprints.push(sprintOptionObject);
					}
				}
			});
		const sprintOptions = [
			{value: null, label: formatMessage({id: 'project_sprints.backlog'}), clearOption: true, nestedOptions: []},
			{
				value: 'active_sprints',
				label: formatMessage({id: 'common.active_sprints'}),
				nestedOptions: activeSprints,
			},
			{
				value: 'future_sprints',
				label: formatMessage({id: 'common.future_sprints'}),
				nestedOptions: futureSprints,
			},
			{
				value: 'past_sprints',
				label: formatMessage({id: 'common.past_sprints'}),
				nestedOptions: pastSprints,
				collapseByDefault: true,
			},
		];

		const loaderComponent = (
			<div className="project-info-container" data-userpilot={'project-info'}>
				<div className="client-name-text" title={project.client ? project.client.name : null}>
					{project.client ? project.client.name : formatMessage({id: 'common.project'})}
				</div>
				<div className="project-text">
					<div className={'id-label-wrapper'}>
						<DeprecatedProjectIndicatorJS project={project} />
					</div>
					<div className="project-name-text">
						{project.name && project.name.trim() ? project.name : formatMessage({id: 'common.untitled'})}
					</div>
				</div>
			</div>
		);

		return (
			<div className="task-modal-v3-top-section" data-userpilot={'task-modal-header'}>
				{project && !isTaskReadOnly && !isClientActionsRestricted ? (
					<a
						href={projectUrl(project.companyProjectId, project.customProjectId)}
						title={formatMessage(
							{id: 'task_modal.go_to_project'},
							{
								project: `${getProjectIndicatorString(project.companyProjectId, project.customProjectId)} ${
									project.name
								}`,
							}
						)}
						className="project-link"
						data-userpilot={'project-link'}
					>
						<LinkIcon />
					</a>
				) : null}
				<div className="info-container">
					{task.jiraKey ||
					task.adoState ||
					isTaskReadOnly ||
					isClientUser() ||
					!task.userCanDeleteTask ||
					task.isUnit4Task ||
					this.props.hasParent ||
					this.props.hasChildren ||
					project.harvestProject ? (
						loaderComponent
					) : (
						<ProjectDropdownWrapper
							project={project}
							onChange={this.changeProjectPrompt.bind(this)}
							loaderComponent={loaderComponent}
						/>
					)}
					{isTaskReadOnly || isClientUser() || task.isUnit4Task || this.props.hasParent ? (
						<div className="phase-info-container">
							<div className="phase-header">{formatMessage({id: 'common.scope-group'})}</div>{' '}
							<div className="phase-text">{phase ? phase.name : formatMessage({id: 'card_modal.no-scope'})}</div>
						</div>
					) : (
						<NestedDropdown
							placeholder={formatMessage({id: 'task_modal.move_to_new_phases'})}
							label={formatMessage({id: 'common.phase'})}
							options={phaseOptions}
							value={phase ? phase.name : formatMessage({id: 'card_modal.no-scope'})}
							onChange={this.selectPhase.bind(this)}
							customClass="phase-dropdown"
							showArrowWhenCollapsed={true}
							userpilot={'milestone-dropdown'}
							nativeScrollbar={hasFeatureFlag('native_scrollbar_replacement')}
						/>
					)}
					{project.sprintTimeBox ? (
						isTaskReadOnly || isClientUser() ? (
							<div className="sprint-info-container">
								<div className="sprint-header">{formatMessage({id: 'common.sprint'})}</div>
								<div className="sprint-text">
									{sprint ? sprint.name : formatMessage({id: 'project_sprints.backlog'})}
								</div>
							</div>
						) : (
							<NestedDropdown
								placeholder={formatMessage({id: 'task_modal.move_to_new_sprint'})}
								label={formatMessage({id: 'common.sprint'})}
								options={sprintOptions}
								value={sprint ? sprint.name : formatMessage({id: 'project_sprints.backlog'})}
								onChange={this.selectSprint.bind(this)}
								customClass="sprint-dropdown"
								showArrowWhenCollapsed={true}
								userpilot={'sprint-dropdown'}
								nativeScrollbar={hasFeatureFlag('native_scrollbar_replacement')}
							/>
						)
					) : null}
					{isTaskReadOnly || isClientUser() ? (
						<div className="status-info-container">
							<div className="status-header">
								{formatMessage({id: 'insights.component.title.workflowStatus'})}
							</div>
							<div className="status-text">
								{task
									? task.statusColumnV2.name
									: formatMessage({id: 'insights.component.title.workflowStatus'})}
							</div>
						</div>
					) : (
						<NestedDropdown
							placeholder={formatMessage({id: 'task_modal.move_to_new_workflow_column'})}
							label={formatMessage({id: 'insights.component.title.workflowStatus'})}
							options={this.props.statusOptions}
							value={
								task ? task.statusColumnV2.name : formatMessage({id: 'insights.component.title.workflowStatus'})
							}
							onChange={this.props.onStatusColumnChange.bind(this, this.props.task)}
							customClass="status-dropdown"
							showArrowWhenCollapsed={true}
							cy="status"
							userpilot={'status-dropdown'}
							nativeScrollbar={hasFeatureFlag('native_scrollbar_replacement')}
						/>
					)}
				</div>

				{!isTaskReadOnly ? (
					<div className="action-menu-container">
						<input
							type="file"
							onClick={e => {
								e.target.value = null;
							}}
							onChange={this.uploadFile.bind(this)}
							ref={input => (this._file = input)}
							className="hidden-file-input"
						/>
						<GooglePicker
							className="hidden-file-input"
							onChange={this.uploadFilesGoogleDrive.bind(this)}
							multiselect={true}
						/>
						<ActionMenu
							disabled={isTaskReadOnly}
							options={this.getActionMenuOptions()}
							whiteInner={true}
							nestedDropdown={true}
							cy="task-modal"
							userpilot={'task-modal-actions-menu'}
						/>
					</div>
				) : null}

				{!this.props.taskHierachyFlag && (
					<div data-cy="close-modal-button" className="close-button" onClick={this.props.closeModal} />
				)}
			</div>
		);
	}
}

TopSection.propTypes = {
	closeModal: PropTypes.func.isRequired,
	viewer: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
};

export default injectIntl(withRouter(TopSection));
