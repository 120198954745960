import React, { useEffect, useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonDropdown, DropdownList, FlexColumn, FlexRow, Icon, Label, Modal, SearchField, SubHeading, Table, Text, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import CreateBulkBillToMutation from '../../../mutations/invoicing/CreateBulkBillToMutation';
import CreateBulkClientBillToMutation from '../../../mutations/invoicing/CreateBulkClientBillToMutation';
import { fetchEconomicContacts, fetchQuickbooksContacts, fetchXeroContacts, filterContactsByIntegrationInstanceId, } from '../InvoiceIntegrationsUtils';
import InvoicingIntegrationIcon from '../InvocingIntegrationIcon';
const AssociateBillToEntityModal = ({ closeModal, viewer }) => {
    var _a, _b, _c;
    const { formatMessage } = useIntl();
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [initialContactsList, setInitialContactsList] = useState([]);
    const billFroms = ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.billFroms) || [];
    const associatedBillTos = (_c = (_b = viewer.company) === null || _b === void 0 ? void 0 : _b.client) === null || _c === void 0 ? void 0 : _c.clientBillTos;
    const [billFrom, setBillFrom] = useState(billFroms[0]);
    const alreadyImportedBillToExtRefs = useMemo(() => { var _a; return new Set((_a = billFrom === null || billFrom === void 0 ? void 0 : billFrom.billTos.edges) === null || _a === void 0 ? void 0 : _a.map(edge => { var _a; return (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.externalId; })); }, [billFrom]);
    const alreadyImportedBillToEntities = useMemo(() => { var _a; return (_a = billFrom === null || billFrom === void 0 ? void 0 : billFrom.billTos.edges) === null || _a === void 0 ? void 0 : _a.map(edge => edge === null || edge === void 0 ? void 0 : edge.node); }, [billFrom]);
    const alreadyAssociatedExternalIds = useMemo(() => {
        var _a;
        return new Set((_a = associatedBillTos === null || associatedBillTos === void 0 ? void 0 : associatedBillTos.edges) === null || _a === void 0 ? void 0 : _a.map(edge => {
            const alreadyAssociatedBillToEntity = alreadyImportedBillToEntities === null || alreadyImportedBillToEntities === void 0 ? void 0 : alreadyImportedBillToEntities.find(entity => { var _a, _b; return (entity === null || entity === void 0 ? void 0 : entity.id) === ((_b = (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.billTo) === null || _b === void 0 ? void 0 : _b.id); });
            return alreadyAssociatedBillToEntity === null || alreadyAssociatedBillToEntity === void 0 ? void 0 : alreadyAssociatedBillToEntity.externalId;
        }));
    }, [billFrom]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const filteredContactsCount = contacts.length - filteredRows.length;
    const [xeroFetched, setXeroFetched] = useState(false);
    const [economicFetched, setEconomicFetched] = useState(false);
    const [quickbooksFetched, setQuickbooksFetched] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const onXeroSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setXeroFetched(true);
        };
        const onQuickbooksSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setEconomicFetched(true);
        };
        const onEconomicSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setQuickbooksFetched(true);
        };
        fetchXeroContacts().then(onXeroSuccess);
        fetchQuickbooksContacts().then(onQuickbooksSuccess);
        fetchEconomicContacts().then(onEconomicSuccess);
    }, []);
    useEffect(() => {
        if (xeroFetched && economicFetched && quickbooksFetched) {
            setIsLoading(false);
            const filteredContacts = filterContactsByIntegrationInstanceId(initialContactsList, billFrom);
            setContacts(filteredContacts);
            setFilteredRows(filteredContacts);
        }
    }, [initialContactsList, billFrom, xeroFetched, economicFetched, quickbooksFetched]);
    const associateBillTos = () => {
        const billToEntitiesToCreate = selectedRows.filter(row => !alreadyImportedBillToExtRefs.has(row.id));
        const billToEntitiesToAssociate = selectedRows
            .filter(row => alreadyImportedBillToExtRefs.has(row.id))
            .map(entity => {
            var _a, _b;
            const billToEntity = (_a = billFrom === null || billFrom === void 0 ? void 0 : billFrom.billTos.edges) === null || _a === void 0 ? void 0 : _a.find(edge => { var _a; return ((_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.externalId) === entity.id; });
            return Object.assign({ id: (_b = billToEntity === null || billToEntity === void 0 ? void 0 : billToEntity.node) === null || _b === void 0 ? void 0 : _b.id }, billToEntity === null || billToEntity === void 0 ? void 0 : billToEntity.node);
        });
        const associateBillTosToClient = response => {
            if (response) {
                const createdBillTos = response.createBulkBillTo.billTos.map(billTo => billTo.node);
                for (let i = 0; i < createdBillTos.length; i++) {
                    billToEntitiesToAssociate.push(createdBillTos[i]);
                }
            }
            Util.CommitMutation(CreateBulkClientBillToMutation, {
                billTos: billToEntitiesToAssociate.map(row => {
                    var _a, _b;
                    return ({
                        clientId: (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.id,
                        billToId: row.id,
                        billFromId: billFrom === null || billFrom === void 0 ? void 0 : billFrom.id,
                    });
                }),
            }, closeModal);
        };
        if (billToEntitiesToCreate.length) {
            Util.CommitMutation(CreateBulkBillToMutation, {
                billTos: billToEntitiesToCreate.map(row => ({
                    name: row.name,
                    address: row.address,
                    taxId: row.taxId,
                    externalId: row.id,
                    billFromId: billFrom === null || billFrom === void 0 ? void 0 : billFrom.id,
                })),
            }, associateBillTosToClient);
        }
        else if (billToEntitiesToAssociate.length) {
            // No billTo entities to create, just associate.
            associateBillTosToClient(null);
        }
        else {
            closeModal();
        }
    };
    const associateBillTosConfirmation = () => {
        const billToEntitiesToCreate = selectedRows.filter(row => !alreadyImportedBillToExtRefs.has(row.id));
        if (billToEntitiesToCreate.length) {
            setShowConfirmation(true);
        }
        else {
            associateBillTos();
        }
    };
    const goBackButtonHandler = () => {
        setShowConfirmation(false);
    };
    const calculateNoOfCustomers = billFrom => {
        return isLoading
            ? ''
            : `(${initialContactsList.filter(contact => (!contact.integrationInstanceId || contact.integrationInstanceId === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.externalId)) &&
                contact.integration === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration)).length})`;
    };
    const BillFromItem = ({ billFrom }) => (React.createElement(FlexRow, { gap: 's' },
        React.createElement(InvoicingIntegrationIcon, { integration: billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration }),
        React.createElement("div", null, billFrom === null || billFrom === void 0 ? void 0 :
            billFrom.name,
            " ",
            calculateNoOfCustomers(billFrom))));
    const alreadyImportedAndAssignedIcon = {
        label: formatMessage({ id: 'settings_finance.bill_to.already_associated' }),
        icon: 'checkCircle',
        color: 'success',
    };
    const notImportedYetIcon = {
        label: formatMessage({ id: 'settings_finance.bill_to.not_imported_yet' }),
        icon: 'alert',
        color: 'warning',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { onCloseModal: closeModal },
            React.createElement("div", { style: { display: showConfirmation === true ? 'contents' : 'none' } },
                React.createElement(Modal.Title, null,
                    React.createElement(FormattedMessage, { id: "settings_finance.associate_bill_to_entities" })),
                React.createElement(Modal.Content, null,
                    React.createElement(Text, null, formatMessage({ id: 'settings_finance.bill_to.confirmation_message' }))),
                React.createElement(Modal.Footer, null,
                    React.createElement(Modal.PrimaryFooterButton, { onClick: associateBillTos },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.confirmation_message_button' })),
                    React.createElement(Modal.SecondaryFooterButton, { onClick: goBackButtonHandler },
                        React.createElement(FormattedMessage, { id: 'common.go_back' })),
                    React.createElement(Modal.TertiaryFooterButton, { onClick: closeModal },
                        React.createElement(FormattedMessage, { id: 'common.cancel' })))),
            React.createElement("div", { style: { display: showConfirmation === false ? 'contents' : 'none' } },
                React.createElement(Modal.Title, null,
                    React.createElement(FormattedMessage, { id: "settings_finance.associate_bill_to_entities" })),
                React.createElement(Modal.Content, null,
                    React.createElement(SearchField, { isExpandable: false, value: searchValue, onChange: setSearchValue, placeholder: formatMessage({ id: 'common.search' }) }),
                    billFroms.length === 1 ? (React.createElement(Label, null,
                        React.createElement(BillFromItem, { billFrom: billFrom }))) : (React.createElement(DropdownList, null,
                        React.createElement(DropdownList.Trigger, null,
                            React.createElement(ButtonDropdown, { emphasis: 'medium', style: { width: 'fit-content' } },
                                React.createElement(BillFromItem, { billFrom: billFrom }))),
                        React.createElement(DropdownList.Content, { offset: 5, align: 'start' }, billFroms.map(current => (React.createElement(DropdownList.Item, { key: current === null || current === void 0 ? void 0 : current.id, onClick: () => setBillFrom(current) },
                            React.createElement(FlexRow, { justifyContent: 'space-between' },
                                React.createElement(BillFromItem, { billFrom: current }),
                                (current === null || current === void 0 ? void 0 : current.id) === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.id) && React.createElement(Icon, { icon: 'iconTick' })))))))),
                    isLoading ? (React.createElement(InlineLoader, null)) : (React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            React.createElement(Table, { data: contacts, searchValue: searchValue, onSelectionChange: setSelectedRows, onFilteringChange: setFilteredRows, enableRowSelection: row => !alreadyAssociatedExternalIds.has(row.original.id), width: '100%' },
                                React.createElement(Table.TextColumn, { header: formatMessage({ id: 'common.select_all' }), accessorKey: 'name' }),
                                React.createElement(Table.IconColumn, { accessorKey: 'id', header: 'Status', hideHeader: true, align: Table.Align.right, icon: (value) => alreadyAssociatedExternalIds.has(value)
                                        ? alreadyImportedAndAssignedIcon
                                        : !alreadyImportedBillToExtRefs.has(value)
                                            ? notImportedYetIcon
                                            : undefined }))),
                        React.createElement(FlexColumn, { alignItems: 'center' },
                            filteredRows.length === 0 && (React.createElement(SubHeading, { size: '2' },
                                React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.no_results' }))),
                            filteredContactsCount > 0 && (React.createElement(Text, null,
                                React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.filtering_count', values: { count: filteredContactsCount } }))))))),
                React.createElement(Modal.Footer, null,
                    React.createElement(Modal.PrimaryFooterButton, { onClick: associateBillTosConfirmation, disabled: !selectedRows.length },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.associate_selected', values: { count: selectedRows.length } })),
                    React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                        React.createElement(FormattedMessage, { id: 'common.close' })))))));
};
export const AssociateBillToEntityModalQuery = graphql `
	query AssociateBillToEntityModalQuery($clientId: String) {
		viewer {
			actualPersonId
			component(name: "AssociateBillToEntityModal")
			...AssociateBillToEntityModal_viewer @arguments(clientId: $clientId)
		}
	}
`;
export default createFragmentContainer(AssociateBillToEntityModal, {
    viewer: graphql `
		fragment AssociateBillToEntityModal_viewer on Viewer @argumentDefinitions(clientId: {type: "String"}) {
			id
			company {
				id
				billFroms {
					id
					integration
					externalId
					name
					billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
						edges {
							node {
								id
								name
								address
								taxId
								externalId
							}
						}
					}
				}
				client(id: $clientId) {
					id
					clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
						edges {
							node {
								id
								billFrom {
									id
								}
								billTo {
									id
								}
								clientId
							}
						}
					}
				}
			}
		}
	`,
});
