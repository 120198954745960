import {getNavigationString} from '../../../forecast-app/shared/components/project-indicator/project_group_indicator';
import {PersonLineStyle, TimeRegistrationNameCell} from './timesheets_team_page_styled';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import Util from '../../../forecast-app/shared/util/util';
import React, {useState} from 'react';
import TimesheetsDayCell from './TimesheetsDayCell';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import useGrouping from '../../../forecast-app/shared/hooks/useGrouping';
import TimeRegistrationLine, {LINE_TYPE} from './TimeRegistrationLine';
import {CaretIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import TimesheetsTotalsCell from './TimesheetsTotalsCell';
import {getTotalWeekTime, hasNoAccessToTimeRegProject} from './TimesheetsUtil';
import TimeRegistrationRolesDisplay from '../../../forecast-app/shared/components/display/TimeRegistrationRolesDisplay';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const EntityLine = ({
	id,
	viewer,
	timeRegistrations,
	timeRegistrations_cpy,
	timeRegistration,
	parentId,
	lastOne,
	person,
	firstDayOfWeek,
	lockedDate,
	timeRegs,
}) => {
	const intl = useIntl();
	const history = useHistory();
	const _grouping = useGrouping();

	const [expanded, setExpanded] = useState(false);

	const isEpic = timeRegistration?.task?.taskType === 'EPIC';
	const timeRegClassName = timeRegistrations ? ' time-reg-cell' : '';

	const rolesRegisteredWith = timeRegistrations?.reduce((acc, timeReg) => {
		const containsRole = () => acc.some(role => (timeReg.node.role ? role.id === timeReg.node.role.id : true));

		return timeReg.node.role && !containsRole() ? [...acc, timeReg.node.role] : acc;
	}, []);

	const isTimeOnNotDefaultRole = rolesRegisteredWith.some(role => role.id !== person?.role?.id);

	const {totalWeekTime, totalWeekBillableTime} = getTotalWeekTime(timeRegistrations, firstDayOfWeek);

	const timeRegistrationsGroupedByRole = timeRegistrations
		? Object.entries(
				_grouping.groupByToMap(
					timeRegistrations,
					'node.role',
					role => (role ? role.id : person?.role?.id),
					role => role?.name || person?.role?.name || 'No role'
				)
		  )
		: [];

	const isExpandable = isTimeOnNotDefaultRole && timeRegistrationsGroupedByRole.length > 0;

	return (
		<>
			<PersonLineStyle
				data-userpilot={id === 0 ? 'timesheets-team-first-person' : ''}
				id={timeRegistration ? `person-${parentId}-time-reg-row-${id}` : `person-row-${id}`}
				className={
					isExpandable
						? ' expandable'
						: '' +
						  (timeRegistrations ? ' time-reg-row' : '') +
						  (id === 0 ? ' first-time-reg-row' : lastOne ? ' last-time-reg-row' : '')
				}
				onClick={isExpandable ? () => setExpanded(!expanded) : null}
			>
				<TimeRegistrationNameCell className={timeRegClassName}>
					{hasNoAccessToTimeRegProject(timeRegistration) ? (
						<div className="name-and-type">
							<div className="arrow" data-userpilot="timesheets-team-arrow">
								{isExpandable ? (
									<CaretIcon
										color={CSS_CONSTANTS.v2_text_gray}
										direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								) : (
									<div className="empty-arrow"></div>
								)}
							</div>
							<div className={'name no-access'}>
								<DeprecatedProjectIndicatorJS
									project={timeRegistration.project || timeRegistration.task.project}
									clearSubPath={true}
									disableLink
									noTitle
								/>
								<div className={'time-name'}>{intl.formatMessage({id: 'timesheets.no_access'})}</div>
							</div>
						</div>
					) : timeRegistration.task ? (
						<div className="name-and-type">
							<div className="arrow" data-userpilot="timesheets-team-arrow">
								{isExpandable ? (
									<CaretIcon
										color={CSS_CONSTANTS.v2_text_gray}
										direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								) : (
									<div className="empty-arrow"></div>
								)}
							</div>
							<div className={'name task'}>
								<TooltipContainer infoText={intl.formatMessage({id: 'common.go_to_project'})}>
									<DeprecatedProjectIndicatorJS project={timeRegistration.task.project} clearSubPath={true} />
								</TooltipContainer>
								<div
									className={'time-name'}
									title={timeRegistration.task.name}
									onClick={() => {
										if (isEpic) return;
										Util.showTaskModal(timeRegistration.task.companyTaskId, history);
									}}
								>
									{timeRegistration.task.name}
								</div>
								{isTimeOnNotDefaultRole && (
									<TimeRegistrationRolesDisplay roles={rolesRegisteredWith.map(role => role.name)} />
								)}
							</div>
							<div className={'type'}>{intl.formatMessage({id: isEpic ? 'common.epic' : 'common.task'})}</div>
						</div>
					) : timeRegistration.project ? (
						<div className="name-and-type">
							<div className="arrow" data-userpilot="timesheets-team-arrow">
								{isExpandable ? (
									<CaretIcon
										color={CSS_CONSTANTS.v2_text_gray}
										direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								) : (
									<div className="empty-arrow"></div>
								)}
							</div>
							<div className={'name project'}>
								<TooltipContainer infoText={intl.formatMessage({id: 'common.go_to_project'})} growable>
									<DeprecatedProjectIndicatorJS project={timeRegistration.project} clearSubPath={true} />
								</TooltipContainer>
								<a
									href={getNavigationString(timeRegistration.project, true)}
									title={timeRegistration.project.name}
									className={'time-name'}
									tabIndex="0"
								>
									{timeRegistration.project.name}
								</a>
								{isTimeOnNotDefaultRole && (
									<TimeRegistrationRolesDisplay roles={rolesRegisteredWith.map(role => role.name)} />
								)}
							</div>
							<div className={'type'}>{intl.formatMessage({id: 'common.project'})}</div>
						</div>
					) : (
						<div className="name-and-type">
							<div className="arrow" data-userpilot="timesheets-team-arrow">
								{isExpandable ? (
									<CaretIcon
										color={CSS_CONSTANTS.v2_text_gray}
										direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
									/>
								) : (
									<div className="empty-arrow"></div>
								)}
							</div>
							<div
								className={'name idle-time'}
								title={timeRegistration.idleTime ? timeRegistration.idleTime.name : ''}
							>
								{timeRegistration.idleTime ? timeRegistration.idleTime.name : ''}
							</div>
							<div className={'type'}>
								{timeRegistration.idleTime && timeRegistration.idleTime.isInternalTime
									? intl.formatMessage({id: 'common.internal_time'})
									: intl.formatMessage({id: 'common.time_off'})}
							</div>
						</div>
					)}
				</TimeRegistrationNameCell>
				{Array(7)
					.fill(0)
					.map((n, i) => (
						<TimesheetsDayCell
							viewer={viewer}
							firstDayOfWeek={firstDayOfWeek}
							timeRegs={timeRegs}
							timeRegistrations={timeRegistrations}
							timeRegClassName={timeRegClassName}
							lockedDate={lockedDate}
							index={i}
						/>
					))}
				<TimesheetsTotalsCell
					totalWeekTime={totalWeekTime}
					totalWeekBillableTime={totalWeekBillableTime}
					lockedDate={lockedDate}
					firstDayOfWeek={firstDayOfWeek}
					timeRegClassName={timeRegClassName}
				/>
			</PersonLineStyle>
			{expanded &&
				isTimeOnNotDefaultRole &&
				timeRegistrationsGroupedByRole.map(([_, timeRegs], index) => {
					return (
						<TimeRegistrationLine
							key={index}
							id={index}
							viewer={viewer}
							timeRegistrations={timeRegs.elements}
							timeRegistrations_cpy={timeRegistrations_cpy}
							timeRegistration={timeRegistration}
							firstDayOfWeek={firstDayOfWeek}
							person={person}
							lockedDate={lockedDate}
							parentId={id}
							lastOne={index === rolesRegisteredWith.length - 1}
							role={timeRegs.display}
							lineType={LINE_TYPE.ROLE}
						/>
					);
				})}
		</>
	);
};

export default EntityLine;
