import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexRow, Modal, DropdownList, Text, ButtonDropdownField } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import CreateBulkClientBillToMutation from '../../../mutations/invoicing/CreateBulkClientBillToMutation';
import Util from '../../../forecast-app/shared/util/util';
const AssociateBillToModal = ({ billToId, billFromId, closeModal, viewer }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { formatMessage } = useIntl();
    const [clientId, setClientId] = useState(undefined);
    const [selectedClient, setSelectedClient] = useState((_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.find(client => { var _a; return ((_a = client === null || client === void 0 ? void 0 : client.node) === null || _a === void 0 ? void 0 : _a.id) === clientId; }));
    const clients = ((_f = (_e = (_d = viewer.company) === null || _d === void 0 ? void 0 : _d.clients) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(edge => edge === null || edge === void 0 ? void 0 : edge.node).filter(client => { var _a, _b; return !((_b = (_a = client === null || client === void 0 ? void 0 : client.clientBillTos) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.some(billTo => { var _a, _b; return ((_b = (_a = billTo === null || billTo === void 0 ? void 0 : billTo.node) === null || _a === void 0 ? void 0 : _a.billTo) === null || _b === void 0 ? void 0 : _b.id) === billToId; })); })) || [];
    const associateBillTo = () => {
        Util.CommitMutation(CreateBulkClientBillToMutation, {
            billTos: [{ billToId: billToId, billFromId: billFromId, clientId: clientId }],
        }, closeModal);
    };
    const handleSelectClient = clientId => {
        var _a, _b, _c;
        setClientId(clientId);
        setSelectedClient((_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.find(client => { var _a; return ((_a = client === null || client === void 0 ? void 0 : client.node) === null || _a === void 0 ? void 0 : _a.id) === clientId; }));
    };
    const areThereClients = clients.length > 0;
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'settings_finance.bill_to.associate_client' })),
        React.createElement(Modal.Content, null, areThereClients ? (React.createElement(React.Fragment, null,
            React.createElement(Text, { size: '1' },
                React.createElement("b", null, formatMessage({ id: 'settings_finance.select_client' }))),
            React.createElement(Text, null, formatMessage({ id: 'settings_finance.associate_single_bill_to_entity' })),
            React.createElement(DropdownList, null,
                React.createElement(DropdownList.Trigger, null,
                    React.createElement(ButtonDropdownField, null, selectedClient
                        ? (_g = selectedClient.node) === null || _g === void 0 ? void 0 : _g.name
                        : formatMessage({ id: 'settings_finance.select_client' }))),
                React.createElement(DropdownList.Content, { offset: 5, align: 'start' }, clients.map(client => (React.createElement(DropdownList.Item, { key: client === null || client === void 0 ? void 0 : client.id, onClick: () => handleSelectClient(client === null || client === void 0 ? void 0 : client.id) },
                    React.createElement(FlexRow, null, client === null || client === void 0 ? void 0 : client.name)))))))) : (React.createElement(Text, { size: '1' }, formatMessage({ id: 'settings_finance.all_clients_linked_to_bill_to_message' })))),
        areThereClients ? (React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: associateBillTo, disabled: !clientId }, formatMessage({ id: 'common.associate' })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal }, formatMessage({ id: 'common.close' })))) : (React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: closeModal, disabled: !clientId }, formatMessage({ id: 'common.close' }))))));
};
export const AssociateBillToModalQuery = graphql `
	query AssociateBillToModalQuery {
		viewer {
			actualPersonId
			component(name: "AssociateBillToModal")
			...AssociateBillToModal_viewer
		}
	}
`;
export default createFragmentContainer(AssociateBillToModal, {
    viewer: graphql `
		fragment AssociateBillToModal_viewer on Viewer {
			id
			company {
				id
				clients(first: 100000) {
					edges {
						node {
							id
							name
							clientBillTos(first: 10000) @connection(key: "Client_clientBillTos", filters: []) {
								edges {
									node {
										id
										billFrom {
											id
										}
										billTo {
											id
											invoiced
										}
										clientId
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
