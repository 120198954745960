import {Table} from 'web-components';
import React from 'react';
import {injectIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {getTotalBillableTimeEntries, getTotalTimeEntries} from '../../TaskReportUtil';

const TaskNonBillableTimeColumn = props => {
	const {timeRegistrations, intl} = props;

	const totalBillableTime = timeRegistrations ? getTotalBillableTimeEntries(timeRegistrations) : 0;
	const totalTime = timeRegistrations ? getTotalTimeEntries(timeRegistrations) : 0;
	const totalNonBillableTime = totalTime - totalBillableTime;

	return <Table.Column.Cell>{Util.convertMinutesToFullHour(totalNonBillableTime, intl)}</Table.Column.Cell>;
};

export default injectIntl(TaskNonBillableTimeColumn);
