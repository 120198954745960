import React, { useEffect, useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonDropdown, DropdownList, FlexColumn, FlexRow, Icon, Label, Modal, SearchField, SubHeading, Table, Text, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import CreateBulkBillToMutation from '../../../mutations/invoicing/CreateBulkBillToMutation';
import { fetchEconomicContacts, fetchQuickbooksContacts, fetchXeroContacts, filterContactsByIntegrationInstanceId, } from '../InvoiceIntegrationsUtils';
import InvoicingIntegrationIcon from '../InvocingIntegrationIcon';
const AddBillToEntityModal = ({ billFromId, closeModal, viewer }) => {
    var _a;
    const { formatMessage } = useIntl();
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [initialContactsList, setInitialContactsList] = useState([]);
    const billFroms = ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.billFroms) || [];
    const [billFrom, setBillFrom] = useState(billFroms.find(billFrom => (billFrom === null || billFrom === void 0 ? void 0 : billFrom.id) === billFromId));
    const alreadyImported = useMemo(() => { var _a; return new Set((_a = billFrom === null || billFrom === void 0 ? void 0 : billFrom.billTos.edges) === null || _a === void 0 ? void 0 : _a.map(edge => { var _a; return (_a = edge === null || edge === void 0 ? void 0 : edge.node) === null || _a === void 0 ? void 0 : _a.externalId; })); }, [billFrom]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const filteredContactsCount = contacts.length - filteredRows.length;
    const [xeroFetched, setXeroFetched] = useState(false);
    const [economicFetched, setEconomicFetched] = useState(false);
    const [quickbooksFetched, setQuickbooksFetched] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        const onXeroSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setXeroFetched(true);
        };
        const onQuickbooksSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setEconomicFetched(true);
        };
        const onEconomicSuccess = (newContacts) => {
            setInitialContactsList(prevValue => [...prevValue, ...newContacts]);
            setQuickbooksFetched(true);
        };
        fetchXeroContacts().then(onXeroSuccess);
        fetchQuickbooksContacts().then(onQuickbooksSuccess);
        fetchEconomicContacts().then(onEconomicSuccess);
    }, []);
    useEffect(() => {
        if (xeroFetched && economicFetched && quickbooksFetched) {
            setIsLoading(false);
            const filteredContacts = filterContactsByIntegrationInstanceId(initialContactsList, billFrom);
            setContacts(filteredContacts);
            setFilteredRows(filteredContacts);
        }
    }, [initialContactsList, billFrom, xeroFetched, economicFetched, quickbooksFetched]);
    const importBillTos = () => {
        if (selectedRows.length) {
            Util.CommitMutation(CreateBulkBillToMutation, {
                billTos: selectedRows.map(row => ({
                    name: row.name,
                    address: row.address,
                    taxId: row.taxId,
                    externalId: row.id,
                    billFromId: billFrom === null || billFrom === void 0 ? void 0 : billFrom.id,
                })),
            }, closeModal);
        }
        else {
            closeModal();
        }
    };
    const calculateNoOfCustomers = billFrom => {
        return isLoading
            ? ''
            : `(${initialContactsList.filter(contact => (!contact.integrationInstanceId || contact.integrationInstanceId === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.externalId)) &&
                contact.integration === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration)).length})`;
    };
    const BillFromItem = ({ billFrom }) => (React.createElement(FlexRow, { gap: 's' },
        React.createElement(InvoicingIntegrationIcon, { integration: billFrom === null || billFrom === void 0 ? void 0 : billFrom.integration }),
        React.createElement("div", null, billFrom === null || billFrom === void 0 ? void 0 :
            billFrom.name,
            " ",
            calculateNoOfCustomers(billFrom))));
    const alreadyImportedIcon = {
        label: formatMessage({ id: 'settings_finance.bill_to.already_added' }),
        icon: 'checkCircle',
        color: 'success',
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "settings_finance.add_bill_to_entities" })),
        React.createElement(Modal.Content, null,
            React.createElement(SearchField, { isExpandable: false, value: searchValue, onChange: setSearchValue, placeholder: formatMessage({ id: 'common.search' }) }),
            billFroms.length === 1 ? (React.createElement(Label, null,
                React.createElement(BillFromItem, { billFrom: billFrom }))) : (React.createElement(DropdownList, null,
                React.createElement(DropdownList.Trigger, null,
                    React.createElement(ButtonDropdown, { emphasis: 'medium', style: { width: 'fit-content' } },
                        React.createElement(BillFromItem, { billFrom: billFrom }))),
                React.createElement(DropdownList.Content, { offset: 5, align: 'start' }, billFroms.map(current => (React.createElement(DropdownList.Item, { key: current === null || current === void 0 ? void 0 : current.id, onClick: () => setBillFrom(current) },
                    React.createElement(FlexRow, null,
                        React.createElement(BillFromItem, { billFrom: current }),
                        (current === null || current === void 0 ? void 0 : current.id) === (billFrom === null || billFrom === void 0 ? void 0 : billFrom.id) && React.createElement(Icon, { icon: 'iconTick' })))))))),
            isLoading ? (React.createElement(InlineLoader, null)) : (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Table, { data: contacts, searchValue: searchValue, onSelectionChange: setSelectedRows, onFilteringChange: setFilteredRows, enableRowSelection: row => !alreadyImported.has(row.original.id), width: '100%' },
                        React.createElement(Table.TextColumn, { header: formatMessage({ id: 'common.select_all' }), accessorKey: 'name' }),
                        React.createElement(Table.IconColumn, { accessorKey: 'id', header: 'Status', hideHeader: true, align: Table.Align.right, icon: (value) => (alreadyImported.has(value) ? alreadyImportedIcon : undefined) }))),
                React.createElement(FlexColumn, { alignItems: 'center' },
                    filteredRows.length === 0 && (React.createElement(SubHeading, { size: '2' },
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.no_results' }))),
                    filteredContactsCount > 0 && (React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.filtering_count', values: { count: filteredContactsCount } }))))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: importBillTos, disabled: !selectedRows.length },
                React.createElement(FormattedMessage, { id: 'settings_finance.bill_to.add_selected', values: { count: selectedRows.length } })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export const AddBillToEntityModalQuery = graphql `
	query AddBillToEntityModalQuery {
		viewer {
			actualPersonId
			component(name: "AddBillToEntityModal")
			...AddBillToEntityModal_viewer
		}
	}
`;
export default createFragmentContainer(AddBillToEntityModal, {
    viewer: graphql `
		fragment AddBillToEntityModal_viewer on Viewer {
			id
			company {
				id
				billFroms {
					id
					integration
					externalId
					name
					billTos(first: 10000) @connection(key: "BillFrom_billTos", filters: []) {
						edges {
							node {
								id
								name
								address
								taxId
								externalId
							}
						}
					}
				}
			}
		}
	`,
});
