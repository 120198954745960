import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import CloseIcon from '../../images/small_close_icon';

import {
	Container,
	Popup,
	Info,
	CounterText,
	Counter,
} from '../../forecast-app/shared/components/popups/bulk-select-popup/bulk_select_popup.styled';
import {Button} from 'web-components';

export const EpicSelectPopup = ({epicIds, options, onClose}) => {
	// used for highlighting the selected button, and showing dropdown menus
	const [selectedButtonId, setSelectedButtonId] = useState(null);

	/**
	 * takes a function and calls it, if its not undefined
	 * @param {Function} callback
	 */
	const handleClick = callback => {
		if (callback) {
			setSelectedButtonId(null);
			callback();
		}
	};
	/**
	 * Sets selected button. and calls the callback function
	 * @param {object} button
	 */
	const handleButtonClick = button => {
		setSelectedButtonId(selectedButtonId !== button.id ? button.id : null);
		handleClick(button.callback);
	};

	const intl = useIntl();

	return (
		<Container>
			<Popup className={'animated faster fadeInUp'} data-userpilot={'bulk-select-popup'}>
				<Info>
					<CloseIcon onClick={onClose} />
					<span className="counter-spacing">
						<Counter data-cy="bulk-popup-counter">{epicIds.length}</Counter>
					</span>
					<CounterText>
						{intl.formatMessage({id: 'integrations.jira.epics_selected'}, {numOfEpics: epicIds.length})}
					</CounterText>
				</Info>
				{options.map(button => (
					<Button
						size={Button.SIZE.STANDARD}
						variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
						onClick={() => handleButtonClick(button)}
					>
						{button.label}
					</Button>
				))}
			</Popup>
		</Container>
	);
};

EpicSelectPopup.propTypes = {
	epicIds: PropTypes.any,
	onClose: PropTypes.func,
	options: PropTypes.array,
};

EpicSelectPopup.defaultProps = {
	epicIds: [],
	onClose: () => false,
	options: [],
};
