import {groupBy} from 'lodash';

export const groupByProperties = (expenses, groupByPropertyList) => {
	groupByPropertyList = groupByPropertyList.slice(0);
	const groupByProperty = groupByPropertyList.shift();
	const groupedExpenses = groupBy(expenses, groupByProperty);
	if (groupByPropertyList.length > 0) {
		Object.keys(groupedExpenses).forEach(groupByValue => {
			groupedExpenses[groupByValue] = groupByProperties(groupedExpenses[groupByValue], groupByPropertyList);
		});
	}
	return groupedExpenses;
};

export const visitGroups = (groups, visitor, groupKeys = []) => {
	if (Array.isArray(groups)) {
		visitor(groups, groupKeys);
	} else if (typeof groups === 'object') {
		Object.keys(groups).forEach(groupKey => {
			const newGroupKeys = groupKeys.slice(0);
			newGroupKeys.push(groupKey);
			visitGroups(groups[groupKey], visitor, newGroupKeys);
		});
	}
};

export const formatDate = (intl, date) => {
	return intl.formatDate(date.startOf('day'), {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
};

export const convertPriceToInvoiceCurrency = (price, sourceCurrency, company, invoice) => {
	let priceInInvoiceCurrency;

	let exchangeRateMap = company.exchangeRateMap;
	if (!exchangeRateMap) {
		exchangeRateMap = company.exchangeRates.edges
			.map(edge => edge.node)
			.reduce((map, exchangeRate) => {
				map[exchangeRate.currency] = exchangeRate.rate;
				return map;
			}, {});
	}

	if (sourceCurrency !== invoice.currency) {
		let priceInCompanyCurrency;
		if (sourceCurrency !== company.currency) {
			const sourceExchangeRate = exchangeRateMap[sourceCurrency];
			priceInCompanyCurrency = price / sourceExchangeRate;
		} else {
			priceInCompanyCurrency = price;
		}

		if (invoice.currency !== company.currency) {
			const invoiceExchangeRate = exchangeRateMap[invoice.currency];
			priceInInvoiceCurrency = priceInCompanyCurrency * invoiceExchangeRate;
		} else {
			priceInInvoiceCurrency = priceInCompanyCurrency;
		}
	} else {
		priceInInvoiceCurrency = price;
	}

	return Math.round(priceInInvoiceCurrency * 100) / 100;
};

export const addQuantityDecimals = (value, company) => {
	return value.toFixed(company.invoiceQuantityDecimals || 2);
};
