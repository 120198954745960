import React from 'react';
import PropTypes from 'prop-types';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import TaskRollupSection, {TaskRollupSectionQuery} from './TaskRollupSection';
import {TaskRollupSectionPlaceholder} from './TaskRollupSectionPlaceholder';

export const TaskRollupSectionRenderer = props => {
	return (
		<ForecastQueryRenderer
			key="query-render-rollup-section"
			query={TaskRollupSectionQuery}
			variables={{id: props.task.companyTaskId.toString()}}
			customLoader={() => <TaskRollupSectionPlaceholder />}
			render={(relayProps, retry) => {
				return <TaskRollupSection {...props} {...relayProps} retry={retry} />;
			}}
		/>
	);
};

TaskRollupSectionRenderer.propTypes = {
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	isClientUser: PropTypes.bool.isRequired,
	personId: PropTypes.string.isRequired,
	showTimeEntries: PropTypes.bool.isRequired,
};
