import React from 'react';
import {injectIntl} from 'react-intl';
import {UnapprovedIcon} from 'web-components';

import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import UpdateTaskMutation from '../../../mutations/update_task_mutation.modern';
import PriorityTaskInputIconPath from '../../../images/ico-priority';
import BlockedTaskInputIconPath from '../../../images/ico-blocked';
import BugTaskInputIconPath from '../../../images/ico-bug';
import NonBillableTaskInputIconPath from '../../../images/ico-nonbillable';
import RepeatingTaskIcon from '../../../images/ico-repeating';
import Timer from '../../../components/timer';
import StarTask from '../../../forecast-app/shared/components/star/star_task';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {hasPermission, isClientUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import {HIDDEN_FEATURES} from '../../../constants';
import {
	canLoggedInPersonManageProgram,
	hasTopDownProgramBudgetFeature,
} from '../../../forecast-app/shared/util/ProgramFinancialLogic';
import {ACTION} from '../../../forecast-app/shared/components/modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {createFragmentContainer, graphql} from 'react-relay';
import {isJiraToForecastOneWaySync} from '../../../forecast-app/shared/util/JiraUtil';

const optionsSection = props => {
	const {formatMessage} = props.intl;
	const {task, project, viewer, company, showTimer, disabled} = props;
	const {repeatingTask} = task;
	const disableBillableChange = task.hasInvoicedTime && task.billable;
	console.log('Function: optionsSection, Line 34 company: ', company);

	const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
	let selectedWeekDays = [];
	if (repeatingTask && repeatingTask.repeatType === 'WEEKLY') {
		selectedWeekDays = weekDays.filter(day => repeatingTask[day] === true);
	}

	const togglePriority = () => {
		if (disabled) return;

		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutation,
			{
				ids: [task.id],
				highPriority: !task.highPriority,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	};

	const toggleBlock = () => {
		if (disabled) return;

		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutation,
			{
				ids: [task.id],
				blocked: !task.blocked,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	};

	const toggleBug = () => {
		if (disabled) return;

		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutation,
			{
				ids: [task.id],
				bug: !task.bug,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	};

	const showProgramRevenueLockedMessage = () => {
		const program = project.program;
		const loggedInPersonId = viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.MARK_TASK_BILLABLE,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});
	};

	const onSuccess = result => {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		} else {
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		}
	};

	const toggleBillable = () => {
		if (disabled || disableBillableChange) return;

		const newBillableStatus = !task.billable;
		if (hasTopDownProgramBudgetFeature() && newBillableStatus && project.isProgramRevenueLocked) {
			trackEvent('Task', 'Set As Billable', {location: 'From Options Section', error: 'Program Revenue Locked'});
			showProgramRevenueLockedMessage();
			return;
		}
		Util.localStorageDeleteFincancialMap();
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutation,
			{
				ids: [task.id],
				billable: newBillableStatus,
			},
			onSuccess
		);
	};

	const onIconKeyPress = (icon, e) => {
		if (e.key === 'Enter') {
			switch (icon) {
				case 'priority':
					togglePriority();
					break;
				case 'blocked':
					toggleBlock();
					break;
				case 'bug':
					toggleBug();
					break;
				case 'non-billable':
					toggleBillable();
					break;
				default:
					return;
			}
		}
	};

	const showRecurringTaskModal = () => {
		if (disabled) return;

		showModal({
			type: MODAL_TYPE.TASK_RECURRENCE,
			repeatingTask: task.repeatingTask,
			taskId: task.id,
		});
	};

	const handleRecurringKeyPress = e => {
		if (e.key === 'Enter') showRecurringTaskModal();
	};

	const sendToJira = () => {
		if (project.isJiraProject) {
			showModal({
				type: MODAL_TYPE.CREATE_JIRA_ISSUE,
				projectId: project.id,
				taskIds: [task.id],
				jiraType: project.jiraCloudProject || project.jiraCloudEpicIds?.length ? 'cloud' : 'server',
				useTaskHierarchy: project.useTaskHierarchy,
				hasParent: task.parentTaskId,
			});
		}
	};

	const sendToAdo = () => {
		if (project.vstsProject && project.vstsAccount) {
			showModal({
				type: MODAL_TYPE.CREATE_ADO_WORKITEM,
				projectId: project.id,
				vstsAccount: project.vstsAccount,
				vstsArea: project.vstsArea,
				taskIds: [task.id],
			});
		}
	};

	return (
		<div className={'task-modal-v3-options-section' + (disabled ? ' disabled' : '')}>
			<div className={'left-section'} data-userpilot={'task-modal-indicators'}>
				{task.approved ? null : <UnapprovedIcon text={formatMessage({id: 'common.unapproved'})} />}
				<PriorityTaskInputIconPath
					cy="icon-priority"
					title={formatMessage({id: task.highPriority ? 'task-modal.unmark-priority' : 'task-modal.mark-priority'})}
					active={task.highPriority}
					className={disabled ? 'disabled' : ''}
					onClick={togglePriority}
					onKeyDown={onIconKeyPress.bind(this, 'priority')}
				/>
				{project.isJiraProject ? null : (
					<BugTaskInputIconPath
						cy="icon-bug"
						title={formatMessage({id: task.bug ? 'card_modal.unmark-as-bug' : 'card_modal.mark-as-bug'})}
						active={task.bug}
						className={(task.bug ? 'active-state ' : '') + (disabled ? ' disabled' : '')}
						onClick={toggleBug}
						onKeyDown={onIconKeyPress.bind(this, 'bug')}
					/>
				)}
				{ProjectUtil.projectTracksRevenue(project) && hasPermission(PERMISSION_TYPE.PHASE_UPDATE) ? ( // not sure if this is the correct permission, seems like it should be tied to financial information but is currently available to managers
					<NonBillableTaskInputIconPath
						cy="icon-nonbillable"
						title={formatMessage({id: task.billable ? 'card_modal.unmark-billable' : 'card_modal.mark-billable'})}
						active={!task.billable}
						className={
							(!task.billable ? 'active-state ' : '') + (disabled || disableBillableChange ? ' disabled' : '')
						}
						onClick={toggleBillable}
						onKeyDown={onIconKeyPress.bind(this, 'non-billable')}
					/>
				) : null}
				<BlockedTaskInputIconPath
					cy="icon-blocked"
					title={formatMessage({id: task.blocked ? 'card_modal.unblock-card' : 'card_modal.block-card'})}
					active={task.blocked}
					className={disabled ? 'disabled' : ''}
					onClick={toggleBlock}
					onKeyDown={onIconKeyPress.bind(this, 'blocked')}
				/>

				{repeatingTask ? (
					<div
						className={'recurring-days' + (disabled ? ' disabled' : '')}
						onClick={showRecurringTaskModal}
						onKeyPress={handleRecurringKeyPress.bind(this)}
						tabIndex="0"
					>
						<RepeatingTaskIcon />
						<div className="repeat-text">{formatMessage({id: 'card_modal.start_recurring'})}:</div>
						{repeatingTask.repeatType === 'WEEKLY' && selectedWeekDays.length > 0 ? (
							<div className="repeating-days">
								{selectedWeekDays.map(day => (
									<span className="day" key={day}>
										{formatMessage({id: `common.${day}`}).substr(0, 3)}
									</span>
								))}
							</div>
						) : repeatingTask.repeatType === 'WEEKLY' && selectedWeekDays.length === 0 ? (
							<div className="repeating-days">
								<span className="day">{formatMessage({id: 'card_modal.repeating.weekly'})}</span>
							</div>
						) : repeatingTask.repeatType === 'MONTHLY' && repeatingTask.monthlyDay ? (
							<span className="day">
								{repeatingTask.monthlyDay}. {formatMessage({id: `task_recurrence_modal.of-the-month`})}
							</span>
						) : null}
					</div>
				) : null}
				{task.jiraKey ||
				task.gitlabIid ||
				(company.gitlabServerUrl && task.gitlabServerIid && project.gitlabServerProjectName) ||
				(task.vstsId && project.vstsProject && project.vstsAccount) ? (
					<div className="integration-link">
						{task.jiraKey ? (
							<a href={task.jiraKey} target="_blank" rel="noopener noreferrer">
								{formatMessage({id: 'task_modal.jira_issue'})}
							</a>
						) : null}
						{task.gitlabIid ? (
							<a
								href={'https://gitlab.com/' + project.gitlabProjectName + '/issues/' + task.gitlabIid}
								target="_blank"
								rel="noopener noreferrer"
							>
								{formatMessage({id: 'task_modal.gitlab_issue'})}
							</a>
						) : null}
						{company.gitlabServerUrl && task.gitlabServerIid ? (
							<a
								href={
									company.gitlabServerUrl +
									'/' +
									project.gitlabServerProjectName +
									'/issues/' +
									task.gitlabServerIid
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								{formatMessage({id: 'task_modal.gitlab_issue'})}
							</a>
						) : null}
						{task.vstsId && project.vstsProject && project.vstsAccount ? (
							<a
								href={
									'https://dev.azure.com/' +
									project.vstsAccount +
									'/' +
									project.vstsProject +
									'/_workitems/edit/' +
									task.vstsId
								}
								target="_blank"
							>
								{formatMessage({id: 'task_modal.ado_workitem'})}
							</a>
						) : null}
					</div>
				) : null}
				{console.log('Function: optionsSection, Line 296 viewer: ', viewer) ||
				(!isJiraToForecastOneWaySync(company) && !task.jiraKey && project.isJiraProject) ? (
					<div className="send-to-jira-link" onClick={sendToJira.bind(this)}>
						{formatMessage({id: 'common.move_to_jira'})}
					</div>
				) : null}
				{!task.vstsId && project.vstsProject && project.vstsAccount && project.vstsTwoWaySync ? (
					<div className="send-to-jira-link" onClick={sendToAdo.bind(this)}>
						{formatMessage({id: 'common.move_to_ado'})}
					</div>
				) : null}
				{showTimer &&
				!disabled &&
				!isClientUser() &&
				!ProjectUtil.projectUsesManualProgress(project) &&
				!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) ? (
					<Timer
						key={viewer.timerTask && viewer.timerTask.id}
						taskModal={true}
						taskId={task.id}
						viewer={{...viewer, task}}
						userpilot={'task-modal-timer'}
					/>
				) : null}
			</div>
			{task.approved ? (
				<div className={'right-section'}>
					<StarTask
						task={task}
						parentId={viewer.id}
						cySource={'task-modal'}
						customSize={16}
						userpilot={'task-modal-star'}
					/>
				</div>
			) : null}
		</div>
	);
};

export default injectIntl(
	createFragmentContainer(optionsSection, {
		company: graphql`
			fragment optionsSection_company on Company {
				gitlabServerUrl
				jiraCloudEnabled
				jiraServerEnabled
				integrations {
					jiraCloud {
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
					jiraServer {
						syncSettings {
							isJiraToForecastOneWaySync
						}
					}
				}
			}
		`,
	})
);
