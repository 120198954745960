import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {SubNavigation} from 'web-components';

import {useGenericReportContext} from '../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import {PageWrapper, StandardMargin} from './ProjectPortfolioReport.styled';
import ProjectPortfolioReportTitleHeaderBar from './ProjectPortfolioReportTitleBar';
import ProjectPortfolioReportHeaderBar from './ProjectPortfolioReportHeaderBar';
import {ProjectPortfolioReportPeriodPage} from './period/ProjectPortfolioReportPeriodPage';
import {ProjectPortfolioReportMonthByMonthPage} from './month-by-month/ProjectPortfolioReportMonthByMonthPage';
import moment from 'moment';
import {getInitialOptionsFromCheckedOptions} from '../../../the_eye_util';
import {getMonthByMonthEyeOptions, getPeriodEyeOptions} from './period/ProjectPortfolioReportPeriodEyeOptions';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {REPORT_TYPE} from '../../../constants';
import {getGroupingOne} from '../../../containers/modal/create-new-report/create-new-report-util';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

export const PROJECT_PORTFOLIO_REPORT_PAGES = {
	PERIOD: {
		path: 'period',
		labelPlain: 'Period',
		getLabel: intl => intl.formatMessage({id: 'common.period'}),
	},
	MONTH_BY_MONTH: {
		path: 'month-by-month',
		labelPlain: 'Month By Month',
		getLabel: intl => intl.formatMessage({id: 'common.month_by_month'}),
	},
};

export const GROUP_BY = {
	NO_GROUPING: 'no_grouping',
	PROGRAM: 'program',
	CONNECTED_PROJECT: 'connected_project',
	CLIENT: 'client',
};

const useActivePageFromRoute = () => {
	const {pathname} = useLocation();
	const pathIndex = pathname.lastIndexOf('/');
	return pathname.substr(pathIndex + 1);
};

const getPlainLabelFromActivePage = activePage => {
	const activePageObject = Object.values(PROJECT_PORTFOLIO_REPORT_PAGES).find(page => page.path === activePage);
	return activePageObject ? activePageObject.labelPlain : PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.labelPlain;
};

const ProjectPortfolioReport = ({viewer}) => {
	const {pathname} = useLocation();
	const intl = useIntl();
	const history = useHistory();
	const {url} = useRouteMatch();

	const [showDatePicker, setShowDatePicker] = useState(true);

	useEffect(() => {
		document.title = 'Project Portfolio Report - Forecast';
	}, []);

	const {
		getTopReportHeader,
		getBottomReportHeader,
		reportName,
		getHasOwnerPermission,
		startDate,
		endDate,
		periodDate: selectedYear,
		theEyeOptionsChecked,
		filterValue,
		firstDropdownValue,
		secondDropdownValue: selectedDateRangeValue,
		thirdDropdownValue,
		handleSetStartDate,
		handleSetEndDate,
		handleSetPeriodDate: onChangeYear,
		handleSetFirstDropdownValue: handleSetSingleValue,
		handleSetSecondDropdownValue: handleSetSingleDateRangeValue,
		handleSetThirdDropdownValue: handleSetGrouping,
	} = useGenericReportContext();
	const hasOwnerPermission = getHasOwnerPermission(viewer.actualPersonId);
	const selectedSingleValue = firstDropdownValue || getGroupingOne(REPORT_TYPE.PROJECT_PORTFOLIO_REPORT);
	const modules = viewer.company.modules;
	const hasFinance = CompanySetupUtil.hasFinance();
	const hasBaseline = Util.hasBaselineModule(modules);
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const hasRetainerPeriodRead = Util.AuthorizeViewerAccess('project-periods');
	const hasPriorityLevels = viewer.company.priorityLevels.edges.length > 0;
	const defaultPeriodEyeOptions = getPeriodEyeOptions(
		hasFinance,
		hasBaseline,
		hasRevenueWithoutCostAccess,
		hasRetainerPeriodRead,
		hasPriorityLevels
	);
	const defaultMonthByMonthEyeOptions = getMonthByMonthEyeOptions(hasPriorityLevels);
	const defaultOptions = [...defaultPeriodEyeOptions, ...defaultMonthByMonthEyeOptions];

	const theEyeOptionsNestedTree = getInitialOptionsFromCheckedOptions(defaultOptions, theEyeOptionsChecked);

	const handlePresetDateRangeChange = selectedDateRange => {
		tracking.trackElementClicked('PPR dropdown date range changed', {
			dropdown_date_range_type: selectedDateRange.option,
		});
		trackEvent('PPR Dropdown Date Range', 'Changed', {
			dropdownDateRangeType: selectedDateRange.option,
		});
		if (selectedDateRange && selectedDateRange.option) {
			handleSetSingleDateRangeValue(selectedDateRange.option);
		}
		if (selectedDateRange && selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE) {
			handleSetStartDate(selectedDateRange.startDate);
			handleSetEndDate(selectedDateRange.endDate);
			setShowDatePicker(false);
		} else {
			setShowDatePicker(true);
			if (!startDate) {
				handleSetStartDate(selectedDateRange.startDate);
			}
			if (!endDate) {
				handleSetEndDate(selectedDateRange.endDate);
			}
		}
	};

	const selectedDates = {startDate, endDate};
	const handleChangeDates = (selectedStartDate, selectedEndDate) => {
		tracking.trackElementClicked('Date selected', {
			startDate: Util.GetYearMonthDateFromMomentDate(selectedStartDate),
			endDate: Util.GetYearMonthDateFromMomentDate(selectedEndDate),
			dateRange: selectedEndDate.diff(selectedStartDate, 'days') + 1,
		});
		trackEvent('Date', 'Selected');
		handleSetStartDate(selectedStartDate);
		handleSetEndDate(selectedEndDate);
	};

	const activePage = useActivePageFromRoute();
	const handleSetActivePage = newPath => {
		if (activePage !== newPath) {
			trackEvent(`${getPlainLabelFromActivePage(newPath)} Tab`, 'Selected');
			history.push(newPath);
		}
	};

	const groupBy = thirdDropdownValue || GROUP_BY.NO_GROUPING;

	useEffect(() => {
		if (!hasPermission(PERMISSION_TYPE.INSIGHTS_READ)) {
			// if user doesnt have access rights to view this page redirect to no access page
			history.push('/not-authorized');
		}
	}, []);

	useEffect(() => {
		tracking.trackPage('Project Portfolio Report_NEW', null, null, {
			'Tab name': activePage,
		});
	}, [pathname]);

	const selectedDateRange = {};
	if (activePage === PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path) {
		selectedDateRange.startDate = startDate;
		selectedDateRange.endDate = endDate;
	} else {
		selectedDateRange.startDate = moment.utc().year(selectedYear).startOf('year');
		selectedDateRange.endDate = moment.utc().year(selectedYear).endOf('year');
	}

	let csvDownloadFunction = null;
	const handleCsvDownload = () => {
		tracking.trackElementClicked('CSV download');
		trackCSVExport('Project Portfolio Report');
		csvDownloadFunction && csvDownloadFunction();
	};
	const setCsvDownloadFunction = downloadFunction => (csvDownloadFunction = downloadFunction);

	if (!viewer.company.isFinancialServiceAvailable) {
		// set to false if financial service is down
		createToast({
			duration: 120000,
			message: intl.formatMessage({id: 'project_budget.financial_service_down_toast'}),
			isDisplayingDuration: true,
			callback: () => {
				location.reload();
			},
		});
	}

	const activePagePlainLabel = useMemo(() => {
		return getPlainLabelFromActivePage(activePage);
	}, [activePage]);

	useTrackPage('Project Portfolio Report', {tabName: activePagePlainLabel});

	return (
		<PageWrapper>
			<ProjectPortfolioReportTitleHeaderBar
				getTopReportHeader={getTopReportHeader}
				reportName={reportName}
				hasOwnerPermission={hasOwnerPermission}
			/>

			{hasFinance && (
				<StandardMargin>
					<SubNavigation>
						{Object.keys(PROJECT_PORTFOLIO_REPORT_PAGES).map(page => (
							<SubNavigation.NavigationItem
								active={activePage === PROJECT_PORTFOLIO_REPORT_PAGES[page].path}
								onClick={() => handleSetActivePage(PROJECT_PORTFOLIO_REPORT_PAGES[page].path)}
								key={page}
								userpilot={'utilization-' + page.toLowerCase() + '-tab'}
							>
								{PROJECT_PORTFOLIO_REPORT_PAGES[page].getLabel(intl)}
							</SubNavigation.NavigationItem>
						))}
					</SubNavigation>
				</StandardMargin>
			)}

			<StandardMargin>
				<ProjectPortfolioReportHeaderBar
					getBottomReportHeader={getBottomReportHeader}
					handleSetSingleValue={handleSetSingleValue}
					selectedSingleValue={selectedSingleValue}
					handleSetGrouping={handleSetGrouping}
					groupBy={groupBy}
					handlePresetDateRangeChange={handlePresetDateRangeChange}
					initialPresetDateRange={selectedDateRangeValue}
					showDatePicker={showDatePicker}
					selectedDates={selectedDates}
					onChangeDates={handleChangeDates}
					selectedYear={selectedYear}
					onChangeYear={onChangeYear}
					handleCsvDownload={handleCsvDownload}
					activePage={activePage}
					viewer={viewer}
				/>
			</StandardMargin>

			<Switch>
				<Route path={`${url}`} exact>
					<Redirect to={`${url}/${PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path}`} />
				</Route>
				<Route
					path={`${url}/${PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path}`}
					exact
					render={() => (
						<ProjectPortfolioReportPeriodPage
							theEyeOptionsNestedTree={theEyeOptionsNestedTree}
							selectedDateRange={selectedDateRange}
							filterValue={filterValue}
							selectedSingleValue={selectedSingleValue}
							hasFinance={hasFinance}
							setCsvDownloadFunction={setCsvDownloadFunction}
							hasBaseline={hasBaseline}
							groupBy={groupBy}
							selectedDateRangeValue={selectedDateRangeValue}
						/>
					)}
				/>
				{hasFinance && (
					<Route
						path={`${url}/${PROJECT_PORTFOLIO_REPORT_PAGES.MONTH_BY_MONTH.path}`}
						exact
						render={() => (
							<ProjectPortfolioReportMonthByMonthPage
								theEyeOptionsNestedTree={theEyeOptionsNestedTree}
								selectedDateRange={selectedDateRange}
								filterValue={filterValue}
								selectedSingleValue={selectedSingleValue}
								hasFinance={hasFinance}
								setCsvDownloadFunction={setCsvDownloadFunction}
								hasBaseline={hasBaseline}
								groupBy={groupBy}
							/>
						)}
					/>
				)}
				{!hasFinance && <Redirect to={`${url}/${PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD.path}`} />}
			</Switch>
		</PageWrapper>
	);
};

const ProjectPortfolioReportQuery = graphql`
	query ProjectPortfolioReport_Query {
		viewer {
			actualPersonId
			component(name: "project_portfolio_report")
			...ProjectPortfolioReport_viewer
		}
	}
`;

export {ProjectPortfolioReportQuery};

export default createFragmentContainer(ProjectPortfolioReport, {
	viewer: graphql`
		fragment ProjectPortfolioReport_viewer on Viewer {
			id
			actualPersonId
			company {
				createdAt
				isFinancialServiceAvailable
				modules {
					moduleType
				}
				priorityLevels {
					edges {
						node {
							id
						}
					}
				}
			}
			...ProjectPortfolioReportTitleBar_viewer
			...ProjectPortfolioReportHeaderBar_viewer
		}
	`,
});
