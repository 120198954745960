import React, { useMemo, useState } from 'react';
import { Alert, FlexColumn, FlexRow, Modal, RadioGroupHandler, SubHeading, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExpenseBoolean } from '../ExpenseTypes';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
const ChangeExpenseApprovalModal = ({ expenseItems, closeModal }) => {
    const { formatMessage } = useIntl();
    const updateableItems = useMemo(() => expenseItems.filter(expenseItem => !expenseItem.locked), [expenseItems]);
    const [newApprovalStatus, setNewApprovalStatus] = useState('');
    const count = expenseItems.length;
    const lockedCount = expenseItems.length - updateableItems.length;
    const showAlert = lockedCount > 0;
    const allowSave = newApprovalStatus && count > lockedCount;
    const saveChanges = () => {
        const onSuccess = () => {
            closeModal();
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: updateableItems.map(expenseItem => expenseItem.id),
            approved: newApprovalStatus === ExpenseBoolean.TRUE,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.change_approval.title" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 's' },
                React.createElement(FlexRow, null,
                    React.createElement(SubHeading, null,
                        React.createElement(FormattedMessage, { id: 'expense_management.approval_status' }))),
                React.createElement(FlexRow, null,
                    React.createElement(Text, { color: 'medium' },
                        React.createElement(FormattedMessage, { id: 'expense_management.approval_status_long' })))),
            React.createElement(FlexColumn, { gap: 'xl' },
                React.createElement(RadioGroupHandler, { onValueChange: setNewApprovalStatus, value: newApprovalStatus },
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.TRUE }),
                        React.createElement(Text, null, formatMessage({ id: 'expense_management.change_approval_status.option_1' }))),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.FALSE }),
                        React.createElement(Text, null, formatMessage({ id: 'expense_management.change_approval_status.option_2' })))),
                showAlert && (React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_approval_status.locked_alert' }, { count, lockedCount }), type: Alert.TYPE.INFORMATION })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onClick: saveChanges, disabled: !allowSave, dataCy: 'confirm' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onClick: closeModal, dataCy: 'cancel' },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ChangeExpenseApprovalModal;
