import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import Util from '../../forecast-app/shared/util/util';
import {INITIALS_SIZE} from '../../constants';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import styled from 'styled-components';
import StatusWrapper, {convertStatusColorToRagStatus} from '../../forecast-app/shared/components/status/StatusWrapper';
import {profilePicSrc, projectUrl} from '../../directApi';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

const ProjectStatusWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;

function idToText(project) {
	const showCustomId = hasFeatureFlag('custom_project_ids');
	if (showCustomId && project.customProjectId) {
		return project.customProjectId;
	}
	return 'P' + project.companyProjectId;
}

class OverviewProjectsProjectCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFullDescription: false,
		};
	}
	getLinkPath(project) {
		let lastViewedPage = localStorage.getItem('project-section-last-viewed') || 'workflow';
		if (lastViewedPage === 'sprints' && !project.node.sprintTimeBox) {
			lastViewedPage = 'workflow';
		}
		return projectUrl(project.node.companyProjectId, project.node.customProjectId) + '/' + lastViewedPage;
	}

	hideDescription(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.id === 'full-desc-read-more-button') return;
		e.preventDefault();
		if (this.state.showFullDescription) {
			this.setState({showFullDescription: false});
		}
	}
	openDescription(e) {
		e.preventDefault();
		this.setState({showFullDescription: !this.state.showFullDescription});
	}

	getFullDescriptionPopUpLeft() {
		if (this.read_more_button) {
			let x = this.read_more_button.getBoundingClientRect().x + 120;
			this.showArrowOnRight = x + 350 > window.innerWidth;
			if (this.showArrowOnRight) {
				x -= 520;
			}
			return x + 'px';
		} else {
			return '50%';
		}
	}

	render() {
		const {project, cardLayoutDisplay} = this.props;
		const calculatedData = this.props.calculatedDataMap.get(project.node.id);
		const completion = calculatedData ? calculatedData.completion : 0;
		let showID, showStatus, showStatusV2, showDates, showClient, showContact, showDescription;
		cardLayoutDisplay.forEach(el => {
			if (el.name === 'id') {
				showID = el.checked;
			} else if (el.name === 'status') {
				showStatus = el.checked;
			} else if (el.name === 'statusV2') {
				showStatusV2 = el.checked;
			} else if (el.name === 'dates') {
				showDates = el.checked;
			} else if (el.name === 'client') {
				showClient = el.checked;
			} else if (el.name === 'contact') {
				showContact = el.checked;
			} else if (el.name === 'description') {
				showDescription = el.checked;
			}
		});

		const hasWhiteColor = project.node.projectColor === '#ffffff';
		const projectStartDate = Util.CreateNonUtcMomentDate(
			project.node.projectStartYear,
			project.node.projectStartMonth,
			project.node.projectStartDay
		);
		const projectEndDate = Util.CreateNonUtcMomentDate(
			project.node.projectEndYear,
			project.node.projectEndMonth,
			project.node.projectEndDay
		);
		const projectDescriptionPlainText = project.node.description
			? Util.ConvertDraftJsToPlainText(project.node.description)
			: null;
		return (
			<div className="project-card-outer-wrapper">
				{this.state.showFullDescription ? (
					<div
						className="full-description-pop-up"
						style={{
							top: this.read_more_button ? this.read_more_button.getBoundingClientRect().y - 50 + 'px' : '50%',
							left: this.getFullDescriptionPopUpLeft(),
						}}
					>
						<div className={'triangle-arrow' + (this.showArrowOnRight ? ' right' : ' left')} />
						<button
							autoFocus
							className="full-description-close-button"
							onBlur={this.hideDescription.bind(this)}
							onClick={this.hideDescription.bind(this)}
						/>
						<div className="full-description-title">
							{this.props.intl.formatMessage({id: 'projects_overview.full_description_title'})}
						</div>
						<div className="full-description-body">
							<Scrollbars>{projectDescriptionPlainText}</Scrollbars>
						</div>
					</div>
				) : null}
				<Link key={project.node.id} className={'react-router-link v2'} to={this.getLinkPath(project)}>
					<div
						data-cy={project.node.companyProjectId}
						data-cy-class="project"
						className={
							'project-card-v2' +
							(hasWhiteColor ? ' white-project-color' : '') +
							(this.props.includeWrapper ? ' extra-margin-v2' : '')
						}
						style={{
							backgroundColor: project.node.projectColor,
							color: Util.getTextColorV2(project.node.projectColor),
							minHeight: this.props.projectCardHeight.heightTotal,
						}}
					>
						<div className="card-header">
							<div className="status-id-wrapper">
								{project.node.currentProjectStatus && showStatus ? (
									<div
										className="project-status"
										title={this.props.intl.formatMessage(
											{id: 'projects_overview.project_status_info'},
											{
												color: Util.getProjectStatusColorName(project.node.currentProjectStatus.color),
												person:
													project.node.currentProjectStatus.person.firstName +
													' ' +
													project.node.currentProjectStatus.person.lastName,
											}
										)}
										style={{backgroundColor: project.node.currentProjectStatus.color}}
									/>
								) : null}
								{showID ? <div className="project-id">{idToText(project.node)}</div> : null}
							</div>
							{showDates && (project.node.projectStartYear || project.node.projectEndYear) ? (
								<div className="project-dates">
									{projectStartDate ? (
										<div className="date">
											<span className="day-month">{projectStartDate.format('D. MMM')}</span>
										</div>
									) : null}
									<div className="space">{' - '}</div>
									{projectEndDate ? (
										<div className="date">
											<span className="day-month">{projectEndDate.format('D. MMM')}</span>
										</div>
									) : null}
								</div>
							) : null}
						</div>
						<div className="card-body">
							<div
								className="project-name"
								title={project.node.name ? project.node.name : null}
								style={{
									minHeight:
										20 +
										(this.props.projectCardHeight && this.props.projectCardHeight.projectNameHeightMax
											? this.props.projectCardHeight.projectNameHeightMax
											: 0),
								}}
							>
								{project.node.name}
							</div>
							{showClient ? (
								<div
									className="project-client"
									style={{
										minHeight:
											this.props.projectCardHeight && this.props.projectCardHeight.projectClientHeightMax
												? this.props.projectCardHeight.projectClientHeightMax - 8
												: 0,
									}}
								>
									{project.node.client ? <span>{project.node.client.name}</span> : null}
								</div>
							) : null}
							{showContact ? (
								<div
									className="project-contacts"
									style={{
										minHeight:
											this.props.projectCardHeight && this.props.projectCardHeight.projectContactHeightMax
												? this.props.projectCardHeight.projectContactHeightMax - 16
												: 0,
									}}
								>
									<FormattedMessage id={'common.project_owner'} />
									{project.node.projectPersons && project.node.projectPersons.edges.length !== 0 ? (
										<div className="imgs-wrapper">
											{project.node.projectPersons.edges
												.filter(pp => pp.node.isContactPerson)
												.map((pp, index) =>
													pp.node.person.profilePictureId ? (
														<img
															crossOrigin="use-credentials"
															className={index === 0 ? 'first' : ''}
															key={index}
															alt=""
															src={profilePicSrc(pp.node.person.profilePictureId)}
															height="28"
															width="28"
														/>
													) : (
														<PersonInitials
															key={index}
															initials={pp.node.person.initials}
															size={INITIALS_SIZE.MEDIUM}
														/>
													)
												)}
										</div>
									) : (
										<span className="space">{'-'}</span>
									)}
								</div>
							) : null}
							{showDescription ? (
								<div
									className="project-description"
									style={{
										minHeight:
											this.props.projectCardHeight && this.props.projectCardHeight.descriptionHeightMax
												? this.props.projectCardHeight.descriptionHeightMax - 16
												: 0,
									}}
								>
									<FormattedMessage id="common.description" />
									{projectDescriptionPlainText ? (
										<span className="description-text-wrapper">
											{projectDescriptionPlainText.length > 76
												? projectDescriptionPlainText.substring(0, 75) + '...'
												: projectDescriptionPlainText}
											{projectDescriptionPlainText.length > 76 ? (
												<button
													id="full-desc-read-more-button"
													className="read-more-button"
													onClick={this.openDescription.bind(this)}
													ref={button => (this.read_more_button = button)}
												>
													{this.props.intl.formatMessage({id: 'common.read_more'})}
												</button>
											) : null}
										</span>
									) : (
										<span className="space">{'-'}</span>
									)}
								</div>
							) : null}
							<ProjectStatusWrapper>
								<div className="project-progress">
									<FormattedMessage id="common.progress" />
									<span className="progress-value">{completion}%</span>
								</div>
								{showStatusV2 ? (
									<StatusWrapper
										projectId={project.node.id}
										currentStatusRag={convertStatusColorToRagStatus(
											project.node.currentProjectStatus?.color
										)}
										noHeader
									/>
								) : null}
							</ProjectStatusWrapper>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}
OverviewProjectsProjectCard.propTypes = {
	project: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	includeWrapper: PropTypes.bool,
};
export default injectIntl(OverviewProjectsProjectCard);
