import React from 'react';
import {useIntl} from 'react-intl';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {createFragmentContainer, graphql} from 'react-relay';
import {useLocation} from 'react-router-dom';

const ProjectPortfolioReportTitleBar = ({getTopReportHeader, reportName, hasOwnerPermission}) => {
	const intl = useIntl();
	const {pathname} = useLocation();

	const getActivePage = () => {
		const pathIndex = pathname.lastIndexOf('/');
		return pathname.substr(pathIndex + 1);
	};

	const content = [];
	const reportNameProps = {
		defaultTitle:
			intl.formatMessage({id: 'financial_portfolio_report.title'}) + ', ' + intl.formatMessage({id: 'common.untitled'}),
		placeholder: intl.formatMessage({id: 'time_reg_report.report_name'}),
	};
	if (hasOwnerPermission) {
		content.push(...getTopReportHeader(reportNameProps));
	}

	const onboardingFlows = [
		{
			id: 'Project Portfolio Report - Overview',
			title: 'Overview',
			description: null,
			contentId: '1620740900sPtu6921',
		},
	];
	if (getActivePage() === 'period') {
		onboardingFlows.push({
			id: 'Project Portfolio Report - Period',
			title: 'Period View',
			description: null,
			contentId: '1620742493sCgb07',
		});
	}
	if (getActivePage() === 'month-by-month') {
		onboardingFlows.push({
			id: 'Project Portfolio Report Month by Month',
			title: 'Month by Month View',
			description: null,
			contentId: '1620742489yZfc6513',
		});
	}
	content.push({
		id: 'onboarding-component',
		type: TopHeaderBar.TYPE.ONBOARDING,
		title: 'Project Portfolio Report',
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/articles/5143442299281',
	});

	return (
		<TopHeaderBarWrapper>
			<TopHeaderBar title={hasOwnerPermission ? null : reportName} content={content} />
		</TopHeaderBarWrapper>
	);
};

export default createFragmentContainer(ProjectPortfolioReportTitleBar, {
	viewer: graphql`
		fragment ProjectPortfolioReportTitleBar_viewer on Viewer {
			email
			actualPersonId
		}
	`,
});
