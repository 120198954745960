import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {createRefetchContainer, graphql} from 'react-relay';

import {FlexRow, OutlineBox, PageWrapper, Section} from './InvoicingPage.styled';
import {StatBox, StatsContainer, StatTitle, StatValue} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import * as tracking from '../../../tracking';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/dropdown_section';
import Util from '../../../forecast-app/shared/util/util';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FormattedMessage, useIntl} from 'react-intl';
import {getInitialOptions, handleChangedOptions} from './invoicing_eye_options';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import {hideLoader, showLoader} from '../../../containers/global_loader';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {InvoicesTable} from './InvoicesTable';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import DeleteInvoiceMutation from '../../../mutations/delete_invoice_mutation';
import UnlinkInvoiceMutation from '../../../mutations/UnlinkInvoiceMutation';
import {useDataExport} from '../../../forecast-app/shared/hooks/useDataExport';
import InvoiceFormatter from '../../../forecast-app/shared/util/export-formatters/InvoiceFormatter';
import DirectApi from '../../../directApi';
import {trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';

const InvoicesPage = ({viewer, relay: {refetch}, startDate, endDate, dateRangeOption, setDate, onInvoiceCreation}) => {
	const STANDARD_MARGIN = 24;
	const LOCALSTORAGE_FILTERS_KEY = 'invoicing-invoices-filter';
	const LOCALSTORAGE_DATE_RANGE_KEY = 'invoices_date_range';
	const intl = useIntl();
	const history = useHistory();
	const [showDatePicker, setShowDatePicker] = useState(true);

	const hasIntegrations =
		viewer.company.newXeroEnabled ||
		viewer.company.quickbooksEnabled ||
		viewer.company.economicEnabled ||
		viewer.company.sageIntacctConnectionEnabled;
	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions('invoices', hasIntegrations));
	const filters = localStorage.getItem(LOCALSTORAGE_FILTERS_KEY)
		? JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY))
		: '';
	const [filterFunctions, setFilterFunctions] = useState(getFilterFunctions(filters));
	const [searchFilterValue, setSearchFilterValue] = useState('');

	const [contextMenuData, setContextMenuData] = useState(null);
	const [xeroTenantToShortCode, setXeroTenantToShortCode] = useState(null);

	const exportData = useDataExport(
		new InvoiceFormatter(intl, [], [], true),
		null,
		data => data,
		_ => `invoices_${startDate.format('D-MMM-YYYY')}_${endDate.format('D-MMM-YYYY')}`
	);

	let invoices = viewer.invoicesV2.edges;

	const filteredInvoices = invoices.map(edge => edge.node).filter(filterFunctions.invoiceFilter);
	const searchQueryFilteredInvoices = useMemo(() => {
		return filteredInvoices.filter(invoice => {
			// filter by search criteria
			const nameMatch = invoice.name.toLowerCase().includes(searchFilterValue.toLowerCase());
			const clientMatch = invoice.client && invoice.client.name.toLowerCase().includes(searchFilterValue.toLowerCase());
			return nameMatch || clientMatch;
		});
	}, [filteredInvoices, searchFilterValue]);

	const invoiceTableData = searchQueryFilteredInvoices.map((invoice, index) => {
		const isExported = !!(invoice.quickbooksId || invoice.xeroId || invoice.economicId || invoice.sageIntacctId);
		const {
			xeroId,
			xeroTenantId,
			quickbooksId,
			economicId,
			sageIntacctId,
			id,
			currency,
			status,
			externalStatus,
			paymentStatus,
			createdYear,
			createdMonth,
			createdDay,
			dueYear,
			dueMonth,
			dueDay,
			name,
			companyInvoiceId,
			invoicedWithoutTax,
			paidWithoutTax,
			client,
			projects,
			invoiceReference,
			billTo,
		} = invoice;
		return {
			name,
			id,
			currency,
			companyInvoiceId,
			invoicedWithoutTax,
			paidWithoutTax,
			client,
			billTo,
			invoiceDate: Util.CreateMomentDate(createdYear, createdMonth, createdDay),
			dueDate: Util.CreateMomentDate(dueYear, dueMonth, dueDay),
			projects,
			status,
			externalStatus,
			paymentStatus,
			xeroId,
			xeroTenantId,
			quickbooksId,
			sageIntacctId,
			economicId,
			quickbooksRealmId: viewer.company.quickbooksRealmId,
			isExported,
			invoiceReference,
		};
	});

	const tableColumns = {
		invoiceId: true,
		invoiceName: true,
		client: theEyeOptions.find(eo => eo.name === 'client').checked,
		projectIds: theEyeOptions.find(eo => eo.name === 'projects').checked,
		projectNames: theEyeOptions.find(eo => eo.name === 'projects').checked,
		status: theEyeOptions.find(eo => eo.name === 'status').checked,
		paymentStatus: theEyeOptions.find(eo => eo.name === 'status').checked && !hasIntegrations,
		reference: theEyeOptions.find(eo => eo.name === 'reference').checked,
		date: theEyeOptions.find(eo => eo.name === 'invoiceDate').checked,
		invoicedAmount: theEyeOptions.find(eo => eo.name === 'total_invoiced').checked,
		paidAmount: theEyeOptions.find(eo => eo.name === 'paid').checked,
		unpaidAmount: theEyeOptions.find(eo => eo.name === 'unpaid').checked,
		dueDate: theEyeOptions.find(eo => eo.name === 'dueDate').checked,
		integrationLink: hasIntegrations,
		hasContextMenu: true,
	};
	if (theEyeOptions.find(eo => eo.name === 'status').checked && viewer.company.newXeroEnabled) {
		tableColumns.xeroStatus = true;
	} else if (theEyeOptions.find(eo => eo.name === 'status').checked && viewer.company.quickbooksEnabled) {
		tableColumns.quickbooksStatus = true;
	} else if (theEyeOptions.find(eo => eo.name === 'status').checked && viewer.company.economicEnabled) {
		tableColumns.economicStatus = true;
	}

	const getHeaderTitle = () => {
		const content = [];

		const onboardingFlows = [
			{
				id: 'invoicing-all-invoices-intro',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681731478yBtt9254',
			},
			{
				id: 'invoicing-all-invoices-view-edit',
				title: 'How to view and edit an invoice',
				description: null,
				contentId: '1681733079vIyc2515',
			},
		];

		let pageTitle = 'All Invoices';

		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: `Learn to use ${pageTitle}`,
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/categories/4418778811281-Financial-Management',
			subLink:
				'https://support.forecast.app/hc/en-us/articles/4838003063185-Creating-invoices-for-your-customers-Pro-and-Plus-only-',
		};

		content.push(onboardingComponent);

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={pageTitle} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	const getHeader = () => {
		let leftContent = [],
			rightContent = [];

		const handleEyeOptionsSelect = (_, __, ___, newOptions) => {
			setTheEyeOptions(handleChangedOptions(newOptions, 'invoices'));
		};

		const handleCsvDownloadPress = () => {
			tracking.trackEvent('download invoices');
			trackCSVExport('Invoices');

			const visibleColumns = Object.keys(tableColumns)
				.filter(columnName => tableColumns[columnName] && columnName !== 'hasContextMenu')
				.map(name => {
					return {
						name: name,
						checked: true,
					};
				});
			visibleColumns.push({name: 'currency', checked: true});

			exportData(visibleColumns, searchQueryFilteredInvoices, null, null, 'CSV');
		};

		const onDateRangeChange = range => {
			setDate({
				startDate: range.startDate,
				endDate: range.endDate,
				option: PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
			});

			Util.localStorageSetItem(LOCALSTORAGE_DATE_RANGE_KEY, JSON.stringify(range));
		};

		const onPresetDateRangeChange = selectedDateRange => {
			if (selectedDateRange == null) {
				return;
			}

			trackEvent('Dropdown Date Range', 'Changed', {
				dropdownDateRangeType: selectedDateRange.option,
			});

			let selectedDateRangeModified = selectedDateRange;

			// If it's ALL TIME, set the dates to 50 years in the past and future, as we cannot pass
			// null.
			if (selectedDateRange.option === PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
				const startDate = moment().subtract(50, 'year');
				const endDate = moment().add(50, 'year');
				selectedDateRangeModified = {option: selectedDateRange.option, startDate: startDate, endDate: endDate};
			}

			const newDate = {startDate: startDate, endDate: endDate, option: selectedDateRangeModified.option};
			if (selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE) {
				newDate.startDate = selectedDateRangeModified.startDate;
				newDate.endDate = selectedDateRangeModified.endDate;
			} else if (dateRangeOption === PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
				newDate.startDate = moment().local().subtract(1, 'month').startOf('month');
				newDate.endDate = moment().local().subtract(1, 'month').endOf('month');
			}
			setDate(newDate);

			if (selectedDateRange && selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
				setShowDatePicker(true);
			} else {
				setShowDatePicker(false);
			}

			Util.localStorageSetItem(LOCALSTORAGE_DATE_RANGE_KEY, JSON.stringify(newDate));
		};

		const onSearchChange = value => {
			setSearchFilterValue(value);
		};

		const onSyncButtonPress = () => {
			showLoader();
			tracking.trackEvent('Synchronize invoices');
			trackEvent('Invoices', 'Synchronized');

			if (viewer.company.newXeroEnabled) {
				DirectApi.Fetch('xero/sync')
					.then(json => {
						window.location.reload();
						hideLoader();
					})
					.catch(error => {
						if (hasFeatureFlag('use_custom_error_boundary')) {
							throw error;
						} else {
							hideLoader();
						}
					});
			} else if (viewer.company.sageIntacctConnectionEnabled) {
				try {
					DirectApi.Fetch(`sageIntacct/sync`).then(json => {
						window.location.reload();
						hideLoader();
					});
				} catch (error) {
					if (hasFeatureFlag('use_custom_error_boundary')) {
						throw error;
					} else {
						hideLoader();
					}
				}
			} else if (viewer.company.quickbooksEnabled) {
				try {
					DirectApi.Fetch(`quickbooks/sync`).then(json => {
						window.location.reload();
						hideLoader();
					});
				} catch (error) {
					if (hasFeatureFlag('use_custom_error_boundary')) {
						throw error;
					} else {
						hideLoader();
					}
				}
			} else if (viewer.company.economicEnabled) {
				try {
					DirectApi.Fetch(`economic/sync`).then(json => {
						window.location.reload();
						hideLoader();
					});
				} catch (error) {
					if (hasFeatureFlag('use_custom_error_boundary')) {
						throw error;
					} else {
						hideLoader();
					}
				}
			}
		};

		const onNewInvoiceButtonPress = () => {
			tracking.trackEvent('Create new invoice modal');
			trackEvent('New Invoice Modal', 'Shown');
			showModal({
				type: MODAL_TYPE.CREATE_INVOICE_V2_STANDALONE,
				refetch,
				startDate: moment().subtract(2, 'month'),
				endDate: moment(),
				onInvoiceCreation: onInvoiceCreation,
				isProjectInvoicing: false,
			});
		};

		leftContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			onSelect: handleEyeOptionsSelect,
			openRight: true,
			userpilot: 'eye-selector',
			cy: 'eye-selector',
		});

		leftContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownloadPress,
			userpilot: 'csv-button',
			cy: 'csv-button',
		});

		if (showDatePicker) {
			leftContent.push({
				type: ELEMENT_TYPE.DATE_RANGE_PICKER_WITH_QUICK_RANGES,
				defaultRangeItem: {startDate: startDate, endDate: endDate},
				onRangeItemChange: onDateRangeChange,
				clearable: false,
				userpilot: 'date-range-picker',
				calendarOffsetX: 10,
			});
		}

		leftContent.push({
			type: ELEMENT_TYPE.PRESET_DATE_RANGE_PICKER,
			handleDateRangeChange: onPresetDateRangeChange,
			initialPresetDateRange: dateRangeOption,
			options: [
				PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME,
				PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH,
				PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH,
				PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH,
				PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR,
				PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR,
				PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE,
				PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
			],
			userpilot: 'invoicing-invoices-single-value-dropdown',
			presetDateRange: dateRangeOption,
		});

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			placeholder: intl.formatMessage({id: 'invoicing.search_invoice_name'}),
			value: searchFilterValue || '',
			onChange: onSearchChange,
			userpilot: 'search-button',
			cy: 'search-button',
		});

		if (
			viewer.company.newXeroEnabled ||
			viewer.company.quickbooksEnabled ||
			viewer.company.economicEnabled ||
			viewer.company.sageIntacctConnectionEnabled
		) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: intl.formatMessage({id: 'common.synchronize'}),
				callback: onSyncButtonPress,
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
				dataCy: 'synchronize-button',
			});
		}

		const onFilterChange = (_, filterFunctions) => {
			setFilterFunctions(filterFunctions);
		};

		const invoiceFilters = [
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.INVOICE_STATUS,
			...(hasIntegrations ? [] : [FILTER_TYPE.PAYMENT_STATUS]),
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.CONTACT,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.DUE_DATE,
		];
		if (hasFeatureFlag('invoicing_pathways')) {
			invoiceFilters.push(FILTER_TYPE.BILL_FROM);
			invoiceFilters.push(FILTER_TYPE.BILL_TO);
		}
		if (viewer.company.quickbooksEnabled) {
			invoiceFilters.push(FILTER_TYPE.QBO_STATUS);
		}
		if (viewer.company.newXeroEnabled) {
			invoiceFilters.push(FILTER_TYPE.XERO_STATUS);
		}
		if (viewer.company.economicEnabled) {
			invoiceFilters.push(FILTER_TYPE.ECONOMIC_STATUS);
		}

		if (viewer.company.sageIntacctConnectionEnabled) {
			invoiceFilters.push(FILTER_TYPE.SAGE_INTACCT_STATUS);
		}

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.INVOICES,
			invoiceFilters,
			filterSection: FILTER_SECTION.INVOICING_INVOICES,
			appliedFiltersName: `invoicing-invoices-filter`,
			viewer,
			onFiltersChange: onFilterChange,
		});

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: intl.formatMessage({id: 'invoicing.new_invoice'}),
			callback: onNewInvoiceButtonPress,
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			userpilot: 'new-invoice-button',
			dataCy: 'new-invoice-button',
		});

		return buildHeaderBar(leftContent, rightContent, {parentGroup: null});
	};

	const onEditInvoicePress = companyInvoiceId => {
		tracking.trackEvent('Edit invoice option pressed');
		trackEvent('Edit Invoice', 'Clicked');
		history.push(`invoices/I-${companyInvoiceId}`);
	};

	const deleteInvoice = invoiceId => {
		const onSuccess = () => {
			// update state
			refetch();

			createToast({duration: 5000, message: intl.formatMessage({id: 'invoice.invoice_deleted'})});
		};

		Util.CommitMutation(DeleteInvoiceMutation, {id: invoiceId}, onSuccess);
	};

	const unlinkInvoice = invoiceId => {
		const onSuccess = () => {
			// update state
			refetch();

			createToast({duration: 5000, message: intl.formatMessage({id: 'invoice.invoice_unlinked'})});
		};

		const callback = () => {
			Util.CommitMutation(UnlinkInvoiceMutation, {id: invoiceId}, onSuccess);
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'invoice.confirm_unlink_invoice',
			warningInformation: [<FormattedMessage id="invoice.unlink_warning" />],
			irreversible: true,
			buttons: [
				{
					text: <FormattedMessage id="common.cancel" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: <FormattedMessage id="common.unlink" />,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callback,
				},
			],
		});
	};

	const getContextMenuOptions = (invoiceId, companyInvoiceId) => {
		let options = [
			{
				text: intl.formatMessage({id: 'common.edit'}),
				onClick: () => onEditInvoicePress(companyInvoiceId),
			},
		];

		const invoice = viewer.invoicesV2.edges.find(invoice => invoice.node.id === invoiceId);
		options.push({
			text: intl.formatMessage({id: 'common.delete'}),
			onClick: () => deleteInvoice(invoiceId),
			disabled:
				invoice.node.status !== 'DRAFT' || invoice.node.xeroId || invoice.node.quickbooksId || invoice.node.economicId,
			cy: 'delete-invoice',
		});

		if (invoice.node.xeroId || invoice.node.quickbooksId || invoice.node.economicId || invoice.node.sageIntacctId) {
			options.push({
				text: intl.formatMessage({id: 'invoice.unlink_invoice'}),
				disabled: !viewer.company.integrations.userCanUnlinkInvoice,
				disabledDescription: 'Unlinking of invoices is not allowed. Contact an administrator',
				onClick: () => unlinkInvoice(invoiceId),
			});
		}

		return options;
	};

	const handleOpenContextMenu = (e, invoiceId, companyInvoiceId) => {
		e.preventDefault();
		const newContextMenuData = {
			invoiceId,
			companyInvoiceId,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 75;
			newContextMenuData.posY = btnBounds.y + 25;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const handleCloseContextMenu = () => {
		setContextMenuData(null);
	};

	const totalInvoiced = filteredInvoices.reduce(
		(invoicesTotal, invoice) => invoicesTotal + invoice.invoicedWithoutTaxBaseCurrency,
		0
	);
	const totalPaid = filteredInvoices.reduce(
		(invoicesPaidTotal, invoice) => invoicesPaidTotal + invoice.paidWithoutTaxBaseCurrency,
		0
	);
	const totalUnpaid = totalInvoiced - totalPaid;

	useEffect(() => {
		if (viewer.company.newXeroEnabled && xeroTenantToShortCode == null) {
			DirectApi.Fetch('xero/shortCodes').then(data => {
				setXeroTenantToShortCode(data);
			});
		}
	});

	useTrackPage('Invoicing Invoices');

	return (
		<PageWrapper data-cy={'all-invoices-page'}>
			{getHeaderTitle()}
			{getHeader()}
			{!!contextMenuData && (
				<div style={{zIndex: 100}}>
					<ContextMenu
						options={getContextMenuOptions(contextMenuData.invoiceId, contextMenuData.companyInvoiceId)}
						contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
						closeContextMenu={handleCloseContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				</div>
			)}
			<CustomScrollDiv>
				<Section sticky marginLeft={STANDARD_MARGIN} marginRight={STANDARD_MARGIN} data-userpilot={'invoices-totals'}>
					<StatsContainer growable columns={3} data-userpilot={'invoices-totals'}>
						<StatBox>
							<StatTitle>{intl.formatMessage({id: 'common.invoiced'})}</StatTitle>
							<StatValue data-cy={'total-invoiced'}>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(viewer.company.currency),
									totalInvoiced,
									intl
								)}
							</StatValue>
						</StatBox>
						<StatBox>
							<StatTitle>{intl.formatMessage({id: 'settings_subscription.paid'})}</StatTitle>
							<StatValue data-cy={'total-paid'}>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(viewer.company.currency),
									totalPaid,
									intl
								)}
							</StatValue>
						</StatBox>
						<StatBox>
							<StatTitle>{intl.formatMessage({id: 'settings_subscription.unpaid'})}</StatTitle>
							<StatValue data-cy={'total-unpaid'}>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(viewer.company.currency),
									totalUnpaid,
									intl
								)}
							</StatValue>
						</StatBox>
					</StatsContainer>
				</Section>
				<Section marginBottom={55} data-userpilot={'invoices-table'}>
					<FlexRow growable>
						<OutlineBox
							growable
							marginRight={STANDARD_MARGIN}
							marginLeft={STANDARD_MARGIN}
							marginBottom={STANDARD_MARGIN}
						>
							<InvoicesTable
								data={invoiceTableData}
								totals={{totalInvoiced, totalPaid, totalUnpaid}}
								theEyeOptions={theEyeOptions}
								companyCurrency={viewer.company.currency}
								handleOpenContextMenu={handleOpenContextMenu}
								onEditInvoice={onEditInvoicePress}
								integrationSettings={{xeroTenantToShortCode}}
								hasIntegrations={hasIntegrations}
								isXeroUsed={viewer.company.newXeroEnabled}
								isEconomicUsed={viewer.company.economicEnabled}
								isQuickbooksUsed={viewer.company.quickbooksEnabled}
								isSageIntacctUsed={viewer.company.sageIntacctConnectionEnabled}
								company={viewer.company}
							/>
						</OutlineBox>
					</FlexRow>
				</Section>
			</CustomScrollDiv>
		</PageWrapper>
	);
};

const invoicesPageQuery = graphql`
	query InvoicesPageQuery($startDate: String, $endDate: String) {
		viewer {
			actualPersonId
			component(name: "invoicing_list_page")
			...InvoicesPage_viewer @arguments(startDate: $startDate, endDate: $endDate)
		}
	}
`;

export {invoicesPageQuery};

export default createRefetchContainer(
	InvoicesPage,
	{
		viewer: graphql`
			fragment InvoicesPage_viewer on Viewer
			@argumentDefinitions(startDate: {type: "String"}, endDate: {type: "String"}) {
				id
				filters(first: 1000000, filterSection: INVOICING_INVOICES) @connection(key: "Viewer_filters", filters: []) {
					edges {
						node {
							id
							name
							section
							value
						}
					}
				}
				company {
					currency
					newXeroEnabled
					quickbooksEnabled
					quickbooksRealmId
					economicEnabled
					newXeroEnabled
					xeroShortCode
					sageIntacctConnectionEnabled
					clients {
						edges {
							node {
								id
								name
								xeroContactId
								economicCustomerId
								quickbooksCustomerId
								sageIntacctCustomerId
							}
						}
					}
					integrations {
						userCanUnlinkInvoice
					}
					allPersons(first: 1000000, onlyActive: true, excludeClientUsers: true) {
						edges {
							node {
								id
								firstName
								lastName
							}
						}
					}
					labels(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
				invoicesV2(first: 100000, startDate: $startDate, endDate: $endDate) @connection(key: "Viewer_invoicesV2") {
					edges {
						node {
							id
							companyInvoiceId
							name
							status
							externalStatus
							paymentStatus
							currency
							hasEntriesWithNoProject
							invoicedWithoutTax
							invoicedWithoutTaxBaseCurrency
							paidWithoutTax
							paidWithoutTaxBaseCurrency
							createdYear
							createdMonth
							createdDay
							dueYear
							dueMonth
							dueDay
							xeroId
							xeroTenantId
							quickbooksId
							economicId
							sageIntacctId
							invoiceReference
							billTo {
								id
								name
								billFrom {
									id
									name
									integration
								}
							}
							client {
								id
								name
							}
							projects {
								id
								companyProjectId
								customProjectId
								name
								projectColor
								projectPersons(first: 100000, excludeClientUsers: true) {
									edges {
										node {
											id
											isContactPerson
											person {
												id
											}
										}
									}
								}
								projectLabels(first: 10000) {
									edges {
										node {
											id
											label {
												id
											}
										}
									}
								}
								...DeprecatedProjectIndicatorJS_project
							}
						}
					}
				}
			}
		`,
	},
	graphql`
		query InvoicesPageRefetchQuery($startDate: String, $endDate: String) {
			viewer {
				component(name: "invoicing_list_page")
				...InvoicesPage_viewer @arguments(startDate: $startDate, endDate: $endDate)
			}
		}
	`
);
