import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';

import {ExpenseCategoryRow} from './settings_finance_expense_category_row';
import {NewExpenseCategory} from './settings_finance_new_expense_category';

export const ExpenseCategoriesStyle = styled.div`
	padding-bottom: 16px;

	table {
		tbody {
			tr {
				td {
					&.column-name,
					&.column-projects {
						cursor: pointer;
					}
				}
			}
		}

		.column-name {
			padding-left: 16px;
		}

		.column-actions {
			width: 1%;
			text-align: right;
		}
	}
`;

export const ExpenseCategories = ({company, projects, expenseCategories}) => {
	return (
		<ExpenseCategoriesStyle data-userpilot={'expense-categories-section'}>
			<p className="description">
				<FormattedMessage id="settings_finance.expense_category_description" />
			</p>
			<table>
				<thead>
					<tr>
						<th className="column-name">
							<FormattedMessage id="common.name" />
						</th>
						<th className="column-actions" />
					</tr>
				</thead>

				<tbody data-userpilot={'expense-categories-table'}>
					{expenseCategories
						.sort((a, b) => Util.sortAlphabetically(a.name, b.name))
						.map((expenseCategory, index) => {
							return (
								<tr key={index}>
									<ExpenseCategoryRow
										expenseCategory={expenseCategory}
										company={company}
										isFirstRow={index === 0}
									/>
								</tr>
							);
						})}
				</tbody>
			</table>
			<NewExpenseCategory companyId={company.id} />
		</ExpenseCategoriesStyle>
	);
};
