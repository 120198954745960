import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {BasicTextInput} from 'web-components';
import styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';
import CreateExpenseCategoryMutation from '../../mutations/create_expense_category_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import AddButton from '../add_button';

const NewExpenseCategoryStyle = styled.div`
	margin-top: 10px;
	padding-left: 16px;
	display: flex;
	flex-direction: column;

	.input-row {
		display: flex;
		flex-direction: row;
	}

	.error-message {
		color: #d0021b;
		font-size: 13px;
		padding-left: 8px;
		padding-top: 4px;
	}

	.button {
		margin-left: 8px;

		.add-button-component {
			width: 30px;
			height: 30px;
		}
	}
`;

export const NewExpenseCategory = injectIntl(({intl: {formatMessage}, companyId}) => {
	const [name, setName] = useState('');
	const [error, setError] = useState(null);

	const handleSetName = name => {
		setName(name);
		if (name.length > 191) {
			setError(formatMessage({id: 'settings_finance.name_limit_191'}));
		} else {
			setError(null);
		}
	};

	const canCreate = name && !error;

	const createExpenseCategory = () => {
		setName('');

		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'add_expense_category.toast_text'}),
			});
		};

		Util.CommitMutation(
			CreateExpenseCategoryMutation,
			{
				companyId,
				name,
			},
			onSuccess
		);
	};

	return (
		<NewExpenseCategoryStyle>
			<div className="input-row">
				<div data-userpilot={'new-expense-category'}>
					<BasicTextInput
						value={name}
						onChange={handleSetName}
						hasError={error}
						width="250px"
						placeholder={formatMessage({id: 'settings_finance.new_expense_category'})}
						callbackOnEnter={createExpenseCategory.bind(this)}
					/>
				</div>
				<div className="button">
					<AddButton disabled={!canCreate} onClick={canCreate ? createExpenseCategory : undefined} />
				</div>
			</div>
			{error && <div className="error-message">{error}</div>}
		</NewExpenseCategoryStyle>
	);
});
