import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import CreateGithubAttachedObjectMutation from '../../mutations/create_github_attached_object_mutation';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../forecast-app/shared/util/util';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import Search from '../../components/new-ui/search';
import DirectApi from '../../directApi';

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class githubAttachModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: '',
			fetching: false,
			initial: true,
			options: [],
			loading: false,
		};
	}

	UNSAFE_componentWillMount() {
		this.timer = null;
	}

	componentDidMount() {
		this.triggerChange();
	}

	searchChange(value) {
		clearTimeout(this.timer);
		this.setState({value});
		this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
	}

	triggerChange() {
		const {value} = this.state;
		this.setState({fetching: true});

		DirectApi.Fetch(
			`github_search?q=${encodeURIComponent(value)}&project=${atob(this.props.viewer.task.project.id).replace(
				'ProjectType:',
				''
			)}&type=${this.props.type}`
		).then(json => {
			this.setState({
				options: json,
				fetching: false,
				initial: false,
			});
		});
	}

	handleKeyDown(e) {
		if (e.keyCode === ENTER_KEY) {
			this.triggerChange();
		}
	}

	optionSelected(option) {
		if (this.state.loading) {
			return;
		}

		this.setState({loading: true});

		Util.CommitMutation(
			CreateGithubAttachedObjectMutation,
			{
				taskId: this.props.viewer.task.id,
				githubId: option.number.toString(),
				githubRepo: option.repository,
				githubType: this.props.type,
			},
			() =>
				this.setState({
					loading: false,
					options: this.state.options.filter(opt => opt.number !== option.number),
				})
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const arr = this.props.viewer.task.githubAttachedObjects.edges;

		const filtered = this.state.options.filter(
			opt => !arr.find(added => added.node.number === opt.number.toString() && added.node.repo === opt.repository)
		);

		return (
			<div className="github-attach-modal">
				<div className="search-input-wrapper">
					<Search
						onChange={this.searchChange.bind(this)}
						value={this.state.value}
						alwaysExpanded={true}
						startFocused={true}
						placeholder={
							this.props.type === 'pullrequest'
								? formatMessage({id: 'github.search_pull_requests'})
								: formatMessage({id: 'github.search_issues'})
						}
					/>
				</div>
				<div className="search-results">
					<CustomScrollDiv autoHeight autoHeightMin={20} autoHeightMax={'50vh'}>
						<ul className="github-list">
							{this.state.fetching ? <div className="loading" /> : null}
							{!this.state.fetching && filtered.length === 0 && !this.state.initial ? (
								<li className={'github-option' + (this.state.fetching ? ' fetching' : '')}>
									<FormattedMessage id="github.search_no_results" />
								</li>
							) : null}
							{!this.state.fetching &&
								filtered.map((option, i) => (
									<li
										key={i}
										className={
											'github-option' + (this.state.fetching || this.state.loading ? ' fetching' : '')
										}
									>
										<div onClick={this.optionSelected.bind(this, option)}>
											<span className="repository">{option.repository}</span> #{option.number}{' '}
											<span className="repository">{option.title}</span>
										</div>
									</li>
								))}
						</ul>
					</CustomScrollDiv>
				</div>
			</div>
		);
	}
}

const githubAttachModalQuery = graphql`
	query githubAttachModal_Query($id: String) {
		viewer {
			actualPersonId
			component(name: "github_attach_modal")
			...githubAttachModal_viewer @arguments(id: $id)
		}
	}
`;

export {githubAttachModalQuery};

export default createFragmentContainer(injectIntl(githubAttachModal), {
	viewer: graphql`
		fragment githubAttachModal_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
			id
			task(companyTaskId: $id) {
				id
				githubAttachedObjects(first: 1000000) {
					edges {
						node {
							id
							number
							title
							repo
							url
							createdBy
							type
							status
							state
							base
							head
							name
						}
					}
				}
				project {
					id
				}
			}
		}
	`,
});
