import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import jiraUtil from '../../components/jira/jira_util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../directApi';
import styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import ChangeJiraRemainingSyncMutation from '../../mutations/ChangeJiraRemainingSyncMutation';
import UpdateProjectMutationModern from '../../mutations/update_project_mutation.modern';
import {BUTTON_COLOR, PROJECT_STATUS} from '../../constants';
import moment from 'moment';
import {Button, Checkbox, DatePicker, FlexColumn, FlexRow, Link, Scrollbar, Text, Toggle} from '@forecast-it/design-system';
import Subheading from '@forecast-it/design-system/dist/forecast-v2/components/text/Subheading';
import {createFragmentContainer, graphql} from 'react-relay';
import * as PropTypes from 'prop-types';
import Styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

const SyncWarning = styled.div`
	color: red;
	font-size: 12px;
	font-style: italic;
	margin-bottom: 16px;
`;

function JiraError({error}) {
	const {formatMessage} = useIntl();
	const history = useHistory();
	return (
		<>
			<Text noWrap={true}>{error?.createdAt?.substring(0, 10)}</Text>
			<Text>{formatMessage({id: 'integrations.jira.error_code.' + error.errorCode})}</Text>
			{!!error.task && (
				<Link onClick={() => Util.showTaskModal(error.task.companyTaskId, history)}>T{error.task.companyTaskId}</Link>
			)}
		</>
	);
}

const Border = Styled.div`
	border: 1px solid ${props => props.theme.sys.color.border.decorative};
	border-radius: 4px;
	padding: 8px;
	margin-bottom: 8px;
`;

function JiraErrors(props) {
	const {formatMessage} = useIntl();
	const [open, setOpen] = useState(false);
	return (
		<>
			<FlexRow>
				<Text>{formatMessage({id: 'integrations.jira.errors_in_the_last_week'})}:</Text>
				<Text>
					{!!props.jiraErrors.edges.length ? (
						<a onClick={() => setOpen(!open)}>{props.jiraErrors.edges.length}</a>
					) : (
						formatMessage({id: 'common.none'})
					)}
				</Text>
			</FlexRow>
			{!!props.jiraErrors.edges.length && (
				<>
					<FlexRow>
						{open ? (
							<Link onClick={() => setOpen(false)}>{formatMessage({id: 'integrations.jira.hide_errors'})}</Link>
						) : (
							<Link onClick={() => setOpen(true)}>{formatMessage({id: 'integrations.jira.show errors'})}</Link>
						)}
					</FlexRow>

					<FlexColumn>
						{open && (
							<Border>
								<Scrollbar maxHeight="200">
									{props.jiraErrors.edges.map(edge => (
										<FlexRow>
											<JiraError error={edge.node} />
										</FlexRow>
									))}
								</Scrollbar>
							</Border>
						)}
					</FlexColumn>
				</>
			)}
		</>
	);
}

JiraErrors.propTypes = {
	intl: PropTypes.any,
	jiraErrors: PropTypes.any,
	prop2: PropTypes.func,
};
const JiraIntegrationSection = ({project}) => {
	const intl = useIntl();

	const [jiraEpics, setJiraEpics] = useState(null);
	const [syncRemaining, setSyncRemaining] = useState(project.jiraCloudProjectSettings.syncRemaining);
	const [useCutoffDate, setUseCutoffDate] = useState(project.jiraCloudProjectSettings.cutoffDate != null);
	const [cutoffDate, setCutoffDate] = useState(
		project.jiraCloudProjectSettings.cutoffDate ? moment(project.jiraCloudProjectSettings.cutoffDate) : null
	);

	const retrieveLinkedEpics = epicIds => {
		if (!epicIds || epicIds.length === 0) return;
		DirectApi.Fetch(`jira/issues?issueIds=${epicIds}`).then(epics => setJiraEpics(epics));
	};

	useEffect(() => {
		retrieveLinkedEpics(project.jiraCloudEpicIds);
	}, []);

	useEffect(() => {
		setCutoffDate(project.jiraCloudProjectSettings.cutoffDate ? moment(project.jiraCloudProjectSettings.cutoffDate) : null);
		setUseCutoffDate(project.jiraCloudProjectSettings.cutoffDate != null);
	}, [project.jiraCloudProjectSettings.cutoffDate]);

	const handleUnlinkFromJira = () => {
		jiraUtil.unlinkProject(project);
	};

	const editJiraEpics = () => {
		showModal({
			type: MODAL_TYPE.JIRA_EDIT_EPICS,
			projectId: project.id,
			epicIds: project.jiraCloudEpicIds,
			retrieveLinkedEpics,
		});
	};

	const findTopLevelParentsPerChild = (tasks, child, lvl) => {
		return child.parentId
			? findTopLevelParentsPerChild(
					tasks,
					tasks.find(task => task.id === child.parentId),
					lvl + 1
			  )
			: {
					topLevelParent: child.id,
					atLevel: lvl,
			  };
	};

	const showSyncProjectModal = (syncType, subsubtasksPresent) => {
		showModal({
			type: MODAL_TYPE.SYNC_JIRA_PROJECT,
			jiraType: 'cloud',
			syncType,
			forecastProject: project,
			retrieveLinkedEpics,
			jiraProject: project.jiraCloudProject ? project.jiraCloudProject : null,
			edit: !!project.jiraCloudProject && hasFeatureFlag('edit_jira_cloud_project_sync_settings'),
			subsubtasksPresent,
		});
	};

	const syncJiraProject = syncType => {
		if (hasFeatureFlag('limit_task_level_depth')) {
			const tasks = project.tasks.edges.map(taskNode => {
				return {
					id: taskNode.node.id,
					parentId: taskNode.node.parentTaskId,
				};
			});

			//The tasks with level 1 -> task, 2 -> subtask and 3 -> subsubtask
			//filterede down to tasks with levels higher then 2 aka subsubtasks
			const subsubtasks = tasks
				.map(task => {
					return {
						id: task.id,
						...findTopLevelParentsPerChild(tasks, task, 1),
					};
				})
				.filter(task => task.atLevel > 2);

			//If any subsubtask in project
			const subsubtasksPresent = Array.isArray(subsubtasks) && !!subsubtasks.length;
			showSyncProjectModal(syncType, subsubtasksPresent);
		} else {
			showSyncProjectModal(syncType, false);
		}
	};

	const selectSyncRemainingTime = sync => {
		if (syncRemaining !== sync) {
			showModal({
				type: MODAL_TYPE.WARNING,
				message: 'Are you sure you want to change the "Synchronize remaining time" setting?',
				warningInformation: [
					<div>Changing this setting will initiate a sync of the project with the new settings.</div>,
				],
				buttons: [
					{
						text: <FormattedMessage id="common.cancel" />,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: <FormattedMessage id="common.confirm" />,
						callback: () => {
							setSyncRemaining(sync);
							const mutationObject = {
								forecastProjectId: project.id,
								syncRemaining: sync,
							};
							Util.CommitMutation(ChangeJiraRemainingSyncMutation, mutationObject);
						},
					},
				],
			});
		}
	};

	const projectDoneOrHalted = project.status === PROJECT_STATUS.DONE || project.status === PROJECT_STATUS.HALTED;

	const setCutoffDateHandler = cutoffDate => {
		Util.CommitMutation(UpdateProjectMutationModern, {
			id: project.id,
			jiraCutoffDate: cutoffDate,
		});
	};

	const setUseCutoffDateHandler = () => {
		if (useCutoffDate) {
			if (cutoffDate) {
				showModal({
					type: MODAL_TYPE.WARNING,
					message: intl.formatMessage({id: 'integrations.jira.cutoff_date_warning_title'}),
					warningInformation: [
						<div>{intl.formatMessage({id: 'integrations.jira.cutoff_date_warning_message'})}</div>,
					],
					buttons: [
						{
							text: <FormattedMessage id="common.cancel" />,
							color: BUTTON_COLOR.WHITE,
						},
						{
							text: <FormattedMessage id="common.confirm" />,
							callback: () => {
								setCutoffDateHandler(null);
							},
						},
					],
				});
			} else {
				setUseCutoffDate(false);
			}
		} else {
			setUseCutoffDate(true);
		}
	};

	return (
		<div>
			{project.jiraCloudProject || project.jiraCloudEpicIds ? (
				<FlexColumn gap={'s'}>
					{!project.jiraCloudProjectSettings.isSyncDone && (
						<SyncWarning>
							<Text>{intl.formatMessage({id: 'integrations.jira.sync_active_message'})}</Text>
						</SyncWarning>
					)}
					{project.jiraCloudProject ? (
						<>
							<FlexRow>
								<Text>
									<FormattedMessage
										id="settings.jira.linked_to_project"
										values={{name: <b>{project.jiraCloudProject.name}</b>}}
									/>
								</Text>
							</FlexRow>
							{!hasFeatureFlag('edit_jira_cloud_project_sync_settings') && (
								<FlexColumn>
									<FlexRow>
										<Text>
											<FormattedMessage id="integrations.jira.sync_remaining" />:
										</Text>
										<Toggle
											checked={syncRemaining}
											onChange={() => selectSyncRemainingTime(!syncRemaining)}
											disabled={projectDoneOrHalted || !project.jiraCloudProjectSettings.isSyncDone}
										/>
									</FlexRow>
								</FlexColumn>
							)}
						</>
					) : (
						<FormattedMessage
							id="settings.jira.linked_to_epics"
							values={{
								name: (
									<b>
										{jiraEpics && jiraEpics.length > 0
											? jiraEpics.map(epic => epic.key).join(', ')
											: 'Loading...'}
									</b>
								),
							}}
						/>
					)}
					{project.jiraCloudEpicIds ? (
						<>
							<Button
								onPress={() => editJiraEpics()}
								disabled={projectDoneOrHalted || !project.jiraCloudProjectSettings.isSyncDone}
								title={
									projectDoneOrHalted
										? intl.formatMessage({id: 'integrations.jira.link_epic_done_error'})
										: intl.formatMessage({id: 'integrations.jira.edit_epics'})
								}
							>
								{intl.formatMessage({id: 'integrations.jira.edit_epics'})}
							</Button>
							<br />
						</>
					) : (
						<>
							{hasFeatureFlag('edit_jira_cloud_project_sync_settings') && (
								<Button onPress={() => syncJiraProject('project')}>
									{intl.formatMessage({id: 'common.edit'})}
								</Button>
							)}
						</>
					)}

					<FlexColumn>
						<Text>{intl.formatMessage({id: 'integrations.jira.cutoff_date_label'})}:</Text>

						<FlexRow>
							<Checkbox
								checked={useCutoffDate}
								onChange={setUseCutoffDateHandler}
								disabled={!project.jiraCloudProjectSettings.isSyncDone}
							/>

							<DatePicker
								value={project.jiraCloudProjectSettings.cutoffDate}
								onChange={setCutoffDateHandler}
								disabled={!useCutoffDate || !project.jiraCloudProjectSettings.isSyncDone}
							/>
						</FlexRow>
					</FlexColumn>

					<br />
					<FlexRow>
						<Subheading>{intl.formatMessage({id: 'integrations.jira.locked_integration_settings'})}:</Subheading>
					</FlexRow>

					<FlexRow>
						<Text>{intl.formatMessage({id: 'sync_jira_project_modal.synchronize_sprints'})}:</Text>
						<Text>
							{!!project.jiraCloudProjectSettings.syncSprints
								? intl.formatMessage({id: 'common.yes'})
								: intl.formatMessage({id: 'common.no'})}
						</Text>
					</FlexRow>

					<FlexRow>
						<Text>{intl.formatMessage({id: 'integrations.jira.estimate_type'})}:</Text>
						<Text>
							{!!project.jiraCloudProjectSettings.estimateInTime
								? intl.formatMessage({id: 'integrations.jira.time_original_estimate'})
								: `${intl.formatMessage({id: 'integrations.jira.story_points'})} (${
										project.jiraCloudProjectSettings.estimateInPointsCustomFieldName
								  })`}
						</Text>
					</FlexRow>
					<FlexRow>
						<Text>{intl.formatMessage({id: 'integrations.jira.phases_synced_as'})}:</Text>
						<Text>
							{!!project.jiraCloudProjectSettings.mapJiraVersionsToPhases
								? intl.formatMessage({id: 'sync_jira_project_modal.versions'})
								: intl.formatMessage({id: 'sync_jira_project_modal.epics'})}
						</Text>
					</FlexRow>
					<FlexRow>
						<Text>{intl.formatMessage({id: 'integrations.jira.subtasks_synced_as'})}:</Text>
						<Text>
							{!!project.jiraCloudProjectSettings.mapJiraSubTasksToToDo
								? intl.formatMessage({id: 'common.todo_items'})
								: project.useTaskHierarchy
								? intl.formatMessage({id: 'common.sub_tasks'})
								: intl.formatMessage({id: 'common.tasks'})}
						</Text>
					</FlexRow>
					{hasFeatureFlag('jira_errors_in_settings') && <JiraErrors jiraErrors={project.jiraErrors} />}
					<br />
					<Button
						onPress={() => handleUnlinkFromJira()}
						disabled={!project.jiraCloudProjectSettings.isSyncDone}
						title={
							!project.jiraCloudProjectSettings.isSyncDone
								? intl.formatMessage({id: 'integrations.jira.cannot_unlink_while_sync'})
								: undefined
						}
					>
						{intl.formatMessage({id: 'settings.unlink_jira'})}
					</Button>
				</FlexColumn>
			) : (
				<>
					<Button onPress={() => syncJiraProject('project')}>
						{intl.formatMessage({id: 'integrations.jira.sync_to_jira_project'})}
					</Button>
					<br />
					<Button onPress={() => syncJiraProject('epics')}>
						{intl.formatMessage({id: 'integrations.jira.sync_to_jira_epics'})}
					</Button>
				</>
			)}
		</div>
	);
};

export default createFragmentContainer(JiraIntegrationSection, {
	project: graphql`
		fragment JiraIntegrationSection_project on ProjectType {
			id
			name
			jiraCloudProjectSettings {
				syncRemaining
				cutoffDate
				isSyncDone
				syncSprints
				estimateInTime
				estimateInPointsCustomFieldName
				mapJiraSubTasksToToDo
				mapJiraVersionsToPhases
			}
			jiraCloudEpicIds
			jiraCloudProject {
				id
				name
				style
			}
			jiraErrors {
				edges {
					node {
						createdAt
						errorCode
						task {
							id
							companyTaskId
						}
						timeRegId
					}
				}
			}
			tasks(first: 100000) {
				edges {
					node {
						id
						parentTaskId
					}
				}
			}
			useTaskHierarchy
			status
		}
	`,
});
